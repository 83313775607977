import axios from "axios";

class WithdrawAPI {
  withdrawBank(
    token,
    portfolioName,
    currency,
    amount,
    accountType,
    accountNumber,
    bankID,
    note,
    code
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_name: portfolioName,
      currency: currency,
      amount: amount,
      account_type: accountType,
      account_number: accountNumber,
      bank_id: Number(bankID),
      note: note,
      code: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/simple_funds_out_bank_with_2fa",
      requestBody,
      headers
    );
  }

  withdrawCash(token, portfolioName, currency, amount, note, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_name: portfolioName,
      currency: currency,
      amount: amount,
      note: note,
      code: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/simple_funds_out_cash_with_2fa",
      requestBody,
      headers
    );
  }

  withdrawCrypto(
    token,
    porfolioId,
    walletAddress,
    cryptoToken,
    network,
    amount,
    code
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_id: porfolioId,
      target_wallet_address: walletAddress,
      token: cryptoToken,
      blockchain: network,
      qty: Number(amount),
      code: code,
    };

    var endpoint = "/wallets/withdraw_with_2fa";

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + endpoint,
      requestBody,
      headers
    );
  }
}

export const withdrawAPI = new WithdrawAPI();
