import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TransactionsService from "./TransactionsService.jsx";
import { FaSearchMinus, FaPrint } from "react-icons/fa";
import { LuPackageSearch } from "react-icons/lu";
import { IoMdDownload } from "react-icons/io";
import { utils } from "../../utils/utils.jsx";
import Accordion from "react-bootstrap/Accordion";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ReceiptComponent from "../receipt/Receipt.jsx";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import PrintPDFComponent from "../printPDF/PrintPDF.jsx";

class Portfolio extends Component {
  state = {
    isMobileWidth: false,

    listTransactions: [],
    listTransactionsShowed: [],

    showModalTransactionDetails: false,

    selectedDivIndex: null,
    selectedTransaction: {},

    loadingTransactions: true,
    loadingDownload: false,
  };

  service = new TransactionsService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.service.getTransactionHistory(
      this.props.token,
      this.setState.bind(this)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchTerm !== prevProps.searchTerm) {
      this.editSearchTermTransactions(this.props.searchTerm);
    }
  }

  componentWillUnmount() {}

  getTitle = (card) => {
    if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === null
    ) {
      return this.props.localizedText["deposit"];
    } else if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === "RECEIVED_FROM_PEER"
    ) {
      return this.props.localizedText["received-from-peer"];
    } else if (
      card["transaction_type"] === "HOLD" &&
      card["transaction_subtype"] === null
    ) {
      return this.props.localizedText["hold"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === null
    ) {
      return this.props.localizedText["withdraw"];
    } else if (
      (card["transaction_type"] === "WITHDRAW" ||
        card["transaction_type"] === "HOLD") &&
      card["transaction_subtype"] === "PAY_VENDOR"
    ) {
      return this.props.localizedText[
        utils.getWhiteLabelText("pay-vendor", "vendor")
      ];
    } else if (
      (card["transaction_type"] === "WITHDRAW" ||
        card["transaction_type"] === "HOLD") &&
      card["transaction_subtype"] === "SEND_TO_PEER"
    ) {
      return this.props.localizedText["send-to-peer"];
    } else if (card["transaction_type"] === "BUY") {
      return this.props.localizedText["buy"];
    } else if (card["transaction_type"] === "SELL") {
      return this.props.localizedText["sell"];
    } else if (card["transaction_type"] === "CONVERSION_DEPOSIT_HOLD") {
      return this.props.localizedText["hold-conversion"];
    } else if (card["transaction_type"] === "PAYME_DEPOSIT_HOLD") {
      return this.props.localizedText["hold-deposit-pay-me"];
    } else if (card["transaction_type"] === "CONVERSION_DEPOSIT") {
      return this.props.localizedText["conversion-completed"];
    } else {
      return card["transaction_type"];
    }
  };

  getDescription = (card) => {
    if (
      (card["transaction_type"] === "WITHDRAW" &&
        card["transaction_subtype"] === null) ||
      (card["transaction_type"] === "HOLD" &&
        card["transaction_subtype"] === null)
    ) {
      return this.props.localizedText["withdraw-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === "SEND_TO_PEER"
    ) {
      return this.props.localizedText["sent-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === "PAY_VENDOR"
    ) {
      return this.props.localizedText["paid-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "BUY" &&
      card["transaction_subtype"] === null
    ) {
      return (
        this.props.localizedText["buy-"] +
        card["qty"] +
        card["base_currency"] +
        this.props.localizedText["-at-"] +
        card["price"]
      );
    } else if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === "RECEIVED_FROM_PEER"
    ) {
      return this.props.localizedText["received-from-"] + card["from"];
    } else if (
      card["transaction_type"] === "SELL" &&
      card["transaction_subtype"] === null
    ) {
      return (
        this.props.localizedText["sell-"] +
        card["qty"] +
        card["base_currency"] +
        this.props.localizedText["-at-"] +
        card["price"]
      );
    } else {
      return card["description"];
    }
  };

  getStatus = (card) => {
    if (
      card["transaction_type"] === "HOLD" ||
      card["transaction_type"] === "CONVERSION_DEPOSIT_HOLD" ||
      card["transaction_type"] === "PAYME_DEPOSIT_HOLD"
    ) {
      return this.props.localizedText["processing"];
    } else {
      return this.props.localizedText["completed"];
    }
  };

  toMonthString = (monthKey) => {
    var monthName = "";

    if (monthKey === "01") {
      monthName = this.props.localizedText["january"];
    } else if (monthKey === "02") {
      monthName = this.props.localizedText["february"];
    } else if (monthKey === "03") {
      monthName = this.props.localizedText["march"];
    } else if (monthKey === "04") {
      monthName = this.props.localizedText["april"];
    } else if (monthKey === "05") {
      monthName = this.props.localizedText["may"];
    } else if (monthKey === "06") {
      monthName = this.props.localizedText["june"];
    } else if (monthKey === "07") {
      monthName = this.props.localizedText["july"];
    } else if (monthKey === "08") {
      monthName = this.props.localizedText["august"];
    } else if (monthKey === "09") {
      monthName = this.props.localizedText["september"];
    } else if (monthKey === "10") {
      monthName = this.props.localizedText["october"];
    } else if (monthKey === "11") {
      monthName = this.props.localizedText["november"];
    } else if (monthKey === "12") {
      monthName = this.props.localizedText["december"];
    }

    return monthName;
  };

  filterFunction = (objects, value) => {
    var filteredObjects = [];
    var lowerCaseName = "";
    var flagMatches = false;
    for (const i in objects) {
      flagMatches = false;

      lowerCaseName = objects[i]["data"]["base_currency"].toLowerCase();
      if (lowerCaseName.includes(value.toLowerCase())) {
        flagMatches = true;
      }

      if (flagMatches) {
        filteredObjects.push(objects[i]);
      }
    }

    return filteredObjects;
  };

  skeletonCards = ({ cards }) => (
    <div class="div-cards">
      {cards.map(() => (
        <div class="card-asset content-container">
          <div class="metadata-column-skeleton">
            <div class=" skeleton-box image-skeleton"></div>
            <div class="text-skeleton">
              <div class="skeleton-box title-skeleton"></div>
              <div class="skeleton-box abbreviation-skeleton"></div>
            </div>
          </div>
          <div class="skeleton-box  graph-column-skeleton"></div>
          <div class="balance-column-skeleton">
            <div class="skeleton-box value-skeleton"></div>
            <div class="skeleton-box percentage-skeleton"></div>
          </div>
        </div>
      ))}
    </div>
  );

  openTransactionDetails = (payment, index) => {
    this.setState({
      selectedDivIndex: index,
      selectedTransaction: payment,
      showModalTransactionDetails: true,
    });
  };

  handleCloseTransactionDetails = () => {
    this.setState({
      selectedDivIndex: null,
      showModalTransactionDetails: false,
    });
  };

  getReceiptProps = () => {
    let data = [
      {
        title: this.props.localizedText["transfer-summary"],
        data: [
          {
            name: this.props.localizedText["transaction-id"],
            value: this.state.selectedTransaction.transaction_id
              ? this.state.selectedTransaction.transaction_id
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["transaction-type"],
            value: this.state.selectedTransaction.transaction_type
              ? this.state.selectedTransaction.transaction_type
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["transaction-sub-type"],
            value: this.state.selectedTransaction.transaction_subtype
              ? this.state.selectedTransaction.transaction_subtype
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["date"],
            value: this.state.selectedTransaction.creation_time
              ? this.state.selectedTransaction.creation_time
              : "-",
            type: "date",
          },
          {
            name: this.props.localizedText["from"],
            value: this.state.selectedTransaction.from
              ? this.state.selectedTransaction.from
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["to"],
            value: this.state.selectedTransaction.to
              ? this.state.selectedTransaction.to
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["description"],
            value: this.state.selectedTransaction.description
              ? this.state.selectedTransaction.description
              : "-",
            type: "normal",
          },

          {
            name: this.props.localizedText["fee-amount"],
            value: this.state.selectedTransaction.fee_amount
              ? Math.abs(this.state.selectedTransaction.fee_amount)
              : "-",
            type: "amount",
          },

          {
            currency: this.state.selectedTransaction.base_currency,
            name: this.props.localizedText["amount"],
            value: Math.abs(this.state.selectedTransaction.qty),
            type: "amount",
          },
          {
            currency: this.state.selectedTransaction.base_currency,
            name: this.props.localizedText["total-amount"],
            value: this.state.selectedTransaction.fee_amount
              ? Math.abs(Number(this.state.selectedTransaction.qty)) +
                Math.abs(Number(this.state.selectedTransaction.fee_amount))
              : Math.abs(this.state.selectedTransaction.qty),
            type: "amount",
          },
        ],
      },
    ];

    return data;
  };

  paymentsHistory = () => (
    <div class="transaction-history-div">
      {this.state.listTransactions.length > 0 &&
      this.state.listTransactionsShowed.length > 0 ? (
        <div>
          {this.state.listTransactionsShowed.map((month, index) => (
            <Accordion
              defaultActiveKey={["0"]}
              className="accordion-transaction-history"
              alwaysOpen
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {Object.keys(month)[0].slice(3, 7) +
                    " - " +
                    this.toMonthString(Object.keys(month)[0].slice(0, 2))}
                </Accordion.Header>
                <Accordion.Body>
                  <div class="div-transaction-cards">
                    {month[Object.keys(month)[0]].map((card) => (
                      <div
                        class={
                          this.state.selectedDivIndex === card.transaction_id
                            ? "card-portfolio selected-card content-container"
                            : "card-portfolio content-container"
                        }
                        onClick={this.openTransactionDetails.bind(
                          this,
                          card,
                          card.transaction_id
                        )}
                      >
                        <div class="metadata-column">
                          <div class="text">
                            <div class="title">{this.getTitle(card)}</div>
                            <div class="date">
                              {utils.formatDateUSWithTime(
                                card["creation_time"]
                              )}
                            </div>
                          </div>
                        </div>

                        <div class="status-column">{this.getStatus(card)}</div>

                        <div class="description-column">
                          {this.getDescription(card)}
                        </div>

                        <div class="amount-column">
                          <div
                            class={
                              card["qty"] > 0
                                ? "amount"
                                : "amount amount-danger"
                            }
                          >
                            {utils.roundDecimals(
                              card["base_currency"],
                              card["fee_amount"]
                                ? Number(card["fee_amount"]) +
                                    Number(card["qty"])
                                : Number(card["qty"])
                            )}
                            &nbsp;
                            {card["base_currency"]}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </div>
      ) : null}
      {this.state.listTransactions.length > 0 &&
      this.state.listTransactionsShowed.length == 0 ? (
        <div class="no-list-div">
          <FaSearchMinus className="empty-icon" />
          <div class="title">{this.props.localizedText["no-result-found"]}</div>
          <div class="sub-title">
            {
              this.props.localizedText[
                "we-couldn't-find-any-items-matching-your-search"
              ]
            }
          </div>
        </div>
      ) : null}
      {this.state.listTransactions.length == 0 &&
      this.state.listTransactionsShowed.length == 0 ? (
        <div class="no-list-div">
          <LuPackageSearch className="empty-icon" />
          <div class="title">{this.props.localizedText["no-payments-yet"]}</div>
          <div class="sub-title">
            {this.props.localizedText["make-a-payment-to-visualize"]}
          </div>
        </div>
      ) : null}
    </div>
  );

  downloadPDF = () => {
    this.setState(
      {
        loadingDownload: true,
      },
      () => {
        utils.generatePDFFromReceipt(
          this.state.selectedTransaction.transaction_type +
            "-" +
            this.state.selectedTransaction.transaction_id,
          this.setState.bind(this)
        );
      }
    );
  };

  editSearchTermTransactions = (value) => {
    console.log("VALUE", value);
    if (value != null && value != "") {
      var filteredTransactions = [];
      for (var i = 0; i < this.state.listTransactions.length; i++) {
        const filteredDayTransactions = utils.filterFunction(
          this.state.listTransactions[i][
            Object.keys(this.state.listTransactions[i])[0]
          ],
          ["transaction_id", "transaction_type", "transaction_subtype"],
          value
        );
        if (filteredDayTransactions.length > 0) {
          filteredTransactions.push({
            [Object.keys(this.state.listTransactions[i])[0]]: [
              ...filteredDayTransactions,
            ],
          });
        }
      }
      this.setState({
        listTransactionsShowed: [...filteredTransactions],
      });
    } else {
      this.setState({
        listTransactionsShowed: this.state.listTransactions,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div>
          {!this.state.loadingTransactions ? (
            <this.paymentsHistory></this.paymentsHistory>
          ) : (
            <div>
              <div class="skeleton-card-seperator"></div>
              <this.skeletonCards
                cards={[1, 2, 3, 4, 5, 6]}
              ></this.skeletonCards>
            </div>
          )}
        </div>

        <Modal
          show={this.state.showModalTransactionDetails}
          onHide={this.handleCloseTransactionDetails}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["transaction-details"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="detail-container transaction-history-detail">
              {Object.keys(this.state.selectedTransaction).length > 0 ? (
                <ReceiptComponent
                  data={this.getReceiptProps()}
                ></ReceiptComponent>
              ) : null}
              <div class="icons-row">
                {this.state.loadingDownload ? (
                  <div class="loader-icon">
                    <Spinner className="loader-download left-icon"></Spinner>
                  </div>
                ) : (
                  <IoMdDownload class="left-icon" onClick={this.downloadPDF} />
                )}

                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <FaPrint class="right-icon" onClick={handlePrint} />
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>{" "}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div class="payment-detail-footer">
              {this.props.localizedText["if-you-need-help"]}
              <br />
              {this.props.localizedText["go-to-our-"]}
              <a href="https://www.blox.global/contact/">
                {this.props.localizedText["resolution-center"]}
              </a>{" "}
              {
                this.props.localizedText[
                  "for-help-with-this-transaction,-to-settle-a-dispute-or-open-a-claim"
                ]
              }
            </div>
          </Modal.Footer>
        </Modal>

        <div id="hidden-div" style={{ display: "none" }}>
          <div
            class="detail-container-print"
            ref={(el) => (this.componentRef = el)}
            id="pdf"
          >
            {Object.keys(this.state.selectedTransaction).length > 0 ? (
              <PrintPDFComponent
                theme={this.props.theme}
                title={this.props.localizedText["transaction-details"]}
                receiptData={this.getReceiptProps()}
                selectedTransaction={this.state.selectedTransaction}
                text={this.props.localizedText["powered-by-blox"]}
                localizedText={this.props.localizedText}
              ></PrintPDFComponent>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Portfolio);
