import React, { useState } from "react";
import "./sortInput.css";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

export default function SortInputComponent(props) {
  return props.listOptions.length > 0 ? (
    <div class="sort-input-div">
      <InputGroup className="input-field sort-input">
        <Form.Select
          variant="outline-secondary"
          onChange={(e) => {
            props.handleChange(e);
          }}
          style={{
            width: "100%",
            flex: "0 1 auto",
            textAlign: "start",
          }}
        >
          {props.listOptions.map((option) => (
            <option value={option.value}>{option.name}</option>
          ))}
        </Form.Select>
      </InputGroup>
    </div>
  ) : null;
}
