import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./insideResult.css";
import Result from "../../../components/result/Result";
import { utils } from "../../../utils/utils";

class InsideResult extends Component {
  state = {
    isMobileWidth: false,
  };

  componentDidMount() {
    if (!this.props.match.params.success && !this.props.match.params.type) {
      utils.goToURL(this.props, "/dashboard");
    }

    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  }

  componentDidUpdate(prevProps, prevState) {}

  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 1200) {
      flag = true;
    } else {
      flag = false;
    }

    this.setState({
      isMobileWidth: flag,
    });
  };

  getResultMessage = () => {
    if (this.props.match.params.type == "deposit") {
      if (this.props.match.params.success === "true") {
        return this.props.localizedText["your-deposit-was-successful"];
      } else {
        return this.props.localizedText[
          "unfortunately,-we-have-encountered-a-problem-while-trying-to-make-your-deposit"
        ];
      }
    } else if (this.props.match.params.type == "delete-vendor") {
      if (this.props.match.params.success === "true") {
        return this.props.localizedText[
          utils.getWhiteLabelText(
            "the-vendor-was-deleted-successfully",
            "vendor"
          )
        ];
      } else {
        return this.props.localizedText[
          utils.getWhiteLabelText(
            "unfortunately,-we-have-encountered-a-problem-while-trying-to-delete-the-vendor",
            "vendor"
          )
        ];
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {" "}
        <Result
          success={this.props.match.params.success === "true"}
          secondButton={false}
          title={
            this.props.localizedText[
              utils.getResultTitle(this.props.match.params.success === "true")
            ]
          }
          message={this.getResultMessage()}
          primaryButtonText={this.props.localizedText["continue"]}
          primaryButtonFunction={utils.goToURL.bind(
            this,
            this.props,
            this.props.location.megastate &&
              this.props.location.megastate.returnURL
              ? this.props.location.megastate.returnURL
              : "/dashboard"
          )}
        ></Result>
      </React.Fragment>
    );
  }
}

export default withRouter(InsideResult);
