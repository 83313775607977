import axios from "axios";

class PayMeAPI {
  getPaymentMethods(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL +
        "/user-key-service/plugin-payment/payment-methods",
      headers
    );
  }

  getAvailableCryptos(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL +
        "/user-key-service/plugin-payment/available-cryptos",
      headers
    );
  }

  getCustomFields(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL +
        "/user-key-service/plugin-payment/custom-fields",
      headers
    );
  }

  getPlugins(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/user-key-service/plugin-payment",
      headers
    );
  }

  getPluginById(token, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL +
        "/user-key-service/plugin-payment/" +
        id,
      headers
    );
  }

  getPayments(token, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL +
        "/credit-card-service/my-payments/plugin/" +
        id,
      headers
    );
  }

  createPlugin(
    token,
    description,
    paymentMethods,
    cryptosAccepted,
    htmlSchema,
    fields,
    invoiceAfterPayment
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      description,
      paymentMethods,
      cryptosAccepted,
      htmlSchema,
      originUrl: window.location.hostname,
      fields,
      invoiceAfterPayment,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/user-key-service/plugin-payment",
      body,
      headers
    );
  }

  updatePlugin(
    token,
    id,
    description,
    paymentMethods,
    cryptosAccepted,
    htmlSchema,
    fields,
    invoiceAfterPayment
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      description,
      paymentMethods,
      cryptosAccepted,
      htmlSchema,
      fields,
      invoiceAfterPayment,
    };

    return axios.put(
      process.env.REACT_APP_BASE_API_URL +
        "/user-key-service/plugin-payment/" +
        id,
      body,
      headers
    );
  }

  disablePlugin(token, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.delete(
      process.env.REACT_APP_BASE_API_URL +
        "/user-key-service/plugin-payment/disable/" +
        id,
      headers
    );
  }

  enablePlugin(token, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.put(
      process.env.REACT_APP_BASE_API_URL +
        "/user-key-service/plugin-payment/enable/" +
        id,
      body,
      headers
    );
  }

  deletePlugin(token, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.delete(
      process.env.REACT_APP_BASE_API_URL +
        "/user-key-service/plugin-payment/" +
        id,
      headers
    );
  }

  confirmPayment(token, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.put(
      process.env.REACT_APP_BASE_API_URL +
        "credit-card-service/my-payments/" +
        id +
        "/confirm",
      body,
      headers
    );
  }

  cancelPayment(token, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.put(
      process.env.REACT_APP_BASE_API_URL +
        "credit-card-service/my-payments/" +
        id +
        "/cancel",
      body,
      headers
    );
  }

  getPayMeCurrencies(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL +
        "/credit-card-service/request-payment/crypto/available",
      headers
    );
  }
}

export const payMeAPI = new PayMeAPI();
