import { walletAPI } from "../../../apis/WalletAPI.jsx";
import { depositAPI } from "../../../apis/DepositAPI.jsx";

export default class PayMeCryptoService {
  checkoutUrlCrypto(
    token,
    crypto,
    network,
    amount,
    payerEmail,
    payerName,
    setState,
    setMessages,
    goToResultStage
  ) {
    depositAPI
      .checkoutUrlCrypto(token, crypto, network, amount, payerEmail, payerName)
      .then((response) => {
        if (response.status === 200 && response.data.length > 0) {
          console.log("checkoutUrl success", response);
          setState({ loadingSent: false });
          goToResultStage(
            true,
            "a-pay-link-has-been-sent-to-your-email-address"
          );
        } else {
          console.log("checkoutUrl failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-send-the-pay-link"
          );
          setState({ loadingSent: false });
        }
      })
      .catch((error) => {
        console.log("checkoutUrl error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-send-the-pay-link");
        setState({ loadingSent: false });
      });
  }

  getNetworksMockResponse() {
    return '[ { "coin": "BTC", "blockchain": "btc", "native_token": false, "description": "Bitcoin Blockchain ", "wallet_direction": "INBOUND" }, { "coin": "USDC", "blockchain": "trx", "native_token": false, "description": "Tron Blockchain ", "wallet_direction": "INBOUND" }, { "coin": "USDC", "blockchain": "eth", "native_token": false, "description": "Ethereum Blockchain ", "wallet_direction": "INBOUND" } ]';
  }
}
