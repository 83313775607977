import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { withRouter } from "react-router-dom";
import "./passCode.css";
import PassCodeService from "./passCodeService";
import ReactCodeInput from "react-code-input";
import Footer from "../../components/footer/Footer.jsx";
import ThemeRegionDiv from "../../components/theme-region-div/ThemeRegionDiv.jsx";
import ErrorMessageComponent from "../../components/errorMessage/ErrorMessage.jsx";
import CryptoJS from "crypto-js";
import { FaLock } from "react-icons/fa";
import { utils } from "../../utils/utils";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

class PassCode extends Component {
  state = {
    isMobileWidth: false,
    message: "",

    code: "",
    reEnterCode: "",

    token: "",
    account: {},

    selectedInactivityTime: {},
    listInactivityTime: [
      {
        name: "5-seconds",
        value: 0.085,
        prod: false,
      },
      {
        name: "1-minute",
        value: 1,
        prod: true,
      },
      {
        name: "3-minutes",
        value: 3,
        prod: true,
      },
      {
        name: "5-minutes",
        value: 5,
        prod: true,
      },
      {
        name: "10-minutes",
        value: 10,
        prod: true,
      },
      {
        name: "1-day-dev-only",
        value: 1440,
        prod: false,
      },
    ],

    invalidCode: false,
    invalidInactivityTime: false,

    //for rerendering and clearing
    inputKey: "",

    stageType: "",
    validationForAuth: false,

    decrypting: false,

    loadingSent: false,

    errorMessageType: "",
    encryptedTimeKey: "",
    previousStage: "",
  };

  service = new PassCodeService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.megastate &&
      this.props.location.megastate.type
    ) {
      this.setState(
        {
          stageType: this.props.location.megastate.type,
        },
        () => {
          this.setState({
            token: this.props.location.megastate.token
              ? this.props.location.megastate.token
              : "",
            account: this.props.location.megastate.account
              ? this.props.location.megastate.account
              : {},
          });
          if (
            this.state.stageType == "lockedEnterPassCode" &&
            Object.keys(localStorage).length == 0
          ) {
            utils.goToURL(this.props, "/");
          }
        }
      );
    } else if (Object.keys(localStorage).length >= 4) {
      this.setState({
        stageType: "lockedEnterPassCode",
      });
    } else if (
      !this.props.location ||
      !this.props.location.megastate ||
      !this.props.location.megastate.token
    ) {
      utils.goToURL(this.props, "/");
    }

    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  }

  componentDidUpdate(prevProps, prevState) {}

  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 1200) {
      flag = true;
    } else {
      flag = false;
    }

    this.setState({
      isMobileWidth: flag,
    });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  handleCodeChange = (code) => {
    this.setState(
      {
        code: code,
      },
      () => {
        if (
          this.state.code.length == 6 &&
          Object.keys(this.state.selectedInactivityTime).length > 0
        ) {
          this.doRegisterPassCode();
        }
      }
    );
  };

  handleSelectedInactivityTimeChange = (selectedInactivityTime) => {
    this.setState(
      {
        selectedInactivityTime: selectedInactivityTime,
      },
      () => {
        if (
          this.state.code.length == 6 &&
          Object.keys(this.state.selectedInactivityTime).length > 0 &&
          this.state.stageType != "changePassCodeCodeIdleTimeout"
        ) {
          this.doRegisterPassCode();
        } else if (
          this.state.code.length == 6 &&
          Object.keys(this.state.selectedInactivityTime).length > 0 &&
          this.state.stageType == "changePassCodeCodeIdleTimeout"
        ) {
          this.doChangeIdleTimeout();
        }
      }
    );
  };

  doRegisterPassCode = () => {
    console.log("this.state.code", this.state.code);
    if (
      this.state.code.length == 6 &&
      Object.keys(this.state.selectedInactivityTime).length > 0
    ) {
      console.log("this.state.selected", this.state.selectedInactivityTime);
      this.moveStage("reEnterPassCode", "registerPassCode");
    } else {
      this.setMessages("*one-or-more-required-field(s)-are-missing");
      this.setState({
        invalidCode: this.state.code.length != 6,
        invalidInactivityTime:
          Object.keys(this.state.selectedInactivityTime).length == 0,
      });
    }
  };

  handleReEnterCodeChange = (code) => {
    this.setState(
      {
        reEnterCode: code,
      },
      () => {
        if (
          this.state.reEnterCode.length == 6 &&
          this.state.stageType == "reEnterPassCode"
        ) {
          this.doRenterPassCode();
        }
      }
    );
  };

  doRenterPassCode = () => {
    if (this.state.reEnterCode == this.state.code) {
      const encryptedJWTKey = CryptoJS.AES.encrypt(
        "token",
        this.state.code
      ).toString();
      const encryptedJWT = CryptoJS.AES.encrypt(
        this.state.token,
        this.state.code
      ).toString();
      const encryptedAccountKey = CryptoJS.AES.encrypt(
        "account",
        this.state.code
      ).toString();
      console.log("ACCOUNT BEING SAVED", this.state.account);
      const encryptedAccountValue = CryptoJS.AES.encrypt(
        JSON.stringify(this.state.account),
        this.state.code
      ).toString();
      const encryptedTimeKey = CryptoJS.AES.encrypt(
        "time",
        this.state.code
      ).toString();
      const encryptedTime = CryptoJS.AES.encrypt(
        this.state.selectedInactivityTime,
        this.state.code
      ).toString();
      const fakeJWTKey = CryptoJS.AES.encrypt(
        "fakeToken",
        this.state.code
      ).toString();
      const fakeJWT = CryptoJS.AES.encrypt(
        "ABC12345ABC12345ABC12345",
        this.state.code
      ).toString();

      this.props.setInactivityTime(this.state.selectedInactivityTime);

      localStorage.setItem(encryptedJWTKey, encryptedJWT);
      localStorage.setItem(encryptedAccountKey, encryptedAccountValue);
      localStorage.setItem(encryptedTimeKey, encryptedTime);
      localStorage.setItem(fakeJWTKey, fakeJWT);

      utils.goToURL(this.props, "/dashboard");
    } else {
      this.setMessages("*pass-codes-don't-match");
      this.setState({
        invalidCode: true,
      });
    }
  };

  handleLockedCodeChange = (code) => {
    this.setState(
      {
        code: code,
      },
      () => {
        console.log("code", this.state.code);
        console.log("decrypting", this.state.decrypting);
        if (this.state.code.length == 6 && !this.state.decrypting) {
          this.doLockedCodeChange(code);
        }
      }
    );
  };

  doLockedCodeChange = () => {
    if (this.state.code.length == 6 && !this.state.decrypting) {
      this.setState(
        {
          decrypting: true,
          loadingSent: true,
        },
        () => {
          const keys = Object.keys(localStorage);
          let token = "";
          let time = "";
          let account = "";
          for (let key of keys) {
            const decryptedKey = utils.decrypt(key, this.state.code);
            const encryptedValue = localStorage.getItem(key);
            const decryptedValue = utils.decrypt(
              encryptedValue,
              this.state.code
            );
            if (decryptedValue && decryptedKey === "token") {
              token = decryptedValue;
            } else if (decryptedValue && decryptedKey === "time") {
              time = decryptedValue;
            } else if (decryptedValue && decryptedKey === "account") {
              account = decryptedValue;
            }
          }
          if (account != "" && token != "" && time != "") {
            this.service.getClientConfig(
              JSON.parse(account),
              token,
              time,
              this.setState.bind(this),
              this.setMessages.bind(this),
              this.props.setAccountTokenTime.bind(this),
              this.props
            );
          } else {
            this.setState({
              decrypting: false,
              loadingSent: false,
            });
            this.setMessages(
              "*it-looks-like-you-have-entered-a-wrong-code.-please-try-again."
            );
          }
        }
      );
    } else {
      this.setMessages("*one-or-more-required-field(s)-are-missing");
      this.setState({
        invalidCode: this.state.code.length != 6,
      });
    }
  };

  handleRegisterNewCodeChange = (code) => {
    this.setState(
      {
        code: code,
      },
      () => {
        console.log("code", code);
        if (
          this.state.code.length == 6 &&
          (this.state.stageType == "registerNewPassCode" ||
            this.state.stageType == "registerNewIdleTimeout")
        ) {
          this.doRegisterNewCode();
        }
      }
    );
  };

  doRegisterNewCode = () => {
    if (this.state.code.length == 6 && !this.state.decrypting) {
      this.setState(
        {
          decrypting: true,
        },
        () => {
          const keys = Object.keys(localStorage);
          let token = "";
          let account = "";
          let time = "";
          for (let key of keys) {
            const decryptedKey = utils.decrypt(key, this.state.code);
            const encryptedValue = localStorage.getItem(key);
            const decryptedValue = utils.decrypt(
              encryptedValue,
              this.state.code
            );
            if (decryptedValue && decryptedKey === "token") {
              token = decryptedValue;
            } else if (decryptedValue && decryptedKey === "account") {
              account = decryptedValue;
            } else if (decryptedValue && decryptedKey === "time") {
              time = decryptedValue;
              this.setState({
                encryptedTimeKey: key,
              });
            }
          }

          if (account != "" && token != "" && time != "") {
            //SET STATE ACCOUNT TOKEN TIME
            if (this.state.stageType == "registerNewPassCode") {
              this.setState({
                account: JSON.parse(account),
                token: token,
                code: "",
                decrypting: false,
              });
              this.moveStage("registerPassCode", "registerNewPassCode", true);
            } else if (this.state.stageType == "registerNewIdleTimeout") {
              this.setState({
                account: JSON.parse(account),
                token: token,
                time: "",
                errorMessageType: "",
                stageType: "changePassCodeCodeIdleTimeout",
                previousStage: "registerNewPassCode",
                decrypting: false,
              });
            }
          } else {
            this.setState({
              decrypting: false,
            });
            this.setMessages(
              "*it-looks-like-you-have-entered-a-wrong-code.-please-try-again."
            );
          }
        }
      );
    } else {
      this.setMessages("*one-or-more-required-field(s)-are-missing");
      this.setState({
        invalidCode: this.state.code.length != 6,
      });
    }
  };

  doChangeIdleTimeout = () => {
    if (
      Object.keys(this.state.selectedInactivityTime).length > 0 &&
      !this.state.decrypting
    ) {
      const encryptedTime = CryptoJS.AES.encrypt(
        this.state.selectedInactivityTime,
        this.state.code
      ).toString();

      this.props.setInactivityTime(this.state.selectedInactivityTime);
      localStorage.setItem(this.state.encryptedTimeKey, encryptedTime);

      utils.goToURL(this.props, "/dashboard");
    } else {
      this.setMessages("*one-or-more-required-field(s)-are-missing");
      this.setState({
        invalidInactivityTime: true,
      });
    }
  };

  moveStage = (stage, previousStage, clearCode) => {
    this.setState({
      invalidCode: false,
      invalidInactivityTime: false,
      errorMessageType: "",
      stageType: stage,
      previousStage: previousStage,

      code: clearCode ? "" : this.state.code,
      reEnterCode: clearCode ? "" : this.state.reEnterCode,
    });
  };

  goBackStage = () => {
    console.log("previousstage", this.state.previousStage);
    //console.log("stagetype")
    if (this.state.previousStage != "") {
      if (this.state.previousStage == "registerPassCode") {
        if (
          this.props.location.megastate &&
          this.props.location.megastate.goBackUrl
        ) {
          if (this.props.location.megastate.goBackUrl == "/login") {
            this.moveStage("registerPassCode", "", true);
          } else {
            this.moveStage("registerPassCode", "registerNewPassCode", true);
          }
        } else {
          this.moveStage("registerPassCode", "registerNewPassCode", true);
        }
      } else if (this.state.previousStage == "lockedEnterPassCode") {
        this.moveStage("lockedEnterPassCode", "", true);
      } else if (this.state.previousStage == "registerNewPassCode") {
        if (
          this.props.location.megastate &&
          this.props.location.megastate.goBackUrl
        ) {
          this.moveStage("registerNewPassCode", "", true);
        } else {
          this.moveStage("registerNewPassCode", "lockedEnterPassCode", true);
        }
      } else if (this.state.previousStage == "registerNewIdleTimeout") {
        if (
          this.props.location.megastate &&
          this.props.location.megastate.goBackUrl
        ) {
          this.moveStage("registerNewIdleTimeout", "", true);
        } else {
          this.moveStage("registerNewIdleTimeout", "lockedEnterPassCode", true);
        }
      } else {
        console.log("enters here ", this.state.previousStage);
      }
    } else {
      console.log("Enters first Else");
      if (
        this.props.location.megastate &&
        this.props.location.megastate.goBackUrl
      ) {
        utils.goToURL(this.props, this.props.location.megastate.goBackUrl);
      } else {
        this.props.history.goBack();
      }
    }
  };

  getSelectedInactivityTimeName = (value) => {
    return this.props.localizedText[
      this.state.listInactivityTime.filter(
        (item) => item.value === Number(value)
      )[0].name
    ];
  };

  registerPassCode = () => (
    <div class="pass-code-container">
      <div class="login-2fa-div">
        <FaLock className="login-div-icon" />

        <div class="register-title">
          {" "}
          {this.props.localizedText["register-pass-code"]}
        </div>
        <p>{this.props.localizedText["please-enter-a-6-digit-pass-code"]}</p>
        <div class="bottom-section">
          <div class="pass-code-form-div">
            <ReactCodeInput
              fields={6}
              name="code"
              value={this.state.code}
              onChange={this.handleCodeChange}
              key={this.state.inputKey}
              isValid={!this.state.invalidCode}
              disabled={this.state.loadingSent}
              type="password"
              onWheel={(event) => event.currentTarget.blur()}
            ></ReactCodeInput>
          </div>
          <InputGroup className="mb-3 input-field select-form">
            <Form.Select
              variant="outline-secondary"
              onChange={(e) =>
                this.handleSelectedInactivityTimeChange(e.target.value)
              }
              name="selectedInactivityTime"
              isInvalid={this.state.invalidInactivityTime}
              style={{
                width: "100%",
                flex: "0 1 auto",
                textAlign: "start",
              }}
              disabled={this.state.loadingSent}
              value={this.state.selectedInactivityTime}
            >
              <option hidden value="">
                {this.props.localizedText["select-idle-timeout"]}
              </option>
              {this.state.listInactivityTime.map((item) => (
                <option
                  hidden={process.env.REACT_APP_ENV == "prod" && !item.prod}
                  value={item.value}
                >
                  {this.props.localizedText[item.name]}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
          <div class="aditional-information">
            {
              this.props.localizedText[
                "for-security-purposes-a-pass-code-is-required-to-use-this-website"
              ]
            }
          </div>
          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>

          <div class="buttons-div">
            <Button
              className={
                this.state.isMobileWidth
                  ? "primary-button outside-primary-button"
                  : "primary-button inside-primary-button"
              }
              onClick={this.doRegisterPassCode}
              disabled={this.state.loadingSent}
            >
              {this.state.loadingSent ? (
                <Spinner
                  className={
                    this.state.isMobileWidth
                      ? "loader-send-outside-div-button"
                      : "loader-send-inside-div-button"
                  }
                ></Spinner>
              ) : null}
              {this.props.localizedText["submit"]}
            </Button>

            <Button
              className={
                this.state.isMobileWidth
                  ? "secondary-button outside-secondary-button"
                  : "secondary-button inside-secondary-button"
              }
              onClick={this.goBackStage}
            >
              {this.props.localizedText["back"]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  reEnterPassCode = () => (
    <div class="pass-code-container">
      <div class="login-2fa-div">
        {" "}
        <FaLock className="login-div-icon" />
        <div class="register-title">
          {this.props.localizedText["register-pass-code"]}
        </div>
        <p>{this.props.localizedText["please-reenter-a-6-digit-pass-code"]}</p>
        <div class="bottom-section">
          <div class="pass-code-form-div">
            <ReactCodeInput
              fields={6}
              name="code"
              value={this.state.reEnterCode}
              onChange={this.handleReEnterCodeChange}
              key={this.state.inputKey}
              isValid={!this.state.invalidCode}
              disabled={this.state.loadingSent}
              type="password"
              onWheel={(event) => event.currentTarget.blur()}
            ></ReactCodeInput>
          </div>
          <InputGroup className="mb-3 input-field select-form">
            <Form.Select
              variant="outline-secondary"
              style={{
                width: "100%",
                flex: "0 1 auto",
                textAlign: "start",
              }}
              disabled={true}
            >
              <option>
                {this.getSelectedInactivityTimeName(
                  this.state.selectedInactivityTime
                )}
              </option>
            </Form.Select>
          </InputGroup>

          <div class="aditional-information">
            {
              this.props.localizedText[
                "for-security-purposes-a-pass-code-is-required-to-use-this-website"
              ]
            }
          </div>
          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>

          <div class="buttons-div">
            <Button
              className={
                this.state.isMobileWidth
                  ? "primary-button outside-primary-button"
                  : "primary-button inside-primary-button"
              }
              onClick={this.doRegisterPassCode}
              disabled={this.state.loadingSent}
            >
              {this.state.loadingSent ? (
                <Spinner
                  className={
                    this.state.isMobileWidth
                      ? "loader-send-outside-div-button"
                      : "loader-send-inside-div-button"
                  }
                ></Spinner>
              ) : null}
              {this.props.localizedText["submit"]}
            </Button>

            <Button
              className={
                this.state.isMobileWidth
                  ? "secondary-button outside-secondary-button"
                  : "secondary-button inside-secondary-button"
              }
              onClick={this.goBackStage}
            >
              {this.props.localizedText["back"]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  lockedEnterPassCode = () => (
    <div class="pass-code-container">
      <div class="login-2fa-div">
        {" "}
        <FaLock className="login-div-icon" />
        <div class="register-title">
          {this.props.localizedText["your-session-has-expired"]}
        </div>
        <p>{this.props.localizedText["please-enter-your-6-digit-pass-code"]}</p>
        <div class="bottom-section">
          <div class="pass-code-form-div">
            <ReactCodeInput
              fields={6}
              name="code"
              value={this.state.code}
              onChange={this.handleLockedCodeChange}
              key={this.state.inputKey}
              isValid={!this.state.invalidCode}
              disabled={this.state.loadingSent}
              type="password"
              onWheel={(event) => event.currentTarget.blur()}
            ></ReactCodeInput>
            <div class="aditional-information">
              {
                this.props.localizedText[
                  "in-case-of-loss-you-can-register-a-new-pass-code-by"
                ]
              }
              <b
                class={
                  (this.state.isMobileWidth ? "outside" : "inside") + " links"
                }
                onClick={utils.clearSession.bind(
                  this,
                  this.props,
                  this.props.setAccountTokenTime
                )}
              >
                {this.props.localizedText["loggin-in-again"]}
              </b>{" "}
            </div>
          </div>

          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>

          <div class="buttons-div">
            <Button
              className={
                this.state.isMobileWidth
                  ? "primary-button outside-primary-button"
                  : "primary-button inside-primary-button"
              }
              onClick={this.moveStage.bind(
                this,
                "registerNewIdleTimeout",
                "lockedEnterPassCode",
                true
              )}
              disabled={this.state.loadingSent}
            >
              {this.state.loadingSent ? (
                <Spinner
                  className={
                    this.state.isMobileWidth
                      ? "loader-send-outside-div-button"
                      : "loader-send-inside-div-button"
                  }
                ></Spinner>
              ) : null}
              {this.props.localizedText["change-idle-timeout"]}
            </Button>

            <Button
              className={
                this.state.isMobileWidth
                  ? "secondary-button outside-secondary-button"
                  : "secondary-button inside-secondary-button"
              }
              onClick={this.moveStage.bind(
                this,
                "registerNewPassCode",
                "lockedEnterPassCode",
                true
              )}
            >
              {this.props.localizedText["change-pass-code"]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  registerNewPassCodeIdleTimeout = () => (
    <div class="pass-code-container">
      <div class="login-2fa-div">
        {" "}
        <FaLock className="login-div-icon" />
        <div class="register-title">
          {this.props.localizedText["enter-old-pass-code"]}
        </div>
        <p>{this.props.localizedText["please-enter-your-6-digit-pass-code"]}</p>
        <div class="bottom-section">
          <div class="pass-code-form-div">
            <ReactCodeInput
              fields={6}
              name="code"
              value={this.state.code}
              onChange={this.handleRegisterNewCodeChange}
              key={this.state.inputKey}
              isValid={!this.state.invalidCode}
              disabled={this.state.loadingSent}
              type="password"
              onWheel={(event) => event.currentTarget.blur()}
            ></ReactCodeInput>
          </div>

          <div class="aditional-information">
            {
              this.props.localizedText[
                "for-security-purposes-a-pass-code-is-required-to-use-this-website"
              ]
            }
          </div>
          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>

          <div class="buttons-div">
            <Button
              className={
                this.state.isMobileWidth
                  ? "primary-button outside-primary-button"
                  : "primary-button inside-primary-button"
              }
              onClick={this.doRegisterNewCode}
              disabled={this.state.loadingSent}
            >
              {this.state.loadingSent ? (
                <Spinner
                  className={
                    this.state.isMobileWidth
                      ? "loader-send-outside-div-button"
                      : "loader-send-inside-div-button"
                  }
                ></Spinner>
              ) : null}
              {this.props.localizedText["submit"]}
            </Button>

            <Button
              className={
                this.state.isMobileWidth
                  ? "secondary-button outside-secondary-button"
                  : "secondary-button inside-secondary-button"
              }
              onClick={this.goBackStage}
            >
              {this.props.localizedText["back"]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  changePassCodeCodeIdleTimeout = () => (
    <div class="pass-code-container">
      <div class="login-2fa-div">
        <FaLock className="login-div-icon" />

        <div class="register-title">
          {" "}
          {this.props.localizedText["register-pass-code"]}
        </div>
        <p>
          {this.props.localizedText["please-select-your-desired-idle-timeout"]}
        </p>
        <div class="bottom-section">
          <div class="pass-code-form-div">
            <ReactCodeInput
              fields={6}
              name="code"
              value={this.state.code}
              disabled={true}
              type="password"
            ></ReactCodeInput>
          </div>
          <InputGroup className="mb-3 input-field select-form">
            <Form.Select
              variant="outline-secondary"
              onChange={(e) =>
                this.handleSelectedInactivityTimeChange(e.target.value)
              }
              name="selectedInactivityTime"
              isInvalid={this.state.invalidInactivityTime}
              style={{
                width: "100%",
                flex: "0 1 auto",
                textAlign: "start",
              }}
              disabled={this.state.loadingSent}
              value={this.state.selectedInactivityTime}
            >
              <option hidden value="">
                {this.props.localizedText["select-idle-timeout"]}
              </option>
              {this.state.listInactivityTime.map((item) => (
                <option
                  hidden={process.env.REACT_APP_ENV == "prod" && !item.prod}
                  value={item.value}
                >
                  {this.props.localizedText[item.name]}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
          <div class="aditional-information">
            {
              this.props.localizedText[
                "for-security-purposes-a-pass-code-is-required-to-use-this-website"
              ]
            }
          </div>
          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>

          <div class="buttons-div">
            <Button
              className={
                this.state.isMobileWidth
                  ? "primary-button outside-primary-button"
                  : "primary-button inside-primary-button"
              }
              onClick={this.doRegisterPassCode}
              disabled={this.state.loadingSent}
            >
              {this.state.loadingSent ? (
                <Spinner
                  className={
                    this.state.isMobileWidth
                      ? "loader-send-outside-div-button"
                      : "loader-send-inside-div-button"
                  }
                ></Spinner>
              ) : null}
              {this.props.localizedText["submit"]}
            </Button>

            <Button
              className={
                this.state.isMobileWidth
                  ? "secondary-button outside-secondary-button"
                  : "secondary-button inside-secondary-button"
              }
              onClick={this.goBackStage}
            >
              {this.props.localizedText["back"]}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <React.Fragment>
        {this.state.stageType == "registerPassCode" ? (
          <this.registerPassCode></this.registerPassCode>
        ) : null}
        {this.state.stageType == "reEnterPassCode" ? (
          <this.reEnterPassCode></this.reEnterPassCode>
        ) : null}
        {this.state.stageType == "lockedEnterPassCode" ? (
          <this.lockedEnterPassCode></this.lockedEnterPassCode>
        ) : null}
        {this.state.stageType == "registerNewPassCode" ||
        this.state.stageType == "registerNewIdleTimeout" ? (
          <this.registerNewPassCodeIdleTimeout></this.registerNewPassCodeIdleTimeout>
        ) : null}
        {this.state.stageType == "changePassCodeCodeIdleTimeout" ? (
          <this.changePassCodeCodeIdleTimeout></this.changePassCodeCodeIdleTimeout>
        ) : null}
        <ThemeRegionDiv
          changeTheme={this.props.changeTheme}
          theme={this.props.theme}
          handleModalOpen={this.props.handleModalOpen}
          region={this.props.region}
          localizedText={this.props.localizedText}
        ></ThemeRegionDiv>
        <Footer
          region={this.props.region}
          localizedText={this.props.localizedText}
          theme={this.props.theme}
        ></Footer>
      </React.Fragment>
    );
  }
}

export default withRouter(PassCode);
