import axios from "axios";
class MarketDataAPI {
  getHistoricalCandles(token, ticker, granularity, start, end) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      ticker: ticker,
      granularity: granularity,
      start: start,
      end: end,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/market_data/product_ohlc_historic",
      requestBody,
      headers
    );
  }
}

export const marketDataAPI = new MarketDataAPI();
