import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./addPlugin.css";
import AddPluginService from "./AddPluginService";
import GoBackButton from "../../../components/go-back-button/GoBackButton.jsx";
import Result from "../../../components/result/Result.jsx";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import { MdDelete } from "react-icons/md";
import { utils } from "../../../utils/utils";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessage.jsx";

class AddPlugin extends Component {
  state = {
    description: "",
    paymentMethods: [],
    cryptos: [],
    customFields: ["FULL_NAME", "EMAIL"],
    htmlSchemas: [],
    key: "",
    value: "",
    invoiceAfterPayment: false,

    invalidDescription: false,
    invalidPaymentMethods: false,
    invalidCryptos: false,

    listPaymentMethods: [],
    listCryptos: [],
    listCustomFields: [],

    stage: 1,

    loadingPaymentMethods: true,
    loadingCryptos: true,
    loadingCustomFields: true,
    loadingSent: false,
  };

  service = new AddPluginService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    this.service.getPaymentMethods(this.props.token, this.setState.bind(this));
    this.service.getAvailableCryptos(
      this.props.token,
      this.setState.bind(this)
    );
    this.service.getCustomFields(this.props.token, this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard/pay-me/plugins"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard/pay-me/plugins"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.phoneVerified != null && !this.props.phoneVerified) {
        utils.goToURL(this.props, "/dashboard/settings/verify-phone");
      } else if (
        this.props.emailVerified != null &&
        !this.props.emailVerified
      ) {
        utils.goToURL(this.props, "/dashboard/settings/verify-email");
      } else if (this.props.idVerified != null && !this.props.idVerified) {
        utils.goToURL(this.props, "/dashboard/settings/id-verification");
      }
    }
  }

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  addPlugin = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.description != "" &&
          this.state.paymentMethods.length > 0
        ) {
          if (
            (this.state.paymentMethods.includes("CRYPTO") &&
              this.state.cryptos.length > 0) ||
            !this.state.paymentMethods.includes("CRYPTO")
          ) {
            this.setState(
              {
                invalidDescription: false,
                errorMessageType: "",
                loadingSent: true,
              },
              () => {
                const htmlSchemaObject = this.state.htmlSchemas.reduce(
                  (acc, schema) => {
                    acc[schema.key] = schema.value;
                    return acc;
                  },
                  {}
                );

                this.service.addPlugin(
                  this.props.token,
                  this.state.description,
                  this.state.paymentMethods,
                  this.state.cryptos,
                  htmlSchemaObject,
                  this.state.customFields,
                  this.state.invoiceAfterPayment,
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              }
            );
          } else {
            this.setMessages(
              "*please-select-at-least-one-cryptocurrency-when-using-the-crypto-payment-method"
            );
            this.setState({
              invalidDescription: false,
              invalidCryptos: true,
            });
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidDescription: this.state.description === "",
            invalidPaymentMethods: this.state.paymentMethods.length == 0,
            invalidCryptos:
              this.state.paymentMethods.includes("CRYPTO") &&
              this.state.cryptos.length == 0,
          });
        }
      }
    );
  };

  addPaymentMethodToRequest = (value) => {
    var arrAux = this.state.paymentMethods;
    const index = arrAux.indexOf(value);
    if (index !== -1) {
      arrAux.splice(index, 1);
    } else {
      arrAux.push(value);
    }

    this.setState(
      {
        paymentMethods: arrAux,
        invalidPaymentMethods: false,
      },
      () => {
        if (!this.state.paymentMethods.includes("CRYPTO")) {
          this.setState({
            cryptos: [],
            invalidCryptos: false,
          });
        }
      }
    );
  };

  addCryptoToRequest = (value) => {
    var arrAux = this.state.cryptos;
    const index = arrAux.indexOf(value);
    if (index !== -1) {
      arrAux.splice(index, 1);
    } else {
      arrAux.push(value);
    }

    this.setState({
      cryptos: arrAux,
      invalidCryptos: false,
    });
  };

  addCustomFieldsToRequest = (value) => {
    var arrAux = this.state.customFields;
    const index = arrAux.indexOf(value);
    if (index !== -1) {
      arrAux.splice(index, 1);
    } else {
      arrAux.push(value);
    }

    this.setState({
      customFields: arrAux,
    });
  };

  addHtmlSchemaToRequest = () => {
    var arrAux = this.state.htmlSchemas;

    arrAux.push({ key: this.state.key, value: this.state.value });

    this.setState({
      htmlSchemas: arrAux,
      key: "",
      value: "",
    });
  };

  deleteHtmlSchemaFromRequest = (keyToDelete) => {
    var arrAux = this.state.htmlSchemas;

    arrAux = arrAux.filter((schema) => schema.key !== keyToDelete);

    this.setState({
      htmlSchemas: arrAux,
    });
  };

  checkInvoiceAfterPayment = () => {
    this.setState(
      {
        invoiceAfterPayment: !this.state.invoiceAfterPayment,
      },
      () => {
        if (this.state.invoiceAfterPayment) {
          var arrAux = this.state.customFields;

          if (!arrAux.includes("INVOICE_NUMBER")) {
            arrAux.push("INVOICE_NUMBER");
          }
          if (!arrAux.includes("MEMO")) {
            arrAux.push("MEMO");
          }
          if (!arrAux.includes("DESCRIPTION")) {
            arrAux.push("DESCRIPTION");
          }

          this.setState({
            customFields: arrAux,
          });
        }
      }
    );
  };

  getDescription = (description) => {
    if (description === "CREDIT_CARD") {
      return this.props.localizedText["credit-card"];
    } else if (description === "CRYPTO") {
      return this.props.localizedText["crypto"];
    } else if (description === "FULL_NAME") {
      return this.props.localizedText["full-name"];
    } else if (description === "EMAIL") {
      return this.props.localizedText["email"];
    } else if (description === "PHONE") {
      return this.props.localizedText["phone"];
    } else if (description === "INVOICE_NUMBER") {
      return this.props.localizedText["invoice-number"];
    } else if (description === "MEMO") {
      return this.props.localizedText["memo"];
    } else if (description === "DESCRIPTION") {
      return this.props.localizedText["description"];
    } else {
      return description;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="add-plugin-container">
          <div class="add-plugin-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard/pay-me/plugins"}
              ></GoBackButton>
            </div>
            <div class="middle-container">
              {this.state.stage === 1 ? (
                <div class="vendor-form-div">
                  <div class="main-title">
                    {this.props.localizedText["add-plugin"]}
                  </div>
                  {this.state.loadingPaymentMethods ||
                  this.state.loadingCryptos ||
                  this.state.loadingCustomFields ? (
                    <Spinner className="loader"></Spinner>
                  ) : (
                    <div className="fade-in-up">
                      <div class="div-parent-flex-column">
                        <Form.Group className="mb-3">
                          <Form.Label className="input-label-outside-div">
                            {this.props.localizedText["description"]}
                          </Form.Label>
                          <FormControl
                            type="text"
                            name="description"
                            value={this.state.description}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidDescription}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </Form.Group>
                        <div class="div-parent-flex-row mb-3">
                          <div class="div-50-son">
                            <Form.Group className="mb-3">
                              <Form.Label className="input-label-outside-div">
                                {this.props.localizedText["payment-methods"]}
                              </Form.Label>{" "}
                              {this.state.listPaymentMethods.map(
                                (paymentMethod) => (
                                  <div key={paymentMethod.id} className="mb-3">
                                    <Form.Check
                                      label={this.getDescription(
                                        paymentMethod.id
                                      )}
                                      type="checkbox"
                                      onClick={() => {
                                        this.addPaymentMethodToRequest(
                                          paymentMethod.id
                                        );
                                      }}
                                      checked={this.state.paymentMethods.includes(
                                        paymentMethod.id
                                      )}
                                      isInvalid={
                                        this.state.invalidPaymentMethods
                                      }
                                    />
                                  </div>
                                )
                              )}
                            </Form.Group>{" "}
                            <Form.Group className="mb-3">
                              <Form.Label className="input-label-outside-div">
                                {this.props.localizedText["available-cryptos"]}
                              </Form.Label>{" "}
                              {this.state.listCryptos.map((crypto) => (
                                <div key={crypto} className="mb-3">
                                  <Form.Check
                                    label={crypto}
                                    type="checkbox"
                                    onClick={() => {
                                      this.addCryptoToRequest(crypto);
                                    }}
                                    checked={this.state.cryptos.includes(
                                      crypto
                                    )}
                                    disabled={
                                      !this.state.paymentMethods.includes(
                                        "CRYPTO"
                                      )
                                    }
                                    isInvalid={this.state.invalidCryptos}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </div>
                          <div class="div-50-son">
                            <Form.Group className="mb-3">
                              <Form.Label className="input-label-outside-div">
                                {this.props.localizedText["custom-fields"]}
                              </Form.Label>
                              {this.state.listCustomFields.map(
                                (customField) => (
                                  <div key={customField} className="mb-3">
                                    <Form.Check
                                      label={this.getDescription(customField)}
                                      type="checkbox"
                                      onClick={() => {
                                        this.addCustomFieldsToRequest(
                                          customField
                                        );
                                      }}
                                      checked={this.state.customFields.includes(
                                        customField
                                      )}
                                      disabled={
                                        customField == "FULL_NAME" ||
                                        customField == "EMAIL" ||
                                        (customField == "INVOICE_NUMBER" &&
                                          this.state.invoiceAfterPayment) ||
                                        (customField == "MEMO" &&
                                          this.state.invoiceAfterPayment) ||
                                        (customField == "DESCRIPTION" &&
                                          this.state.invoiceAfterPayment)
                                      }
                                    />
                                  </div>
                                )
                              )}
                            </Form.Group>
                          </div>
                        </div>

                        <Form.Group
                          className="mb-3"
                          style={{ display: "none" }}
                        >
                          <Form.Label className="input-label-outside-div">
                            {this.props.localizedText["html-schema"]}
                          </Form.Label>
                          {this.state.htmlSchemas.map((schema) => (
                            <div class="html-schema-form-div mb-3">
                              <FormControl
                                type="text"
                                value={schema.key}
                                disabled
                                className="input-field"
                              />

                              <FormControl
                                type="text"
                                value={schema.value}
                                disabled
                                className="input-field"
                              />
                              <MdDelete
                                class="html-schema-row-icon"
                                onClick={() => {
                                  this.deleteHtmlSchemaFromRequest(schema.key);
                                }}
                              >
                                {this.props.localizedText["delete"]}
                              </MdDelete>
                            </div>
                          ))}

                          <div class="html-schema-form-div">
                            <FormControl
                              type="text"
                              placeholder="Key"
                              name="key"
                              value={this.state.key}
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              isInvalid={this.state.invalidKey}
                              className="input-field"
                            />

                            <FormControl
                              type="text"
                              placeholder="Value"
                              name="value"
                              value={this.state.value}
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              isInvalid={this.state.invalidValue}
                              className="input-field"
                            />
                            <Button
                              className="outside-primary-button"
                              onClick={this.addHtmlSchemaToRequest}
                              disabled={
                                this.state.key == "" && this.state.value == ""
                              }
                            >
                              {this.props.localizedText["add"]}
                            </Button>
                          </div>
                        </Form.Group>

                        <div
                          key="checkbox-invoice-after-payment"
                          className="mb-3"
                        >
                          <Form.Check
                            label={
                              this.props.localizedText["invoice-after-payment"]
                            }
                            type="checkbox"
                            onClick={this.checkInvoiceAfterPayment}
                            class="invoice-after-payment-check"
                          />
                        </div>
                      </div>
                      <ErrorMessageComponent
                        errorMessage={
                          this.props.localizedText[this.state.errorMessageType]
                        }
                      ></ErrorMessageComponent>
                      <div class="buttons-div">
                        <Button
                          className="primary-button outside-primary-button"
                          onClick={this.addPlugin}
                          disabled={this.state.loadingSent}
                        >
                          {this.state.loadingSent ? (
                            <Spinner className="loader-send-outside-div-button"></Spinner>
                          ) : null}
                          {this.props.localizedText["add"]}
                        </Button>

                        <Button
                          className="secondary-button outside-secondary-button"
                          onClick={utils.goToURL.bind(
                            this,
                            this.props,
                            "/dashboard/pay-me/plugins"
                          )}
                        >
                          {this.props.localizedText["cancel"]}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <Result
                  success={true}
                  secondButton={false}
                  title={this.props.localizedText["great-news!"]}
                  message={"Plugin has been successfully added"}
                  primaryButtonText={this.props.localizedText["continue"]}
                  primaryButtonFunction={utils.goToURL.bind(
                    this,
                    this.props,
                    "/dashboard/pay-me/plugins"
                  )}
                ></Result>
              )}
            </div>
            <div class="side-container"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddPlugin);
