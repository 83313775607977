import { loginAPI } from "../../apis/LoginAPI.jsx";
import { utils } from "../../utils/utils";

export default class LayoutService {
  logout(token, redirectCallback, props, setAccountAndToken) {
    console.log("token", token);
    loginAPI
      .logout(token)
      .then((response) => {
        console.log("logout success", response);

        utils.clearSession(props, setAccountAndToken);
      })
      .catch((error) => {
        //Failure
        console.log("logout error", error);

        utils.clearSession(props, setAccountAndToken);
      });
  }
}
