import { loginAPI } from "../../apis/LoginAPI.jsx";
import { utils } from "../../utils/utils";

export default class LoginService {
  doLogin(
    username,
    password,
    rememberEmail,
    redirectCallback,
    setState,
    setMessages
  ) {
    loginAPI
      .login(username, password)
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data.access_token != null) {
          //Success

          if (rememberEmail) {
            localStorage.clear();
            localStorage.setItem("remember-email", username);
          }
          redirectCallback(response.data);
        } else {
          //Failure
          setMessages("*incorrect-email-or-password");
          setState({
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        console.log(error.response);
        setMessages("*incorrect-email-or-password");
        setState({
          loadingSent: false,
        });
      });
  }
}
