import { portfolioAPI } from "../../apis/PortfolioAPI.jsx";
import { refdataAPI } from "../../apis/RefdataAPI.jsx";
import { withdrawAPI } from "../../apis/WithdrawAPI.jsx";
import { walletAPI } from "../../apis/WalletAPI.jsx";
import { loginAPI } from "../../apis/LoginAPI.jsx";
import { swapAPI } from "../../apis/SwapAPI.jsx";
import { utils } from "../../utils/utils.jsx";

export default class WithdrawService {
  getCurrenciesWithdraw(token, setState) {
    refdataAPI
      .getCurrenciesWithdraw(token)
      .then((response) => {
        console.log("getCurrenciesWithdraw", response);
        if (response.status === 200 && response.data) {
          this.getDefaultPortfolio(
            token,
            setState,
            utils.orderAlphabetically(
              response.data.filter((element) => element.fiat),
              "name"
            )
          );
        } else {
          setState({
            loadingCurrenciesWithdraw: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        setState({
          loadingCurrenciesWithdraw: false,
        });
      });
  }

  //get currencies crypto
  getDefaultPortfolio(token, setState, listCurrenciesWithdrawFiat) {
    portfolioAPI
      .getDefaultPortfolio(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("success", response);
          this.getPortfolioBalances(
            token,
            setState,
            response.data.name,
            listCurrenciesWithdrawFiat
          );
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getPortfolioBalances(
    token,
    setState,
    portfolioName,
    listCurrenciesWithdrawFiat
  ) {
    portfolioAPI
      .getPortfolioBalances(token, portfolioName)
      .then((response) => {
        console.log("getPortfolioBalances", response);
        if (response.status === 200) {
          let listCurrenciesFiatAvailable = [];
          let fiatRequests = [];
          let fiatResponses = [];
          let auxCurrency = {};

          for (let i = 0; i < listCurrenciesWithdrawFiat.length; i++) {
            //conversion for fiat
            fiatRequests.push(listCurrenciesWithdrawFiat[i].base_currency);
            fiatResponses.push(
              swapAPI.convertEstimate(
                token,
                response.data.filter(
                  (element) => element.base_currency == "USD"
                )[0].qty,
                "USD",
                listCurrenciesWithdrawFiat[i].base_currency,
                0
              )
            );
          }
          Promise.allSettled(fiatResponses)
            .then((fiatResponses) => {
              console.log("fiatResponse", fiatResponses);
              for (let i = 0; i < fiatResponses.length; i++) {
                if (fiatResponses[i].status != "rejected") {
                  auxCurrency = listCurrenciesWithdrawFiat.filter(
                    (element) => element.base_currency == fiatRequests[i]
                  )[0];
                  auxCurrency["available_qty"] =
                    fiatResponses[i].value.data &&
                    fiatResponses[i].value.data.amount_to_receive &&
                    fiatResponses[i].value.data.amount_to_send
                      ? fiatResponses[i].value.data.amount_to_receive
                      : response.data.filter(
                          (element) => element.base_currency == fiatRequests[i]
                        )[0].qty;

                  listCurrenciesFiatAvailable.push(auxCurrency);
                } else {
                  console.log("There was an error converting a currency");
                }
              }

              console.log("listFiatWithdraw", listCurrenciesFiatAvailable);
              console.log(
                "listCryptoWithdraw",
                response.data.filter((element) => !element.fiat)
              );

              setState({
                listFiatWithdraw: listCurrenciesFiatAvailable,
                listCryptoWithdraw: response.data.filter(
                  (element) => !element.fiat
                ),
                loadingCurrenciesWithdraw: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getClientConfig(token, setState) {
    loginAPI
      .getClientConfig(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("config", response);
          setState({
            authChoice:
              response.data.auth_choice == "auth_pending" ||
              response.data.auth_choice == "auth_declined"
                ? "Phone"
                : "Google Auth",
          });

          if (
            response.data.auth_choice == "auth_pending" ||
            response.data.auth_choice == "auth_declined"
          ) {
            this.confirmPhone(setState);
          }
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }
}
