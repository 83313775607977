import axios from "axios";

class VendorsAPI {
  // Vendors
  getVendors(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/my_vendors",
      requestBody,
      headers
    );
  }

  createVendor(
    token,
    name,
    email,
    countryCode,
    phoneNumber,
    location,
    address,
    city
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      vendor_name: name,
      vendor_email: email,
      vendor_phone_country: countryCode,
      vendor_phone: phoneNumber,
      vendor_location: location,
      vendor_address: address,
      vendor_city: city,
      metadata: {},
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/vendors/new_vendor",
      body,
      headers
    );
  }

  updateVendor(token, state, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      vendor_name: state.name,
      vendor_email: state.email,
      vendor_location: state.location,
      vendor_phone_country: state.countryCode,
      vendor_phone: state.phoneNumber,
      vendor_address: state.address,
      vendor_city: state.city,
      metadata: {},
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/update_vendor/" + id,
      body,
      headers
    );
  }

  deleteVendor(token, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/delete_vendor/" + id,
      body,
      headers
    );
  }

  // Vendor Payment Methods
  getVendorPaymentMethods(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/my_vendor_accounts",
      requestBody,
      headers
    );
  }

  // Vendor Payment Accounts
  getPaymentMethods(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/all_vendor_payment_methods",
      body,
      headers
    );
  }

  checkVendorPaymentAccountName(token, clientVendorID, payAccountName) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      client_vendor_id: clientVendorID,
      pay_account_name: payAccountName,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/check_vendor_account_exists",
      body,
      headers
    );
  }

  createVendorPaymentAccount(
    token,
    paymentAccountName,
    methodType,
    clientVendorId,
    metaData
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      pay_account_name: paymentAccountName,
      pay_method_type: methodType,
      client_vendor_id: clientVendorId,
      metadata: metaData,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/vendors/new_vendor_account",
      body,
      headers
    );
  }

  updateVendorPaymentAccount(
    token,
    paymentAccountName,
    methodType,
    clientVendorId,
    metaData,
    vendorAccountId
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      pay_account_name: paymentAccountName,
      pay_method_type: methodType,
      client_vendor_id: clientVendorId,
      metadata: metaData,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/update_vendor_account/" +
        vendorAccountId,
      body,
      headers
    );
  }

  deleteVendorPaymentAccount(token, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/delete_vendor_account/" +
        id,
      body,
      headers
    );
  }

  // Payments
  getPayments(token, vendorId) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      vendor_id: vendorId,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/get_vendor_payments",
      requestBody,
      headers
    );
  }

  payVendor(token, id, requestData, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      payment_account_id: id,
      amount: Number(requestData.amount),
      pay_currency: requestData.currencyTo,
      source_currency: requestData.currencyFrom,
      description: requestData.description,
      external_memo: "For you",
      metadata: {
        purpose: "For me",
      },
      code: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/vendors/pay_vendor_with_2fa",
      body,
      headers
    );
  }
}

export const vendorsAPI = new VendorsAPI();
