import { portfolioAPI } from "../../apis/PortfolioAPI.jsx";
import { marketDataAPI } from "../../apis/MarketDataAPI.jsx";
import { swapAPI } from "../../apis/SwapAPI.jsx";
import { utils } from "../../utils/utils.jsx";

export default class AssetsService {
  getDefaultPortfolio(token, setState, chartOption, chartIndexPicked) {
    portfolioAPI
      .getDefaultPortfolio(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("success", response);
          this.getPortfolioBalances(
            token,
            setState,
            response.data.name,
            chartOption,
            chartIndexPicked
          );
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getPortfolioBalances(
    token,
    setState,
    portfolioName,
    chartOption,
    chartIndexPicked
  ) {
    portfolioAPI
      .getPortfolioBalances(token, portfolioName)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let assets = [];
          let promiseArrayHistoricalCandles = [];
          let convertRequests = [];
          let convertResponses = [];
          for (let i = 0; i < response.data.length; i++) {
            promiseArrayHistoricalCandles.push(
              marketDataAPI.getHistoricalCandles(
                token,
                response.data[i].base_currency + "-USD",
                chartOption[chartIndexPicked].granularity,
                Date.now() / 1000 -
                  86400 * chartOption[chartIndexPicked].length, //Multiplying days to get in seconds
                Date.now() / 1000
              )
            );
            if (response.data[i].base_currency != "USD") {
              convertRequests.push(response.data[i].base_currency);
              convertResponses.push(
                swapAPI.convertEstimate(
                  token,
                  response.data[i].qty,
                  response.data[i].base_currency,
                  "USD",
                  0
                )
              );
            } else {
              assets.push({
                data: response.data.filter(
                  (element) => element.base_currency == "USD"
                )[0],
                value_usd: null,
              });
            }
          }
          console.log("convertRequests", convertRequests);
          console.log("convertResponses", convertResponses);
          Promise.all(
            convertResponses.map((response) =>
              response.catch((err) => ({ error: err }))
            )
          )
            .then((convertResponses) => {
              for (let i = 0; i < convertResponses.length; i++) {
                console.log("convertResponses i", convertResponses[i]);
                console.log("convertResponses data", convertResponses[i].data);
                assets.push({
                  data: response.data.filter(
                    (element) => element.base_currency == convertRequests[i]
                  )[0],
                  value_usd:
                    convertResponses[i].data &&
                    convertResponses[i].data.amount_to_receive
                      ? Number(convertResponses[i].data.amount_to_receive)
                      : null,
                });

                console.log("asssets", assets);
              }
              this.getHistoricalCandles(
                token,
                setState,
                assets,
                chartOption,
                chartIndexPicked
              );
            })
            .catch((err) => {
              console.log("error with converting", err);
            });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getHistoricalCandles(token, setState, assets, chartOption, chartIndexPicked) {
    let assetsAux = assets;
    let promiseArrayHistoricalCandles = [];

    for (let i = 0; i < assets.length; i++) {
      promiseArrayHistoricalCandles.push(
        marketDataAPI.getHistoricalCandles(
          token,
          assets[i].data.base_currency + "-USD",
          chartOption[chartIndexPicked].granularity,
          Date.now() / 1000 - 86400 * chartOption[chartIndexPicked].length, //Multiplying days to get in seconds
          Date.now() / 1000
        )
      );
    }

    Promise.all(promiseArrayHistoricalCandles)
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          assetsAux[i] = {
            name: assets[i]["data"]["base_currency"],
            data: assets[i]["data"],
            "chart-data":
              data[i]["data"] &&
              data[i]["data"]["payload"] &&
              Array.isArray(JSON.parse(data[i]["data"]["payload"]))
                ? JSON.parse(data[i]["data"]["payload"]).reverse()
                : null,
            value_usd: assets[i]["value_usd"],
          };
        }
        let assetsAuxOrdered = utils.orderAlphabetically(assetsAux, "name");
        setState({
          chartIndexPicked: chartIndexPicked,
          listPortfolios: assetsAuxOrdered,
          listPortfoliosShowed: assetsAuxOrdered,
          totalPortfolios: assetsAuxOrdered.length,
          loadingAssets: false,
          loadingHistoricalCandles: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  changeCurrencyFavorite(
    token,
    state,
    setState,
    currency,
    enabled,
    shouldCheckTotalBalance
  ) {
    portfolioAPI
      .getDefaultPortfolio(token)
      .then((responseDefaultPortfolio) => {
        if (
          responseDefaultPortfolio.status === 200 &&
          responseDefaultPortfolio.data
        ) {
          console.log("getDefaultPortfolio success", responseDefaultPortfolio);
          portfolioAPI
            .changeCurrencyFavorite(
              token,
              responseDefaultPortfolio.data.name,
              currency,
              enabled
            )
            .then((response) => {
              if (response.status === 200 || response.status === 204) {
                console.log("changeCurrencyFavorite success", response);
                var auxAurr = state.listLoadingFavorite.filter(
                  (item) => item != currency
                );
                var listPortfolios = state.listPortfolios;
                const index = listPortfolios.findIndex(
                  (item) => item.data.base_currency == currency
                );
                if (index !== -1) {
                  listPortfolios[index].data.fav = enabled;
                }
                this.updateOnlyPortfolioBalances(
                  token,
                  setState,
                  listPortfolios,
                  auxAurr,
                  shouldCheckTotalBalance
                );
              } else {
                console.log("changeCurrencyFavorite failure", response);
                var auxAurr = state.listLoadingFavorite.filter(
                  (item) => item != currency
                );
                setState(
                  {
                    listLoadingFavorite: auxAurr,
                  },
                  () => {
                    //enableChartAnimation();
                  }
                );
              }
            })
            .catch((error) => {
              //Failure
              console.log("changeCurrencyFavorite error", error);
              var auxAurr = state.listLoadingFavorite.filter(
                (item) => item != currency
              );

              setState(
                {
                  listLoadingFavorite: auxAurr,
                },
                () => {
                  //enableChartAnimation();
                }
              );
            });
        } else {
          console.log("getDefaultPortfolio failure", responseDefaultPortfolio);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getDefaultPortfolio error", error);
      });
  }

  updateOnlyPortfolioBalances(
    token,
    setState,
    listPortfolios,
    listLoadingFavorite,
    shouldCheckTotalBalance
  ) {
    let convertRequests = [];
    let convertResponses = [];
    for (let i = 0; i < listPortfolios.length; i++) {
      convertRequests.push(listPortfolios[i]["data"].base_currency);
      if (listPortfolios[i]["data"].base_currency != "USD") {
        convertResponses.push(
          swapAPI.convertEstimate(
            token,
            listPortfolios[i].data.qty,
            listPortfolios[i].data.base_currency,
            "USD",
            0
          )
        );
      } else {
        convertResponses.push(null);
      }
    }

    var listPortfoliosUpdated = listPortfolios;

    Promise.all(convertResponses)
      .then((convertResponses) => {
        for (let i = 0; i < convertResponses.length; i++) {
          const index = listPortfolios.findIndex(
            (item) => item.data.base_currency == convertRequests[i]
          );
          listPortfoliosUpdated[index]["value_usd"] =
            convertResponses[i] &&
            convertResponses[i].data &&
            convertResponses[i].data.amount_to_receive
              ? convertResponses[i].data.amount_to_receive
              : null;
        }

        shouldCheckTotalBalance();
        setState({
          listLoadingFavorite: listLoadingFavorite,
          listPortfolios: listPortfoliosUpdated,
          listPortfoliosShowed: listPortfoliosUpdated,
          totalPortfolios: listPortfoliosUpdated.length,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
