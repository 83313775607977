import axios from "axios";

class AdminAPI {
  getPayments(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/payments/transactions/",
      headers
    );
  }

  approvePayment(token, transactionId) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/payments/transactions/approve/" +
        transactionId,
      body,
      headers
    );
  }
}

export const adminAPI = new AdminAPI();
