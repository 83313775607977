import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./verificationModal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class VerificationModal extends Component {
  state = { show: true };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("this.props", this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
    }
  }

  goToURL = (url) => {
    this.handleClose();
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  handleClose = () => {
    this.props.toggleModalVerify(false);
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.props.showVerifyModal}
          onHide={this.handleClose}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              {this.props.localizedText["complete-registration"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              this.props.localizedText[
                "in-order-to-deposit,-withdraw-and-make-payments,-please-complete-your-registration-by:"
              ]
            }
            <ul class="verifications-div">
              {!this.props.idVerified ? (
                <li
                  class="verification-row"
                  onClick={this.goToURL.bind(
                    this,
                    "/dashboard/settings/id-verification"
                  )}
                >
                  {this.props.localizedText["verifying-your-identity"]}
                </li>
              ) : null}
              {!this.props.phoneVerified ? (
                <li
                  class="verification-row"
                  onClick={this.goToURL.bind(
                    this,
                    "/dashboard/settings/verify-phone"
                  )}
                >
                  {this.props.localizedText["verifying-your-phone"]}
                </li>
              ) : null}
              {!this.props.emailVerified ? (
                <li
                  class="verification-row"
                  onClick={this.goToURL.bind(
                    this,
                    "/dashboard/settings/verify-email"
                  )}
                >
                  {this.props.localizedText["verifying-your-email"]}
                </li>
              ) : null}
            </ul>
          </Modal.Body>
          <Modal.Footer
            className={
              (!this.props.idVerified && !this.props.phoneVerified) ||
              (!this.props.idVerified && !this.props.emailVerified) ||
              (!this.props.phoneVerified && !this.props.emailVerified)
                ? "two-verification-required"
                : ""
            }
          >
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleClose}
            >
              {this.props.localizedText["close"]}
            </Button>
            {!this.props.idVerified &&
            !this.props.phoneVerified &&
            !this.props.emailVerified ? (
              <Button
                className={
                  (!this.props.idVerified ? "verify-email-button" : "") +
                  " verify-button outside-primary-button"
                }
                onClick={this.goToURL.bind(this, "/dashboard/settings")}
              >
                {this.props.localizedText["settings"]}
              </Button>
            ) : (
              <div class="two-button-div">
                {!this.props.phoneVerified ? (
                  <Button
                    className="go-verify-button outside-primary-button"
                    onClick={this.goToURL.bind(
                      this,
                      "/dashboard/settings/verify-phone"
                    )}
                  >
                    {this.props.localizedText["verify-phone"]}
                  </Button>
                ) : null}
                {!this.props.emailVerified ? (
                  <Button
                    className="go-verify-button outside-primary-button"
                    onClick={this.goToURL.bind(
                      this,
                      "/dashboard/settings/verify-email"
                    )}
                  >
                    {this.props.localizedText["verify-email"]}
                  </Button>
                ) : null}
                {!this.props.idVerified ? (
                  <Button
                    className="go-verify-button outside-primary-button"
                    onClick={this.goToURL.bind(
                      this,
                      "/dashboard/settings/id-verification"
                    )}
                  >
                    {this.props.localizedText["verify-id"]}
                  </Button>
                ) : null}
              </div>
            )}
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(VerificationModal);
