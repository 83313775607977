import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./adminPayments.css";
import GoBackButton from "../../../components/go-back-button/GoBackButton.jsx";
import Button from "react-bootstrap/Button";
import AdminPaymentsService from "./AdminPaymentsService.jsx";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { ImSearch } from "react-icons/im";
import { FaSearchMinus, FaPrint } from "react-icons/fa";
import { LuPackageSearch } from "react-icons/lu";
import { utils } from "../../../utils/utils.jsx";
import { IoMdDownload, IoMdOpen } from "react-icons/io";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import ReceiptComponent from "../../../components/receipt/Receipt.jsx";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import PrintPDFComponent from "../../../components/printPDF/PrintPDF.jsx";

class AdminPayments extends Component {
  state = {
    width: window.innerWidth,

    listPayments: [],
    listPaymentsShowed: [],

    selectedTransaction: {},

    showModalPaymentDetails: false,
    showModalApprovePayment: false,

    selectedDivIndex: null,
    tableSortPicked: "",

    loadingPayments: true,
    loadingSentApprove: false,
    loadingSentReject: false,
  };

  service = new AdminPaymentsService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("ADMIN PAYMENTS");
    this.service.getPayments(this.props.token, this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
    }
  }

  componentWillUnmount() {}

  openPaymentDetails = (payment, index) => {
    if (payment.status == "Pending" && process.env.REACT_APP_ENV != "prod") {
      this.setState({
        selectedDivIndex: index,
        selectedPayment: payment,
        showModalApprovePayment: true,
      });
    } else {
      this.setState({
        selectedDivIndex: index,
        selectedPayment: payment,
        showModalPaymentDetails: true,
      });
    }
  };

  handleCloseApprovePayment = () => {
    this.setState({
      selectedDivIndex: null,
      showModalApprovePayment: false,
    });
  };

  handleClosePaymentDetails = () => {
    this.setState({
      selectedDivIndex: null,
      showModalPaymentDetails: false,
    });
  };

  sortTable = (sortOption, order) => {
    let newArray = [];
    if (order == "up") {
      newArray = this.state.listPayments.sort((a, b) => {
        if (a[sortOption] < b[sortOption]) {
          return 1;
        }
        if (a[sortOption] > b[sortOption]) {
          return -1;
        }
        return 0;
      });
    } else {
      newArray = this.state.listPayments.sort((a, b) => {
        if (a[sortOption] < b[sortOption]) {
          return -1;
        }
        if (a[sortOption] > b[sortOption]) {
          return 1;
        }
        return 0;
      });
    }

    this.setState({
      tableSortPicked: sortOption + " " + order,
      listPayments: newArray,
      listPaymentsShowed: newArray,
    });
  };

  getTitle = (card) => {
    if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === null
    ) {
      return this.props.localizedText["deposit"];
    } else if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === "RECEIVED_FROM_PEER"
    ) {
      return this.props.localizedText["received-from-peer"];
    } else if (
      card["transaction_type"] === "HOLD" &&
      card["transaction_subtype"] === null
    ) {
      return this.props.localizedText["hold"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === null
    ) {
      return this.props.localizedText["withdraw"];
    } else if (
      (card["transaction_type"] === "WITHDRAW" ||
        card["transaction_type"] === "HOLD") &&
      card["transaction_subtype"] === "PAY_VENDOR"
    ) {
      return this.props.localizedText[
        utils.getWhiteLabelText("pay-vendor", "vendor")
      ];
    } else if (
      (card["transaction_type"] === "WITHDRAW" ||
        card["transaction_type"] === "HOLD") &&
      card["transaction_subtype"] === "SEND_TO_PEER"
    ) {
      return this.props.localizedText["send-to-peer"];
    } else if (card["transaction_type"] === "BUY") {
      return this.props.localizedText["buy"];
    } else if (card["transaction_type"] === "SELL") {
      return this.props.localizedText["sell"];
    } else if (card["transaction_type"] === "PAYME_DEPOSIT_HOLD") {
      return this.props.localizedText["hold-deposit-pay-me"];
    } else if (card["transaction_type"] === "CONVERSION_DEPOSIT_HOLD") {
      return this.props.localizedText["hold-conversion"];
    } else if (card["transaction_type"] === "CONVERSION_DEPOSIT") {
      return this.props.localizedText["conversion-completed"];
    } else {
      return card["transaction_type"];
    }
  };

  getDescription = (card) => {
    if (
      (card["transaction_type"] === "WITHDRAW" &&
        card["transaction_subtype"] === null) ||
      (card["transaction_type"] === "HOLD" &&
        card["transaction_subtype"] === null)
    ) {
      return this.props.localizedText["withdraw-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === "SEND_TO_PEER"
    ) {
      return this.props.localizedText["sent-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === "PAY_VENDOR"
    ) {
      return this.props.localizedText["paid-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "BUY" &&
      card["transaction_subtype"] === null
    ) {
      return (
        this.props.localizedText["buy-"] +
        card["qty"] +
        card["base_currency"] +
        this.props.localizedText["-at-"] +
        card["price"]
      );
    } else if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === "RECEIVED_FROM_PEER"
    ) {
      return this.props.localizedText["received-from-"] + card["from"];
    } else if (
      card["transaction_type"] === "SELL" &&
      card["transaction_subtype"] === null
    ) {
      return (
        this.props.localizedText["sell-"] +
        card["qty"] +
        card["base_currency"] +
        this.props.localizedText["-at-"] +
        card["price"]
      );
    } else {
      return card["description"];
    }
  };

  getStatus = (payment) => {
    if (
      payment["transaction_type"] === "HOLD" ||
      payment["transaction_type"] === "CONVERSION_DEPOSIT_HOLD" ||
      payment["transaction_type"] === "PAYME_DEPOSIT_HOLD"
    ) {
      return this.props.localizedText["processing"];
    } else {
      return this.props.localizedText["completed"];
    }
  };

  getReceiptProps = () => {
    let data = [
      {
        title: this.props.localizedText["transfer-summary"],
        data: [
          {
            name: this.props.localizedText["transaction-id"],
            value: this.state.selectedTransaction.transaction_id
              ? this.state.selectedTransaction.transaction_id
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["transaction-type"],
            value: this.state.selectedTransaction.transaction_type
              ? this.state.selectedTransaction.transaction_type
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["transaction-sub-type"],
            value: this.state.selectedTransaction.transaction_subtype
              ? this.state.selectedTransaction.transaction_subtype
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["date"],
            value: this.state.selectedTransaction.creation_time
              ? this.state.selectedTransaction.creation_time
              : "-",
            type: "date",
          },
          {
            name: this.props.localizedText["from"],
            value: this.state.selectedTransaction.from
              ? this.state.selectedTransaction.from
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["to"],
            value: this.state.selectedTransaction.to
              ? this.state.selectedTransaction.to
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["description"],
            value: this.state.selectedTransaction.description
              ? this.state.selectedTransaction.description
              : "-",
            type: "normal",
          },

          {
            name: this.props.localizedText["fee-amount"],
            value: this.state.selectedTransaction.fee_amount
              ? Math.abs(this.state.selectedTransaction.fee_amount)
              : "-",
            type: "amount",
          },

          {
            currency: this.state.selectedTransaction.base_currency,
            name: this.props.localizedText["amount"],
            value: Math.abs(this.state.selectedTransaction.qty),
            type: "amount",
          },
          {
            currency: this.state.selectedTransaction.base_currency,
            name: this.props.localizedText["total-amount"],
            value: this.state.selectedTransaction.fee_amount
              ? Math.abs(Number(this.state.selectedTransaction.qty)) +
                Math.abs(Number(this.state.selectedTransaction.fee_amount))
              : Math.abs(this.state.selectedTransaction.qty),
            type: "amount",
          },
        ],
      },
    ];

    return data;
  };

  openTransactionDetails = (payment, index) => {
    if (
      payment["transaction_type"] === "HOLD" ||
      payment["transaction_type"] === "CONVERSION_DEPOSIT_HOLD"
    ) {
      this.setState({
        selectedDivIndex: index,
        selectedTransaction: payment,
        showModalApprovePayment: true,
      });
    } else {
      this.setState({
        selectedDivIndex: index,
        selectedTransaction: payment,
        showModalPaymentDetails: true,
      });
    }
  };

  approvePayment = () => {
    this.setState(
      {
        loadingSentApprove: true,
      },
      () => {
        this.service.approvePayment(
          this.props.token,
          this.state.selectedTransaction.transaction_id,
          this.setState.bind(this)
        );
      }
    );
  };

  skeletonCards = ({ cards }) => (
    <div class="div-cards">
      {cards.map(() => (
        <div class="card-payment content-container">
          <div class="metadata-column-skeleton">
            <div class="text-skeleton">
              <div class="skeleton-box title-skeleton"></div>
              <div class="skeleton-box date-skeleton"></div>
            </div>
          </div>
          <div class="balance-column-skeleton">
            <div class="skeleton-box value-skeleton"></div>
          </div>

          <div class="skeleton-box  graph-column-skeleton"></div>
          <div class="balance-column-skeleton">
            <div class="skeleton-box value-skeleton"></div>
          </div>
        </div>
      ))}
    </div>
  );

  render() {
    return (
      <React.Fragment>
        <div class="admin-payments-container">
          <div class="admin-payments-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard"}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              <div class="title-div">
                <div class="title">
                  {this.props.localizedText["admin-payments"]}
                </div>
              </div>
              <div class="filter-search-div">
                <InputGroup className="search-bar">
                  <ImSearch class="search-icon"></ImSearch>
                  <FormControl
                    onChange={(e) => {
                      utils.editSearchTermDefault(
                        e,
                        this.setState.bind(this),
                        this.state.listPayments,
                        "listPaymentsShowed",
                        ["name"]
                      );
                    }}
                    type="search"
                    id="search-bar-entities"
                    placeholder={this.props.localizedText["search-payments"]}
                    autoComplete="off"
                  />
                </InputGroup>
              </div>
              <div class="bottom-content">
                {!this.state.loadingPayments ? (
                  <div style={{ height: "100%" }}>
                    {this.state.listPayments.length > 0 &&
                    this.state.listPaymentsShowed.length > 0 ? (
                      <div class="div-cards">
                        {this.state.listPayments.map((payment, index) => (
                          <div
                            class={
                              this.state.selectedDivIndex ===
                              payment.transaction_id
                                ? "card-payment selected-card content-container"
                                : "card-payment content-container"
                            }
                            onClick={this.openTransactionDetails.bind(
                              this,
                              payment,
                              payment.transaction_id
                            )}
                          >
                            <div class="metadata-column">
                              <div class="text">
                                <div class="title">
                                  {this.getTitle(payment)}
                                </div>
                                <div class="date">
                                  {utils.formatDateUSWithTime(
                                    payment["creation_time"]
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="status-column">
                              {this.getStatus(payment)}
                            </div>

                            <div class="description-column">
                              {this.getDescription(payment)}
                            </div>

                            <div class="amount-column">
                              <div
                                class={
                                  payment["qty"] > 0
                                    ? "amount"
                                    : "amount amount-danger"
                                }
                              >
                                {utils.roundDecimals(
                                  payment["base_currency"],
                                  payment["fee_amount"]
                                    ? Number(payment["fee_amount"]) +
                                        Number(payment["qty"])
                                    : Number(payment["qty"])
                                )}
                                &nbsp;
                                {payment["base_currency"]}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.listPayments.length > 0 &&
                    this.state.listPaymentsShowed.length == 0 ? (
                      <div class="no-list-div">
                        <FaSearchMinus className="empty-icon" />
                        <div class="title">
                          {this.props.localizedText["no-result-found"]}
                        </div>
                        <div class="sub-title">
                          {
                            this.props.localizedText[
                              "we-couldn't-find-any-items-matching-your-search"
                            ]
                          }
                        </div>
                      </div>
                    ) : null}
                    {this.state.listPayments.length == 0 &&
                    this.state.listPaymentsShowed.length == 0 ? (
                      <div class="no-list-div">
                        <LuPackageSearch className="empty-icon" />
                        <div class="title">
                          {this.props.localizedText["no-payments-yet"]}
                        </div>
                        <div class="sub-title">
                          {
                            this.props.localizedText[
                              "add-payment-methods-for-easy-payments"
                            ]
                          }
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <this.skeletonCards
                    cards={[1, 2, 3, 4, 5, 6]}
                  ></this.skeletonCards>
                )}
              </div>
            </div>
            <div class="side-container"></div>
          </div>
        </div>

        <Modal
          show={this.state.showModalPaymentDetails}
          onHide={this.handleClosePaymentDetails}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["payment-details"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="detail-container">
              {Object.keys(this.state.selectedTransaction).length > 0 ? (
                <ReceiptComponent
                  data={this.getReceiptProps()}
                ></ReceiptComponent>
              ) : null}

              <div class="icons-row">
                {this.state.loadingDownload ? (
                  <div class="loader-icon">
                    <Spinner className="loader-download left-icon"></Spinner>
                  </div>
                ) : (
                  <IoMdDownload class="left-icon" onClick={this.downloadPDF} />
                )}
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <FaPrint class="right-icon" onClick={handlePrint} />
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div class="payment-detail-footer">
              {this.props.localizedText["if-you-need-help"]}
              <br />
              {this.props.localizedText["go-to-our-"]}
              <a href="https://www.blox.global/contact/">
                {this.props.localizedText["resolution-center"]}
              </a>{" "}
              {
                this.props.localizedText[
                  "for-help-with-this-transaction,-to-settle-a-dispute-or-open-a-claim"
                ]
              }
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalApprovePayment}
          onHide={this.handleCloseApprovePayment}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["approve-payment"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="approve-text">
              {
                this.props.localizedText[
                  "are-you-sure-you-want-to-approve-the-following-payment:"
                ]
              }
            </div>
            {Object.keys(this.state.selectedTransaction).length > 0 ? (
              <ReceiptComponent
                data={this.getReceiptProps()}
              ></ReceiptComponent>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleCloseApprovePayment}
            >
              {this.props.localizedText["close"]}
            </Button>

            <Button
              className="outside-primary-button"
              disabled={this.state.loadingSentApprove}
              onClick={this.approvePayment}
            >
              {this.state.loadingSentApprove ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["approve"]}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(AdminPayments);
