import { paymentAPI } from "../../../apis/PaymentAPI.jsx";
import { portfolioAPI } from "../../../apis/PortfolioAPI.jsx";

export default class PaymentInAppUserService {
  getDefaultPortfolio(token, setState) {
    portfolioAPI
      .getDefaultPortfolio(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getDefaultPortfolio success", response);
          this.getPortfolioBalances(token, setState, response.data.name);
        } else {
          console.log("getDefaultPortfolio failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getDefaultPortfolio error", error);
      });
  }

  getPortfolioBalances(token, setState, portfolioName) {
    portfolioAPI
      .getPortfolioBalances(token, portfolioName)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log("getPortfolioBalances success", response);
          setState({
            listCurrencies: response.data,
          });
        } else {
          //Failure
          console.log("getPortfolioBalances failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPortfolioBalances error", error);
      });
  }

  checkPeer(token, state, setState, setMessages) {
    paymentAPI
      .checkPeer(token, state.id)
      .then((response) => {
        console.log("success", response);
        if (response.data.code === 0) {
          setState({
            loadingSent: false,
            invalidID: false,
            name: JSON.parse(response.data.payload).name,
            email: JSON.parse(response.data.payload).email,
            phone: JSON.parse(response.data.payload).phone,
            stage: 2,
          });
        } else {
          setMessages("*couldn't-find-any-user-with-this-id");
          setState({
            loadingPeer: false,
            invalidID: true,
          });
        }
      })
      .catch((error) => {
        console.log("fail", error);

        setMessages("*couldn't-find-any-user-with-this-id");
        setState({
          loadingPeer: false,
          invalidID: true,
        });
      });
  }

  payPeer(token, stage, phone, amount, currency, code, setState) {
    paymentAPI
      .payPeer(token, phone, amount, currency, code)
      .then((response) => {
        if (response.data.code === 0) {
          setState({
            invalidCode: false,
            stage: stage + 1,
            resultSuccess: true,
          });
        } else {
          setState({
            invalidCode: false,
            stage: stage + 1,
            resultSuccess: false,
          });
        }
      })
      .catch((error) => {
        setState({
          invalidCode: false,
          stage: stage + 1,
          resultSuccess: false,
        });
      });
  }
}
