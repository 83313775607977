import { loginAPI } from "../../apis/LoginAPI.jsx";
import { utils } from "../../utils/utils.jsx";

export default class PassCodeService {
  getClientConfig(
    account,
    token,
    time,
    setState,
    setMessages,
    setAccountTokenTime,
    props
  ) {
    loginAPI
      .getClientConfig(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getClientConfig success", response.data);
          setState({
            decrypting: false,
            loadingSent: false,
          });
          setAccountTokenTime(account, token, time);
        } else {
          //Failure
          console.log("getClientConfig failure", response);
          setState({
            decrypting: false,
            loadingSent: false,
          });
          setMessages(
            "*it-looks-like-you-have-entered-a-wrong-code.-please-try-again."
          );
        }
      })
      .catch((error) => {
        //Failure
        console.log("getClientConfig error", error);

        if (error.response.status == 401) {
          utils.clearSession(props, setAccountTokenTime);
        } else {
          setState({
            decrypting: false,
            loadingSent: false,
          });
          setMessages(
            "*it-looks-like-you-have-entered-a-wrong-code.-please-try-again."
          );
        }
      });
  }

  confi;
}
