import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./vendors.css";
import GoBackButton from "../../../components/go-back-button/GoBackButton.jsx";
import Button from "react-bootstrap/Button";
import VendorsService from "./vendorsService";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { ImSearch } from "react-icons/im";
import { CgClose } from "react-icons/cg";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaSearchMinus } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { LuPackageSearch } from "react-icons/lu";
import { utils } from "../../../utils/utils";
import { Link } from "react-router-dom";

class Vendors extends Component {
  state = {
    width: window.innerWidth,
    name: null,
    listVendors: [],
    listVendorsShowed: [],
    listVendorsSkeleton: [1, 2, 3, 4, 5, 6],
    listPaymentMethods: [],
    listSelectedVendorPaymentMethods: [],
    listPayments: [],
    listPaymentsShowed: [],

    selectedDivIndex: null,
    selectedVendor: {},
    selectedPaymentMethod: {}, //to delete

    filterPaymentsPicked: "all",

    selectedButtonType: null,

    showDetailDiv: false,
    showHistoryDiv: false,

    showModalDeletePaymentMethod: false,

    loading: true,
    loadingPaymentMethods: true,
    loadingPayments: true,
    loadingSentDelete: false,
  };

  service = new VendorsService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    this.service.getVendors(this.props.token, this.setState.bind(this));
    this.service.getVendorPaymentMethods(
      this.props.token,
      this.setState.bind(this)
    );

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.tryToGoBackStage
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.phoneVerified != null && !this.props.phoneVerified) {
        utils.goToURL(this.props, "/dashboard/settings/verify-phone");
      } else if (
        this.props.emailVerified != null &&
        !this.props.emailVerified
      ) {
        utils.goToURL(this.props, "/dashboard/settings/verify-email");
      } else if (this.props.idVerified != null && !this.props.idVerified) {
        utils.goToURL(this.props, "/dashboard/settings/id-verification");
      }
    }
  }

  componentWillUnmount() {}

  reloadVendorPaymentMethods = () => {
    this.setState({
      listSelectedVendorPaymentMethods: this.getSelectedVendorPaymentMethods(
        this.state.selectedVendor.client_vendor_id
      ),
    });
  };

  tryToGoBackStage = () => {
    //remove this when visited pages are tracked
    if (
      utils.conditionalPermission(this.props.account, ["can_pay_vendor"]) &&
      utils.conditionalPermission(this.props.account, ["can_pay_peer"])
    ) {
      utils.goToURL(this.props, "/dashboard/payment-options");
    } else {
      utils.goToURL(this.props, "/dashboard/");
    }
  };

  goToVendorDetail = (vendor) => {
    this.props.history.push({
      pathname: "/dashboard/payments/vendors/detail/" + vendor.client_vendor_id,
      megastate: {
        from: "vendors",
        vendor: vendor,
        listPaymentMethods: this.state.listPaymentMethods,
      },
    });
  };

  goToVendorDetailForPayment = (index) => {
    this.props.history.push({
      pathname:
        "/dashboard/payments/vendors/detail/" +
        this.state.selectedVendor.client_vendor_id,
      megastate: {
        from: "vendors",
        vendor: this.state.selectedVendor,
        listPaymentMethods: this.state.listPaymentMethods,
        selectedPaymentIndex: index,
      },
    });
  };

  goToAddPaymentMethod = (id) => {
    this.props.history.push({
      pathname: "/dashboard/payment/vendors/payment-methods",
      megastate: {
        from: "vendors",
        client_vendor_id: id,
      },
    });
  };

  goToPayVendor = (paymentMethod) => {
    this.props.history.push({
      pathname:
        "/dashboard/payment/vendors/pay/" + paymentMethod.payment_account_id,
      megastate: {
        from: "vendors",
        paymentMethod: paymentMethod,
      },
    });
  };

  getSelectedVendorPaymentMethods = (id) => {
    const selectedPaymentMethods = this.state.listPaymentMethods.find(
      (x) => x.client_vendor_id === id
    );

    if (selectedPaymentMethods) {
      return selectedPaymentMethods.json_build_object.accounts;
    } else {
      return [];
    }
  };

  selectVendor = (event, vendor, index) => {
    event.stopPropagation();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState(
      {
        showHistoryDiv: false,
        showDetailDiv: false,
        selectedDivIndex: index,
        selectedButtonType: "pay",
      },
      () => {
        this.setState(
          {
            showDetailDiv: true,
          },
          () => {
            this.setState({
              selectedVendor: vendor,
              listSelectedVendorPaymentMethods:
                this.getSelectedVendorPaymentMethods(vendor.client_vendor_id),
            });
          }
        );
      }
    );
  };

  showVendorHistory = (event, vendor, index) => {
    event.stopPropagation();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState(
      {
        showHistoryDiv: false,
        showDetailDiv: false,
        loadingPayments: true,
        selectedDivIndex: index,
        selectedButtonType: "history",
      },
      () => {
        this.setState({
          showHistoryDiv: true,
          selectedVendor: vendor,
        });
        this.service.getPayments(
          this.props.token,
          vendor.client_vendor_id,
          this.setState.bind(this)
        );
      }
    );
  };

  closeDetailVendor = () => {
    this.setState({
      showDetailDiv: false,
      selectedButtonType: null,
      selectedVendor: {},
      selectedDivIndex: null,
    });
  };

  closeDetailHistory = () => {
    this.setState({
      showHistoryDiv: false,
      selectedButtonType: null,
      selectedVendor: {},
      selectedDivIndex: null,
    });
  };

  handleClose = () => {
    this.setState({
      showModalDeletePaymentMethod: false,
    });
  };

  openDeletePaymentMethod = (paymentMethod) => {
    this.setState({
      showModalDeletePaymentMethod: true,
      selectedPaymentMethod: paymentMethod,
    });
  };

  deletePaymentMethod = () => {
    this.setState(
      {
        loadingSentDelete: true,
      },
      () => {
        this.service.deletePaymentMethod(
          this.props.token,
          this.state.selectedPaymentMethod.payment_account_id,
          this.setState.bind(this),
          this.reloadVendorPaymentMethods.bind(this)
        );
      }
    );
  };

  goToUpdatePaymentMethod = (paymentMethod) => {
    this.props.history.push({
      pathname:
        "/dashboard/payment/vendors/" +
        this.state.selectedVendor.client_vendor_id +
        "/payment-methods/update/" +
        paymentMethod.payment_account_id,
      megastate: {
        from: "vendorDetail",
        client_vendor_id: this.state.selectedVendor.client_vendor_id,
        paymentMethod: paymentMethod,
      },
    });
  };

  getButtonClass = (baseClass, index, type) => {
    if (this.state.selectedDivIndex === index) {
      baseClass = baseClass + " inside-selected-div-button";
      if (this.state.selectedButtonType === type) {
        baseClass = baseClass + " selected-button";
      }
    }

    return baseClass;
  };

  responsiveGoToVendorDetail = (vendor) => {
    this.goToVendorDetail(vendor);
  };

  handlePaymentsFilter = (filter) => {
    if (filter == "all") {
      this.setState({
        filterPaymentsPicked: filter,
        listPaymentsShowed: this.state.listPayments,
      });
    } else {
      this.setState({
        filterPaymentsPicked: filter,
        listPaymentsShowed: utils.filterFunction(
          this.state.listPayments,
          ["status"],
          filter
        ),
      });
    }
  };

  skeletonCards = ({ cards }) => (
    <div class="div-cards">
      {cards.map((_, index) => (
        <div
          class={
            "card-vendors content-container " +
            (this.state.listVendorsSkeleton.length - 1 === index
              ? "last-indexed"
              : "")
          }
        >
          <div class="metadata-column-skeleton">
            <div class="skeleton-box image-skeleton"></div>
            <div class="text-skeleton">
              <div class="skeleton-box title-skeleton"></div>
              <div class="skeleton-box abbreviation-skeleton"></div>
            </div>
          </div>
          <div class="skeleton-box graph-column-skeleton"></div>
          <div class="balance-column-skeleton">
            <div class="skeleton-box value-skeleton"></div>
            <div class="skeleton-box percentage-skeleton"></div>
          </div>
        </div>
      ))}
    </div>
  );

  mainContent = ({ vendors }) => (
    <div style={{ height: "100%" }}>
      {this.state.listVendors.length > 0 &&
      this.state.listVendorsShowed.length > 0 ? (
        <div class="div-cards">
          {vendors.map((vendor, index) => (
            <div
              class={
                "card-vendors content-container " +
                (this.state.selectedDivIndex === index
                  ? "selected-card "
                  : "") +
                (vendors.length - 1 === index ? "last-indexed" : "")
              }
              onClick={this.responsiveGoToVendorDetail.bind(this, vendor)}
            >
              <div class="name-column">
                <div class="name">{vendor.name}</div>
                <div class="region">{vendor.location}</div>
              </div>
              <div class="info-column">
                <div class="email">{vendor.email}</div>
                <div class="phone">{vendor.phone}</div>
              </div>
              <div class="status-column">
                <div class="status">{vendor.status}</div>
              </div>

              {this.state.width > 1200 ? (
                <div class="button-column">
                  <Button
                    className={this.getButtonClass(
                      "show-payments-button inside-primary-button vendor-button",
                      index,
                      "history"
                    )}
                    onClick={(e) => this.showVendorHistory(e, vendor, index)}
                  >
                    {this.props.localizedText["show-payments"]}
                  </Button>
                  <Button
                    className={this.getButtonClass(
                      "inside-primary-button vendor-button",
                      index,
                      "pay"
                    )}
                    onClick={(e) => this.selectVendor(e, vendor, index)}
                  >
                    {this.props.localizedText["pay"]}
                  </Button>
                  <Link
                    to={{
                      pathname:
                        "/dashboard/payments/vendors/detail/" +
                        vendor.client_vendor_id,
                      megastate: {
                        from: "vendors",
                        vendor: vendor,
                        listPaymentMethods: this.state.listPaymentMethods,
                      },
                    }}
                    class={
                      this.state.selectedDivIndex === index
                        ? "icon icon-selected"
                        : "icon "
                    }
                  >
                    <IoIosArrowForward />
                  </Link>
                </div>
              ) : (
                <Link
                  to={{
                    pathname:
                      "/dashboard/payments/vendors/detail/" +
                      vendor.client_vendor_id,
                    megastate: {
                      from: "vendors",
                      vendor: vendor,
                      listPaymentMethods: this.state.listPaymentMethods,
                    },
                  }}
                  class={
                    this.state.selectedDivIndex === index
                      ? "icon icon-selected"
                      : "icon "
                  }
                >
                  <IoIosArrowForward />
                </Link>
              )}
            </div>
          ))}
        </div>
      ) : null}
      {this.state.listVendors.length > 0 &&
      this.state.listVendorsShowed.length == 0 ? (
        <div class="no-list-div">
          <FaSearchMinus className="empty-icon" />
          <div class="title">{this.props.localizedText["no-result-found"]}</div>
          <div class="sub-title">
            {
              this.props.localizedText[
                "we-couldn't-find-any-items-matching-your-search"
              ]
            }
          </div>
        </div>
      ) : null}
      {this.state.listVendors.length < 1 &&
      this.state.listVendorsShowed.length < 1 ? (
        <div class="no-list-div">
          <LuPackageSearch className="empty-icon" />
          <div class="title">
            {
              this.props.localizedText[
                utils.getWhiteLabelText("no-vendors-yet", "vendor")
              ]
            }
          </div>
          <div class="sub-title">
            {
              this.props.localizedText[
                utils.getWhiteLabelText(
                  "add-vendors-saving-payment-methods-and-easy-payments",
                  "vendor"
                )
              ]
            }
          </div>
          <Button
            className="primary-button outside-primary-button small-button"
            onClick={utils.goToURL.bind(
              this,
              this.props,
              "/dashboard/payment/vendors/create"
            )}
          >
            {
              this.props.localizedText[
                utils.getWhiteLabelText("add-vendor", "vendor")
              ]
            }
          </Button>
        </div>
      ) : null}
    </div>
  );

  vendorDetail = ({ vendor }) => (
    <div class="vendor-detail-div content-container-no-hover">
      <div class="header">
        <div class="title">
          {vendor.name + this.props.localizedText["-payment-methods"]}{" "}
        </div>
        <CgClose class="close-icon" onClick={this.closeDetailVendor}></CgClose>
      </div>

      {this.state.loadingPaymentMethods ? (
        <Spinner className="loader"></Spinner>
      ) : (
        <div class="body">
          {this.state.listSelectedVendorPaymentMethods.length > 0 ? (
            <div class="list-payment-methods">
              <div class="headers">
                <div class="name-header">
                  {this.props.localizedText["name"]}
                </div>
                <div class="type-header">
                  {this.props.localizedText["type"]}
                </div>
                <div class="icon-header"></div>
                <div class="icon-header"></div>
                <div class="button-header"></div>
              </div>
              {this.state.listSelectedVendorPaymentMethods.map(
                (paymentMethod) => (
                  <div class="payment-method-row">
                    <div class="name-header"> {paymentMethod.name}</div>
                    <div class="type-header">
                      {paymentMethod.pay_method_type}
                    </div>
                    <div class="icon-header">
                      <MdEdit
                        className="icon"
                        onClick={this.goToUpdatePaymentMethod.bind(
                          this,
                          paymentMethod
                        )}
                      ></MdEdit>
                    </div>{" "}
                    <div class="icon-header">
                      <MdDelete
                        className="icon"
                        onClick={this.openDeletePaymentMethod.bind(
                          this,
                          paymentMethod
                        )}
                      ></MdDelete>
                    </div>{" "}
                    <div class="button-header">
                      <Button
                        className="inside-primary-button pay-button"
                        onClick={this.goToPayVendor.bind(this, paymentMethod)}
                      >
                        {this.props.localizedText["pay"]}
                      </Button>
                    </div>
                  </div>
                )
              )}
            </div>
          ) : (
            <div>
              {
                this.props.localizedText[
                  utils.getWhiteLabelText(
                    "looks-like-this-vendor-doesn't-have-any-payment-methods",
                    "vendor"
                  )
                ]
              }
            </div>
          )}
        </div>
      )}
      {!this.state.loadingPaymentMethods ? (
        <div class="footer">
          <Button
            className="inside-primary-button detail-button"
            onClick={this.goToAddPaymentMethod.bind(
              this,
              this.state.selectedVendor.client_vendor_id
            )}
          >
            {this.props.localizedText["add-payment-method"]}
          </Button>
        </div>
      ) : null}
    </div>
  );

  vendorHistory = ({ vendor }) => (
    <div class="vendor-detail-div-history content-container-no-hover">
      <div class="header">
        <div class="title">
          {vendor.name + this.props.localizedText["-payment-history"]}
        </div>
        <CgClose class="close-icon" onClick={this.closeDetailHistory}></CgClose>
      </div>

      {this.state.loadingPayments ? (
        <Spinner className="loader"></Spinner>
      ) : (
        <div>
          <div class="body">
            {this.state.listPayments.length > 0 ? (
              <div class="list-payments">
                {process.env.REACT_APP_ENV == "local" ? (
                  <div class="filter-div">
                    <div class="filter-options">
                      <div
                        class={
                          "option-div " +
                          (this.state.filterPaymentsPicked == "approved"
                            ? "option-div-picked"
                            : "")
                        }
                        onClick={this.handlePaymentsFilter.bind(
                          this,
                          "approved"
                        )}
                      >
                        <div class="option">
                          {this.props.localizedText["approved"]}
                        </div>
                      </div>
                      <div
                        class={
                          "option-div " +
                          (this.state.filterPaymentsPicked == "failed"
                            ? "option-div-picked"
                            : "")
                        }
                        onClick={this.handlePaymentsFilter.bind(this, "failed")}
                      >
                        <div class="option">
                          {this.props.localizedText["failed"]}
                        </div>
                      </div>
                      <div
                        class={
                          "option-div " +
                          (this.state.filterPaymentsPicked == "pending"
                            ? "option-div-picked"
                            : "")
                        }
                        onClick={this.handlePaymentsFilter.bind(
                          this,
                          "pending"
                        )}
                      >
                        <div class="option">
                          {this.props.localizedText["pending"]}
                        </div>
                      </div>
                      <div
                        class={
                          "option-div " +
                          (this.state.filterPaymentsPicked == "all"
                            ? "option-div-picked"
                            : "")
                        }
                        onClick={this.handlePaymentsFilter.bind(this, "all")}
                      >
                        <div class="option">
                          {this.props.localizedText["all"]}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div class="headers">
                  <div class="to-column">{this.props.localizedText["to"]}</div>
                  <div class="date-column">
                    {this.props.localizedText["date"]}
                  </div>
                  {process.env.REACT_APP_ENV == "local" ? (
                    <div class="status-column">
                      {this.props.localizedText["status"]}
                    </div>
                  ) : null}
                  <div class="amount-column">
                    {this.props.localizedText["amount"]}
                  </div>
                </div>
                {this.state.listPaymentsShowed.map((payment, index) => (
                  <div
                    class="payments-row"
                    onClick={this.goToVendorDetailForPayment.bind(this, index)}
                  >
                    <div class="to-column"> {payment.account}</div>
                    <div class="date-column">
                      {utils.formatDateUS(payment.creation_time)}
                    </div>{" "}
                    {payment.status == "Approved" &&
                    process.env.REACT_APP_ENV == "local" ? (
                      <div class="status-column">
                        <div class="status success-status">
                          {this.props.localizedText["approved"][0]}
                        </div>
                      </div>
                    ) : null}
                    {payment.status == "Failed" &&
                    process.env.REACT_APP_ENV == "local" ? (
                      <div class="status-column">
                        <div class="status danger-status">
                          {this.props.localizedText["failed"][0]}
                        </div>
                      </div>
                    ) : null}{" "}
                    {payment.status == "Pending" &&
                    process.env.REACT_APP_ENV == "local" ? (
                      <div class="status-column">
                        <div class="status pending-status">
                          {this.props.localizedText["pending"][0]}
                        </div>
                      </div>
                    ) : null}
                    <div class="amount-column">
                      {" "}
                      {utils.roundDecimals("", Number(payment.amount))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {
                  this.props.localizedText[
                    utils.getWhiteLabelText(
                      "looks-like-this-you-haven't-paid-this-vendor-yet",
                      "vendor"
                    )
                  ]
                }
              </div>
            )}
          </div>

          <div class="footer">
            <Button
              className="inside-primary-button detail-button"
              onClick={this.goToAddPaymentMethod.bind(
                this,
                this.state.selectedVendor.client_vendor_id
              )}
            >
              {this.props.localizedText["pay"]}
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  render() {
    return (
      <React.Fragment>
        <div class="vendors-container">
          <div class="vendors-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={this.tryToGoBackStage}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              <div class="title-div">
                <div class="title">
                  {
                    this.props.localizedText[
                      utils.getWhiteLabelText("vendors", "vendor")
                    ]
                  }
                </div>
                <Link
                  to="/dashboard/payment/vendors/create"
                  class="primary-button outside-primary-button small-button"
                >
                  {
                    this.props.localizedText[
                      utils.getWhiteLabelText("add-vendor", "vendor")
                    ]
                  }
                </Link>
              </div>
              <div class="filter-search-div">
                <InputGroup className="search-bar">
                  <ImSearch class="search-icon"></ImSearch>
                  <FormControl
                    onChange={(e) => {
                      utils.editSearchTermDefault(
                        e,
                        this.setState.bind(this),
                        this.state.listVendors,
                        "listVendorsShowed",
                        ["name", "location"]
                      );
                    }}
                    type="search"
                    id="search-bar-entities"
                    placeholder={
                      this.props.localizedText[
                        utils.getWhiteLabelText("search-for-vendors", "vendor")
                      ]
                    }
                    autoComplete="off"
                  />
                </InputGroup>
              </div>
              <div class="bottom-content">
                {!this.state.loading ? (
                  <this.mainContent
                    vendors={this.state.listVendorsShowed}
                  ></this.mainContent>
                ) : (
                  <this.skeletonCards
                    cards={this.state.listVendorsSkeleton}
                  ></this.skeletonCards>
                )}
              </div>
            </div>
            <div class="side-container">
              {this.state.showDetailDiv ? (
                <this.vendorDetail
                  vendor={this.state.selectedVendor}
                ></this.vendorDetail>
              ) : null}
              {this.state.showHistoryDiv ? (
                <this.vendorHistory
                  vendor={this.state.selectedVendor}
                ></this.vendorHistory>
              ) : null}
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showModalDeletePaymentMethod}
          onHide={this.handleClose}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["delete-payment-method"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.localizedText[
              "are-you-sure-you-want-to-delete-the-payment-method"
            ] + " "}
            <b>{this.state.selectedPaymentMethod.name}</b>
            {"?"}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleClose}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button
              className="verify-button outside-primary-button"
              onClick={this.deletePaymentMethod}
              disabled={this.state.loadingSentDelete}
            >
              {this.state.loadingSentDelete ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["delete"]}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(Vendors);
