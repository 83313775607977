import axios from "axios";
import { vendorsAPI } from "../../../apis/VendorsAPI.jsx";
import { refdataAPI } from "../../../apis/RefdataAPI.jsx";
import { utils } from "../../../utils/utils";

export default class VendorDetailService {
  getVendors(token, setState, id, listCountries) {
    vendorsAPI
      .getVendors(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getVendors success", response);
          if (Array.isArray(response.data)) {
            const vendor = response.data.find((x) => x.client_vendor_id === id);
            console.log("vendoor", vendor);
            setState({
              vendor: vendor,

              name: vendor.name,
              email: vendor.email,
              countryCode: utils.filterFunction(
                listCountries,
                ["gec"],
                vendor.phone_country
              )[0]["gec"],
              phoneNumber: vendor.phone,
              location: utils.filterFunction(
                listCountries,
                ["gec"],
                vendor.location
              )[0]["gec"],
              address: vendor.address ? vendor.address : "",
              city: vendor.city ? vendor.city : "",
            });
          }
        } else {
          console.log("getVendors failure", response);
          setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        //error
        console.log("getVendors error", error);
        setState({
          loading: false,
        });
      });
  }

  getVendorPaymentMethods(
    token,
    setState,
    getSelectedVendorPaymentMethods,
    id
  ) {
    vendorsAPI
      .getVendorPaymentMethods(token)
      .then((response) => {
        console.log("getVendorPaymentMethods", response);
        if (response.status === 200 && response.data) {
          if (Array.isArray(response.data)) {
            setState(
              {
                listPaymentMethods: response.data,
                loadingPaymentMethods: false,
              },
              () => {
                const listSelectedVendorPaymentMethods =
                  getSelectedVendorPaymentMethods(id);
                setState({
                  listSelectedVendorPaymentMethods:
                    listSelectedVendorPaymentMethods,
                  listSelectedVendorPaymentMethodsShowed:
                    listSelectedVendorPaymentMethods,
                });
              }
            );
          } else {
            setState(
              {
                listPaymentMethods: [],
                loadingPaymentMethods: false,
              },
              () => {
                setState({
                  listSelectedVendorPaymentMethods: [],
                  listSelectedVendorPaymentMethodsShowed: [],
                });
              }
            );
          }
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getPayments(
    token,
    vendorId,
    setState,
    openPaymentDetailsFromSelectedPayment
  ) {
    vendorsAPI
      .getPayments(token, vendorId)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getPayments success", response);
          setState(
            {
              loadingPayments: false,
              listPayments:
                process.env.REACT_APP_ENV == "local"
                  ? this.getPaymentsMockResponse()
                  : Array.isArray(response.data)
                  ? response.data
                  : [],
              listPaymentsShowed:
                process.env.REACT_APP_ENV == "local"
                  ? this.getPaymentsMockResponse()
                  : Array.isArray(response.data)
                  ? response.data
                  : [],
            },
            () => {
              openPaymentDetailsFromSelectedPayment();
            }
          );
        } else {
          console.log("getPayments failure", response);
          setState({
            loadingPayments: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPayments error", error);
        setState({
          loadingPayments: false,
        });
      });
  }

  deletePaymentMethod(token, id, reloadInfo, setState) {
    vendorsAPI
      .deleteVendorPaymentAccount(token, id)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("deletePaymentMethod success", response);
          setState(
            { loadingSentDelete: false, showModalDeletePaymentMethod: false },
            () => {
              reloadInfo();
            }
          );
        } else {
          console.log("deletePaymentMethod failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("deletePaymentMethod error", error);
      });
  }

  deleteVendor(token, id, goToDeleteVendorResult) {
    vendorsAPI
      .deleteVendor(token, id)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("deleteVendor success", response);
          goToDeleteVendorResult("true");
        } else {
          console.log("deleteVendor failure", response);
          goToDeleteVendorResult("false");
        }
      })
      .catch((error) => {
        //Failure
        console.log("deleteVendor error", error);
        goToDeleteVendorResult("false");
      });
  }

  updateVendor(token, state, id, newVendor, setState, setMessages) {
    vendorsAPI
      .updateVendor(token, state, id)
      .then((response) => {
        console.log("createVendor", response);
        if (response.data.code === 0) {
          setState({
            vendor: newVendor,
            loadingSentUpdate: false,
          });
        } else {
          setMessages(
            utils.getWhiteLabelText(
              "*an-error-has-ocurred-while-trying-to-update-the-vendor",
              "vendor"
            )
          );

          setState({
            loadingSentUpdate: false,
          });
        }
      })
      .catch((error) => {
        setMessages(
          utils.getWhiteLabelText(
            "*an-error-has-ocurred-while-trying-to-update-the-vendor",
            "vendor"
          )
        );
        setState({
          loadingSentUpdate: false,
        });
      });
  }

  getAllCountries(token, setState, loadInfo) {
    refdataAPI
      .getAllCountries(token)
      .then((response) => {
        console.log("getCountries", response);
        if (response.status === 200 && response.data) {
          setState(
            {
              listCountries: utils.orderAlphabetically(
                response.data.filter((country) => country.phone_area_code),
                "country"
              ),
              listAreaCodes: utils.removeObjectDuplicates(
                response.data
                  .filter((country) => country.phone_area_code)
                  .sort((a, b) => {
                    const phone_area_codeA = a.phone_area_code;
                    const phone_area_codeB = b.phone_area_code;
                    if (phone_area_codeA < phone_area_codeB) {
                      return -1;
                    }
                    if (phone_area_codeA > phone_area_codeB) {
                      return 1;
                    }
                    return 0;
                  }),
                "phone_area_code"
              ),
              loadingCountries: false,
            },
            () => {
              loadInfo();
            }
          );
        } else {
        }
      })
      .catch((error) => {
        //Failure
      });
  }

  getPaymentsMockResponse() {
    return [
      {
        creation_time: "2024-08-16T17:53:13.241839+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "b70526dc-7e81-47f0-b5b9-aea2dcf611ea",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "67230564283832301",
        amount: 100.0,
        paid_with: "100 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-14T00:29:35.235354+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "731f6055-40a8-4470-b721-9adaf23b54ad",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66995146277428541",
        amount: 120.0,
        paid_with: "120 USD @ 1",
        notes: null,
        status: "Failed",
      },
      {
        creation_time: "2024-08-14T00:28:22.663816+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "308d3532-3833-42dc-87a9-9b6d78eecad0",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66995073705895482",
        amount: 120.0,
        paid_with: "120 USD @ 1",
        notes: null,
        status: "Pending",
      },
      {
        creation_time: "2024-08-14T00:25:50.114956+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "2e9864ae-1549-4570-b616-2494b7ae6def",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66994921157026152",
        amount: 120.0,
        paid_with: "120 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-13T23:52:11.027961+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "3d544739-e7de-4d4e-af81-12555d48b007",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66992902070034883",
        amount: 123.0,
        paid_with: "123 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-13T23:37:52.596097+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "e69fc3eb-5426-41d8-865a-a06c7daa1987",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66992043638172413",
        amount: 123.0,
        paid_with: "123 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-13T23:37:16.587941+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "3cd70692-0e9d-4df6-8b3b-f7d67ca37e74",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66992007630014671",
        amount: 120.0,
        paid_with: "120 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-13T23:35:46.149576+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "8899b44f-1b7b-451e-94f5-2c3da4c282f8",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66991917191647715",
        amount: 120.0,
        paid_with: "120 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-13T23:09:56.936573+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "fba7459b-88ff-4a75-b467-6f803ba909ce",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66990367978653479",
        amount: 100.0,
        paid_with: "100 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-13T15:48:28.07944+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "502f0b37-eb78-418d-984a-7a55e73ac2ab",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66963879121522055",
        amount: 3831.66,
        paid_with:
          "0.059999999999999997779553950749686919152736663818359375 BTC @ 63860.94000",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-10T16:55:21.192688+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "4f687860-5fb8-4456-8618-6a70e16b200b",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66708692234767321",
        amount: 100.0,
        paid_with: "100 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-10T16:43:11.510883+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "46433287-4592-437a-b13d-367fe9e4c065",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66707962552953144",
        amount: 100.0,
        paid_with: "100 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-10T16:40:37.241206+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "973f7d8d-3029-46b1-82d8-fd6f51f8729c",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66707808283280406",
        amount: 1300.0,
        paid_with: "1300 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-08T14:50:37.824704+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "0f2ac8b1-008b-480a-aa75-ad60c5090a2d",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66528408866777912",
        amount: 100.0,
        paid_with: "100 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-08T14:26:09.010937+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "af77403f-d152-4a8d-b817-aa80331bc596",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "",
        hold_transaction_id: "66526940053011697",
        amount: 1300.0,
        paid_with: "1300 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-08-07T13:44:46.845934+00:00",
        vendor: "vendor example 12345",
        vendor_name:
          "vendor example 12345:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "09d4436c-d3c7-4a9f-8449-8caf1626a3e0",
        pay_method_type: "paypal",
        account: "luis eduardo kcomt lam",
        account_id: "fb32f5b1-11f3-403c-a51c-c939b5ca54df",
        description: "descripction",
        hold_transaction_id: "66438057887996602",
        amount: 300.0,
        paid_with: "300 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-06-17T18:42:23.051546+00:00",
        vendor: "vendor example 123",
        vendor_name: "vendor example 123:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "46eece4a-bebc-41d1-becf-4b863d89e250",
        pay_method_type: "paypal",
        account: "paypal124",
        account_id: "85e33eec-4b1e-4a3c-a382-ce9609651345",
        description: null,
        hold_transaction_id: "62049514093622287",
        amount: 1234.0,
        paid_with: "1234.00000 USD @ 1",
        notes: null,
        status: "Approved",
      },
      {
        creation_time: "2024-05-07T16:13:53.211659+00:00",
        vendor: "vendor example 123",
        vendor_name: "vendor example 123:68b4a87a-8cd1-4501-bb8a-aad19c43e279",
        external_memo: "For you",
        payment_request_id: "fe472f62-fdd9-44e2-890a-6a5bb7d25b21",
        pay_method_type: "paypal",
        account: "paypal2",
        account_id: "33593b58-f71a-400d-8409-7c35413414c9",
        description: "444",
        hold_transaction_id: "58498204253738049",
        amount: 100.0,
        paid_with: "100.0000000000000000 USD @ 1",
        notes: null,
        status: "Approved",
      },
    ];
  }
}
