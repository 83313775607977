import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./payMeCrypto.css";
import PayMeCryptoService from "./PayMeCryptoService.jsx";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { utils } from "../../../utils/utils.jsx";
import ConversionLinkComponent from "../../conversionLink/ConversionLink.jsx";
import { Spinner } from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import ErrorMessageComponent from "../../errorMessage/ErrorMessage.jsx";

class PayMeCrypto extends Component {
  state = {
    width: window.innerWidth,

    listNetworks: [],

    crypto: "",
    network: "",
    amount: "",
    payerEmail: "",
    payerName: "",

    loadingSent: false,
    loadingNetworks: false,

    invalidCrypto: false,
    invalidAmount: false,
    invalidNetwork: false,
    invalidPayerEmail: false,
    invalidPayerName: false,

    resultSuccess: true,
  };

  service = new PayMeCryptoService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  handleChangeForCrypto = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (this.state.crypto != value) {
      if (value != "" && value != null) {
        this.setState(
          {
            [name]: value,
          },
          () => {
            console.log(
              "things",
              this.props.listCryptos.filter(
                (element) => element.symbol == this.state.crypto
              )
            );

            this.setState(
              {
                listNetworks: this.props.listCryptos.filter(
                  (element) => element.symbol == this.state.crypto
                )[0].networks,
              },
              () => {
                this.setState({
                  network:
                    this.state.listNetworks.length == 1
                      ? this.state.listNetworks[0]
                      : "",
                });
              }
            );
          }
        );
      } else {
        if (value == "") {
          this.setState({
            listNetworks: [],
            network: "",
          });
        }
        this.setState({
          [name]: value,
        });
      }
    }
  };

  handleCheckout = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.crypto != "" &&
          this.state.network != "" &&
          this.state.amount != "" &&
          this.state.payerEmail != "" &&
          this.state.payerName != ""
        ) {
          this.setState(
            {
              invalidCrypto: false,
              invalidAmount: false,
              invalidNetwork: false,
              invalidPayerEmail: false,
              invalidPayerName: false,

              loadingSent: true,
            },
            () => {
              this.service.checkoutUrlCrypto(
                this.props.token,
                this.state.crypto,
                this.state.network,
                this.state.amount,
                this.state.payerEmail,
                this.state.payerName,
                this.setState.bind(this),
                this.setMessages.bind(this),
                this.props.goToResultStage.bind(this)
              );
            }
          );
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCrypto: this.state.crypto == "",
            invalidNetwork: this.state.network == "",
            invalidAmount: this.state.amount == "",
            invalidPayerEmail: this.state.payerEmail == "",
            invalidPayerName: this.state.payerName == "",
            invalidInvoiceNumber: this.state.invoiceNumber == "",
          });
        }
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="pay-me-credit-card-div">
          {" "}
          {utils.conditionalPermission(this.props.account, ["can_convert"]) ? (
            <ConversionLinkComponent
              width={this.state.width}
              currencyFrom={this.state.crypto}
              currencyTo={"USD"}
              region={this.props.region}
              localizedText={this.props.localizedText}
            />
          ) : null}
          <div class="section">
            <div class="left-input content-container">
              <Form.Group className="form-div mb-3">
                <Form.Label className={"input-label-inside-div"}>
                  {
                    this.props.localizedText[
                      "which-asset-would-you-like-to-recieve?"
                    ]
                  }
                </Form.Label>
                <InputGroup className="mb-3 input-field">
                  <Form.Select
                    variant="outline-secondary"
                    onChange={(e) => this.handleChangeForCrypto(e)}
                    name="crypto"
                    style={{
                      width: "100%",
                      flex: "0 1 auto",
                      textAlign: "start",
                    }}
                    isInvalid={this.state.invalidCrypto}
                    disabled={this.state.loadingSent}
                  >
                    <option></option>
                    {this.props.listCryptos.map((currency) => (
                      <option value={currency.symbol}>{currency.symbol}</option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
              <div class="message-div">
                *Please select a crypto asset and then fill in the amount, email
                and payer name
              </div>
            </div>
            <div class="right-div">
              {this.state.crypto != "" && !this.state.loadingConversion ? (
                <div class="value-input  fade-in-up">
                  <Form.Group className="mb-3">
                    <Form.Label className={"input-label-outside-div"}>
                      {
                        this.props.localizedText[
                          "which-blockchain-would-you-like-to-use?"
                        ]
                      }
                    </Form.Label>
                    <InputGroup className="input-field">
                      <Form.Select
                        variant="outline-secondary"
                        onChange={(e) =>
                          utils.handleChange(e, this.setState.bind(this))
                        }
                        name="network"
                        style={{
                          width: "100%",
                          flex: "0 1 auto",
                          textAlign: "start",
                        }}
                        disabled={
                          this.state.loadingSent ||
                          this.state.listNetworks.length <= 1
                        }
                      >
                        {this.state.listNetworks.length == 1 ? (
                          <option>{this.state.listNetworks[0]}</option>
                        ) : null}
                        {this.state.listNetworks.length != 1 ? (
                          <option></option>
                        ) : null}
                        {this.state.listNetworks.length != 1 ? (
                          <>
                            {" "}
                            {this.state.listNetworks.map((network) => (
                              <option value={network}>{network}</option>
                            ))}
                          </>
                        ) : null}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="form-div mb-3">
                    <Form.Label className="input-label-outside-div">
                      {this.props.localizedText["amount"]}
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="input-text no-background-input-outside"
                        type="number"
                        name="amount"
                        value={this.state.amount}
                        isInvalid={this.state.invalidAmount}
                        disabled={this.state.loadingSent}
                        autoComplete="off"
                        onChange={(e) =>
                          utils.handleChange(e, this.setState.bind(this))
                        }
                        onWheel={(e) => e.target.blur()}
                      />{" "}
                      <InputGroup.Text
                        className={
                          this.state.invalidAmount
                            ? "outside-input-symbol input-symbol-danger"
                            : "outside-input-symbol"
                        }
                      >
                        {this.state.crypto}
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="input-label-outside-div">
                      {this.props.localizedText["customer-name"]}
                    </Form.Label>
                    <FormControl
                      name="payerName"
                      value={this.state.payerName}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      isInvalid={this.state.invalidPayerName}
                      className="input-field"
                      disabled={this.state.loadingSent}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="input-label-outside-div">
                      {this.props.localizedText["payer-email"]}
                    </Form.Label>
                    <FormControl
                      name="payerEmail"
                      value={this.state.payerEmail}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      isInvalid={this.state.invalidPayerEmail}
                      className="input-field"
                      disabled={this.state.loadingSent}
                      autoComplete="off"
                    />
                  </Form.Group>
                </div>
              ) : null}
              {this.state.loadingConversion ? (
                <div class="fade-in-up loading-quote">
                  <Spinner className="loading-conversions" />
                </div>
              ) : null}
            </div>
          </div>
          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>
          {this.state.crypto != "" ? (
            <div class="buttons-div fade-in-up">
              <Button
                className="primary-button outside-primary-button"
                onClick={this.handleCheckout}
                disabled={this.state.loadingSent}
              >
                {this.state.loadingSent ? (
                  <Spinner className="loader-send-outside-div-button"></Spinner>
                ) : null}
                {this.props.localizedText["send-payment-request"]}
              </Button>

              <Button
                className="secondary-button outside-secondary-button"
                onClick={this.props.tryToGoBackStage}
                disabled={this.state.loadingSent}
              >
                {this.props.localizedText["cancel"]}
              </Button>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PayMeCrypto);
