import { portfolioAPI } from "../../../apis/PortfolioAPI.jsx";

export default class CompanyDailyStatisticsService {
  generateDailyFile(
    token,
    startDate,
    endDate,
    setState,
    setMessages,
    downloadPDF
  ) {
    portfolioAPI
      .generateDailyFile(token, startDate, endDate)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("generateDailyFile success", response);
          setState({
            pdfGenerated: {
              name: "ds" + "-" + endDate + ".pdf",
              data: response.data,
              endDate: endDate,
            },
          });
          downloadPDF(response.data, "ds" + "-" + endDate);
        } else {
          console.log("generateDailyFile failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-generate-the-file"
          );
          setState({
            loadingDownload: false,
          });
        }
      })
      .catch((error) => {
        console.log("generateDailyFile error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-generate-the-file");
        setState({ loadingDownload: false });
      });
  }
}
