import { portfolioAPI } from "../../../apis/PortfolioAPI.jsx";
import { loginAPI } from "../../../apis/LoginAPI.jsx";
import { withdrawAPI } from "../../../apis/WithdrawAPI.jsx";

export default class WithdrawCashService {
  withdrawCash(token, currency, amount, note, code, setState, goToResult) {
    portfolioAPI
      .getDefaultPortfolio(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          withdrawAPI
            .withdrawCash(
              token,
              response.data.name,
              currency,
              amount,
              note,
              code
            )
            .then((response) => {
              console.log("simple_funds_out_cash", response);
              if (response.status === 200 && response.data) {
                goToResult(true, JSON.parse(response.data.payload));
              } else {
                goToResult(false, null);
              }
            })
            .catch((error) => {
              //Failure
              console.log("simple_funds_out_cash error", response);
              goToResult(false, null);
            });
        } else {
          console.log("getDefaultPortfolio failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getDefaultPortfolio error", error);
      });
  }

  getClientConfig(token, setState) {
    loginAPI
      .getClientConfig(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("config", response);
          setState({
            authChoice:
              response.data.auth_choice == "auth_pending" ||
              response.data.auth_choice == "auth_declined"
                ? "Phone"
                : "Google Auth",
          });

          if (
            response.data.auth_choice == "auth_pending" ||
            response.data.auth_choice == "auth_declined"
          ) {
            this.confirmPhone(setState);
          }
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  confirmPhone(token, setState) {
    loginAPI
      .confirmPhone(token)
      .then((response) => {
        if (response.status === 200) {
          //Success
          var mySubString = response.data.payload.substring(
            response.data.payload.indexOf("[") + 1,
            response.data.payload.lastIndexOf("]]")
          );

          setState({
            confirmPhoneMessage:
              "xxx-xxx-" +
              mySubString.slice(mySubString.length - 3, mySubString.length),
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  validatePhone(token, code, setState, setMessages, doWithdraw) {
    loginAPI
      .validatePhone(token, code)
      .then((response) => {
        console.log("responssse", response);
        if (response.status === 200 && response.data.success) {
          //Success
          doWithdraw();
        } else {
          //Failure
          setMessages("*we-couldn't-validate-the-entered-code");
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("*we-couldn't-validate-the-entered-code");
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateAuth(token, code, setState, setMessages, doWithdraw) {
    loginAPI
      .validateAuth(token, code)
      .then((response) => {
        console.log("responssse", response);
        if (response.status === 200 && response.data.success) {
          //Success
          doWithdraw();
        } else {
          //Failure
          setMessages("*we-couldn't-validate-the-entered-code");
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("*we-couldn't-validate-the-entered-code");
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }
}
