import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./PluginDetail.css";
import GoBackButton from "../../../components/go-back-button/GoBackButton.jsx";
import Button from "react-bootstrap/Button";
import PluginDetailService from "./PluginDetailService";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { TabContent } from "react-bootstrap";
import { ImSearch } from "react-icons/im";
import { AiFillCopy } from "react-icons/ai";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaSearchMinus, FaPrint, FaCheck } from "react-icons/fa";
import { LuPackageSearch } from "react-icons/lu";
import { utils } from "../../../utils/utils";
import { IoMdDownload, IoMdOpen } from "react-icons/io";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ReceiptComponent from "../../../components/receipt/Receipt.jsx";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessage.jsx";

class PluginDetail extends Component {
  state = {
    plugin: {},

    listPayments: [],
    listPaymentsShowed: [],

    listPaymentMethods: [],
    listCustomFields: [],

    selectedDivIndex: null,
    selectedPayment: {},
    selectedButtonType: null,

    showModalPaymentDetails: false,
    showModalApprovePayment: false,
    showModalDeletePlugin: false,

    tabPicked: "payments",
    loadingPayments: true,
    loadingSentUpdateDelete: false,

    description: "",
    paymentMethods: [],
    cryptos: [],
    customFields: [],
    htmlSchemas: [],
    key: "",
    value: "",
    invoiceAfterPayment: false,
    status: true,

    invalidDescription: false,
    invalidPaymentMethods: false,
    invalidCryptos: false,

    loadingPaymentMethods: true,
    loadingCryptos: true,
    loadingCustomFields: true,
    loadingChangeStatusSent: false,
    loadingSentUpdate: false,
    loadingSentDelete: false,
    loadingSentApprove: false,
    loadingSentReject: false,

    scriptCopied: false,
  };

  service = new PluginDetailService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      this.goToURL("/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      this.goToURL("/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      this.goToURL("/dashboard/settings/id-verification");
    }

    this.loadInfo();

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard/pay-me/plugins"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard/pay-me/plugins"
      )
    );
  }

  loadInfo() {
    // Comes from payment detail click
    if (this.props.location.megastate && this.props.location.megastate.plugin) {
      const plugin = { ...this.props.location.megastate.plugin };
      this.setState(
        {
          plugin,
          description: plugin.description,
          paymentMethods: Array.from(plugin.paymentMethods),
          cryptos: Array.from(plugin.cryptosAccepted),
          customFields: Array.from(plugin.customFields),
          htmlSchemas: Object.entries(
            this.props.location.megastate.plugin.htmlSchema
          ).map(([key, value]) => {
            return { key: [key], value: [value][0] };
          }),
          invoiceAfterPayment: plugin.invoiceAfterPayment,
          status: plugin.active,
        },
        () => {
          console.log("invoiceAfterPayment", this.state.invoiceAfterPayment);
          this.service.getPayments(
            this.props.token,
            this.props.match.params.id,
            this.setState.bind(this)
          );
        }
      );
    } else {
      // Call service
      this.service.getPlugins(
        this.props.token,
        this.props.match.params.id,
        this.setState.bind(this),
        () => {
          this.service.getPayments(
            this.props.token,
            this.props.match.params.id,
            this.setState.bind(this)
          );
        }
      );
    }

    this.service.getPaymentMethods(this.props.token, this.setState.bind(this));
    this.service.getAvailableCryptos(
      this.props.token,
      this.setState.bind(this)
    );
    this.service.getCustomFields(this.props.token, this.setState.bind(this));

    if (
      this.props.location.megastate &&
      this.props.location.megastate.forEdit
    ) {
      this.clickTab("settings");
    }
  }

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  clickTab = (tabClicked) => {
    this.setState({
      tabPicked: tabClicked,
      selectedDivIndex: null,
    });
  };

  getClassForStatus = (status) => {
    if (status == "COMPLETED") {
      return "success-status";
    } else if (status == "FAILED") {
      return "danger-status";
    } else if (status == "CANCELLED") {
      return "dull-status";
    } else {
      return "pending-status";
    }
  };

  skeletonCards = ({ cards }) => (
    <div class="div-cards-container">
      <div class="headers">
        <div class="payer-info-column">
          {this.props.localizedText["payer-info"]}
        </div>
        <div class="date-column"></div>
        <div class="status-column"></div>
        <div class="payment-method-column">
          {this.props.localizedText["payment-method"]}
        </div>
        <div class="currency-column">
          {this.props.localizedText["currency"]}
        </div>
        <div class="amount-column">
          {this.props.localizedText["amount-in-usd"]}
        </div>
      </div>
      <div class="div-cards">
        {cards.map((portfolio) => (
          <div class="card-plugin content-container">
            <div class="payer-info-column-skeleton">
              <div class="name skeleton-box"></div>
              <div class="email skeleton-box"></div>
            </div>
            <div class="date-column-skeleton"></div>
            <div class="status-column-skeleton"></div>
            <div class="payment-method-column-skeleton"></div>
            <div class="currency-column-skeleton"></div>
            <div class="amount-column-skeleton"></div>
          </div>
        ))}
      </div>{" "}
    </div>
  );

  listPayments = ({ payments }) => (
    <div style={{ height: "100%" }}>
      {" "}
      <InputGroup className="search-bar search-plugin-detail">
        <ImSearch class="search-icon"></ImSearch>
        <FormControl
          onChange={(e) => {
            utils.editSearchTermDefault(
              e,
              this.setState.bind(this),
              this.state.listPayments,
              "listPaymentsShowed",
              ["payerFullName", "payerEmail", "paymentMethod"]
            );
          }}
          type="search"
          id="search-bar-entities"
          placeholder={this.props.localizedText["search-payments"]}
        />
      </InputGroup>
      {this.state.listPayments.length > 0 &&
      this.state.listPaymentsShowed.length > 0 ? (
        <div class="div-cards-container">
          <div class="headers">
            <div class="payer-info-column">
              {this.props.localizedText["payer-info"]}
            </div>
            <div class="date-column"></div>
            <div class="status-column"></div>
            <div class="payment-method-column">
              {this.props.localizedText["payment-method"]}
            </div>
            <div class="currency-column">
              {this.props.localizedText["currency"]}
            </div>
            <div class="amount-column">
              {this.props.localizedText["amount-in-usd"]}
            </div>
          </div>
          <div class="div-cards">
            {payments.map((payment, index) => (
              <div
                class={
                  "card-plugin cursor-pointer content-container " +
                  (this.state.selectedDivIndex === index
                    ? "selected-card "
                    : "") +
                  (payments.length - 1 === index ? "last-indexed" : "")
                }
                onClick={this.openPaymentDetails.bind(this, payment, index)}
              >
                <div class="payer-info-column">
                  <div class="name">{payment.payerFullName}</div>
                  <div class="email">{payment.payerEmail}</div>
                </div>{" "}
                <div class="date-column">
                  {utils.formatDateUSWithTime(payment.updatedAt)}
                </div>
                <div
                  class={
                    "status-column " + this.getClassForStatus(payment.status)
                  }
                >
                  {payment.status.toLowerCase()}
                </div>
                <div class="payment-method-column">{payment.paymentMethod}</div>
                <div class="currency-column">{payment.currency}</div>
                <div class="amount-column">${payment.amount}</div>
              </div>
            ))}{" "}
          </div>
        </div>
      ) : null}
      {this.state.listPayments.length > 0 &&
      this.state.listPaymentsShowed.length == 0 ? (
        <div class="no-list-div">
          <FaSearchMinus className="empty-icon" />
          <div class="title">{this.props.localizedText["no-result-found"]}</div>
          <div class="sub-title">
            {
              this.props.localizedText[
                "we-couldn't-find-any-items-matching-your-search"
              ]
            }
          </div>
        </div>
      ) : null}
      {this.state.listPayments.length == 0 &&
      this.state.listPaymentsShowed.length == 0 ? (
        <div class="no-list-div">
          <LuPackageSearch className="empty-icon" />
          <div class="title">{this.props.localizedText["no-payments-yet"]}</div>
          <div class="sub-title">
            {this.props.localizedText["add-payment-methods-for-easy-payments"]}
          </div>
        </div>
      ) : null}
    </div>
  );

  openPaymentDetails = (payment, index) => {
    if (payment.status == "ANALYSIS" && process.env.REACT_APP_ENV != "prod") {
      this.setState({
        selectedDivIndex: index,
        selectedPayment: payment,
        showModalApprovePayment: true,
      });
    } else {
      this.setState({
        selectedDivIndex: index,
        selectedPayment: payment,
        showModalPaymentDetails: true,
      });
    }
  };

  handleClosePaymentDetails = () => {
    this.setState({
      selectedDivIndex: null,
      showModalPaymentDetails: false,
    });
  };

  handleCloseApprovePayment = () => {
    this.setState({
      selectedDivIndex: null,
      showModalApprovePayment: false,
    });
  };

  getReceiptProps = () => {
    var arrayData = [
      {
        name: this.props.localizedText["payment-id"],
        value: this.state.selectedPayment.paymentId,
        type: "normal",
      },
      {
        name: this.props.localizedText["payer-full-name"],
        value: this.state.selectedPayment.payerFullName,
        type: "normal",
      },
      {
        name: this.props.localizedText["payer-email"],
        value: this.state.selectedPayment.payerEmail,
        type: "normal",
      },
      {
        name: this.props.localizedText["payment-method"],
        value: this.state.selectedPayment.paymentMethod,
        type: "normal",
      },
      {
        name: this.props.localizedText["currency"],
        value: this.state.selectedPayment.currency,
        type: "normal",
      },
      {
        name: this.props.localizedText["created-at"],
        value: this.state.selectedPayment.createdAt,
        type: "date",
      },
      {
        name: this.props.localizedText["updated-at"],
        value: this.state.selectedPayment.updatedAt,
        type: "date",
      },
      {
        name: this.props.localizedText["status"],
        value: this.state.selectedPayment.status,
        class: this.getClassForStatus(this.state.selectedPayment.status),
        type: "status",
      },
      {
        currency: "",
        name: this.props.localizedText["amount-in-usd"],
        value: "$" + this.state.selectedPayment.amount,
        type: "amount",
      },
    ];

    var data = [
      {
        title: this.props.localizedText["transfer-summary"],
        data: arrayData,
      },
    ];

    return data;
  };

  downloadPDF = () => {
    this.setState(
      {
        loadingDownload: true,
      },
      () => {
        utils.generatePDFFromReceipt(
          this.state.selectedPayment.account +
            "-" +
            this.state.selectedPayment.amount,
          this.setState.bind(this)
        );
      }
    );
  };

  updatePlugin = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.description != "" &&
          this.state.paymentMethods.length > 0
        ) {
          if (
            (this.state.paymentMethods.includes("CRYPTO") &&
              this.state.cryptos.length > 0) ||
            !this.state.paymentMethods.includes("CRYPTO")
          ) {
            this.setState(
              {
                invalidDescription: false,
                errorMessageType: "",
                loadingSentUpdate: true,
              },
              () => {
                const htmlSchemaObject = this.state.htmlSchemas.reduce(
                  (acc, schema) => {
                    acc[schema.key] = schema.value;
                    return acc;
                  },
                  {}
                );

                this.service.updatePlugin(
                  this.props.token,
                  this.props.match.params.id,
                  this.state.description,
                  this.state.paymentMethods,
                  this.state.cryptos,
                  htmlSchemaObject,
                  this.state.customFields,
                  this.state.invoiceAfterPayment,
                  {
                    id: this.props.match.params.id,
                    description: this.state.description,
                    publicKey: this.state.plugin.publicKey,
                    htmlSchema: this.state.htmlSchemas.reduce((acc, schema) => {
                      acc[schema.key] = schema.value;
                      return acc;
                    }, {}),
                    cdnUrl: this.state.plugin.cdnUrl,
                    paymentMethods: [...this.state.paymentMethods],
                    cryptosAccepted: [...this.state.cryptos],
                    customFields: [...this.state.customFields],
                    invoiceAfterPayment: this.state.invoiceAfterPayment,
                    active: this.state.status,
                    createdAt: this.state.plugin.createdAt,
                  },
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              }
            );
          } else {
            this.setMessages(
              "*please-select-at-least-one-cryptocurrency-when-using-the-crypto-payment-method"
            );
            this.setState({
              invalidDescription: false,
              invalidCryptos: true,
            });
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidDescription: this.state.description === "",
            invalidPaymentMethods: this.state.paymentMethods.length == 0,
            invalidCryptos:
              this.state.paymentMethods.includes("CRYPTO") &&
              this.state.cryptos.length == 0,
          });
        }
      }
    );
  };

  cancelUpdate = () => {
    this.setState({
      description: this.state.plugin.description,
      paymentMethods: JSON.parse(
        JSON.stringify(this.state.plugin.paymentMethods)
      ),
      cryptos: JSON.parse(JSON.stringify(this.state.plugin.cryptosAccepted)),
      customFields: JSON.parse(JSON.stringify(this.state.plugin.customFields)),
      htmlSchemas: Object.entries(this.state.plugin.htmlSchema).map(
        ([key, value]) => {
          return { key: [key], value: [value][0] };
        }
      ),
      key: "",
      value: "",
      invoiceAfterPayment: this.state.plugin.invoiceAfterPayment,
    });
  };

  addPaymentMethodToRequest = (value) => {
    var arrAux = this.state.paymentMethods;
    const index = arrAux.indexOf(value);
    if (index !== -1) {
      arrAux.splice(index, 1);
    } else {
      arrAux.push(value);
    }

    this.setState(
      {
        paymentMethods: arrAux,
        invalidPaymentMethods: false,
      },
      () => {
        if (!this.state.paymentMethods.includes("CRYPTO")) {
          this.setState({
            cryptos: [],
            invalidCryptos: false,
          });
        }
      }
    );
  };

  addCryptoToRequest = (value) => {
    var arrAux = this.state.cryptos;
    const index = arrAux.indexOf(value);
    if (index !== -1) {
      arrAux.splice(index, 1);
    } else {
      arrAux.push(value);
    }

    this.setState({
      cryptos: arrAux,
      invalidCryptos: false,
    });
  };

  addCustomFieldsToRequest = (value) => {
    var arrAux = this.state.customFields;
    const index = arrAux.indexOf(value);
    if (index !== -1) {
      arrAux.splice(index, 1);
    } else {
      arrAux.push(value);
    }

    this.setState({
      customFields: arrAux,
    });
  };

  addHtmlSchemaToRequest = () => {
    var arrAux = this.state.htmlSchemas;

    arrAux.push({ key: this.state.key, value: this.state.value });

    this.setState({
      htmlSchemas: arrAux,
      key: "",
      value: "",
    });
  };

  deleteHtmlSchemaFromRequest = (keyToDelete) => {
    var arrAux = this.state.htmlSchemas;

    arrAux = arrAux.filter((schema) => schema.key !== keyToDelete);

    this.setState({
      htmlSchemas: arrAux,
    });
  };

  checkInvoiceAfterPayment = () => {
    this.setState(
      {
        invoiceAfterPayment: !this.state.invoiceAfterPayment,
      },
      () => {
        if (this.state.invoiceAfterPayment) {
          var arrAux = this.state.customFields;

          if (!arrAux.includes("INVOICE_NUMBER")) {
            arrAux.push("INVOICE_NUMBER");
          }
          if (!arrAux.includes("MEMO")) {
            arrAux.push("MEMO");
          }
          if (!arrAux.includes("DESCRIPTION")) {
            arrAux.push("DESCRIPTION");
          }

          this.setState({
            customFields: arrAux,
          });
        } /*else {
          var arrAux = this.state.customFields;

          if (arrAux.includes("INVOICE_NUMBER")) {
            const index = arrAux.indexOf("INVOICE_NUMBER");
            if (index > -1) {
              arrAux.splice(index, 1);
            }
          }
          if (arrAux.includes("MEMO")) {
            const index = arrAux.indexOf("MEMO");
            if (index > -1) {
              arrAux.splice(index, 1);
            }
          }
          if (arrAux.includes("DESCRIPTION")) {
            const index = arrAux.indexOf("DESCRIPTION");
            if (index > -1) {
              arrAux.splice(index, 1);
            }
          }

          this.setState({
            customFields: arrAux,
          });
        }*/
      }
    );
  };

  changePluginStatus = (id, currentStatus) => {
    this.setState(
      {
        loadingChangeStatusSent: true,
      },
      () => {
        if (currentStatus) {
          this.service.disablePlugin(
            this.props.token,
            id,
            this.setState.bind(this),
            this.setMessages.bind(this)
          );
        } else {
          this.service.enablePlugin(
            this.props.token,
            id,
            this.setState.bind(this),
            this.setMessages.bind(this)
          );
        }
      }
    );
  };

  shallowEqual = (obj1, obj2) => {
    console.log("obj1", obj1);
    console.log("obj2", obj2);

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (key != "active") {
        const val1 = obj1[key];
        const val2 = obj2[key];

        if (
          typeof val1 === "object" &&
          typeof val2 === "object" &&
          val1 !== null &&
          val2 !== null
        ) {
          if (!this.shallowEqual(val1, val2)) {
            return false;
          }
        } else if (val1 !== val2) {
          return false;
        }
      }
    }

    return true;
  };

  openDeletePlugin = () => {
    this.setState({
      showModalDeletePlugin: true,
    });
  };

  handleCloseDelete = () => {
    this.setState({
      showModalDeletePlugin: false,
    });
  };

  deletePlugin = () => {
    this.setState(
      {
        loadingSentDelete: true,
      },
      () => {
        this.service.deletePlugin(
          this.props.token,
          this.props.match.params.id,
          this.setState.bind(this),
          this.setMessages.bind(this),
          () => {
            utils.goToURL(this.props, "/dashboard/pay-me/plugins");
          }
        );
      }
    );
  };

  confirmPayment = () => {
    this.setState(
      {
        loadingSentApprove: true,
      },
      () => {
        this.service.confirmPayment(
          this.props.token,
          this.state.selectedPayment.paymentId,
          this.props.match.params.id,
          this.setState.bind(this)
        );
      }
    );
  };

  cancelPayment = () => {
    this.setState(
      {
        loadingSentReject: true,
      },
      () => {
        this.service.cancelPayment(
          this.props.token,
          this.state.selectedPayment.paymentId,
          this.props.match.params.id,
          this.setState.bind(this)
        );
      }
    );
  };

  copyText = () => {
    this.setState({
      scriptCopied: true,
    });

    const text = `<script async src="https://payment-button-stg.s3.amazonaws.com/bloxpay-widget.js"></script> <bloxcross-widget public-key="${this.state.plugin.publicKey}" cart-total-id="cart-total-value" ></bloxcross-widget>`;

    navigator.clipboard.writeText(text);
  };

  render() {
    return (
      <React.Fragment>
        <div class="plugin-container">
          <div class="plugin-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard/pay-me/plugins"}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              <div class="title-div">
                <div class="title">{this.state.plugin.description}</div>
              </div>
              <Tab.Container
                defaultActiveKey={
                  this.props.location.megastate &&
                  this.props.location.megastate.forEdit != null
                    ? "settings"
                    : "payments"
                }
              >
                <div class="tab-header">
                  <div class="tab-buttons">
                    <Nav.Item
                      class={
                        this.state.tabPicked == "payments"
                          ? "tab-button tab-picked"
                          : "tab-button"
                      }
                    >
                      <Nav.Link
                        eventKey="payments"
                        onClick={this.clickTab.bind(this, "payments")}
                      >
                        {this.props.localizedText["payments"]}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      class={
                        this.state.tabPicked == "settings"
                          ? "tab-button tab-picked"
                          : "tab-button"
                      }
                    >
                      <Nav.Link
                        eventKey="settings"
                        onClick={this.clickTab.bind(this, "settings")}
                      >
                        {this.props.localizedText["settings"]}
                      </Nav.Link>
                    </Nav.Item>
                  </div>{" "}
                  <Button
                    className="primary-button outside-primary-button small-button"
                    onClick={this.copyText}
                  >
                    {this.props.localizedText["copy-script"]}
                    {this.state.scriptCopied ? (
                      <FaCheck class="copy-success-icon" />
                    ) : (
                      <AiFillCopy class="copy-key-icon" />
                    )}
                  </Button>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="payments" tabClassName="tab">
                    <TabContent>
                      <div class="bottom-content">
                        {!this.state.loadingPayments ? (
                          <this.listPayments
                            payments={this.state.listPaymentsShowed}
                          ></this.listPayments>
                        ) : (
                          <this.skeletonCards
                            cards={[0, 1, 2, 3, 4]}
                          ></this.skeletonCards>
                        )}
                      </div>
                    </TabContent>
                  </Tab.Pane>

                  <Tab.Pane eventKey="settings" tabClassName="tab">
                    <TabContent>
                      <div class="bottom-content settings-div">
                        <div class="settings-sub-section-div">
                          <div class="sub-section-title">
                            {" "}
                            {this.props.localizedText["plugin-info"]}
                          </div>
                          <div class="sub-section-body">
                            {this.state.loadingPaymentMethods ||
                            this.state.loadingCryptos ||
                            this.state.loadingCustomFields ? (
                              <Spinner className="loader"></Spinner>
                            ) : (
                              <div className="fade-in-up">
                                <div class="div-parent-flex-column">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="input-label-outside-div">
                                      {this.props.localizedText["description"]}
                                    </Form.Label>
                                    <FormControl
                                      type="text"
                                      name="description"
                                      value={this.state.description}
                                      onChange={(e) =>
                                        utils.handleChange(
                                          e,
                                          this.setState.bind(this)
                                        )
                                      }
                                      isInvalid={this.state.invalidDescription}
                                      className="input-field"
                                      disabled={this.state.loadingSentUpdate}
                                    />
                                  </Form.Group>
                                  <div class="div-parent-flex-row mb-3">
                                    <div class="div-50-son">
                                      <Form.Group className="mb-3">
                                        <Form.Label className="input-label-outside-div">
                                          {
                                            this.props.localizedText[
                                              "payment-methods"
                                            ]
                                          }
                                        </Form.Label>{" "}
                                        {this.state.listPaymentMethods.map(
                                          (paymentMethod) => (
                                            <div
                                              key={paymentMethod.id}
                                              className="mb-3"
                                            >
                                              <Form.Check
                                                label={
                                                  paymentMethod.description
                                                }
                                                type="checkbox"
                                                onClick={() => {
                                                  this.addPaymentMethodToRequest(
                                                    paymentMethod.id
                                                  );
                                                }}
                                                checked={this.state.paymentMethods.includes(
                                                  paymentMethod.id
                                                )}
                                                isInvalid={
                                                  this.state
                                                    .invalidPaymentMethods
                                                }
                                              />
                                            </div>
                                          )
                                        )}
                                      </Form.Group>{" "}
                                      <Form.Group className="mb-3">
                                        <Form.Label className="input-label-outside-div">
                                          {
                                            this.props.localizedText[
                                              "available-cryptos"
                                            ]
                                          }
                                        </Form.Label>{" "}
                                        {this.state.listCryptos.map(
                                          (crypto) => (
                                            <div key={crypto} className="mb-3">
                                              <Form.Check
                                                label={crypto}
                                                type="checkbox"
                                                onClick={() => {
                                                  this.addCryptoToRequest(
                                                    crypto
                                                  );
                                                }}
                                                checked={this.state.cryptos.includes(
                                                  crypto
                                                )}
                                                disabled={
                                                  !this.state.paymentMethods.includes(
                                                    "CRYPTO"
                                                  )
                                                }
                                                isInvalid={
                                                  this.state.invalidCryptos
                                                }
                                              />
                                            </div>
                                          )
                                        )}
                                      </Form.Group>
                                      <div class="status-form-div mb-3">
                                        <label class="form-label input-label-outside-div">
                                          {this.props.localizedText["status"]}
                                        </label>
                                        <div
                                          class={
                                            "status" +
                                            (this.state.status
                                              ? " success-status"
                                              : " danger-status") +
                                            (this.state.loadingChangeStatusSent
                                              ? " loading-thing"
                                              : " ")
                                          }
                                          onClick={() =>
                                            this.changePluginStatus(
                                              this.props.match.params.id,
                                              this.state.status
                                            )
                                          }
                                        >
                                          {this.state
                                            .loadingChangeStatusSent ? (
                                            <Spinner className="loader-send-inside-status"></Spinner>
                                          ) : null}
                                          {this.state.status
                                            ? this.props.localizedText[
                                                "enabled"
                                              ]
                                            : this.props.localizedText[
                                                "disabled"
                                              ]}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="div-50-son">
                                      <Form.Group className="mb-3">
                                        <Form.Label className="input-label-outside-div">
                                          {
                                            this.props.localizedText[
                                              "custom-fields"
                                            ]
                                          }
                                        </Form.Label>
                                        {this.state.listCustomFields.map(
                                          (customField) => (
                                            <div
                                              key={customField}
                                              className="mb-3"
                                            >
                                              <Form.Check
                                                label={customField}
                                                type="checkbox"
                                                onClick={() => {
                                                  this.addCustomFieldsToRequest(
                                                    customField
                                                  );
                                                }}
                                                checked={this.state.customFields.includes(
                                                  customField
                                                )}
                                                disabled={
                                                  customField == "FULL_NAME" ||
                                                  customField == "EMAIL" ||
                                                  (customField ==
                                                    "INVOICE_NUMBER" &&
                                                    this.state
                                                      .invoiceAfterPayment) ||
                                                  (customField == "MEMO" &&
                                                    this.state
                                                      .invoiceAfterPayment) ||
                                                  (customField ==
                                                    "DESCRIPTION" &&
                                                    this.state
                                                      .invoiceAfterPayment)
                                                }
                                              />
                                            </div>
                                          )
                                        )}
                                      </Form.Group>
                                    </div>
                                  </div>

                                  <Form.Group
                                    className="mb-3"
                                    style={{ display: "none" }}
                                  >
                                    <Form.Label className="input-label-outside-div">
                                      {this.props.localizedText["html-schema"]}
                                    </Form.Label>
                                    {this.state.htmlSchemas.map((schema) => (
                                      <div class="html-schema-form-div mb-3">
                                        <FormControl
                                          type="text"
                                          value={schema.key}
                                          disabled
                                          className="input-field"
                                        />

                                        <FormControl
                                          type="text"
                                          value={schema.value}
                                          disabled
                                          className="input-field"
                                        />
                                        <MdDelete
                                          class="html-schema-row-icon"
                                          onClick={() => {
                                            this.deleteHtmlSchemaFromRequest(
                                              schema.key
                                            );
                                          }}
                                        >
                                          {this.props.localizedText["delete"]}
                                        </MdDelete>
                                      </div>
                                    ))}

                                    <div class="html-schema-form-div">
                                      <FormControl
                                        type="text"
                                        placeholder="Key"
                                        name="key"
                                        value={this.state.key}
                                        onChange={(e) =>
                                          utils.handleChange(
                                            e,
                                            this.setState.bind(this)
                                          )
                                        }
                                        isInvalid={this.state.invalidKey}
                                        className="input-field"
                                      />

                                      <FormControl
                                        type="text"
                                        placeholder="Value"
                                        name="value"
                                        value={this.state.value}
                                        onChange={(e) =>
                                          utils.handleChange(
                                            e,
                                            this.setState.bind(this)
                                          )
                                        }
                                        isInvalid={this.state.invalidValue}
                                        className="input-field"
                                      />
                                      <Button
                                        className="outside-primary-button"
                                        onClick={this.addHtmlSchemaToRequest}
                                        disabled={
                                          this.state.key == "" &&
                                          this.state.value == ""
                                        }
                                      >
                                        {this.props.localizedText["add"]}
                                      </Button>
                                    </div>
                                  </Form.Group>

                                  <div
                                    key="checkbox-invoice-after-payment"
                                    className="mb-3"
                                  >
                                    <Form.Check
                                      label={
                                        this.props.localizedText[
                                          "invoice-after-payment"
                                        ]
                                      }
                                      type="checkbox"
                                      onClick={this.checkInvoiceAfterPayment}
                                      class="invoice-after-payment-check"
                                      checked={this.state.invoiceAfterPayment}
                                    />
                                  </div>
                                </div>
                                <div class="save-buttons-div">
                                  <Button
                                    className="back-button outside-secondary-button"
                                    onClick={this.cancelUpdate}
                                  >
                                    {this.props.localizedText["cancel"]}
                                  </Button>

                                  <Button
                                    className="small-button outside-primary-button"
                                    onClick={this.updatePlugin}
                                    disabled={
                                      this.state.loadingSentUpdate ||
                                      this.shallowEqual(this.state.plugin, {
                                        id: this.props.match.params.id,
                                        description: this.state.description,
                                        publicKey: this.state.plugin.publicKey,
                                        htmlSchema:
                                          this.state.htmlSchemas.reduce(
                                            (acc, schema) => {
                                              acc[schema.key] = schema.value;
                                              return acc;
                                            },
                                            {}
                                          ),
                                        cdnUrl: this.state.plugin.cdnUrl,
                                        paymentMethods:
                                          this.state.paymentMethods,
                                        cryptosAccepted: this.state.cryptos,
                                        customFields: this.state.customFields,
                                        invoiceAfterPayment:
                                          this.state.invoiceAfterPayment,
                                        active: this.state.status,
                                        createdAt: this.state.plugin.createdAt,
                                      })
                                    }
                                  >
                                    {this.state.loadingSentUpdate ? (
                                      <Spinner className="loader-send-outside-div-button"></Spinner>
                                    ) : null}
                                    {this.props.localizedText["save"]}
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="settings-sub-section-div">
                          <div class="sub-section-title">
                            {this.props.localizedText["delete"]}
                          </div>
                          <div class="sub-section-body">
                            <Button
                              className="outside-danger-button"
                              onClick={this.openDeletePlugin}
                              disabled={this.state.listPayments.length > 0}
                            >
                              {this.props.localizedText["delete-plugin"]}
                            </Button>
                          </div>
                        </div>

                        <ErrorMessageComponent
                          errorMessage={
                            this.props.localizedText[
                              this.state.errorMessageType
                            ]
                          }
                        ></ErrorMessageComponent>
                      </div>
                    </TabContent>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            <div class="side-container"></div>
          </div>
        </div>

        <Modal
          show={this.state.showModalPaymentDetails}
          onHide={this.handleClosePaymentDetails}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["payment-details"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="detail-container">
              {Object.keys(this.state.selectedPayment).length > 0 ? (
                <ReceiptComponent
                  data={this.getReceiptProps()}
                ></ReceiptComponent>
              ) : null}

              <div class="icons-row">
                {this.state.loadingDownload ? (
                  <div class="loader-icon">
                    <Spinner className="loader-download left-icon"></Spinner>
                  </div>
                ) : (
                  <IoMdDownload class="left-icon" onClick={this.downloadPDF} />
                )}
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <FaPrint class="right-icon" onClick={handlePrint} />
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div class="payment-detail-footer">
              {this.props.localizedText["if-you-need-help"]}
              <br />
              {this.props.localizedText["go-to-our-"]}
              <a href="https://www.blox.global/contact/">
                {this.props.localizedText["resolution-center"]}
              </a>{" "}
              {
                this.props.localizedText[
                  "for-help-with-this-transaction,-to-settle-a-dispute-or-open-a-claim"
                ]
              }
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalApprovePayment}
          onHide={this.handleCloseApprovePayment}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["approve-payment"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="approve-text">
              {
                this.props.localizedText[
                  "are-you-sure-you-want-to-approve-the-following-payment:"
                ]
              }
            </div>
            {Object.keys(this.state.selectedPayment).length > 0 ? (
              <ReceiptComponent
                data={this.getReceiptProps()}
              ></ReceiptComponent>
            ) : null}
          </Modal.Body>
          <Modal.Footer className="vendor-detail-modal-footer">
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleCloseApprovePayment}
            >
              {this.props.localizedText["close"]}
            </Button>
            <div style={{ display: "flex" }}>
              <Button
                className="reject-button outside-primary-button"
                disabled={this.state.loadingSentReject}
                onClick={this.cancelPayment}
              >
                {this.state.loadingSentReject ? (
                  <Spinner className="loader-send-outside-div-button"></Spinner>
                ) : null}
                {this.props.localizedText["reject"]}
              </Button>
              <Button
                className="outside-primary-button"
                disabled={this.state.loadingSentApprove}
                onClick={this.confirmPayment}
              >
                {this.state.loadingSentApprove ? (
                  <Spinner className="loader-send-outside-div-button"></Spinner>
                ) : null}
                {this.props.localizedText["approve"]}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalDeletePlugin}
          onHide={this.handleCloseDelete}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["delete-plugin"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.localizedText[
              "are-you-sure-you-want-to-delete-the-plugin"
            ] + " "}
            <b>{this.state.plugin.description}</b>
            {"?"}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleClose}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button
              className="verify-button outside-primary-button"
              disabled={this.state.loadingSentDelete}
              onClick={this.deletePlugin}
            >
              {this.state.loadingSentDelete ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["delete"]}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(PluginDetail);
