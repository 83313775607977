import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./idVerification.css";
import IdVerificaitonService from "./idVerificationService";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Result from "../../../components/result/Result.jsx";
import GoBackButton from "../../../components/go-back-button/GoBackButton.jsx";
import { Spinner } from "react-bootstrap";
import { utils } from "../../../utils/utils.jsx";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessage.jsx";
class IdVerificaiton extends Component {
  state = {
    isMobileWidth: false,
    fullName: "",
    fullLastName: "",

    invalidFullname: false,
    invalidLastname: false,

    resultSuccess: null,
    resultType: "",

    errorMessageType: "",

    finishedLoading: false,
    publicKey: "",
    goBackURL: null, //checkprops will make goback function only goback to either settings or else

    stage: 1,
  };

  vouched = null;
  service = new IdVerificaitonService();

  constructor(props) {
    super(props);
    this.props.toggleModalVerify(false);
  }

  vstyle = getComputedStyle(document.body);

  componentDidMount() {
    this.service.getClientConfig(this.props.token, this.setState.bind(this));
    this.checkProps();

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this.unloadVouched();
  }

  checkProps = () => {
    if (
      this.props.location &&
      this.props.location.megastate &&
      this.props.location.megastate.fromSettings
    ) {
      this.setState({
        goBackURL: "/dashboard/settings",
      });
    } else {
      this.setState({
        goBackURL: "/dashboard/",
      });
    }
  };

  loadVouched = () => {
    const config = {
      appId: this.state.publicKey,

      // your webhook for POST verification processing
      callbackURL: "https://webhook.site/8a4bdc81-f793-47fe-bf41-081d495e2388?",

      // optional verification information for comparison
      verification: {
        firstName: this.state.fullName,
        lastName: this.state.fullLastName,
      },

      // mobile handoff fields, a job will be created automatically if true
      crossDevice: true,
      crossDeviceQRCode: true,
      crossDeviceSMS: true,

      liveness: "mouth",
      id: "both",
      includeBarcode: "true",
      manualCaptureTimeout: 20000,

      // have the user confirm information
      userConfirmation: {
        confirmData: true,
        confirmImages: true,
      },

      onInit: ({ token, job }) => {
        console.log("initialization");
        this.setState(
          {
            finishedLoading: true,
          },
          () => {
            console.log(this.state.finishedLoading);
          }
        );
      },

      onDone: (job) => {
        this.service.vouchedJobSubmitted(
          this.props.token,
          job.id,
          this.moveToResult.bind(this),
          this.props.getClientConfig.bind(this)
        );
      },

      // callback executed after attempt to find camera device
      onCamera: ({ hasCamera, hasPermission }) => {
        console.log("attempted to find camera");
      },

      // callback when there are changes to the Camera DOM element
      onCameraEvent: (cameraEvent) => {
        console.log("camera DOM element updated");
      },

      // callback when a reverification job is complete
      onReverify: (job) => {
        console.log("reverification complete");
      },

      // callback when a survey is submitted, (per customer basis)
      onSurveyDone: (job) => {
        console.log("survey done");
      },

      // callback when user confirms data and photos
      onConfirm: (userConfirmEvent) => {
        console.log("user confirmation");
      },

      // theme
      theme: {
        name: "avant",
      },
    };

    const existingScript = document.getElementById("vouched");
    if (!existingScript) {
      console.log("Script is not created");
      const script = document.createElement("script");
      script.src = "https://static.vouched.id/widget/vouched-2.0.0.js";
      script.id = "vouched";
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        this.vouched = window["Vouched"]({ ...config });
        console.log("mount vouched-element");
        this.vouched.mount("#vouched-element");
      };
    } else {
      console.log("Script is created");
      this.vouched = window["Vouched"]({ ...config });
      console.log("mount vouched-element");
      this.vouched.mount("#vouched-element");
    }
  };

  unloadVouched = () => {
    const config = {
      appId: this.state.publicKey,

      // your webhook for POST verification processing
      callbackURL: "https://webhook.site/8a4bdc81-f793-47fe-bf41-081d495e2388?",

      // optional verification information for comparison
      verification: {
        firstName: this.state.fullName,
        lastName: this.state.fullLastName,
      },

      liveness: "straight",
      // mobile handoff fields, a job will be created automatically if true
      crossDevice: true,
      crossDeviceQRCode: true,
      crossDeviceSMS: true,

      liveness: "mouth",
      id: "camera",
      includeBarcode: "true",
      manualCaptureTimeout: 20000,

      // have the user confirm information
      userConfirmation: {
        confirmData: true,
        confirmImages: true,
      },

      onInit: ({ token, job }) => {
        console.log("initialization");
        this.setState(
          {
            finishedLoading: true,
          },
          () => {
            console.log(this.state.finishedLoading);
          }
        );
      },

      onDone: (job) => {
        this.service.vouchedJobSubmitted(
          this.props.token,
          job.id,
          this.moveToResult.bind(this),
          this.props.getClientConfig.bind(this)
        );
      },

      // callback executed after attempt to find camera device
      onCamera: ({ hasCamera, hasPermission }) => {
        console.log("attempted to find camera");
      },

      // callback when there are changes to the Camera DOM element
      onCameraEvent: (cameraEvent) => {
        console.log("camera DOM element updated");
      },

      // callback when a reverification job is complete
      onReverify: (job) => {
        console.log("reverification complete");
      },

      // callback when a survey is submitted, (per customer basis)
      onSurveyDone: (job) => {
        console.log("survey done");
      },

      // callback when user confirms data and photos
      onConfirm: (userConfirmEvent) => {
        console.log("user confirmation");
      },

      // theme
      theme: {
        name: "avant",
      },
    };

    const existingScript = document.getElementById("vouched");

    if (existingScript) {
      console.log("unmount vouched-element");
      this.vouched.unmount("#vouched-element");
    }
  };

  moveToResult = (value, type) => {
    this.setState({
      stage: 3,
      resultSuccess: value,
      resultType: type,
    });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  startVerification = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.fullName != "" && this.state.fullLastName != "") {
          this.loadVouched();
          this.setState({
            stage: this.state.stage + 1,
          });
        } else {
          this.setState({
            invalidFullname: this.state.fullName == "",
            invalidLastname: this.state.fullLastName == "",
          });
          this.setMessages("*one-or-more-required-field(s)-are-missing");
        }
      }
    );
  };

  stage1 = () => (
    <div class="id-verification-form-div">
      <div class="message">
        {
          this.props.localizedText[
            "*to-setupt-your-account-for-trading-we-need-to-verify-your-identity"
          ]
        }
      </div>

      <div class="div-parent-flex-column">
        <div>
          <Form.Group className="mb-3">
            <Form.Label className="input-label-outside-div">
              {this.props.localizedText["full-name"]}
            </Form.Label>
            <FormControl
              type="text"
              name="fullName"
              value={this.state.fullName}
              onChange={(e) => utils.handleChange(e, this.setState.bind(this))}
              isInvalid={this.state.invalidFullname}
              className="input-field"
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="mb-3">
            <Form.Label className="input-label-outside-div">
              {this.props.localizedText["full-last-name"]}
            </Form.Label>
            <FormControl
              name="fullLastName"
              value={this.state.fullLastName}
              onChange={(e) => utils.handleChange(e, this.setState.bind(this))}
              isInvalid={this.state.invalidLastname}
              className="input-field"
            />
          </Form.Group>
        </div>
        <ErrorMessageComponent
          errorMessage={this.props.localizedText[this.state.errorMessageType]}
        ></ErrorMessageComponent>
        <div class="buttons-div">
          <Button
            className="primary-button outside-primary-button"
            onClick={this.startVerification}
          >
            {this.props.localizedText["continue"]}
          </Button>

          <Button
            className="secondary-button outside-secondary-button"
            onClick={utils.goToURL.bind(this, this.props, this.state.goBackURL)}
          >
            {this.props.localizedText["cancel"]}
          </Button>
        </div>
      </div>
    </div>
  );

  stage2 = () => (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <div
        id="vouched-element"
        style={{
          height: "100%",
        }}
      ></div>
    </div>
  );

  getResultMessage = () => {
    if (this.state.resultType == "general") {
      if (this.state.resultSuccess) {
        return this.props.localizedText[
          "your-identification-has-been-successfully-verified"
        ];
      } else {
        return this.props.localizedText[
          "unfortunately,-we-have-encountered-a-problem-while-trying-to-verify-your-identity"
        ];
      }
    } else if (this.state.resultType == "already-verified") {
      if (this.state.resultSuccess) {
        return "";
      } else {
        return this.props.localizedText[
          "looks-like-your-identity-has-already-been-verified"
        ];
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="id-verification-container">
          <div class="side-container">
            <GoBackButton
              region={this.props.region}
              localizedText={this.props.localizedText}
              goBack={this.state.goBackURL}
            ></GoBackButton>
          </div>
          <div class="middle-container container-padding">
            {this.state.stage != 3 ? (
              <div className="id-verification-form-div">
                <div class="id-verification-title">
                  {this.props.localizedText["id-verification"]}
                </div>
                {this.state.stage === 1 ? <this.stage1></this.stage1> : null}
                {this.state.stage === 2 ? <this.stage2></this.stage2> : null}
                {this.state.stage === 2 && !this.state.finishedLoading ? (
                  <div class="loading-vouched">
                    <Spinner className="loader"></Spinner>
                  </div>
                ) : null}{" "}
              </div>
            ) : (
              <Result
                success={this.state.resultSuccess}
                secondButton={false}
                title={
                  this.props.localizedText[
                    utils.getResultTitle(this.state.resultSuccess)
                  ]
                }
                message={this.getResultMessage()}
                primaryButtonText={this.props.localizedText["continue"]}
                primaryButtonFunction={utils.goToURL.bind(
                  this,
                  this.props,
                  this.state.goBackURL
                )}
              ></Result>
            )}
          </div>
          <div class="side-container"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(IdVerificaiton);
