import { portfolioAPI } from "../../apis/PortfolioAPI.jsx";
import { marketDataAPI } from "../../apis/MarketDataAPI.jsx";
import { swapAPI } from "../../apis/SwapAPI.jsx";
import { utils } from "../../utils/utils";

export default class AssetDetailService {
  getDefaultPortfolio(token, setState, currency) {
    portfolioAPI
      .getDefaultPortfolio(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getDefaultPortfolio success", response);
          this.getPortfolioBalances(
            token,
            setState,
            response.data.name,
            currency
          );
          this.getTransactionHistoryForSymbol(
            token,
            setState,
            response.data.name,
            currency
          );
        } else {
          console.log("getDefaultPortfolio failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getDefaultPortfolio error", error);
      });
  }

  getPortfolioBalances(token, setState, portfolioName, currency) {
    portfolioAPI
      .getPortfolioBalances(token, portfolioName)
      .then((response) => {
        if (response.status === 200) {
          console.log("getPortfolioBalances success", response);
          let currencyData = response.data.filter(
            (element) => element.base_currency == currency
          )[0];
          console.log(
            "filter",
            response.data.filter((element) => element.base_currency == currency)
          );
          if (currencyData.base_currency != "USD") {
            swapAPI
              .convertEstimate(
                token,
                currencyData.qty,
                currencyData.base_currency,
                "USD",
                0
              )
              .then((responseConvert) => {
                if (responseConvert.status === 200) {
                  console.log("convertEstimate success", responseConvert);
                  currencyData["value_usd"] =
                    responseConvert.data.amount_to_receive;
                  console.log("currencyData", currencyData);
                  setState({
                    currencyData: currencyData,
                    loadingCurrencyData: false,
                  });
                } else {
                  console.log("convertEstimate failure", responseConvert);
                  //Failure
                }
              })
              .catch((error) => {
                //Failure
                console.log("convertEstimate error", error);
              });
          } else {
            currencyData["value_usd"] = currencyData.qty;
            console.log("currencyData", currencyData);
            setState({
              currencyData: currencyData,
              loadingCurrencyData: false,
            });
          }
        } else {
          console.log("getPortfolioBalances failure", response);
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPortfolioBalances error", error);
      });
  }

  getHistoricalCandles(
    token,
    setState,
    currency,
    granularity,
    start,
    period,
    index
  ) {
    marketDataAPI
      .getHistoricalCandles(
        token,
        currency + "-USD",
        granularity,
        start,
        period, //Multiplying days to get in seconds
        Date.now() / 1000
      )
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getHistoricalCandles success", response);

          setState({
            chartIndexPicked: index,
            chartData:
              response.data &&
              response.data["payload"] &&
              Array.isArray(JSON.parse(response.data["payload"]))
                ? JSON.parse(response.data["payload"]).reverse()
                : [],
            loadingHistoricalCandles: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getHistoricalCandles error", error);
      });
  }

  getTransactionHistoryForSymbol(token, setState, portfolioName, currency) {
    portfolioAPI
      .getTransactionHistoryForSymbol(token, portfolioName, currency)
      .then((response) => {
        if (response.status === 200) {
          console.log("getTransactionHistoryForSymbol success", response);
          const listTransactions = response.data[0].holdings[0].transactions;

          for (let i = 0; i < listTransactions.length; i++) {
            if (listTransactions[i].childTransactions.length > 0) {
              let totalFeeAmount = 0;
              for (
                let j = 0;
                j < listTransactions[i].childTransactions.length;
                j++
              ) {
                totalFeeAmount =
                  totalFeeAmount +
                  Number(listTransactions[i].childTransactions[j].qty);
              }
              listTransactions[i].fee_amount = totalFeeAmount;
            }
          }

          listTransactions.sort((a, b) => {
            return new Date(b.creation_time) - new Date(a.creation_time);
          });

          setState({
            listTransactions: listTransactions,
            listTransactionsShowed: listTransactions,
            loadingTransactions: false,
          });
        } else {
          console.log("getTransactionHistoryForSymbol failure", response);
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("getTransactionHistoryForSymbol error", error);
      });
  }
}
