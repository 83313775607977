import React, { Component } from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import "./dashboard.css";
import DashboardService from "./dashboardService";
import Layout from "../../components/layout/Layout.jsx";
import Footer from "../../components/footer/Footer.jsx";
import Portfolio from "../portfolio/portfolio";
import AssetDetail from "../asset-detail/assetDetail";
import Deposit from "../deposit/deposit";
import Withdraw from "../withdraw/withdraw";
import DepositWithdraw from "../deposit-withdraw/depositWithdraw";
import PayMe from "../pay-me/payMe";
import PluginDetail from "../pay-me/plugin-detail/PluginDetail";
import PaymentOptions from "../payments/paymentOptions/paymentOptions";
import PaymentInAppUser from "../payments/paymentInAppUser/paymentInAppUser";
import Vendors from "../vendors/vendors/vendors";
import VendorDetail from "../vendors/vendorDetail/vendorDetail";
import NewVendor from "../vendors/newVendor/newVendor";
import NewVendorPaymentMethod from "../vendors/newVendorPaymentMethod/newVendorPaymentMethod";
import UpdateVendorPaymentMethod from "../vendors/updateVendorPaymentMethod/updateVendorPaymentMethod";
import VerificationModal from "../../components/verification-modal/VerificationModal.jsx";
import PaymentInfoModal from "../../components/payment-info-modal/PaymentInfoModal.jsx";
import PayVendor from "../vendors/payVendor/payVendor";
import Home from "../home/home";
import Settings from "../settings/settings/settings";
import IdVerification from "../settings/idVerification/idVerification";
import ChangeEmail from "../settings/changeEmail/changeEmail";
import VerifyEmail from "../settings/verifyEmail/verifyEmail";
import ChangePhone from "../settings/changePhone/changePhone";
import VerifyPhone from "../settings/verifyPhone/verifyPhone";
import InsideResult from "../result/insideResult/insideResult";
import Conversion from "../conversion/conversion";
import { utils } from "../../utils/utils";
import DashboardAdmin from "./dashboardAdmin/dashboardAdmin";
import Plugins from "../pay-me/plugins/Plugins";
import AddPlugin from "../pay-me/add-plugin/AddPlugin";
import MonthlyPaymentReport from "../monthly-payment-report/MonthlyPaymentReport.jsx";

class Dashboard extends Component {
  state = {
    linkedAccount: false,
    goBackNavBar: false,
    goBackUrl: "/dashboard",
    phoneVerified: null,
    emailVerified: null,
    idVerified: null,
    showVerifyModal: null,
    showPaymentInfoModal: null,
  };

  service = new DashboardService();

  componentDidMount() {
    console.log("this,props", this.props);
    if (this.props.token && this.props.account) {
      /*
      this.setState({
        account: JSON.parse(utils.getTokenFromStorage()),
      });*/
      this.getClientConfig(true);
    } else {
      utils.goToURL(this.props, "/");
    }
  }

  componentDidUpdate() {}

  changeGoBackNavBar = (value, url) => {
    this.setState({
      goBackNavBar: value,
      goBackUrl: url,
    });
  };

  toggleModalVerify = (value) => {
    this.setState({
      showVerifyModal: value,
    });
  };

  toggleModalPaymentInfo = (value) => {
    this.setState({
      showPaymentInfoModal: value,
    });
  };

  //firstLoad is to open modal or not
  getClientConfig = (firstLoad) => {
    this.service.getClientConfig(
      this.props.token,
      this.setState.bind(this),
      firstLoad
    );
  };

  render() {
    return (
      <React.Fragment>
        <Layout
          account={this.props.account}
          token={this.props.token}
          time={this.props.time}
          goBackNavBar={this.state.goBackNavBar}
          goBackUrl={this.state.goBackUrl}
          changeTheme={this.props.changeTheme}
          theme={this.props.theme}
          handleModalOpen={this.props.handleModalOpen}
          region={this.props.region}
          localizedText={this.props.localizedText}
          setAccountAndToken={this.props.setAccountAndToken}
          handleInactivity={this.props.handleInactivity}
        ></Layout>
        <Switch>
          <Route
            exact
            path="/dashboard/settings/id-verification"
            render={(props) => (
              <div class="welcome-div-container">
                <IdVerification
                  account={this.props.account}
                  token={this.props.token}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  toggleModalVerify={this.toggleModalVerify}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                  getClientConfig={this.getClientConfig}
                ></IdVerification>
              </div>
            )}
          />

          {utils.conditionalPermission(this.props.account, ["can_deposit"]) ? (
            <Route
              exact
              path="/dashboard/deposit"
              render={(props) => (
                <div class="welcome-div-container">
                  <Deposit
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></Deposit>
                </div>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, ["can_withdraw"]) ? (
            <Route
              exact
              path="/dashboard/withdraw"
              render={(props) => (
                <div class="welcome-div-container">
                  <Withdraw
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></Withdraw>
                </div>
              )}
            />
          ) : null}

          {process.env.REACT_APP_ENV != "prod" ? (
            <Route
              exact
              path="/dashboard/hidden/deposit-dev"
              render={(props) => (
                <div class="welcome-div-container">
                  <DepositWithdraw
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></DepositWithdraw>
                </div>
              )}
            />
          ) : null}

          {this.props.account &&
          this.props.account.roles &&
          this.props.account.roles.some((role) =>
            this.props.adminRoles.includes(role.name)
          ) ? (
            <Route
              path="/dashboard/admin"
              render={(props) => (
                <DashboardAdmin
                  account={this.props.account}
                  token={this.props.token}
                  time={this.props.time}
                  goBackNavBar={this.state.goBackNavBar}
                  goBackUrl={this.state.goBackUrl}
                  changeTheme={this.props.changeTheme}
                  theme={this.props.theme}
                  handleModalOpen={this.props.handleModalOpen}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  setAccountAndToken={this.props.setAccountAndToken}
                  handleInactivity={this.props.handleInactivity}
                ></DashboardAdmin>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, ["can_pay_me"]) ? (
            <Route
              exact
              path="/dashboard/pay-me"
              render={(props) => (
                <div class="welcome-div-container">
                  <PayMe
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></PayMe>
                </div>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, ["can_pay_me"]) ? (
            <Route
              exact
              path="/dashboard/pay-me/plugins"
              render={(props) => (
                <div class="welcome-div-container">
                  <Plugins
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></Plugins>
                </div>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, ["can_pay_me"]) ? (
            <Route
              exact
              path="/dashboard/pay-me/plugins/add"
              render={(props) => (
                <div class="welcome-div-container">
                  <AddPlugin
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></AddPlugin>
                </div>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, ["can_pay_me"]) ? (
            <Route
              exact
              path="/dashboard/pay-me/plugins/:id"
              render={(props) => (
                <div class="welcome-div-container">
                  <PluginDetail
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    theme={this.props.theme}
                  ></PluginDetail>
                </div>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, [
            "can_pay_vendor",
            "can_pay_peer",
          ]) ? (
            <Route
              exact
              path="/dashboard/payment-options"
              render={(props) => (
                <div class="welcome-div-container">
                  <PaymentOptions
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></PaymentOptions>
                </div>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, ["can_pay_peer"]) ? (
            <Route
              exact
              path="/dashboard/payment-in-app-users/"
              render={(props) => (
                <div class="welcome-div-container">
                  <PaymentInAppUser
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></PaymentInAppUser>
                </div>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, [
            "can_portfolio",
          ]) ? (
            <Route
              exact
              path="/dashboard/portfolio/:tab"
              render={(props) => (
                <div class="welcome-div-container">
                  <Portfolio
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    theme={this.props.theme}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></Portfolio>
                </div>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, [
            "can_portfolio_asset",
          ]) ? (
            <Route
              exact
              path="/dashboard/portfolio/asset/:asset"
              render={(props) => (
                <div class="welcome-div-container">
                  <AssetDetail
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    theme={this.props.theme}
                  ></AssetDetail>
                </div>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, ["can_convert"]) ? (
            <Route
              exact
              path="/dashboard/conversion"
              render={(props) => (
                <div class="welcome-div-container">
                  <Conversion
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></Conversion>
                </div>
              )}
            />
          ) : null}

          {utils.conditionalPermission(this.props.account, [
            "can_mreport_payment",
          ]) ? (
            <Route
              exact
              path="/dashboard/monthy-payment-report"
              render={(props) => (
                <div class="welcome-div-container">
                  <MonthlyPaymentReport
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></MonthlyPaymentReport>
                </div>
              )}
            />
          ) : null}

          <Route
            exact
            path="/dashboard"
            render={(props) => (
              <div class="welcome-div-container">
                <Home
                  account={this.props.account}
                  token={this.props.token}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                  adminRoles={this.props.adminRoles}
                ></Home>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/result/:type/:success"
            render={(props) => (
              <div class="welcome-div-container">
                <InsideResult
                  account={this.props.account}
                  token={this.props.token}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  theme={this.props.theme}
                ></InsideResult>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/settings"
            render={(props) => (
              <div class="welcome-div-container">
                <Settings
                  account={this.props.account}
                  token={this.props.token}
                  time={this.props.time}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  theme={this.props.theme}
                  handleInactivity={this.props.handleInactivity}
                ></Settings>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/settings/change-email"
            render={(props) => (
              <div class="welcome-div-container">
                <ChangeEmail
                  account={this.props.account}
                  token={this.props.token}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  getClientConfig={this.getClientConfig}
                ></ChangeEmail>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/settings/verify-email"
            render={(props) => (
              <div class="welcome-div-container">
                <VerifyEmail
                  account={this.props.account}
                  token={this.props.token}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  getClientConfig={this.getClientConfig}
                ></VerifyEmail>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/settings/change-phone"
            render={(props) => (
              <div class="welcome-div-container">
                <ChangePhone
                  account={this.props.account}
                  token={this.props.token}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  getClientConfig={this.getClientConfig}
                ></ChangePhone>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/settings/verify-phone"
            render={(props) => (
              <div class="welcome-div-container">
                <VerifyPhone
                  account={this.props.account}
                  token={this.props.token}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  getClientConfig={this.getClientConfig}
                ></VerifyPhone>
              </div>
            )}
          />

          {utils.conditionalPermission(this.props.account, [
            "can_pay_vendor",
          ]) ? (
            <>
              <Route
                exact
                path="/dashboard/payment/vendors"
                render={(props) => (
                  <div class="welcome-div-container">
                    <Vendors
                      account={this.props.account}
                      token={this.props.token}
                      changeGoBackNavBar={this.changeGoBackNavBar}
                      region={this.props.region}
                      localizedText={this.props.localizedText}
                      phoneVerified={this.state.phoneVerified}
                      emailVerified={this.state.emailVerified}
                      idVerified={this.state.idVerified}
                    ></Vendors>
                  </div>
                )}
              />
              <Route
                exact
                path="/dashboard/payment/vendors/create"
                render={(props) => (
                  <div class="welcome-div-container">
                    <NewVendor
                      account={this.props.account}
                      token={this.props.token}
                      changeGoBackNavBar={this.changeGoBackNavBar}
                      region={this.props.region}
                      localizedText={this.props.localizedText}
                      phoneVerified={this.state.phoneVerified}
                      emailVerified={this.state.emailVerified}
                      idVerified={this.state.idVerified}
                    ></NewVendor>
                  </div>
                )}
              />
              <Route
                exact
                path="/dashboard/payment/vendors/payment-methods"
                render={(props) => (
                  <div class="welcome-div-container">
                    <NewVendorPaymentMethod
                      account={this.props.account}
                      token={this.props.token}
                      changeGoBackNavBar={this.changeGoBackNavBar}
                      region={this.props.region}
                      localizedText={this.props.localizedText}
                    ></NewVendorPaymentMethod>
                  </div>
                )}
              />
              <Route
                exact
                path="/dashboard/payment/vendors/:vendor_id/payment-methods/update/:payment_method_id"
                render={(props) => (
                  <div class="welcome-div-container">
                    <UpdateVendorPaymentMethod
                      account={this.props.account}
                      token={this.props.token}
                      changeGoBackNavBar={this.changeGoBackNavBar}
                      region={this.props.region}
                      localizedText={this.props.localizedText}
                      phoneVerified={this.state.phoneVerified}
                      emailVerified={this.state.emailVerified}
                      idVerified={this.state.idVerified}
                    ></UpdateVendorPaymentMethod>
                  </div>
                )}
              />

              <Route
                exact
                path="/dashboard/payment/vendors/pay/:id"
                render={(props) => (
                  <div class="welcome-div-container">
                    <PayVendor
                      account={this.props.account}
                      token={this.props.token}
                      changeGoBackNavBar={this.changeGoBackNavBar}
                      region={this.props.region}
                      localizedText={this.props.localizedText}
                    ></PayVendor>
                  </div>
                )}
              />

              <Route
                exact
                path="/dashboard/payments/vendors/detail/:id"
                render={(props) => (
                  <div class="welcome-div-container">
                    <VendorDetail
                      account={this.props.account}
                      token={this.props.token}
                      changeGoBackNavBar={this.changeGoBackNavBar}
                      region={this.props.region}
                      localizedText={this.props.localizedText}
                      theme={this.props.theme}
                    ></VendorDetail>
                  </div>
                )}
              />
            </>
          ) : null}

          <Route path="/" render={(props) => <Redirect to="/dashboard" />} />
        </Switch>
        <VerificationModal
          localizedText={this.props.localizedText}
          showVerifyModal={this.state.showVerifyModal}
          toggleModalVerify={this.toggleModalVerify}
          phoneVerified={this.state.phoneVerified}
          emailVerified={this.state.emailVerified}
          idVerified={this.state.idVerified}
        ></VerificationModal>
        <PaymentInfoModal
          localizedText={this.props.localizedText}
          showVerifyModal={this.state.showPaymentInfoModal}
          toggleModalVerify={this.toggleModalPaymentInfo}
        ></PaymentInfoModal>
        <Footer
          region={this.props.region}
          localizedText={this.props.localizedText}
          theme={this.props.theme}
        ></Footer>
      </React.Fragment>
    );
  }
}

export default withRouter(Dashboard);
