import axios from "axios";

class PortfolioAPI {
  getDefaultPortfolio(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/get_default",
      headers
    );
  }

  getPortfolioBalances(token, portfolioName) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = { portfolio_name: portfolioName };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/get_portfolio_balances",
      requestBody,
      headers
    );
  }

  getAllProducts(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/get_all_products",
      headers
    );
  }

  getTransactionHistory(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/get_deep_portfolio_info",
      requestBody,
      headers
    );
  }

  getTransactionHistoryForSymbol(token, portfolioName, symbol) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_name: portfolioName,
      symbol: symbol,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/portfolio/portfolio_transactions_for_symbol",
      requestBody,
      headers
    );
  }

  changeCurrencyFavorite(token, portfolioName, currency, enabled) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_name: portfolioName,
      base_currency: currency,
      enabled: enabled,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/portafolio_fav",
      requestBody,
      headers
    );
  }

  generateDailyFile(token, startDate, endDate) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = { startDate: startDate, endDate: endDate };

    var endpoint = "/portfolio/reports/generateDailyFile";

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + endpoint,
      requestBody,
      headers
    );
  }

  generateMonthlyReportHTML(token, month, year) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = { month, year };

    var endpoint = "/portfolio/reports/generateDetailPaymentsHtml";

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + endpoint,
      requestBody,
      headers
    );
  }

  generateMonthlyReportPDF(token, month, year) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = { month, year };

    var endpoint = "/portfolio/reports/generateDetailPayments";

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + endpoint,
      requestBody,
      headers
    );
  }
}

export const portfolioAPI = new PortfolioAPI();
