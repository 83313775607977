import React, { Component } from "react";
import "./App.css";
import {
  withRouter,
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/login/login";
import Register from "./pages/register/register";
import TwoSTepVerification from "./pages/twostep-verification/twoStepVerification";
import BloxTerms from "./pages/terms/blox-terms/bloxTerms";
import OutsideResult from "./pages/result/outsideResult/outsideResult";
import ForgotPassword from "./pages/forgot-password/forgotPassword";
import ScrollToTop from "./ScrollToTop";
import RemoveGoBackAutoScroll from "./RemoveGoBackAutoScroll";
import ChangePassword from "./pages/change-password/changePassword";
import { utils } from "./utils/utils";
import Modal from "react-bootstrap/Modal";
import { IoIosArrowForward } from "react-icons/io";
import PassCode from "./pages/pass-code/passCode";

class App extends Component {
  state = {
    region: "",
    stylePath: "",
    theme: "en",
    localizedText: require("./locales/textEn.json"),
    visibility: "hidden",
    showModal: false,

    inactivityTime: 0,
    account: {},
    token: "",

    adminRoles: ["Company_Admin", "Blox_Admin"],
    version: "v.1.3.1",
  };

  constructor(props) {
    super(props);

    //invalidation console function for higher enviorments
    if (process.env.REACT_APP_ENV == "prod") console.log = () => {};

    this.timeoutRef = null;
    this.handleInactivity = this.handleInactivity.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
  }

  componentDidMount() {
    const events = ["mousemove", "keydown", "scroll", "click"];
    events.forEach((event) => {
      window.addEventListener(event, this.resetTimer);
    });

    this.checkForSavedRegion();
    this.checkForSavedTheme();

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      this.setState({
        visibility: "visible",
      });
    } else {
      window.addEventListener("load", this.onPageLoad);
    }
    window.addEventListener("beforeunload", this.savePathNameBeforeRefresh);
    /*
    if (utils.getTokenFromStorage() ) {
      this.goToURL("/dashboard");
    }*/

    // Set the initial timer
    this.resetTimer();
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.onPageLoad);
    window.removeEventListener("beforeunload", this.savePathNameBeforeRefresh); // remove the event handler for normal unmounting

    const events = ["mousemove", "keydown", "scroll", "click"];

    events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    });

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }

  savePathNameBeforeRefresh = () => {
    if (
      window.location.pathname != "/" &&
      window.location.pathname.includes("/dashboard")
    ) {
      sessionStorage.setItem("savedPathname", window.location.pathname);
    }
  };

  onPageLoad = () => {
    this.setState({
      visibility: "visible",
    });
  };

  checkForSavedTheme() {
    if (process.env.REACT_APP_THEMES == "1") {
      //1 theme
      this.setState({
        stylePath: "/themes/light/lightTheme.css",
        theme: "light",
      });
    } else {
      if (localStorage.getItem("theme")) {
        if (localStorage.getItem("theme") == "dark") {
          this.setState({
            stylePath: "/themes/dark/darkTheme.css",
            theme: "dark",
          });
        } else {
          this.setState({
            stylePath: "/themes/light/lightTheme.css",
            theme: "light",
          });
        }
      } else {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          this.setState({
            stylePath: "/themes/dark/darkTheme.css",
            theme: "dark",
          });
        } else {
          this.setState({
            stylePath: "/themes/light/lightTheme.css",
            theme: "light",
          });
        }
      }
    }
  }

  checkForSavedRegion() {
    if (localStorage.getItem("region")) {
      if (localStorage.getItem("region") == "en") {
        this.setState(
          {
            region: "en",
          },
          () => {
            this.changeRequiredText();
          }
        );
      } else if (localStorage.getItem("region") == "es") {
        this.setState(
          {
            region: "es",
          },
          () => {
            this.changeRequiredText();
          }
        );
      } else if (localStorage.getItem("region") == "pt") {
        this.setState(
          {
            region: "pt",
          },
          () => {
            this.changeRequiredText();
          }
        );
      } else {
        this.setState(
          {
            region: "en",
          },
          () => {
            this.changeRequiredText();
          }
        );
      }
    } else {
      this.setState(
        {
          region: "en",
        },
        () => {
          this.changeRequiredText();
        }
      );
    }
  }

  changeTheme = (theme) => {
    if (theme == "dark") {
      this.setState({
        stylePath: "/themes/dark/darkTheme.css",
      });
    } else {
      this.setState({
        stylePath: "/themes/light/lightTheme.css",
      });
    }
    localStorage.setItem("theme", theme);
    this.setState({
      theme: theme,
    });
  };

  changeRegion = (region) => {
    this.setState(
      {
        region: region,
      },
      () => {
        localStorage.setItem("region", region);
        this.changeRequiredText();
        this.setState({
          showModal: false,
        });
      }
    );
  };

  changeRequiredText = () => {
    if (this.state.region == "en") {
      this.setState({
        localizedText: require("./locales/textEn.json"),
      });
    } else if (this.state.region == "es") {
      this.setState({
        localizedText: require("./locales/textEs.json"),
      });
    } else if (this.state.region == "pt") {
      this.setState({
        localizedText: require("./locales/textPt.json"),
      });
    } else {
      this.setState({
        localizedText: require("./locales/textEn.json"),
      });
    }
  };

  handleModalOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      showModal: false,
    });
  };

  setAccountAndToken = (account, token) => {
    console.log("account being set", account);
    this.setState({
      account: account,
      token: token,
    });
  };

  setAccountTokenTime = (account, token, time) => {
    console.log("account being set setAccountTokenTime", account);
    this.setState(
      {
        account: account,
        token: token,
        inactivityTime: Number(time),
      },
      () => {
        if (this.state.token != "") {
          if (
            sessionStorage.getItem("savedPathname") != null &&
            sessionStorage.getItem("savedPathname") != "" &&
            sessionStorage.getItem("savedPathname").includes("/dashboard")
          ) {
            utils.goToURL(this.props, sessionStorage.getItem("savedPathname"));
            sessionStorage.removeItem("savedPathname");
          } else {
            utils.goToURL(this.props, "/dashboard");
          }
        } else {
          utils.goToURL(this.props, "/");
        }
      }
    );
  };

  setInactivityTime = (time) => {
    this.setState({
      inactivityTime: Number(time),
    });
  };

  handleInactivity() {
    if (
      Object.keys(this.state.account).length >= 0 &&
      this.state.token != "" &&
      window.location.pathname != "/verification" &&
      window.location.pathname != "/pass-code"
    ) {
      this.setState(
        {
          token: "",
        },
        () => {
          this.props.history.push({
            pathname: "/pass-code",
            megastate: {
              type: "lockedEnterPassCode",
            },
          });
        }
      );
    }
  }

  resetTimer() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
    if (this.state.inactivityTime != 0) {
      this.timeoutRef = setTimeout(
        this.handleInactivity,
        this.state.inactivityTime * 60 * 1000
      ); // x minutes
    }
  }

  render() {
    return (
      <React.Fragment>
        {" "}
        <div
          style={{
            "font-family": process.env.REACT_APP_FONT,
            visibility: this.state.visibility,
          }}
        >
          <link rel="stylesheet" type="text/css" href={this.state.stylePath} />

          <ScrollToTop />
          <RemoveGoBackAutoScroll />
          <Switch>
            <Route
              path="/dashboard"
              render={(props) =>
                this.state.token != "" &&
                Object.keys(this.state.account).length > 0 ? (
                  <Dashboard
                    changeTheme={this.changeTheme}
                    theme={this.state.theme}
                    handleModalOpen={this.handleModalOpen}
                    region={this.state.region}
                    localizedText={this.state.localizedText}
                    account={this.state.account}
                    token={this.state.token}
                    time={this.state.inactivityTime}
                    setAccountAndToken={this.setAccountAndToken}
                    handleInactivity={this.handleInactivity}
                    adminRoles={this.state.adminRoles}
                  ></Dashboard>
                ) : (
                  <Redirect to="/pass-code" />
                )
              }
            />

            <Route
              exact
              path="/verification"
              render={(props) => (
                <TwoSTepVerification
                  changeTheme={this.changeTheme}
                  theme={this.state.theme}
                  handleModalOpen={this.handleModalOpen}
                  region={this.state.region}
                  localizedText={this.state.localizedText}
                  setAccountAndToken={this.setAccountAndToken}
                  version={this.state.version}
                ></TwoSTepVerification>
              )}
            />
            <Route
              exact
              path="/register"
              render={(props) => (
                <Register
                  changeTheme={this.changeTheme}
                  theme={this.state.theme}
                  handleModalOpen={this.handleModalOpen}
                  region={this.state.region}
                  localizedText={this.state.localizedText}
                ></Register>
              )}
            />
            <Route
              exact
              path="/result"
              render={(props) => (
                <OutsideResult
                  changeTheme={this.changeTheme}
                  theme={this.state.theme}
                  handleModalOpen={this.handleModalOpen}
                  region={this.state.region}
                  localizedText={this.state.localizedText}
                ></OutsideResult>
              )}
            />
            <Route
              exact
              path="/forgot-password"
              render={(props) => (
                <ForgotPassword
                  changeTheme={this.changeTheme}
                  theme={this.state.theme}
                  handleModalOpen={this.handleModalOpen}
                  region={this.state.region}
                  localizedText={this.state.localizedText}
                  version={this.state.version}
                ></ForgotPassword>
              )}
            />
            <Route
              exact
              path="/change-password"
              render={(props) => (
                <ChangePassword
                  changeTheme={this.changeTheme}
                  theme={this.state.theme}
                  handleModalOpen={this.handleModalOpen}
                  region={this.state.region}
                  localizedText={this.state.localizedText}
                  token={this.state.token}
                  setAccountAndToken={this.setAccountAndToken}
                ></ChangePassword>
              )}
            />
            <Route
              exact
              path="/pass-code"
              render={(props) => (
                <PassCode
                  changeTheme={this.changeTheme}
                  theme={this.state.theme}
                  handleModalOpen={this.handleModalOpen}
                  region={this.state.region}
                  localizedText={this.state.localizedText}
                  token={this.state.token}
                  account={this.state.account}
                  setAccountTokenTime={this.setAccountTokenTime}
                  setInactivityTime={this.setInactivityTime}
                ></PassCode>
              )}
            />
            <Route
              exact
              path="/"
              render={(props) => (
                <Login
                  changeTheme={this.changeTheme}
                  theme={this.state.theme}
                  handleModalOpen={this.handleModalOpen}
                  region={this.state.region}
                  localizedText={this.state.localizedText}
                  setAccountAndToken={this.setAccountAndToken}
                  version={this.state.version}
                ></Login>
              )}
            />
            <Route
              exact
              path="/terms/:region"
              render={(props) => <BloxTerms></BloxTerms>}
            />
            <Route path="/" render={(props) => <Redirect to="/" />} />
          </Switch>
        </div>
        <Modal
          show={this.state.showModal}
          onHide={this.handleModalClose}
          className="primary-modal theme-region-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              {this.state.localizedText["change-region"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div class="region-list">
              <div
                class="region-row"
                onClick={this.changeRegion.bind(this, "en")}
              >
                <div class="text-column">English (EN)</div>

                <IoIosArrowForward class="arrow"></IoIosArrowForward>
              </div>

              <div
                class="region-row"
                onClick={this.changeRegion.bind(this, "es")}
              >
                <div class="text-column">Español (ES)</div>

                <IoIosArrowForward class="arrow"></IoIosArrowForward>
              </div>

              <div
                class="region-row"
                onClick={this.changeRegion.bind(this, "pt")}
              >
                <div class="text-column">Portugues do Brasil (pt-BR)</div>

                <IoIosArrowForward class="arrow"></IoIosArrowForward>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
