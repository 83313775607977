import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./settings.css";
import GoBackButton from "../../../components/go-back-button/GoBackButton.jsx";
import Button from "react-bootstrap/Button";
import SettingsService from "./settingsService";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Spinner from "react-bootstrap/Spinner";
import { AiFillCopy, AiOutlineClose, AiOutlineWarning } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import Switch from "react-switch";
import Accordion from "react-bootstrap/Accordion";
import { utils } from "../../../utils/utils.jsx";
import { Link } from "react-router-dom";

class Settings extends Component {
  state = {
    isMobileWidth: false,
    account: {},

    firstName: "",
    lastName: "",
    email: "",
    phone: "",

    newFirstName: "",
    newLastName: "",

    authChoice: "",
    phoneVerified: false,
    emailVerified: false,
    idVerified: false,

    errorMessageType: "",

    qrcodeBase64: "",
    authKey: "",
    authKeyCopied: false,

    showGoogleAuthModal: false,
    showDisableAccountModal: false,

    disableType: "",
    disableAccountStage: 1,
    disableAccountCode: "",
    invalidDisableAccountCode: false,

    confirmPhoneMessage: "",
    loadingPhone: false,

    loading: true,

    sentNumber: 0,
    finishedNumber: 0,
    changeInfoResults: [],

    listInactivityTime: [
      {
        name: "5-seconds",
        value: 0.085,
        prod: false,
      },
      {
        name: "1-minute",
        value: 1,
        prod: true,
      },
      {
        name: "3-minutes",
        value: 3,
        prod: true,
      },
      {
        name: "5-minutes",
        value: 5,
        prod: true,
      },
      {
        name: "10-minutes",
        value: 10,
        prod: true,
      },
      {
        name: "1-day-dev-only",
        value: 1440,
        prod: false,
      },
    ],

    navigationPicked: "account-info",

    loadingSentAuthChange: false,
    loadingSentDisableAccount: false,
  };

  service = new SettingsService();
  vstyle = getComputedStyle(document.body);

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("this.props.history settings", this.props.history);
    this.service.getClientConfig(this.props.token, this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      //maybe take out?
      if (this.props.idVerified != null && !this.props.idVerified) {
        utils.goToURL(this.props, "/dashboard/settings/id-verification");
      }
    }
  }

  componentWillUnmount() {}

  goToURL = (url) => {
    this.props.history.push({ pathname: url, megastate: { from: "vendors" } });
  };

  copyText = () => {
    this.setState({
      authKeyCopied: true,
    });
    navigator.clipboard.writeText(this.state.authKey);
  };

  changeAuthMethod = () => {
    this.setState(
      {
        loadingSentAuthChange: true,
      },
      () => {
        if (this.state.authChoice == "Google Auth") {
          this.service.decline2FA(this.props.token, this.setState.bind(this));
        } else {
          this.service.alwasyOn2FA(this.props.token, this.setState.bind(this));
        }
      }
    );
  };

  handleAuthModalClose = () => {
    this.setState({
      showGoogleAuthModal: false,
    });
  };

  handleDeleteModalOpen = () => {
    this.setState({
      disableType: "delete",
      disableAccountStage: 1,
      showDisableAccountModal: true,
    });
  };

  handleDeactivateModalOpen = () => {
    this.setState({
      disableType: "deactivate",
      disableAccountStage: 1,
      showDisableAccountModal: true,
    });
  };

  handleDisableModalClose = () => {
    this.setState({
      showDisableAccountModal: false,
    });
  };

  saveAccountInfo = () => {
    if (
      this.state.firstName != this.state.newFirstName ||
      this.state.lastName != this.state.newLastName
    ) {
      this.setState(
        {
          sentNumber: this.state.sentNumber + 1,
        },
        () => {
          this.service.changeName(
            this.props.token,
            this.state,
            this.setState.bind(this)
          );
        }
      );
    }
  };

  cancelSaveAccountInfo = () => {
    this.setState({
      newFirstName: this.state.firstName,
      newLastName: this.state.lastName,
    });
  };

  getResultMessage = () => {
    if (
      this.state.sentNumber > 0 &&
      this.state.sentNumber == this.state.finishedNumber
    ) {
      if (this.state.changeInfoResults.some((r) => r)) {
        this.setState(
          {
            errorMessageType: "",
          },
          () => {
            this.setMessages("*information-was-saved-successfully");
          }
        );
      } else {
        this.setState(
          {
            errorMessageType: "",
          },
          () => {
            this.setMessages(
              "*there-was-a-problem-saving-some-of-the-information"
            );
          }
        );
      }
    } else {
      this.setState({
        errorMessageType: "",
      });
    }
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  confirmPhone = () => {
    this.setState(
      {
        loadingPhone: true,
      },
      () => {
        this.service.confirmPhone(this.props.token, this.setState.bind(this));
      }
    );
  };

  moveDisableAccountStage = () => {
    this.setState(
      {
        disableAccountStage: this.state.disableAccountStage + 1,
      },
      () => {
        if (this.state.authChoice == "Phone") {
          this.confirmPhone();
        }
      }
    );
  };

  goToChangePhone = () => {
    this.props.history.push({
      pathname: "/dashboard/settings/change-phone",
      megastate: {
        phone: this.state.phone,
      },
    });
  };

  goToChangeEmail = () => {
    this.props.history.push({
      pathname: "/dashboard/settings/change-email",
      megastate: {
        email: this.state.email,
      },
    });
  };

  goToChangePassword = () => {
    this.props.history.push({
      pathname: "/change-password",
      megastate: {
        fromSettings: true,
      },
    });
  };

  goToVerifyPhone = () => {
    this.props.history.push({
      pathname: "/dashboard/settings/verify-phone",
      megastate: {
        fromSettings: true,
      },
    });
  };

  goToVerifyEmail = () => {
    this.props.history.push({
      pathname: "/dashboard/settings/verify-email",
      megastate: {
        fromSettings: true,
      },
    });
  };

  goToVerifyIdentity = () => {
    this.props.history.push({
      pathname: "/dashboard/settings/id-verification",
      megastate: {
        fromSettings: true,
      },
    });
  };

  goToDisableAccountResultPage = (value) => {
    if (value) {
      this.props.history.push({
        pathname: "/result",
        megastate: {
          success: value,
          type: this.state.disableType + "-account",
          primaryButtonFunction: utils.goToURL.bind(this, this.props, "/"),
          secondButton: false,
        },
      });
    } else {
      this.props.history.push({
        pathname: "/result",
        megastate: {
          success: value,
          type: this.state.disableType + "-account",
          primaryButtonFunction: utils.goToURL.bind(this, this.props, "/"),
          secondButton: false,
        },
      });
    }
  };

  disableAccount = () => {
    if (
      this.state.disableAccountCode != "" &&
      this.state.disableAccountCode.length > 5
    ) {
      this.setState({ loadingSentDisableAccount: true }, () => {
        if (this.state.disableType == "delete") {
          this.service.deleteAccount(
            this.props.token,
            this.state.disableAccountCode,
            this.goToDisableAccountResultPage.bind(this)
          );
        } else {
          this.service.deactivateAccount(
            this.props.token,
            this.state.disableAccountCode,
            this.goToDisableAccountResultPage.bind(this)
          );
        }
      });
    } else {
      this.setState({ invalidDisableAccountCode: true });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  getDisableModalTitle = () => {
    console.log("this.state.disableType", this.state.disableType);
    if (this.state.disableType == "delete") {
      return this.props.localizedText["permanently-delete-account"];
    } else if (this.state.disableType == "deactivate") {
      return this.props.localizedText["deactivate-account"];
    }
  };

  getDisableModalBody = () => {
    if (this.state.disableType == "delete") {
      return this.props.localizedText[
        "before-you-go-on-are-you-really-sure-that-you-want-to-delete-your-account"
      ];
    } else if (this.state.disableType == "deactivate") {
      return this.props.localizedText[
        "deactivating-your-account-will-temporarily-suspend-your-access.-your-data-will-be-retained,-but-you-won't-be-able-to-use-the-platform-until-you-reactivate-your-account."
      ];
    }
  };

  getDisableModalButton = () => {
    if (this.state.disableType == "delete") {
      return this.props.localizedText["delete"];
    } else if (this.state.disableType == "deactivate") {
      return this.props.localizedText["deactivate"];
    }
  };

  getSelectedInactivityTimeName = (value) => {
    return this.props.localizedText[
      this.state.listInactivityTime.filter(
        (item) => item.value === Number(value)
      )[0].name
    ];
  };

  goToChangeIdleTimeout = () => {
    this.props.history.push({
      pathname: "/pass-code",
      megastate: {
        type: "registerNewIdleTimeout",
        goBackUrl: "/dashboard/settings",
      },
    });
  };

  goToRegisterNewPassCode = () => {
    this.props.history.push({
      pathname: "/pass-code",
      megastate: {
        type: "registerNewPassCode",
        goBackUrl: "/dashboard/settings",
      },
    });
  };

  scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const yOffset = -80;
    const y = section.getBoundingClientRect().top + yOffset;

    this.setState(
      {
        navigationPicked: sectionId,
      },
      () => {
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="settings-container">
          <div class="settings-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={
                  this.props.history.action == "PUSH" &&
                  this.props.history.length > 1
                    ? this.goBack
                    : "/dashboard"
                }
              ></GoBackButton>
              {!this.state.loading ? (
                <div class="scroll-navigation-div fade-in-up">
                  {" "}
                  <div
                    class={
                      this.state.navigationPicked == "account-info"
                        ? "scroll-navigation-item scroll-navigation-item-picked"
                        : "scroll-navigation-item"
                    }
                    onClick={() => this.scrollToSection("account-info")}
                  >
                    {this.props.localizedText["account-info"]}
                  </div>
                  <div
                    class={
                      this.state.navigationPicked == "security"
                        ? "scroll-navigation-item scroll-navigation-item-picked"
                        : "scroll-navigation-item"
                    }
                    onClick={() => this.scrollToSection("security")}
                  >
                    {this.props.localizedText["security"]}
                  </div>
                  <div
                    class={
                      this.state.navigationPicked == "verification"
                        ? "scroll-navigation-item scroll-navigation-item-picked"
                        : "scroll-navigation-item"
                    }
                    onClick={() => this.scrollToSection("verification")}
                  >
                    {this.props.localizedText["verification"]}
                  </div>
                  <div
                    class={
                      this.state.navigationPicked == "delete-account"
                        ? "scroll-navigation-item scroll-navigation-item-picked"
                        : "scroll-navigation-item"
                    }
                    onClick={() => this.scrollToSection("delete-account")}
                  >
                    {this.props.localizedText["delete-account"]}
                  </div>
                </div>
              ) : null}
            </div>
            <div class="middle-container">
              {this.state.loading ? (
                <div class="loader-div">
                  <Spinner className="loader"></Spinner>
                </div>
              ) : (
                <div class="container-padding">
                  <div class="title">
                    {this.props.localizedText["settings"]}
                  </div>

                  <Accordion
                    defaultActiveKey={["0", "1", "2", "3", "4"]}
                    className="accordion-settings"
                    alwaysOpen
                  >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header id="account-info">
                        {this.props.localizedText["account-info"]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div class="account-info">
                          <Form.Group className="mb-3">
                            <Form.Label className="input-label-outside-div">
                              {this.props.localizedText["first-name"]}
                            </Form.Label>
                            <FormControl
                              name="newFirstName"
                              value={this.state.newFirstName}
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              className="input-field"
                              disabled={this.state.loadingSent}
                              autoComplete="off"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label className="input-label-outside-div">
                              {this.props.localizedText["last-name"]}
                            </Form.Label>
                            <FormControl
                              name="newLastName"
                              value={this.state.newLastName}
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              className="input-field"
                              disabled={this.state.loadingSent}
                              autoComplete="off"
                            />
                          </Form.Group>

                          <div class="bottom-section-div">
                            <div
                              class={
                                this.state.changeInfoResults.some((r) => r)
                                  ? "result-message success"
                                  : "result-message danger"
                              }
                            >
                              {
                                this.props.localizedText[
                                  this.state.errorMessageType
                                ]
                              }
                            </div>
                            <div class="closer-buttons-div">
                              <Button
                                className="small-button outside-primary-button"
                                disabled={
                                  this.state.firstName ==
                                    this.state.newFirstName &&
                                  this.state.lastName ==
                                    this.state.newLastName &&
                                  this.state.phone == this.state.newPhone
                                }
                                onClick={this.saveAccountInfo}
                              >
                                {this.state.sentNumber !=
                                this.state.finishedNumber ? (
                                  <Spinner className="loader-send-outside-div-button"></Spinner>
                                ) : null}
                                {this.props.localizedText["save"]}
                              </Button>
                              <Button
                                className="small-button outside-secondary-button"
                                onClick={this.cancelSaveAccountInfo}
                              >
                                {this.props.localizedText["cancel"]}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header id="security">
                        {this.props.localizedText["security"]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div class="verification-div">
                          <div class="sub-title">
                            {this.props.localizedText["idle-timeout"]}
                          </div>

                          <div class="verification-info-div">
                            <div class="current-verified">
                              {this.props.localizedText["lock-in:"]}
                              {" " +
                                this.getSelectedInactivityTimeName(
                                  this.props.time
                                )}
                            </div>
                            <Button
                              className="small-button outside-primary-button"
                              onClick={this.goToChangeIdleTimeout}
                            >
                              {this.props.localizedText["change-idle-timeout"]}
                            </Button>
                          </div>
                        </div>
                        <div class="verification-div">
                          <div class="sub-title">
                            {this.props.localizedText["pass-code"]}
                          </div>

                          <div class="verification-info-div">
                            <div class="current-verified">******</div>
                            <Button
                              className="small-button outside-primary-button"
                              onClick={this.goToRegisterNewPassCode}
                            >
                              {this.props.localizedText["change-pass-code"]}
                            </Button>
                          </div>
                        </div>
                        <div class="verification-div">
                          <div class="sub-title">
                            {this.props.localizedText["phone"]}
                          </div>

                          <div class="verification-info-div">
                            <div class="current-verified">
                              {this.state.phone}
                            </div>
                            <Button
                              className="small-button outside-primary-button"
                              onClick={this.goToChangePhone}
                            >
                              {this.props.localizedText["change-phone"]}
                            </Button>
                          </div>
                        </div>
                        <div class="verification-div">
                          <div class="sub-title">
                            {this.props.localizedText["email"]}
                          </div>

                          <div class="verification-info-div">
                            <div class="current-verified">
                              {this.state.email}
                            </div>
                            <Button
                              className="small-button outside-primary-button"
                              onClick={this.goToChangeEmail}
                            >
                              {this.props.localizedText["change-email"]}
                            </Button>
                          </div>
                        </div>
                        <div class="verification-div">
                          <div class="sub-title">
                            {this.props.localizedText["password"]}
                          </div>

                          <div class="verification-info-div">
                            <div class="current-verified">********</div>
                            <Button
                              className="small-button outside-primary-button"
                              onClick={this.goToChangePassword}
                            >
                              {this.props.localizedText["change-password"]}
                            </Button>
                          </div>
                        </div>
                        <div class="verification-div">
                          <div class="sub-title">
                            {
                              this.props.localizedText[
                                "change-authentication-method"
                              ]
                            }
                          </div>

                          <div class="verification-info-div">
                            <div class="current-verified">
                              {this.props.localizedText["current-method:-"]}{" "}
                              {this.state.authChoice}
                            </div>
                            <div class="toggle-button-div">
                              {this.state.loadingSentAuthChange ? (
                                <Spinner className="loader-small-outside "></Spinner>
                              ) : null}
                              <Switch
                                checkedIcon={null}
                                uncheckedIcon={null}
                                onChange={this.changeAuthMethod}
                                activeBoxShadow={null}
                                onColor={this.vstyle.getPropertyValue(
                                  "--inside-success-color"
                                )}
                                checked={this.state.authChoice == "Google Auth"}
                              />
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" id="verification">
                      <Accordion.Header>
                        {this.props.localizedText["verification"]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div class="verification-div">
                          <div class="sub-title">
                            {this.props.localizedText["phone-verification"]}
                          </div>
                          {this.state.phoneVerified ? (
                            <div class="verification-info-div">
                              <div class="current-verified">
                                <FaCheck className="verified-icon" />
                                {this.props.localizedText["phone-is-verified"]}
                              </div>
                            </div>
                          ) : (
                            <div class="verification-info-div">
                              <div class="current-verified">
                                <AiOutlineClose className="not-verified-icon" />
                                {
                                  this.props.localizedText[
                                    "phone-is-not-verified"
                                  ]
                                }
                              </div>
                              <Button
                                className="small-button outside-primary-button"
                                onClick={this.goToVerifyPhone}
                              >
                                {this.props.localizedText["verify-phone"]}
                              </Button>
                            </div>
                          )}
                        </div>
                        <div class="verification-div">
                          <div class="sub-title">
                            {this.props.localizedText["email-verification"]}
                          </div>
                          {this.state.emailVerified ? (
                            <div class="verification-info-div">
                              <div class="current-verified">
                                <FaCheck className="verified-icon" />
                                {this.props.localizedText["email-is-verified"]}
                              </div>
                            </div>
                          ) : (
                            <div class="verification-info-div">
                              <div class="current-verified">
                                <AiOutlineClose className="not-verified-icon" />
                                {
                                  this.props.localizedText[
                                    "email-is-not-verified"
                                  ]
                                }
                              </div>
                              <Button
                                className="small-button outside-primary-button"
                                onClick={this.goToVerifyEmail}
                              >
                                {this.props.localizedText["verify-email"]}
                              </Button>
                            </div>
                          )}
                        </div>
                        <div class="verification-div">
                          <div class="sub-title">
                            {this.props.localizedText["id-verification"]}
                          </div>
                          {this.state.idVerified ? (
                            <div class="verification-info-div">
                              <div class="current-verified">
                                <FaCheck className="verified-icon" />
                                {this.props.localizedText["id-is-verified"]}
                              </div>
                            </div>
                          ) : (
                            <div class="verification-info-div">
                              <div class="current-verified">
                                <AiOutlineClose className="not-verified-icon" />
                                {this.props.localizedText["id-is-not-verified"]}
                              </div>
                              <Button
                                className="small-button outside-primary-button"
                                onClick={this.goToVerifyIdentity}
                              >
                                {this.props.localizedText["verify-identity"]}
                              </Button>
                            </div>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {process.env.REACT_APP_ENV !== "prod" ? (
                      <Accordion.Item eventKey="3">
                        <Accordion.Header id="deposit-dev">
                          Deposit For Development
                        </Accordion.Header>
                        <Accordion.Body>
                          <div class="verification-info-div">
                            <Button
                              className="small-button outside-primary-button"
                              onClick={this.goToRegisterNewPassCode}
                            >
                              Go to Deposit Dev
                            </Button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null}
                    <Accordion.Item eventKey="4">
                      <Accordion.Header id="delete-account">
                        {this.props.localizedText["delete-account"]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div class="disable-div">
                          <div class="description">
                            {
                              this.props.localizedText[
                                "permanently-or-temporarily-suspend-your-account's-activity"
                              ]
                            }
                          </div>
                          <div class="disable-buttons-div">
                            <Button
                              className="outside-danger-button top-button"
                              onClick={this.handleDeactivateModalOpen}
                            >
                              {this.props.localizedText["deactivate-account"]}
                            </Button>

                            <Button
                              className="outside-danger-button"
                              onClick={this.handleDeleteModalOpen}
                            >
                              {this.props.localizedText["delete-account"]}
                            </Button>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
            </div>
            <div class="side-container"></div>
          </div>

          <Modal
            show={this.state.showGoogleAuthModal}
            onHide={this.handleAuthModalClose}
            className="settings-modal"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props.localizedText["google-authenticator"]}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                this.props.localizedText[
                  "please-save-this-within-your-authenticator-app"
                ]
              }
              <img
                className="qrcode"
                src={`data:image/png;base64, ${this.state.qrcodeBase64}`}
              />
              <div class="key-div" onClick={this.copyText}>
                {this.props.localizedText["key:"]}
                <div class="key">
                  {this.state.authKey}
                  {this.state.authKeyCopied ? (
                    <FaCheck class="copy-success-icon" />
                  ) : (
                    <AiFillCopy class="copy-key-icon" />
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="small-button outside-secondary-button"
                onClick={this.handleAuthModalClose}
              >
                {this.props.localizedText["close"]}
              </Button>
              <Button
                className="small-button outside-primary-button"
                onClick={this.handleAuthModalClose}
              >
                {this.props.localizedText["continue"]}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showDisableAccountModal}
            onHide={this.handleDisableModalClose}
            className="settings-modal"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <AiOutlineWarning className="warning-icon danger" />
                {this.getDisableModalTitle()}
              </Modal.Title>
            </Modal.Header>

            {this.state.disableAccountStage == 1 ? (
              <Modal.Body>{this.getDisableModalBody()}</Modal.Body>
            ) : (
              <Modal.Body>
                {!this.state.loadingPhone ? (
                  <div>
                    <Form.Group className="mb-3">
                      <Form.Label className="input-label-outside-div">
                        {this.props.localizedText["verification-code"]}
                      </Form.Label>
                      <FormControl
                        name="disableAccountCode"
                        value={this.state.disableAccountCode}
                        onChange={(e) =>
                          utils.handleChange(e, this.setState.bind(this))
                        }
                        isInvalid={this.state.invalidDisableAccountCode}
                        className="input-field"
                        disabled={this.state.loadingSentDisableAccount}
                      />
                    </Form.Group>
                    {this.state.authChoice == "Phone" ? (
                      <div>
                        {
                          this.props.localizedText[
                            "a-text-message-with-a-8-digit-verification-code-was-just-sent-to"
                          ]
                        }
                        {this.state.confirmPhoneMessage + " "}
                        <b onClick={this.confirmPhone}>
                          {this.props.localizedText["resend-code"]}
                        </b>
                      </div>
                    ) : (
                      <div>
                        {
                          this.props.localizedText[
                            "please-enter-the-code-from-your-authenticator-app"
                          ]
                        }
                      </div>
                    )}
                  </div>
                ) : (
                  <Spinner className="loader" />
                )}
              </Modal.Body>
            )}
            <Modal.Footer>
              <Button
                className="small-button outside-secondary-button"
                onClick={this.handleDisableModalClose}
              >
                {this.props.localizedText["close"]}
              </Button>
              {this.state.disableAccountStage == 1 ? (
                <Button
                  className="small-button outside-primary-button"
                  onClick={this.moveDisableAccountStage}
                >
                  {this.props.localizedText["continue"]}
                </Button>
              ) : (
                <Button
                  className="small-button outside-primary-button"
                  onClick={this.disableAccount}
                  disabled={this.state.loadingSentDisableAccount}
                >
                  {this.state.loadingSentDisableAccount ? (
                    <Spinner className="loader-send-outside-div-button"></Spinner>
                  ) : null}
                  {this.getDisableModalButton()}
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Settings);
