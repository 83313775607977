import { refdataAPI } from "../../../apis/RefdataAPI.jsx";
import { vendorsAPI } from "../../../apis/VendorsAPI.jsx";
import { utils } from "../../../utils/utils";

export default class NewVendorService {
  createVendor(
    token,
    name,
    email,
    countryCode,
    phoneNumber,
    location,
    address,
    city,
    setState,
    setMessages
  ) {
    vendorsAPI
      .createVendor(
        token,
        name,
        email,
        countryCode,
        phoneNumber,
        location,
        address,
        city
      )
      .then((response) => {
        console.log("createVendor", response);
        if (response.data.code === 0) {
          setState(
            {
              loadingSent: false,
              newVendorId: response.data.payload,
            },
            () => {
              setState({
                stage: 2,
              });
            }
          );
        } else {
          setMessages(
            utils.getWhiteLabelText(
              "*an-error-has-ocurred-while-trying-to-create-the-vendor",
              "vendor"
            )
          );
          setState({
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        setMessages(
          utils.getWhiteLabelText(
            "*an-error-has-ocurred-while-trying-to-create-the-vendor",
            "vendor"
          )
        );
        setState({
          loadingSent: false,
        });
      });
  }

  getAllCountries(token, setState) {
    refdataAPI
      .getAllCountries(token)
      .then((response) => {
        console.log("getAllCountries", response);
        if (response.status === 200 && response.data) {
          setState({
            listCountries: utils.orderAlphabetically(
              response.data.filter((country) => country.phone_area_code),
              "country"
            ),
            listAreaCodes: utils.removeObjectDuplicates(
              response.data
                .filter((country) => country.phone_area_code)
                .sort((a, b) => {
                  const phone_area_codeA = a.phone_area_code;
                  const phone_area_codeB = b.phone_area_code;
                  if (phone_area_codeA < phone_area_codeB) {
                    return -1;
                  }
                  if (phone_area_codeA > phone_area_codeB) {
                    return 1;
                  }
                  return 0;
                }),
              "phone_area_code"
            ),
            loadingCountries: false,
          });

          console.log(
            "AREA CODES",
            utils.removeObjectDuplicates(
              response.data
                .filter((country) => country.phone_area_code)
                .sort((a, b) => {
                  const phone_area_codeA = a.phone_area_code;
                  const phone_area_codeB = b.phone_area_code;
                  if (phone_area_codeA < phone_area_codeB) {
                    return -1;
                  }
                  if (phone_area_codeA > phone_area_codeB) {
                    return 1;
                  }
                  return 0;
                }),
              "phone_area_code"
            )
          );
        } else {
        }
      })
      .catch((error) => {
        //Failure
      });
  }
}
