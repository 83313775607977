import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./searchPages.css";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { ImSearch } from "react-icons/im";
import { IoIosArrowForward } from "react-icons/io";
import { utils } from "../../utils/utils";
import { Link } from "react-router-dom";

class SearchPages extends Component {
  state = {
    listPagesShowed: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setListPages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.region) {
        this.setListPages();
      }
    }
  }

  listPages = ({ pages }) => (
    <div class="div-pages">
      {pages.map((page, index) => (
        <Link class="page-row content-container" to={page.path}>
          {page["name"]}
          <IoIosArrowForward class="arrow"></IoIosArrowForward>
        </Link>
      ))}
    </div>
  );

  setListPages = () => {
    let listPages = [];

    if (utils.conditionalPermission(this.props.account, ["can_portfolio"])) {
      listPages.push({
        name: this.props.localizedText["assets"],
        path: "/dashboard/portfolio/assets",
      });
    }

    listPages.push({
      name: this.props.localizedText["change-email"],
      path: "/dashboard/settings/change-email",
    });

    listPages.push({
      name: this.props.localizedText["change-password"],
      path: "/dashboard/settings/change-password",
    });

    if (utils.conditionalPermission(this.props.account, ["can_convert"])) {
      listPages.push({
        name: this.props.localizedText["convert"],
        path: "/dashboard/conversion",
      });
    }

    if (utils.conditionalPermission(this.props.account, ["can_pay_vendors"])) {
      listPages.push({
        name: this.props.localizedText[
          utils.getWhiteLabelText("add-vendor", "vendor")
        ],
        path: "/dashboard/payment/vendors/create",
      });
    }

    if (utils.conditionalPermission(this.props.account, ["can_deposit"])) {
      listPages.push({
        name: this.props.localizedText["deposit"],
        path: "/dashboard/deposit",
      });
    }

    listPages.push({
      name: this.props.localizedText["id-verification"],
      path: "/dashboard/settings/id-verification",
    });

    if (
      utils.conditionalPermission(this.props.account, [
        "can_pay_peer",
        "can_pay_vendor",
      ])
    ) {
      listPages.push({
        name: this.props.localizedText["pay"],
        path: "/dashboard/payment-options",
      });
    }

    if (utils.conditionalPermission(this.props.account, ["can_pay_peer"])) {
      listPages.push({
        name: this.props.localizedText["pay-in-app-users"],
        path: "/dashboard/payment-in-app-users",
      });
    }

    if (utils.conditionalPermission(this.props.account, ["can_pay_vendors"])) {
      listPages.push({
        name: this.props.localizedText[
          utils.getWhiteLabelText("pay-vendor", "vendor")
        ],
        path: "/dashboard/payment/vendors",
      });
    }

    if (utils.conditionalPermission(this.props.account, ["can_portfolio"])) {
      listPages.push({
        name: this.props.localizedText["portfolio"],
        path: "/dashboard/portfolio/assets",
      });
    }

    listPages.push({
      name: this.props.localizedText["settings"],
      path: "/dashboard/settings",
    });

    listPages.push({
      name: this.props.localizedText["transaction-history"],
      path: "/dashboard/portfolio/history",
    });

    listPages.push({
      name: this.props.localizedText["verify-email"],
      path: "/dashboard/settings/verify-email",
    });

    listPages.push({
      name: this.props.localizedText["verify-phone"],
      path: "/dashboard/settings/verify-phone",
    });

    if (utils.conditionalPermission(this.props.account, ["can_withdraw"])) {
      listPages.push({
        name: this.props.localizedText["withdraw"],
        path: "/dashboard/withdraw",
      });
    }

    this.setState({
      listPages,
      listPagesShowed: [],
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="search-pages-container">
          <div class="filter-search-div">
            <InputGroup className="search-bar">
              <ImSearch class="search-icon"></ImSearch>
              <FormControl
                onChange={(e) => {
                  utils.editSearchTermDefaultSearch(
                    e,
                    this.setState.bind(this),
                    this.state.listPages,
                    "listPagesShowed",
                    ["name"]
                  );
                }}
                type="search"
                id="search-bar-entities"
                placeholder={
                  this.props.localizedText["what-are-you-looking-for?"]
                }
                autoComplete="off"
              />
            </InputGroup>
          </div>

          <this.listPages pages={this.state.listPagesShowed}></this.listPages>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SearchPages);
