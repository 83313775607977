import { vendorsAPI } from "../../../apis/VendorsAPI.jsx";
import { refdataAPI } from "../../../apis/RefdataAPI.jsx";
import { utils } from "../../../utils/utils";

export default class NewVendorPaymentMethodService {
  getPaymentMethods(token, setState) {
    vendorsAPI
      .getPaymentMethods(token)
      .then((response) => {
        console.log("getPaymentMethods", response);
        if (response.status === 200 && response.data) {
          var methodsOrdered = utils.orderAlphabetically(
            response.data,
            "description"
          );

          setState({
            listPaymentMethods: methodsOrdered,
            loadingPaymentMethods: false,
          });
        } else {
          console.log("getPaymentMethods", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPaymentMethods", error);
        setState({
          selectedPaymentMethod: this.getMockPaymentMethodResponse()[0],
          listPaymentMethods: this.getMockPaymentMethodResponse(),
          loadingPaymentMethods: false,
        });
      });
  }

  createVendorPaymentAccount(token, id, state, setState, setMessages) {
    let promiseArray = [];
    let failureArray = [];

    vendorsAPI
      .checkVendorPaymentAccountName(token, id, state.paymentAccountName)
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          !response.data.success
        ) {
          console.log("checkVendorPaymentAccountName success", response);
          if (state.addPaymentRequest.swift_bic_code) {
            promiseArray.push(
              refdataAPI.validateSwiftCode(
                token,
                state.addPaymentRequest.swift_bic_code
              )
            );
          } else {
            promiseArray.push(null);
          }

          if (state.addPaymentRequest.routing_number) {
            promiseArray.push(
              refdataAPI.validateRoutingNumber(
                token,
                state.addPaymentRequest.routing_number,
                state.selectedPaymentMethod.in_out_method_type == "ach"
                  ? "ach"
                  : "wire"
              )
            );
          } else {
            promiseArray.push(null);
          }

          Promise.all(promiseArray)
            .then((data) => {
              for (let i = 0; i < data.length; i++) {
                if (data[i] != null) {
                  if (
                    Array.isArray(data[i].data) &&
                    (data[i].data.length == 0 ||
                      (data[i].data[0].status &&
                        data[i].data[0].status == "fail"))
                  ) {
                    if (i == 0) {
                      failureArray.push("swift_bic_code");
                    }
                    if (i == 1) {
                      failureArray.push("routing_number");
                    }
                  }
                }
              }

              if (failureArray.length == 0) {
                vendorsAPI
                  .createVendorPaymentAccount(
                    token,
                    state.paymentAccountName,
                    state.selectedPaymentMethod.in_out_method_type,
                    id,
                    state.addPaymentRequest
                  )
                  .then((response) => {
                    if (response.data.code === 0 && response.data.success) {
                      const newPaymentMethod = {
                        name: state.paymentAccountName,
                        payment_account_id: response.data.payload,
                        pay_method_type:
                          state.selectedPaymentMethod.in_out_method_type,
                        metadata: state.addPaymentRequest,
                      };

                      setState(
                        {
                          loadingSent: false,
                          newPaymentMethod: newPaymentMethod,
                        },
                        () => {
                          setState({
                            stage: 2,
                          });
                        }
                      );
                    } else {
                      setMessages(
                        "*looks-like-we're-having-some-trouble-creating-the-payment-method.-please-try-again."
                      );
                      setState({
                        loadingSent: false,
                      });
                    }
                  })
                  .catch((error) => {
                    setMessages(
                      "*looks-like-we're-having-some-trouble-creating-the-payment-method.-please-try-again."
                    );
                    setState({
                      loadingSent: false,
                    });
                  });
              } else {
                var invalidListPaymentMethods = [];
                for (
                  var i = 0;
                  i < state.selectedPaymentMethod.fields.fields.length;
                  i++
                ) {
                  if (
                    failureArray.includes(
                      state.selectedPaymentMethod.fields.fields[i].name
                    )
                  ) {
                    invalidListPaymentMethods.push({
                      index: i,
                      value: true,
                    });
                  } else {
                    invalidListPaymentMethods.push({
                      index: i,
                      value: false,
                    });
                  }
                }
                setMessages(
                  "*looks-like-some-of-the-info-couldn't-be-validated"
                );
                setState({
                  loadingSent: false,
                  invalidListPaymentMethods: invalidListPaymentMethods,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("checkVendorPaymentAccountName failure", response);
          setMessages(
            "*looks-like-this-account-name-is-already-in-use,-please-rename-it"
          );
          setState({
            loadingSent: false,
          });
        }
      })
      .catch((err) => {
        console.log("checkVendorPaymentAccountName err", err);
        setMessages(
          "*looks-like-this-account-name-is-already-in-use,-please-rename-it"
        );
        setState({
          loadingSent: false,
        });
      });
  }

  validateRoutingNumber(token, routing_number, in_out_method_type, setState) {
    refdataAPI
      .validateRoutingNumber(token, routing_number, in_out_method_type)
      .then((response) => {
        console.log("validateRoutingNumber", response);
        if (
          response.status === 200 &&
          response.data &&
          response.data[0].status &&
          response.data[0].status == "success"
        ) {
          setState({
            loadingInfo: false,
            searchedInfoSuccess: true,
            searchedInfo: response.data[0].data,
          });
        } else {
          console.log("validateRoutingNumber failure", response);
          setState({
            loadingInfo: false,
            searchedInfoSuccess: false,
            searchedInfo: {},
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("validateRoutingNumber error", error);
        setState({
          loadingInfo: false,
          searchedInfoSuccess: false,
          searchedInfo: {},
        });
      });
  }

  validateSwiftCode(token, swift_bic_code, paymentMethodType, setState) {
    refdataAPI
      .validateSwiftCode(token, swift_bic_code)
      .then((response) => {
        console.log("validateSwiftCode", response);
        console.log("paymentMethodType", paymentMethodType);
        if (response.status === 200 && response.data && response.data[0]) {
          setState({
            loadingInfo: false,
            searchedInfoSuccess: true,
            searchedInfo: response.data[0],
          });

          if (
            paymentMethodType == "bank_wire_intl" &&
            response.data[0].country_code == "US"
          ) {
            setState({
              showWireUSNotice: true,
            });
          }
        } else {
          console.log("validateSwiftCode failure", response);
          setState({
            loadingInfo: false,
            searchedInfoSuccess: false,
            searchedInfo: {},
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("validateSwiftCode error", error);
        setState({
          loadingInfo: false,
          searchedInfoSuccess: false,
          searchedInfo: {},
        });
      });
  }
}
