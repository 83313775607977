import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { utils } from "../../../utils/utils.jsx";

class BloxTerms extends Component {
  state = {
    staticHtml: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      this.props.match.params.region &&
      (this.props.match.params.region.toUpperCase() == "EN" ||
        this.props.match.params.region.toUpperCase() == "ES" ||
        this.props.match.params.region.toUpperCase() == "PT")
    ) {
      this.setState({
        staticHtml: require("../../../../public/terms/terms" +
          this.props.match.params.region.toUpperCase() +
          ".pdf"),
      });
    } else {
      utils.goToURL(this.props, "/dashboard");
    }
  }

  render() {
    return (
      <React.Fragment>
        <iframe
          style={{ height: "100vh", width: "100vw" }}
          src={this.state.staticHtml}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(BloxTerms);
