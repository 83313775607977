import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { withRouter } from "react-router-dom";
import "./twoStepVerification.css";
import TwoStepVerificationService from "./twoStepVerificationService";
import ReactCodeInput from "react-code-input";
import Footer from "../../components/footer/Footer.jsx";
import ThemeRegionDiv from "../../components/theme-region-div/ThemeRegionDiv.jsx";
import { AiFillCopy } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import ErrorMessageComponent from "../../components/errorMessage/ErrorMessage.jsx";
import Modal from "react-bootstrap/Modal";
import { FcGoogle } from "react-icons/fc";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaApple } from "react-icons/fa";
import { utils } from "../../utils/utils";

class TwoStepVerification extends Component {
  state = {
    isMobileWidth: false,
    message: "",

    code: "",

    invalidCode: false,

    //for rerendering and clearing
    inputKey: "",

    stageType: "",
    validationForAuth: false,

    //gauth service isn't neccesairly called unless user forgts
    loadingGAuth: false,
    loadingPhone: true,
    loadingEmail: true,

    loadingSent: false,

    qrcodeBase64: "",
    authKey: "",

    authKeyCopied: false,

    showModalDownloadGAuth: false,

    errorMessageType: "",
  };

  service = new TwoStepVerificationService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("2fa props", this.props);
    if (
      !this.props.location ||
      !this.props.location.megastate ||
      !this.props.location.megastate.token
    ) {
      utils.goToURL(this.props, "/");
    } else {
      this.service.checkAuthType(
        this.props.location.megastate.token,
        this.setState.bind(this),
        this.goToChangePassword
      );

      this.checkWindowWidth();

      window.addEventListener("resize", this.checkWindowWidth);
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 1200) {
      flag = true;
    } else {
      flag = false;
    }

    this.setState({
      isMobileWidth: flag,
    });
  };

  onChange = (value) => {
    this.setState(
      {
        code: value,
      },
      () => {
        if (this.state.stageType === "auth" && this.state.code.length == 6) {
          this.validateAuth();
        } else if (
          this.state.stageType === "phone" &&
          this.state.code.length == 8
        ) {
          this.validatePhone();
        } else if (
          this.state.stageType === "email" &&
          this.state.code.length == 8
        ) {
          this.validateEmail();
        }
      }
    );
  };

  goBack = () => {
    console.log("this.props.history", this.props.history);
    this.props.history.goBack();
  };

  goToPassCode = (account) => {
    this.props.history.push({
      pathname: "/pass-code",
      megastate: {
        token: this.props.location.megastate.token,
        account: account,
        type: "registerPassCode",
        goBackUrl: "/login",
      },
    });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  confirmAuthPhone = () => {
    this.setState(
      {
        stageType: "phone",
        validationForAuth: true,
      },
      () => {
        this.confirmPhone();
      }
    );
  };

  confirmAuthEmail = () => {
    this.setState(
      {
        stageType: "email",
        validationForAuth: true,
      },
      () => {
        this.confirmEmail();
      }
    );
  };

  validateAuth = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.code.length == 6) {
          this.setState(
            {
              loadingSent: true,
            },
            () => {
              this.service.validateAuth(
                this.props.location.megastate.token,
                this.goToPassCode.bind(this),
                this.state,
                this.setState.bind(this),
                this.setMessages.bind(this),
                this.props.setAccountAndToken
              );
            }
          );
        } else {
          this.setMessages("*please-enter-an-8-digit-code");
          this.setState({
            invalidCode: true,
          });
        }
      }
    );
  };

  confirmPhone = () => {
    this.setState(
      {
        loadingPhone: true,
        inputKey: this.state.inputKey + 1,
        code: "",
        invalidCode: false,
        errorMessageType: "",
      },
      () => {
        this.service.confirmPhone(
          this.props.location.megastate.token,
          this.setState.bind(this)
        );
      }
    );
  };

  validatePhone = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.code.length == 8) {
          this.setState(
            {
              loadingSent: true,
            },
            () => {
              if (this.state.validationForAuth) {
                this.service.validateAuthPhone(
                  this.props.location.megastate.token,
                  this.state,
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              } else {
                this.service.validatePhone(
                  this.props.location.megastate.token,
                  this.goToPassCode.bind(this),
                  this.state,
                  this.setState.bind(this),
                  this.setMessages.bind(this),
                  this.props.setAccountAndToken
                );
              }
            }
          );
        } else {
          this.setMessages("*please-enter-an-8-digit-code");
          this.setState({
            invalidCode: true,
          });
        }
      }
    );
  };

  confirmEmail = () => {
    this.setState(
      {
        loadingEmail: true,
        inputKey: this.state.inputKey + 1,
        code: "",
        invalidCode: false,
        errorMessageType: "",
      },
      () => {
        this.service.confirmEmail(
          this.props.location.megastate.token,
          this.setState.bind(this)
        );
      }
    );
  };

  validateEmail = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.code.length == 8) {
          this.setState(
            {
              loadingSent: true,
            },
            () => {
              if (this.state.validationForAuth) {
                this.service.validateAuthEmail(
                  this.props.location.megastate.token,
                  this.state.code,
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              } else {
                this.service.validateEmail(
                  this.props.location.megastate.token,
                  utils.goToURL.bind(this),
                  this.state.code,
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              }
            }
          );
        } else {
          this.setMessages("*please-enter-an-8-digit-code");
          this.setState({
            invalidCode: true,
          });
        }
      }
    );
  };

  copyText = () => {
    this.setState({
      authKeyCopied: true,
    });
    navigator.clipboard.writeText(this.state.authKey);
  };

  continueFromQrCode = () => {
    this.setState({
      inputKey: this.state.inputKey + 1,
      code: "",
      stageType: "auth",
    });
  };

  goToChangePassword = () => {
    this.props.history.push({
      pathname: "/change-password",
      megastate: {
        from2fa: true,
      },
    });
  };

  openDownloadGAuth = () => {
    this.setState({
      showModalDownloadGAuth: true,
    });
  };

  handleCloseDownloadGAuth = () => {
    this.setState({
      showModalDownloadGAuth: false,
    });
  };

  openWindow = (url) => {
    window.open(url, "_blank");
  };

  showQrCode = () => (
    <div class="login-2fa-div">
      <img
        class="verification-image"
        src={"/themes/" + this.props.theme + "/logo.png"}
      ></img>
      <div class="register-title">
        {
          this.props.localizedText[
            "please-save-this-within-your-authenticator-app"
          ]
        }
      </div>
      <img
        className="qrcode"
        src={`data:image/png;base64, ${this.state.qrcodeBase64}`}
      />
      <div class="key-div" onClick={this.copyText}>
        {this.props.localizedText["key:"]}
        <div class="key">
          {this.state.authKey}
          {this.state.authKeyCopied ? (
            <FaCheck class="copy-success-icon" />
          ) : (
            <AiFillCopy class="copy-key-icon" />
          )}
        </div>
      </div>
      <div class="buttons-div">
        <Button
          className={
            this.state.isMobileWidth
              ? "primary-button outside-primary-button"
              : "primary-button inside-primary-button"
          }
          onClick={this.continueFromQrCode}
        >
          {this.props.localizedText["continue"]}
        </Button>

        <div class="version-number">{this.props.version}</div>
      </div>
    </div>
  );

  verifyGoogleAuth = () => (
    <div class="login-2fa-div">
      <img
        class="verification-image"
        src={"/themes/" + this.props.theme + "/logo.png"}
      ></img>
      <div class="register-title">
        {this.props.localizedText["2fa-authentication"]}
      </div>

      <p>
        {
          this.props.localizedText[
            "please-enter-the-code-from-your-authenticator-app"
          ]
        }
      </p>
      {this.state.loadingGAuth ? (
        <Spinner className="loader"></Spinner>
      ) : (
        <div class="bottom-section">
          <div class="verification-form-div">
            <ReactCodeInput
              fields={6}
              name="code"
              value={this.state.code}
              onChange={this.onChange}
              key={this.state.inputKey}
              isValid={!this.state.invalidCode}
              disabled={this.state.loadingSent}
              type="tel"
              onWheel={(event) => event.currentTarget.blur()}
            ></ReactCodeInput>
            <div class="first-time-user">
              {
                this.props.localizedText[
                  "still-haven't-generated-your-qr-code?-"
                ]
              }
              <br />
              <b
                class={
                  (this.state.isMobileWidth ? "outside" : "inside") + " links"
                }
                onClick={this.confirmAuthEmail}
              >
                {this.props.localizedText["-generate-new-qr-code"]}
              </b>
            </div>
          </div>

          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>

          <div class="buttons-div">
            <Button
              className={
                this.state.isMobileWidth
                  ? "primary-button outside-primary-button"
                  : "primary-button inside-primary-button"
              }
              onClick={this.validateAuth}
              disabled={this.state.loadingSent}
            >
              {this.state.loadingSent ? (
                <Spinner
                  className={
                    this.state.isMobileWidth
                      ? "loader-send-outside-div-button"
                      : "loader-send-inside-div-button"
                  }
                ></Spinner>
              ) : null}
              {this.props.localizedText["submit"]}
            </Button>

            <Button
              className={
                this.state.isMobileWidth
                  ? "secondary-button outside-secondary-button"
                  : "secondary-button inside-secondary-button"
              }
              onClick={this.goBack}
            >
              {this.props.localizedText["back"]}
            </Button>

            <div class="resend-message">
              {
                this.props.localizedText[
                  "in-the-case-of-loss,-we-can-send-you-a-new-key-to-your-"
                ]
              }
              <b
                class={
                  (this.state.isMobileWidth ? "outside" : "inside") + " links"
                }
                onClick={this.confirmAuthEmail}
              >
                {this.props.localizedText["email"]}
              </b>{" "}
              <div
                class="open-modal-gauth-link"
                onClick={this.openDownloadGAuth}
              >
                {this.props.localizedText["get-google-authenticator"]}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  verifyPhone = () => (
    <div class="login-2fa-div">
      <img
        class="verification-image"
        src={"/themes/" + this.props.theme + "/logo.png"}
      ></img>
      <div class="register-title">
        {this.props.localizedText["2fa-authentication"]}
      </div>
      <p>
        {this.props.localizedText[
          "a-text-message-with-a-8-digit-verification-code-was-just-sent-to"
        ] +
          " " +
          this.state.message}
      </p>
      {this.state.loadingPhone ? (
        <Spinner className="loader"></Spinner>
      ) : (
        <div class="bottom-section">
          <div class="verification-form-div">
            <ReactCodeInput
              fields={8}
              name="code"
              value={this.state.code}
              onChange={this.onChange}
              key={this.state.inputKey}
              isValid={!this.state.invalidCode}
              disabled={this.state.loadingSent}
              type="tel"
            ></ReactCodeInput>
            <p class="resend-message">
              {
                this.props.localizedText[
                  "it-may-take-some-seconds-for-the-code-to-arrive!"
                ]
              }
              <br />
              {this.props.localizedText["still-a-minute?"]}
              &nbsp;
              <b
                onClick={this.confirmPhone}
                class={
                  (this.state.isMobileWidth ? "outside" : "inside") + " links"
                }
              >
                {this.props.localizedText["resend-code"]}
              </b>
            </p>
          </div>
          <div class="error-form-text">
            {this.props.localizedText[this.state.errorMessageType]}
          </div>
          <div class="buttons-div">
            <Button
              className={
                this.state.isMobileWidth
                  ? "primary-button outside-primary-button"
                  : "primary-button inside-primary-button"
              }
              disabled={this.state.loadingSent}
              onClick={this.validatePhone}
            >
              {this.state.loadingSent ? (
                <Spinner
                  className={
                    this.state.isMobileWidth
                      ? "loader-send-outside-div-button"
                      : "loader-send-inside-div-button"
                  }
                ></Spinner>
              ) : null}
              {this.props.localizedText["submit"]}
            </Button>

            <Button
              className={
                this.state.isMobileWidth
                  ? "secondary-button outside-secondary-button"
                  : "secondary-button inside-secondary-button"
              }
              onClick={this.goBack}
            >
              {this.props.localizedText["back"]}
            </Button>
          </div>

          <div class="version-number">{this.props.version}</div>
        </div>
      )}
    </div>
  );

  verifyEmail = () => (
    <div class="login-2fa-div">
      <img
        class="verification-image"
        src={"/themes/" + this.props.theme + "/logo.png"}
      ></img>
      <div class="register-title">
        {this.props.localizedText["2fa-authentication"]}
      </div>
      <p>
        {this.props.localizedText[
          "an-8-digit-verification-code-has-been-sent-to-your-email-address"
        ] + this.state.message}
      </p>
      {this.state.loadingEmail ? (
        <Spinner className="loader"></Spinner>
      ) : (
        <div class="bottom-section">
          <div class="verification-form-div">
            <ReactCodeInput
              fields={8}
              name="code"
              value={this.state.code}
              onChange={this.onChange}
              key={this.state.inputKey}
              isValid={!this.state.invalidCode}
              disabled={this.state.loadingSent}
              type="tel"
            ></ReactCodeInput>
            <p class="resend-message">
              {
                this.props.localizedText[
                  "it-may-take-some-seconds-for-the-code-to-arrive!"
                ]
              }
              <br />
              {this.props.localizedText["still-a-minute?"]}
              &nbsp;
              <b
                onClick={this.confirmEmail}
                class={
                  (this.state.isMobileWidth ? "outside" : "inside") + " links"
                }
              >
                {this.props.localizedText["resend-code"]}
              </b>
            </p>
          </div>

          <div class="error-form-text">
            {this.props.localizedText[this.state.errorMessageType]}
          </div>

          <div class="buttons-div">
            <Button
              className={
                this.state.isMobileWidth
                  ? "primary-button outside-primary-button"
                  : "primary-button inside-primary-button"
              }
              onClick={this.validateEmail}
              disabled={this.state.loadingSent}
            >
              {this.state.loadingSent ? (
                <Spinner
                  className={
                    this.state.isMobileWidth
                      ? "loader-send-outside-div-button"
                      : "loader-send-inside-div-button"
                  }
                ></Spinner>
              ) : null}
              {this.props.localizedText["submit"]}
            </Button>

            <Button
              className={
                this.state.isMobileWidth
                  ? "secondary-button outside-secondary-button"
                  : "secondary-button inside-secondary-button"
              }
              onClick={this.goBack}
            >
              {this.props.localizedText["back"]}
            </Button>
          </div>
          <div class="version-number">{this.props.version}</div>
          {!this.state.validationForAuth ? (
            <Button
              className={"skip-button"}
              onClick={utils.goToURL.bind(this, this.props, "/dashboard/")}
            >
              {this.props.localizedText["skip"]}
            </Button>
          ) : null}
        </div>
      )}
    </div>
  );

  render() {
    return (
      <React.Fragment>
        <div class="verification-container">
          {this.state.stageType === "qrcode" ? (
            <this.showQrCode></this.showQrCode>
          ) : null}
          {this.state.stageType === "auth" ? (
            <this.verifyGoogleAuth></this.verifyGoogleAuth>
          ) : null}
          {this.state.stageType === "phone" ? (
            <this.verifyPhone></this.verifyPhone>
          ) : null}
          {this.state.stageType === "email" ? (
            <this.verifyEmail></this.verifyEmail>
          ) : null}
          <ThemeRegionDiv
            changeTheme={this.props.changeTheme}
            theme={this.props.theme}
            handleModalOpen={this.props.handleModalOpen}
            region={this.props.region}
            localizedText={this.props.localizedText}
          ></ThemeRegionDiv>
        </div>
        <Footer
          region={this.props.region}
          localizedText={this.props.localizedText}
          theme={this.props.theme}
        ></Footer>

        <Modal
          show={this.state.showModalDownloadGAuth}
          onHide={this.handleCloseDownloadGAuth}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="get-google-authenticator-title-div">
              <FcGoogle className="google-icon" />
              {this.props.localizedText["get-google-authenticator"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="download-options-div">
              <div
                class="outside-primary-button download-option first-option"
                onClick={this.openWindow.bind(
                  this,

                  "https://play.google.com/store/search?q=google+authenticator&c=apps&hl=en_US"
                )}
              >
                <IoLogoGooglePlaystore className="store-icon" />
                {this.props.localizedText["get-it-on-google-play"]}
              </div>
              <div
                class="outside-primary-button download-option"
                onClick={this.openWindow.bind(
                  this,
                  "https://apps.apple.com/ng/app/google-authenticator/id388497605"
                )}
              >
                <FaApple className="store-icon" />
                {this.props.localizedText["download-on-the-app-store"]}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(TwoStepVerification);
