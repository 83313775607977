import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./portfolio.css";
import PortfolioService from "./portfolioService";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { TabContent } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { ImSearch } from "react-icons/im";
import TotalBalance from "../../components/total-balance/TotalBalance.jsx";
import { utils } from "../../utils/utils";
import GoBackButton from "../../components/go-back-button/GoBackButton.jsx";
import Assets from "../../components/assets/Assets.jsx";
import Transactions from "../../components/transactions/Transactions.jsx";

class Portfolio extends Component {
  state = {
    isMobileWidth: false,

    name: null,
    account: {},

    assetsSearchTerm: "",
    transactionsSearchTerm: "",

    tabPicked: "assets",
  };

  service = new PortfolioService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    } else {
      //ComponentDidUpdateWill take care of it
    }
    this.checkForTab();
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard"
    );
    window.addEventListener("resize", this.checkWindowWidth);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
    }
  }

  componentWillUnmount() {}

  checkGoBackNavBar = () => {
    this.props.changeGoBackNavBar(false, null);
  };

  checkForTab = () => {
    if (
      this.props.match.params.tab &&
      this.props.match.params.tab == "history"
    ) {
      this.setState({
        tabPicked: "history",
      });
    }
  };

  clickTab = (tabClicked) => {
    this.setState({
      tabPicked: tabClicked,
      selectedDivIndex: null,
    });
  };

  editSearchTermAssets = (e) => {
    this.setState({
      assetsSearchTerm: e.target.value,
    });
  };

  editSearchTermTransactions = (e) => {
    this.setState({
      transactionsSearchTerm: e.target.value,
    });
  };

  shouldCheckTotalBalance = () => {
    this.setState({
      shouldCheckTotalBalance: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="portfolio-container">
          <div class="portfolio-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard"}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              <TotalBalance
                account={this.props.account}
                region={this.props.region}
                localizedText={this.props.localizedText}
                shouldCheckTotalBalance={this.state.shouldCheckTotalBalance}
                stopChecking={this.stopChecking}
                token={this.props.token}
              ></TotalBalance>
              <div class="bottom-content">
                {utils.conditionalPermission(this.props.account, [
                  "can_portfolio_assets",
                ]) &&
                utils.conditionalPermission(this.props.account, [
                  "can_portfolio_history",
                ]) ? (
                  <Tab.Container
                    defaultActiveKey={
                      this.props.match.params.tab &&
                      this.props.match.params.tab == "history"
                        ? "history"
                        : "assets"
                    }
                  >
                    <div class="tab-header">
                      <div class="tab-buttons">
                        <Nav.Item
                          class={
                            this.state.tabPicked == "assets"
                              ? "tab-button tab-picked"
                              : "tab-button"
                          }
                        >
                          <Nav.Link
                            eventKey="assets"
                            onClick={this.clickTab.bind(this, "assets")}
                          >
                            {this.props.localizedText["assets"]}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          class={
                            this.state.tabPicked == "history"
                              ? "tab-button tab-picked"
                              : "tab-button"
                          }
                        >
                          <Nav.Link
                            eventKey="history"
                            onClick={this.clickTab.bind(this, "history")}
                          >
                            {this.props.localizedText["transaction-history"]}
                          </Nav.Link>
                        </Nav.Item>
                      </div>

                      {this.state.tabPicked == "assets" ? (
                        <div class="filter-search-div">
                          <InputGroup className="search-bar">
                            <ImSearch class="search-icon"></ImSearch>
                            <FormControl
                              onChange={(e) => {
                                this.editSearchTermAssets(
                                  e,
                                  this.setState.bind(this),
                                  this.state.listPortfolios,
                                  "listPortfoliosShowed"
                                );
                              }}
                              id="search-bar-entities"
                              placeholder={
                                this.props.localizedText["search-your-assets"]
                              }
                            />
                          </InputGroup>
                        </div>
                      ) : (
                        <div class="filter-search-div">
                          <InputGroup className="search-bar">
                            <ImSearch class="search-icon"></ImSearch>
                            <FormControl
                              onChange={(e) => {
                                this.editSearchTermTransactions(e);
                              }}
                              type="search"
                              id="search-bar-entities"
                              placeholder={
                                this.props.localizedText["search-transactions"]
                              }
                            />
                          </InputGroup>
                        </div>
                      )}
                    </div>
                    <Tab.Content>
                      <Tab.Pane
                        eventKey="assets"
                        title="Assets"
                        tabClassName="tab"
                      >
                        <TabContent>
                          <Assets
                            token={this.props.token}
                            localizedText={this.props.localizedText}
                            shouldCheckTotalBalance={
                              this.shouldCheckTotalBalance
                            }
                            searchTerm={this.state.assetsSearchTerm}
                          />
                        </TabContent>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="history"
                        title="History"
                        tabClassName="tab"
                      >
                        <TabContent>
                          <Transactions
                            token={this.props.token}
                            localizedText={this.props.localizedText}
                            searchTerm={this.state.transactionsSearchTerm}
                          />
                        </TabContent>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                ) : (
                  <div>
                    {utils.conditionalPermission(this.props.account, [
                      "can_portfolio_assets",
                    ]) ? (
                      <div class="fade-in-up">
                        <div class="filter-search-div">
                          <InputGroup className="search-bar">
                            <ImSearch class="search-icon"></ImSearch>
                            <FormControl
                              onChange={(e) => {
                                this.editSearchTermAssets(
                                  e,
                                  this.setState.bind(this),
                                  this.state.listPortfolios,
                                  "listPortfoliosShowed"
                                );
                              }}
                              id="search-bar-entities"
                              placeholder={
                                this.props.localizedText["search-your-assets"]
                              }
                            />
                          </InputGroup>
                        </div>
                        <Assets
                          token={this.props.token}
                          localizedText={this.props.localizedText}
                          shouldCheckTotalBalance={this.shouldCheckTotalBalance}
                          searchTerm={this.state.assetsSearchTerm}
                        />
                      </div>
                    ) : null}
                    {utils.conditionalPermission(this.props.account, [
                      "can_portfolio_history",
                    ]) ? (
                      <div class="fade-in-up">
                        <div class="filter-search-div">
                          <InputGroup className="search-bar">
                            <ImSearch class="search-icon"></ImSearch>
                            <FormControl
                              onChange={(e) => {
                                this.editSearchTermTransactions(e);
                              }}
                              type="search"
                              id="search-bar-entities"
                              placeholder={
                                this.props.localizedText["search-transactions"]
                              }
                            />
                          </InputGroup>
                        </div>
                        <Transactions
                          token={this.props.token}
                          localizedText={this.props.localizedText}
                          searchTerm={this.state.transactionsSearchTerm}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div class="side-container"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Portfolio);
