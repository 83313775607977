import React, { useState } from "react";
import "./noFundsComponent.css";
import { MdWallet } from "react-icons/md";
import { Button } from "react-bootstrap";

export default function NoFundsComponent(props) {
  return (
    <div class="no-funds-div fade-in-up">
      <MdWallet className="no-funds-icon" />
      <div class="title">{props.localizedText["no-funds-yet"]}</div>
      <div class="sub-title">
        {props.localizedText["looks-like-this-you-dont-have-any-funds-yet"]}
      </div>
      <div class="buttons-div">
        <Button
          className="primary-button outside-primary-button"
          onClick={props.primaryButtonFunction}
        >
          {props.localizedText["deposit"]}
        </Button>

        <Button
          className="secondary-button outside-secondary-button"
          onClick={props.secondaryButtonFunction}
        >
          {props.localizedText["cancel"]}
        </Button>
      </div>
    </div>
  );
}
