import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function RemoveGoBackAutoScroll({ history }) {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  return null;
}

export default withRouter(RemoveGoBackAutoScroll);
