import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./depositStatic.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { Spinner } from "react-bootstrap";
import { utils } from "../../../utils/utils.jsx";
import ErrorMessageComponent from "../../errorMessage/ErrorMessage.jsx";
import ConversionLinkComponent from "../../conversionLink/ConversionLink.jsx";
import { AiFillCopy } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";

class DepositStatic extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    errorMessageType: "",
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  copyText = () => {
    this.setState({
      inboundAddressCopied: true,
    });
    navigator.clipboard.writeText(this.state.inboundAddress);
  };

  render() {
    return (
      <React.Fragment>
        <div class="deposit-static-div fade-in-up">
          <div class="bank-info-message">
            Please deposit your desired funds by following the instructions and
            using the payment information provided below
          </div>
          <div class="bank-info-subtitle">Bank Details</div>
          <div class="bank-info-div">
            <div class="info-row">
              <div class="key">Beneficiary Name</div>
              <div class="value">Blox Cross</div>
            </div>{" "}
            <div class="info-row">
              <div class="key">Beneficiary Address</div>
              <div class="value">330 S Rampart Blvd, Las Vegas, Nevada</div>
            </div>
            <div class="info-row">
              <div class="key">Account Number</div>
              <div class="value">37430077</div>
            </div>
            <div class="info-row">
              <div class="key">SWIFT/BIC Code</div>
              <div class="value">RBBCUS6L</div>
            </div>
            <div class="info-row">
              <div class="key">Routing Number</div>
              <div class="value">1220455037</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(DepositStatic);
