import axios from "axios";

class DepositAPI {
  depositDev(token, amount, instrument, portfolioName) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_name: portfolioName,
      instrument: instrument,
      qty: Number(amount),
      cost_basis: 1,
      from: "Chase-8074",
      notes: "Wired from Chase",
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/portfolio/deposit",
      requestBody,
      headers
    );
  }

  depositSimple(
    token,
    portfolioName,
    currency,
    amount,
    note,
    usdEquivalent,
    payer_redirect_url
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      portfolio_name: portfolioName,
      amount: amount,
      currency: currency,
      note: note,
      payer_redirect_url: payer_redirect_url,
      usd_equiv: usdEquivalent,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/simple_funds_in",
      requestBody,
      headers
    );
  }

  depositBank(
    token,
    currency,
    toCurrency,
    amount,
    note,
    usdEquivalent,
    payer_redirect_url
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      amount: amount,
      from_currency: currency,
      to_currency: toCurrency,
      note: note,
      payer_redirect_url: payer_redirect_url,
      usd_equiv: usdEquivalent,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/funds_in_bank",
      requestBody,
      headers
    );
  }

  depositBankBRL(
    token,
    currency,
    toCurrency,
    amount,
    note,
    usdEquivalent,
    payer_redirect_url,
    request_quote_id
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      amount: amount,
      from_currency: currency,
      to_currency: toCurrency,
      note: note,
      payer_redirect_url: payer_redirect_url,
      usd_equiv: usdEquivalent,
      request_quote_id: request_quote_id,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/funds_in_bank",
      requestBody,
      headers
    );
  }

  depositCash(
    token,
    currency,
    amount,
    note,
    usdEquivalent,
    payer_redirect_url
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      amount: amount,
      from_currency: currency,
      note: note,
      payer_redirect_url: payer_redirect_url,
      usd_equiv: usdEquivalent,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/funds_in_cash",
      requestBody,
      headers
    );
  }

  getQuote(token, fiatCurrency, fiatAmount, cryptoSymbol) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let url =
      "/credit-card-service/request-payment/estimate?fiatCurrency=" +
      fiatCurrency +
      "&fiatAmount=" +
      fiatAmount +
      "&cryptoSymbol=" +
      cryptoSymbol;

    return axios.get(process.env.REACT_APP_BASE_API_URL + url, headers);
  }

  checkoutUrl(
    token,
    cryptoSymbol,
    fiatAmount,
    payerEmail,
    payerName,
    invoiceNumber,
    memo,
    description
  ) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let url = "/credit-card-service/request-payment/email";

    const requestBody = {
      symbol: cryptoSymbol,
      fiatAmount: Number(fiatAmount),
      payerName: payerName,
      payerEmail: payerEmail,
      invoiceNumber: invoiceNumber,
      memo: memo,
      description: description,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + url,
      requestBody,
      headers
    );
  }

  checkoutUrlCrypto(token, crypto, network, amount, payerEmail, payerName) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let url = "/credit-card-service/request-payment/crypto/email";

    const requestBody = {
      symbol: crypto,
      amount: Number(amount),
      blockchain: network,
      payerName: payerName,
      payerEmail: payerEmail,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + url,
      requestBody,
      headers
    );
  }
}

export const depositAPI = new DepositAPI();
