import { payMeAPI } from "../../apis/PayMeAPI.jsx";

export default class PayMeService {
  getCurrencies(token, setState) {
    payMeAPI
      .getPayMeCurrencies(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getPayMeCurrencies success", response);
          var listCryptosWithUSD = [...response.data];
          listCryptosWithUSD.unshift({
            symbol: "USD",
            fiat: true,
          });
          setState({
            listCurrenciesCreditCard: listCryptosWithUSD,
            listCryptos: response.data,
            loadingCurrencies: false,
          });
        } else {
          console.log("getPayMeCurrencies failure", response);
          setState({
            loadingCurrencies: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPayMeCurrencies error", error);
        setState({
          loadingCurrencies: false,
        });
      });
  }
}
