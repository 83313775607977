import { loginAPI } from "../../apis/LoginAPI.jsx";

export default class TwoStepVerificationService {
  checkAuthType(token, setState, goToChangePassword) {
    loginAPI
      .getClientConfig(token)
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data) {
          if (response.data.required_2fa_2proceed == "SMS") {
            setState(
              {
                stageType: "phone",
                validationForAuth: false,
              },
              () => {
                this.confirmPhone(token,setState);
              }
            );
          }

          if (response.data.required_2fa_2proceed == "GOOGLE_AUTH") {
            setState({
              stageType: "auth",
            });
          }
          if (response.data.required_2fa_2proceed == "CHANGE_PASSWORD") {
            goToChangePassword();
          }
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  confirmPhone(token, setState) {
    loginAPI
      .confirmPhone(token)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          //Success
          var mySubString = response.data.payload.substring(
            response.data.payload.indexOf("[") + 1,
            response.data.payload.lastIndexOf("]]")
          );

          setState(
            {
              message:
                "xxx-xxx-" +
                mySubString.slice(mySubString.length - 3, mySubString.length),
            },
            () => {
              setState({ loadingPhone: false });
            }
          );
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error.response);
      });
  }

  confirmEmail(token, setState) {
    loginAPI
      .confirmEmail(token)
      .then((response) => {
        if (response.status === 200) {
          //Success
          var arrays = response.data.payload.split(" ");
          var cutEmail = arrays[arrays.length - 1].slice(3);

          setState(
            {
              message: "***" + cutEmail,
            },
            () => {
              setState({ loadingEmail: false });
            }
          );
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  validatePhone(
    token,
    redirectCallback,
    state,
    setState,
    setMessages,
    setAccountAndToken
  ) {
    loginAPI
      .validatePhone(token, state.code)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          //Success

          this.getClientValidationStatus(
            token,
            redirectCallback,
            state,
            setState,
            setAccountAndToken
          );
        } else {
          //Failure
          setMessages(
            "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateEmail(token, redirectCallback, code, setState, setMessages) {
    loginAPI
      .validateEmail(token, code)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          //Success
          redirectCallback("/dashboard");
        } else {
          //Failure
          setMessages(
            "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateAuthPhone(token, state, setState, setMessages) {
    loginAPI
      .validateAuthPhone(token, state.code)
      .then((response) => {
        console.log("SMS", response);
        if (response.status === 200 && response.data) {
          //Success
          setState({
            qrcodeBase64: response.data.qrcode,
            authKey: response.data.key,

            invalidCode: false,
            loadingSent: false,
            stageType: "qrcode",
          });
        } else {
          //Failure
          setMessages(
            "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateAuthEmail(token, code, setState, setMessages) {
    loginAPI
      .validateAuthEmail(token, code)
      .then((response) => {
        if (response.status === 200 && response.data) {
          //Success
          setState({
            qrcodeBase64: response.data.qrcode,
            authKey: response.data.key,

            invalidCode: false,
            loadingSent: false,
            stageType: "qrcode",
          });
        } else {
          //Failure
          setMessages(
            "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateAuth(
    token,
    redirectCallback,
    state,
    setState,
    setMessages,
    setAccountAndToken
  ) {
    loginAPI
      .validateAuth(token, state.code)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          //Success

          this.getClientValidationStatus(
            token,
            redirectCallback,
            state,
            setState,
            setAccountAndToken
          );
        } else {
          //Failure
          setMessages(
            "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  getClientValidationStatus(
    token,
    redirectCallback,
    state,
    setState,
    setAccountAndToken
  ) {
    loginAPI
      .getClientConfig(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          //Code for adding auth and logging goes here
          //SEND TO PIN CODE
          var cleanedUser = response.data;
          if (cleanedUser.token_type) {
            delete cleanedUser.token_type;
          }
          if (cleanedUser.required_2fa_2proceed) {
            delete cleanedUser.required_2fa_2proceed;
          }
          setAccountAndToken(cleanedUser, token);
          if (response.data.email_validated) {
            redirectCallback(cleanedUser, "/pass-code");
          } else {
            setState(
              {
                stageType: "email",
                loadingSent: false,
                inputKey: state.inputKey + 1,
                code: "",
                invalidCode: false,
                errorMessageType: "",
              },
              () => {
                this.confirmEmail(setState);
              }
            );
          }
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
      });
  }
}
