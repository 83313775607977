import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./vendorDetail.css";
import GoBackButton from "../../../components/go-back-button/GoBackButton.jsx";
import Button from "react-bootstrap/Button";
import VendorDetailService from "./vendorDetailService";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { TabContent } from "react-bootstrap";
import { ImSearch } from "react-icons/im";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaSearchMinus, FaPrint } from "react-icons/fa";
import { LuPackageSearch } from "react-icons/lu";
import { utils } from "../../../utils/utils";
import { IoMdDownload, IoMdOpen } from "react-icons/io";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ReceiptComponent from "../../../components/receipt/Receipt.jsx";
import Spinner from "react-bootstrap/Spinner";
import PrintPDFComponent from "../../../components/printPDF/PrintPDF.jsx";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessage.jsx";
import Form from "react-bootstrap/Form";

class VendorDetail extends Component {
  state = {
    isMobileWidth: false,

    vendor: {},
    listVendorsSkeleton: [1, 2, 3, 4, 5, 6],
    listPaymentMethods: [], //only used for loading selected payment methods

    listSelectedVendorPaymentMethods: [],
    listSelectedVendorPaymentMethodsShowed: [],

    listPayments: [],
    listPaymentsShowed: [],

    selectedDivIndex: null,
    selectedPaymentMethod: {}, //to delete
    selectedPayment: {},
    selectedButtonType: null,

    showModalDeletePaymentMethod: false,
    showModalDeleteVendor: false,
    showModalPaymentDetails: false,
    showModalApprovePayment: false,

    tabPicked: "pay",
    loadingPaymentMethods: true,
    loadingPayments: false,
    loadingSentDelete: false,

    name: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    location: "",
    address: "",
    city: "",

    errorMessageType: "",
    newVendorId: "",
    listAreaCodes: [],
    listCountries: [],
    stage: 1,

    invalidName: false,
    invalidEmail: false,
    invalidRegionCode: false,
    invalidPhoneNumber: false,
    invalidLocation: false,
    invalidAddress: false,
    invalidCity: false,

    loadingCountries: true,
    loadingSentUpdate: false,
  };

  service = new VendorDetailService();

  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      this.goToURL("/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      this.goToURL("/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      this.goToURL("/dashboard/settings/id-verification");
    }

    this.service.getAllCountries(
      this.props.token,
      this.setState.bind(this),
      this.loadInfo.bind(this)
    );

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard/payment/vendors"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard/payment/vendors"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.phoneVerified != null && !this.props.phoneVerified) {
        this.goToURL("/dashboard/settings/verify-phone");
      } else if (
        this.props.emailVerified != null &&
        !this.props.emailVerified
      ) {
        this.goToURL("/dashboard/settings/verify-email");
      } else if (this.props.idVerified != null && !this.props.idVerified) {
        this.goToURL("/dashboard/settings/id-verification");
      }
    }
  }

  loadInfo() {
    //comes from payment detail click
    this.service.getPayments(
      this.props.token,
      this.props.match.params.id,
      this.setState.bind(this),
      this.openPaymentDetailsFromSelectedPayment.bind(this)
    );
    if (
      this.props.location.megastate &&
      this.props.location.megastate.vendor &&
      this.props.location.megastate.listPaymentMethods
    ) {
      this.setState(
        {
          vendor: this.props.location.megastate.vendor,
          listPaymentMethods: this.props.location.megastate.listPaymentMethods,

          name: this.props.location.megastate.vendor.name,
          email: this.props.location.megastate.vendor.email,
          countryCode: utils.filterFunction(
            this.state.listCountries,
            ["gec"],
            this.props.location.megastate.vendor.phone_country
          )[0]["gec"],
          phoneNumber: this.props.location.megastate.vendor.phone,
          location: utils.filterFunction(
            this.state.listCountries,
            ["gec"],
            this.props.location.megastate.vendor.location
          )[0]["gec"],
          address: this.props.location.megastate.vendor.address
            ? this.props.location.megastate.vendor.address
            : "",
          city: this.props.location.megastate.vendor.city
            ? this.props.location.megastate.vendor.city
            : "",
        },
        () => {
          if (this.state.listPaymentMethods.length > 0) {
            const listSelectedVendorPaymentMethods =
              this.getSelectedVendorPaymentMethods(this.props.match.params.id);
            this.setState({
              listSelectedVendorPaymentMethods:
                listSelectedVendorPaymentMethods,
              listSelectedVendorPaymentMethodsShowed:
                listSelectedVendorPaymentMethods,
              //have to wait for listPaymethods to fill
              loadingPaymentMethods: false,
            });
          } else {
            this.setState({
              listSelectedVendorPaymentMethods: [],
              listSelectedVendorPaymentMethodsShowed: [],
              loadingPaymentMethods: false,
            });
          }
        }
      );
    } else {
      //call service
      this.setState(
        {
          loadingPaymentMethods: true,
          loadingPayments: true,
        },
        () => {
          this.service.getVendors(
            this.props.token,
            this.setState.bind(this),
            this.props.match.params.id,
            this.state.listCountries
          );
          this.service.getVendorPaymentMethods(
            this.props.token,
            this.setState.bind(this),
            this.getSelectedVendorPaymentMethods.bind(this),
            this.props.match.params.id
          );
        }
      );
    }

    if (
      this.props.location.megastate &&
      this.props.location.megastate.forEdit
    ) {
      this.clickTab("settings");
    }
  }

  reloadPaymentMethods = () => {
    this.setState(
      {
        loadingPaymentMethods: true,
      },
      () => {
        this.service.getVendorPaymentMethods(
          this.props.token,
          this.setState.bind(this),
          this.getSelectedVendorPaymentMethods.bind(this),
          this.props.match.params.id
        );
      }
    );
  };

  goToURL = (url) => {
    this.props.history.push({
      pathname: url,
      megastate: { from: "vendorDetail" },
    });
  };

  goToAddPaymentMethod = (id) => {
    this.props.history.push({
      pathname: "/dashboard/payment/vendors/payment-methods",
      megastate: {
        from: "vendorDetail",
        client_vendor_id: id,
      },
    });
  };

  goToUpdateVendor = () => {
    this.props.history.push({
      pathname:
        "/dashboard/payment/vendors/update/" + this.props.match.params.id,
      megastate: {
        from: "vendorDetail",
        vendor: this.state.vendor,
      },
    });
  };

  goToUpdatePaymentMethod = (paymentMethod) => {
    this.props.history.push({
      pathname:
        "/dashboard/payment/vendors/" +
        this.props.match.params.id +
        "/payment-methods/update/" +
        paymentMethod.payment_account_id,
      megastate: {
        from: "vendorDetail",
        client_vendor_id: this.props.match.params.id,
        paymentMethod: paymentMethod,
      },
    });
  };

  goToPayVendor = (paymentMethod) => {
    this.props.history.push({
      pathname:
        "/dashboard/payment/vendors/pay/" + paymentMethod.payment_account_id,
      megastate: {
        from: "vendorDetail",
        paymentMethod: paymentMethod,
      },
    });
  };

  getSelectedVendorPaymentMethods = (id) => {
    const selectedPaymentMethods = this.state.listPaymentMethods.find(
      (x) => x.client_vendor_id === id
    );
    console.log("selectedPaymentMethods", selectedPaymentMethods);
    if (selectedPaymentMethods) {
      return selectedPaymentMethods.json_build_object.accounts;
    } else {
      return [];
    }
  };

  openDeletePaymentMethod = (paymentMethod) => {
    this.setState({
      showModalDeletePaymentMethod: true,
      selectedPaymentMethod: paymentMethod,
    });
  };

  handleCloseDeletePaymentMethod = () => {
    this.setState({
      showModalDeletePaymentMethod: false,
    });
  };

  handleClosePaymentDetails = () => {
    this.setState({
      selectedDivIndex: null,
      showModalPaymentDetails: false,
    });
  };

  handleCloseApprovePayment = () => {
    this.setState({
      selectedDivIndex: null,
      showModalApprovePayment: false,
    });
  };

  openPaymentDetails = (payment, index) => {
    if (payment.status == "Pending" && process.env.REACT_APP_ENV == "local") {
      this.setState({
        selectedDivIndex: index,
        selectedPayment: payment,
        showModalApprovePayment: true,
      });
    } else {
      this.setState({
        selectedDivIndex: index,
        selectedPayment: payment,
        showModalPaymentDetails: true,
      });
    }
  };

  openPaymentDetailsFromSelectedPayment = () => {
    if (
      this.props.location.megastate &&
      this.props.location.megastate.selectedPaymentIndex != null
    ) {
      this.setState({
        tabPicked: "payments",
        selectedDivIndex: this.props.location.megastate.selectedPaymentIndex,
        selectedPayment:
          this.state.listPayments[
            this.props.location.megastate.selectedPaymentIndex
          ],
        showModalPaymentDetails: true,
      });
    }
  };

  openDeleteVendor = () => {
    this.setState({
      showModalDeleteVendor: true,
    });
  };

  handleCloseDeleteVendor = () => {
    this.setState({
      showModalDeleteVendor: false,
    });
  };

  deletePaymentMethod = () => {
    this.setState(
      {
        loadingSentDelete: true,
      },
      () => {
        this.service.deletePaymentMethod(
          this.props.token,
          this.state.selectedPaymentMethod.payment_account_id,
          this.reloadPaymentMethods.bind(this),
          this.setState.bind(this)
        );
      }
    );
  };

  deleteVendor = () => {
    this.setState(
      {
        loadingSentDelete: true,
      },
      () => {
        this.service.deleteVendor(
          this.props.token,
          this.props.match.params.id,
          this.goToDeleteVendorResult.bind(this)
        );
      }
    );
  };

  goToDeleteVendorResult = (value) => {
    this.props.history.push({
      pathname: "/dashboard/result/delete-vendor/" + value,
      megastate: { returnURL: "/dashboard/payment/vendors" },
    });
  };

  clickTab = (tabClicked) => {
    this.setState({
      tabPicked: tabClicked,
      selectedDivIndex: null,
    });
  };

  skeletonCards = ({ cards }) => (
    <div class="div-cards-container">
      {cards.map((portfolio) => (
        <div class="card-vendor content-container">
          <div class="metadata-column-skeleton">
            <div class="skeleton-box image-skeleton"></div>
            <div class="text-skeleton">
              <div class="skeleton-box title-skeleton"></div>
              <div class="skeleton-box abbreviation-skeleton"></div>
            </div>
          </div>
          <div class="skeleton-box graph-column-skeleton"></div>
          <div class="balance-column-skeleton">
            <div class="skeleton-box value-skeleton"></div>
            <div class="skeleton-box percentage-skeleton"></div>
          </div>
        </div>
      ))}
    </div>
  );

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  paymentMethods = ({ listPaymentMethods }) => (
    <div style={{ height: "100%" }}>
      <InputGroup className="search-bar search-vendor-detail">
        <ImSearch class="search-icon"></ImSearch>
        <FormControl
          onChange={(e) => {
            utils.editSearchTermDefault(
              e,
              this.setState.bind(this),
              this.state.listSelectedVendorPaymentMethods,
              "listSelectedVendorPaymentMethodsShowed",
              ["name"]
            );
          }}
          type="search"
          id="search-bar-entities"
          placeholder={this.props.localizedText["search-payment-methods"]}
        />
      </InputGroup>
      {this.state.listSelectedVendorPaymentMethods.length > 0 &&
      this.state.listSelectedVendorPaymentMethodsShowed.length > 0 ? (
        <div class="div-cards-container">
          <div class="headers">
            <div class="info-column">{this.props.localizedText["name"]}</div>
            <div class="type-column">{this.props.localizedText["type"]}</div>
            <div class="metadata-column"></div>
            <div class="button-column"></div>
          </div>
          <div class="div-cards">
            {listPaymentMethods.map((paymentMethod, index) => (
              <div
                class={
                  "card-vendor content-container-no-hover " +
                  (listPaymentMethods.length - 1 === index
                    ? "last-indexed "
                    : "")
                }
              >
                <div class="info-column">
                  <div class="name">{paymentMethod.name}</div>
                  <div class="id">{paymentMethod.payment_account_id}</div>
                </div>
                <div class="type-column">{paymentMethod.pay_method_type}</div>
                {Object.keys(paymentMethod.metadata).length < 4 ? (
                  <div class="metadata-column">
                    {Object.keys(paymentMethod.metadata).map((key, i) => (
                      <span class="metadata-data-row">
                        <div class="key">{key}</div>
                        <div class="value"> {paymentMethod.metadata[key]}</div>
                      </span>
                    ))}
                  </div>
                ) : (
                  <div class="metadata-column">
                    {Object.keys(paymentMethod.metadata)
                      .slice(0, 4)
                      .map((key, i) => (
                        <span class="metadata-data-row">
                          <div class="key">{key}</div>
                          <div class="value">
                            {" "}
                            {paymentMethod.metadata[key]}
                          </div>
                        </span>
                      ))}
                  </div>
                )}

                <div class="button-column">
                  <MdEdit
                    className="icon"
                    onClick={this.goToUpdatePaymentMethod.bind(
                      this,
                      paymentMethod
                    )}
                  ></MdEdit>
                  <MdDelete
                    className="icon"
                    onClick={this.openDeletePaymentMethod.bind(
                      this,
                      paymentMethod
                    )}
                  ></MdDelete>{" "}
                  <Button
                    className="pay-button inside-primary-button"
                    onClick={this.goToPayVendor.bind(this, paymentMethod)}
                  >
                    {this.props.localizedText["pay"]}
                  </Button>
                </div>
              </div>
            ))}{" "}
          </div>
        </div>
      ) : null}
      {this.state.listSelectedVendorPaymentMethods.length > 0 &&
      this.state.listSelectedVendorPaymentMethodsShowed.length == 0 ? (
        <div class="no-list-div">
          <FaSearchMinus className="empty-icon" />
          <div class="title">{this.props.localizedText["no-result-found"]}</div>
          <div class="sub-title">
            {
              this.props.localizedText[
                "we-couldn't-find-any-items-matching-your-search"
              ]
            }
          </div>
        </div>
      ) : null}
      {this.state.listSelectedVendorPaymentMethods.length == 0 &&
      this.state.listSelectedVendorPaymentMethodsShowed.length == 0 ? (
        <div class="no-list-div">
          <LuPackageSearch className="empty-icon" />
          <div class="title">
            {this.props.localizedText["no-payment-methods-yet"]}
          </div>
          <div class="sub-title">
            {this.props.localizedText["add-payment-methods-for-easy-payments"]}
          </div>
        </div>
      ) : null}
    </div>
  );

  listPayments = ({ payments }) => (
    <div style={{ height: "100%" }}>
      {" "}
      <InputGroup className="search-bar search-vendor-detail">
        <ImSearch class="search-icon"></ImSearch>
        <FormControl
          onChange={(e) => {
            utils.editSearchTermDefault(
              e,
              this.setState.bind(this),
              this.state.listPayments,
              "listPaymentsShowed",
              ["account", "account_id"]
            );
          }}
          type="search"
          id="search-bar-entities"
          placeholder={this.props.localizedText["search-payments"]}
        />
      </InputGroup>
      {this.state.listPayments.length > 0 &&
      this.state.listPaymentsShowed.length > 0 ? (
        <div class="div-cards-container">
          <div class="headers">
            <div class="account-column">
              {this.props.localizedText["payment-info"]}
            </div>
            <div class="payment-method-type-column">
              {this.props.localizedText["type"]}
            </div>
            {process.env.REACT_APP_ENV != "prod" ? (
              <div class="status-column"></div>
            ) : null}
            <div class="paid-with-column"></div>{" "}
            <div class="amount-column">
              {this.props.localizedText["amount"]}
            </div>
          </div>
          <div>
            {payments.map((payment, index) => (
              <div
                class={
                  this.state.selectedDivIndex === index
                    ? "card-vendor cursor-pointer selected-card content-container"
                    : "card-vendor cursor-pointer content-container"
                }
                onClick={this.openPaymentDetails.bind(this, payment, index)}
              >
                <div class="account-column">
                  <div class="account">{payment.account}</div>
                  <div class="date">
                    {utils.formatDateUSWithTime(payment.creation_time)}
                  </div>
                </div>
                <div class="payment-method-type-column">
                  {payment.pay_method_type}
                </div>
                {payment.status == "Approved" &&
                process.env.REACT_APP_ENV != "prod" ? (
                  <div class="status-column success-status">
                    {payment.status}
                  </div>
                ) : null}
                {payment.status == "Failed" &&
                process.env.REACT_APP_ENV != "prod" ? (
                  <div class="status-column danger-status">
                    {payment.status}
                  </div>
                ) : null}{" "}
                {payment.status == "Pending" &&
                process.env.REACT_APP_ENV != "prod" ? (
                  <div class="status-column pending-status">
                    {payment.status}
                  </div>
                ) : null}
                <div class="paid-with-column">
                  {payment.paid_with.slice(0, 4) +
                    " " +
                    payment.paid_with.slice(
                      payment.paid_with.length - 8,
                      payment.paid_with.length
                    )}
                </div>
                <div class="amount-column">
                  {utils.roundDecimals("", payment.amount)}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {this.state.listPayments.length > 0 &&
      this.state.listPaymentsShowed.length == 0 ? (
        <div class="no-list-div">
          <FaSearchMinus className="empty-icon" />
          <div class="title">{this.props.localizedText["no-result-found"]}</div>
          <div class="sub-title">
            {
              this.props.localizedText[
                "we-couldn't-find-any-items-matching-your-search"
              ]
            }
          </div>
        </div>
      ) : null}
      {this.state.listPayments.length == 0 &&
      this.state.listPaymentsShowed.length == 0 ? (
        <div class="no-list-div">
          <LuPackageSearch className="empty-icon" />
          <div class="title">{this.props.localizedText["no-payments-yet"]}</div>
          <div class="sub-title">
            {this.props.localizedText["add-payment-methods-for-easy-payments"]}
          </div>
        </div>
      ) : null}
    </div>
  );

  getClassForStatus = (status) => {
    if (status == "approved") {
      return "success-status";
    } else if (status == "failed") {
      return "danger-status";
    } else if (status == "pending") {
      return "pending-status";
    }
  };

  getReceiptProps = () => {
    //because of mock status for vendors
    var arrayData = [
      {
        name: this.props.localizedText["transaction-id"],
        value: this.state.selectedPayment.hold_transaction_id
          ? this.state.selectedPayment.hold_transaction_id
          : "-",
        type: "normal",
      },

      {
        name: this.props.localizedText["account"],
        value: this.state.selectedPayment.account
          ? this.state.selectedPayment.account
          : "-",
        type: "normal",
      },
      {
        name: this.props.localizedText["account-type"],
        value: this.state.selectedPayment.pay_method_type
          ? this.state.selectedPayment.pay_method_type
          : "-",
        type: "normal",
      },
      {
        name: this.props.localizedText["account-id"],
        value: this.state.selectedPayment.account_id
          ? this.state.selectedPayment.account_id
          : "-",
        type: "normal",
      },
      {
        name: this.props.localizedText["date"],
        value: this.state.selectedPayment.creation_time
          ? this.state.selectedPayment.creation_time
          : "-",
        type: "date",
      },
      {
        name: this.props.localizedText["description"],
        value: this.state.selectedPayment.description
          ? this.state.selectedPayment.description
          : "-",
        type: "normal",
      },
      {
        name: this.props.localizedText["external-memo"],
        value: this.state.selectedPayment.external_memo
          ? this.state.selectedPayment.external_memo
          : "-",
        type: "normal",
      },
      process.env.REACT_APP_ENV != "prod"
        ? {
            name: this.props.localizedText["status"],
            value: this.state.selectedPayment.status.toLowerCase(),
            class: this.getClassForStatus(
              this.state.selectedPayment.status.toLowerCase()
            ),
            type: "status",
          }
        : null,
      {
        currency: "",
        name: this.props.localizedText["amount"],
        value: this.state.selectedPayment.amount,
        type: "amount",
      },
    ];

    var data = [
      {
        title: this.props.localizedText["transfer-summary"],
        data: arrayData,
      },
    ];

    return data;
  };

  downloadPDF = () => {
    this.setState(
      {
        loadingDownload: true,
      },
      () => {
        utils.generatePDFFromReceipt(
          this.state.selectedPayment.account +
            "-" +
            this.state.selectedPayment.amount,
          this.setState.bind(this)
        );
      }
    );
  };

  shallowEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (key != "active") {
        const val1 = obj1[key];
        const val2 = obj2[key];

        if (
          typeof val1 === "object" &&
          typeof val2 === "object" &&
          val1 !== null &&
          val2 !== null
        ) {
          if (!this.shallowEqual(val1, val2)) {
            return false;
          }
        } else if (val1 !== val2) {
          return false;
        }
      }
    }

    return true;
  };

  updateVendor = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.name != "" &&
          this.state.email != "" &&
          this.state.countryCode != "" &&
          this.state.phoneNumber != "" &&
          this.state.location != "" &&
          this.state.address != "" &&
          this.state.city != ""
        ) {
          if (this.emailRegex.test(this.state.email)) {
            this.setState(
              {
                invalidName: false,
                invalidEmail: false,
                invalidRegionCode: false,
                invalidPhoneNumber: false,
                invalidLocation: false,
                invalidAddress: false,
                invalidCity: false,
                errorMessageType: "",
                loadingSentUpdate: true,
              },
              () => {
                this.service.updateVendor(
                  this.props.token,
                  this.state,
                  this.props.match.params.id,
                  {
                    client_vendor_id: this.props.match.params.id,
                    name: this.state.name,
                    location: this.state.location,
                    email: this.state.email,
                    phone_country: this.state.countryCode,
                    phone: this.state.phoneNumber,
                    address: this.state.address,
                    city: this.state.city,
                    metadata: {},
                    status: this.state.vendor.status,
                  },
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              }
            );
          } else {
            this.setMessages("*please-enter-a-valid-email");
            this.setState({
              invalidEmail: !this.emailRegex.test(this.state.email),
            });
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidName: this.state.name === "",
            invalidEmail: this.state.email === "",
            invalidRegionCode: this.state.countryCode === "",
            invalidPhoneNumber: this.state.phoneNumber === "",
            invalidLocation: this.state.country === "",
            invalidAddress: this.state.address === "",
            invalidCity: this.state.city === "",
          });
        }
      }
    );
  };

  cancelUpdate = () => {
    this.setState({
      name: this.state.vendor.name,
      email: this.state.vendor.email,
      countryCode: this.state.vendor.phone_country,
      phoneNumber: this.state.vendor.phone,
      location: this.state.vendor.location,
      address: this.state.vendor.address,
      city: this.state.vendor.city,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="vendor-container">
          <div class="vendor-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard/payment/vendors"}
              ></GoBackButton>
            </div>

            <div class="middle-container container-padding">
              {this.props.location.megastate &&
              this.props.location.megastate.vendor &&
              this.props.location.megastate.vendor.name ? (
                <div class="title-div">
                  <div class="title">
                    {this.props.location.megastate.vendor.name}
                  </div>
                </div>
              ) : (
                <div class="title-div">
                  {this.state.vendor.name && this.state.vendor.name != "" ? (
                    <div class="title">{this.state.vendor.name}</div>
                  ) : (
                    <Spinner className="loader-title" />
                  )}
                </div>
              )}

              <Tab.Container
                defaultActiveKey={
                  this.props.location.megastate &&
                  this.props.location.megastate.selectedPaymentIndex != null
                    ? "payments"
                    : "pay"
                }
              >
                <div class="tab-header">
                  <div class="tab-buttons">
                    <Nav.Item
                      class={
                        this.state.tabPicked == "pay"
                          ? "tab-button tab-picked"
                          : "tab-button"
                      }
                    >
                      <Nav.Link
                        eventKey="pay"
                        onClick={this.clickTab.bind(this, "pay")}
                      >
                        {this.props.localizedText["pay"]}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      class={
                        this.state.tabPicked == "payments"
                          ? "tab-button tab-picked"
                          : "tab-button"
                      }
                    >
                      <Nav.Link
                        eventKey="payments"
                        onClick={this.clickTab.bind(this, "payments")}
                      >
                        {this.props.localizedText["payments"]}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      class={
                        this.state.tabPicked == "info"
                          ? "tab-button tab-picked"
                          : "tab-button"
                      }
                    >
                      <Nav.Link
                        eventKey="info"
                        onClick={this.clickTab.bind(this, "info")}
                      >
                        {this.props.localizedText["info"]}
                      </Nav.Link>
                    </Nav.Item>
                  </div>

                  {this.state.tabPicked == "pay" ? (
                    <Button
                      className="primary-button outside-primary-button small-button fade-in-up"
                      onClick={this.goToAddPaymentMethod.bind(
                        this,
                        this.state.vendor.client_vendor_id
                      )}
                    >
                      {this.props.localizedText["add-payment-method"]}
                    </Button>
                  ) : null}
                  {this.state.tabPicked == "payments" ? (
                    <Nav.Link
                      className="primary-button outside-primary-button small-button fade-in-up"
                      eventKey="pay"
                      onClick={this.clickTab.bind(this, "pay")}
                    >
                      {this.props.localizedText["pay"]}
                    </Nav.Link>
                  ) : null}
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="pay" tabClassName="tab">
                    <TabContent>
                      <div class="bottom-content">
                        {!this.state.loadingPaymentMethods ? (
                          <this.paymentMethods
                            listPaymentMethods={
                              this.state.listSelectedVendorPaymentMethodsShowed
                            }
                          ></this.paymentMethods>
                        ) : (
                          <this.skeletonCards
                            cards={this.state.listVendorsSkeleton}
                          ></this.skeletonCards>
                        )}
                      </div>
                    </TabContent>
                  </Tab.Pane>
                  <Tab.Pane eventKey="payments" tabClassName="tab">
                    <TabContent>
                      <div class="bottom-content">
                        {!this.state.loadingPayments ? (
                          <this.listPayments
                            payments={this.state.listPaymentsShowed}
                          ></this.listPayments>
                        ) : (
                          <this.skeletonCards
                            cards={this.state.listVendorsSkeleton}
                          ></this.skeletonCards>
                        )}
                      </div>
                    </TabContent>
                  </Tab.Pane>

                  <Tab.Pane eventKey="info" tabClassName="tab">
                    <TabContent>
                      <div class="bottom-content info-div">
                        <div class="settings-sub-section-div">
                          <div class="sub-section-title">
                            {this.props.localizedText["name"]}
                          </div>
                          <div class="sub-section-body">
                            {this.state.loadingCountries ? (
                              <Spinner className="loader"></Spinner>
                            ) : (
                              <div className="fade-in-up">
                                <div class="div-parent-flex-column">
                                  <Form.Group className="mb-3">
                                    <Form.Label className="input-label-outside-div">
                                      {this.props.localizedText["name"]}
                                    </Form.Label>
                                    <FormControl
                                      type="text"
                                      name="name"
                                      value={this.state.name}
                                      onChange={(e) =>
                                        utils.handleChange(
                                          e,
                                          this.setState.bind(this)
                                        )
                                      }
                                      isInvalid={this.state.invalidName}
                                      className="input-field"
                                      disabled={this.state.loadingSent}
                                    />
                                  </Form.Group>

                                  <Form.Group className="mb-3">
                                    <Form.Label className="input-label-outside-div">
                                      {this.props.localizedText["email"]}
                                    </Form.Label>
                                    <FormControl
                                      name="email"
                                      value={this.state.email}
                                      onChange={(e) =>
                                        utils.handleChange(
                                          e,
                                          this.setState.bind(this)
                                        )
                                      }
                                      isInvalid={this.state.invalidEmail}
                                      className="input-field"
                                      disabled={this.state.loadingSent}
                                    />
                                  </Form.Group>

                                  <Form.Group className="mb-3">
                                    <Form.Label className="input-label-outside-div">
                                      {this.props.localizedText["phone-number"]}
                                    </Form.Label>
                                    <InputGroup className="mb-3 input-field">
                                      <Form.Select
                                        variant="outline-secondary"
                                        onChange={(e) =>
                                          utils.handleChange(
                                            e,
                                            this.setState.bind(this)
                                          )
                                        }
                                        name="countryCode"
                                        isInvalid={this.state.invalidRegionCode}
                                        style={{
                                          width: "8rem",
                                          flex: "0 1 auto",
                                          textAlign: "center",
                                        }}
                                        disabled={this.state.loadingSent}
                                        value={this.state.countryCode}
                                      >
                                        <option></option>
                                        {this.state.listAreaCodes.map(
                                          (country) => (
                                            <option value={country.gec}>
                                              {country.phone_area_code}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                      <Form.Control
                                        type="text"
                                        name="phoneNumber"
                                        value={this.state.phoneNumber}
                                        onChange={(e) =>
                                          utils.handleChange(
                                            e,
                                            this.setState.bind(this)
                                          )
                                        }
                                        isInvalid={
                                          this.state.invalidPhoneNumber
                                        }
                                        className="input-field"
                                      />
                                    </InputGroup>
                                  </Form.Group>

                                  <Form.Group className="mb-3 form-group">
                                    <Form.Label className="input-label-outside-div">
                                      {this.props.localizedText["location"]}
                                    </Form.Label>
                                    <InputGroup className="mb-3 input-field">
                                      <Form.Select
                                        variant="outline-secondary"
                                        onChange={(e) =>
                                          utils.handleChange(
                                            e,
                                            this.setState.bind(this)
                                          )
                                        }
                                        name="location"
                                        isInvalid={this.state.invalidLocation}
                                        style={{
                                          width: "100%",
                                          flex: "0 1 auto",
                                          textAlign: "start",
                                        }}
                                        disabled={this.state.loadingSent}
                                        value={this.state.location}
                                      >
                                        <option></option>
                                        {this.state.listCountries.map(
                                          (country) => (
                                            <option value={country.gec}>
                                              {country.country}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                    </InputGroup>
                                  </Form.Group>

                                  <div class="div-parent-flex-row">
                                    <div class="div-50-son">
                                      {" "}
                                      <Form.Group className="mb-3">
                                        <Form.Label className="input-label-outside-div">
                                          {this.props.localizedText["address"]}
                                        </Form.Label>
                                        <FormControl
                                          name="address"
                                          value={this.state.address}
                                          onChange={(e) =>
                                            utils.handleChange(
                                              e,
                                              this.setState.bind(this)
                                            )
                                          }
                                          isInvalid={this.state.invalidAddress}
                                          className="input-field"
                                          disabled={this.state.loadingSent}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div class="div-50-son">
                                      {" "}
                                      <Form.Group className="mb-3">
                                        <Form.Label className="input-label-outside-div">
                                          {this.props.localizedText["city"]}
                                        </Form.Label>
                                        <FormControl
                                          name="city"
                                          value={this.state.city}
                                          onChange={(e) =>
                                            utils.handleChange(
                                              e,
                                              this.setState.bind(this)
                                            )
                                          }
                                          isInvalid={this.state.invalidCity}
                                          className="input-field"
                                          disabled={this.state.loadingSent}
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>

                                  <ErrorMessageComponent
                                    errorMessage={
                                      this.props.localizedText[
                                        this.state.errorMessageType
                                      ]
                                    }
                                  ></ErrorMessageComponent>
                                </div>
                                <div class="save-buttons-div">
                                  <Button
                                    className="back-button outside-secondary-button"
                                    disabled={
                                      this.state.loadingSentUpdate ||
                                      this.shallowEqual(this.state.vendor, {
                                        client_vendor_id:
                                          this.props.match.params.id,
                                        name: this.state.name,
                                        location: this.state.location,
                                        email: this.state.email,
                                        phone_country: this.state.countryCode,
                                        phone: this.state.phoneNumber,
                                        address: this.state.address,
                                        city: this.state.city,
                                        metadata: {},
                                        status: this.state.vendor.status,
                                      })
                                    }
                                    onClick={this.cancelUpdate}
                                  >
                                    {this.props.localizedText["cancel"]}
                                  </Button>

                                  <Button
                                    className="small-button outside-primary-button"
                                    onClick={this.updateVendor}
                                    disabled={
                                      this.state.loadingSentUpdate ||
                                      this.shallowEqual(this.state.vendor, {
                                        client_vendor_id:
                                          this.props.match.params.id,
                                        name: this.state.name,
                                        location: this.state.location,
                                        email: this.state.email,
                                        phone_country: this.state.countryCode,
                                        phone: this.state.phoneNumber,
                                        address: this.state.address,
                                        city: this.state.city,
                                        metadata: {},
                                        status: this.state.vendor.status,
                                      })
                                    }
                                  >
                                    {this.state.loadingSentUpdate ? (
                                      <Spinner className="loader-send-outside-div-button"></Spinner>
                                    ) : null}
                                    {this.props.localizedText["save"]}
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="settings-sub-section-div">
                          <div class="sub-section-title">
                            {this.props.localizedText["delete"]}
                          </div>
                          <div class="sub-section-body">
                            <Button
                              className="outside-danger-button"
                              onClick={this.openDeleteVendor}
                            >
                              {
                                this.props.localizedText[
                                  utils.getWhiteLabelText(
                                    "delete-vendor",
                                    "vendor"
                                  )
                                ]
                              }
                            </Button>
                          </div>
                        </div>

                        <ErrorMessageComponent
                          errorMessage={
                            this.props.localizedText[
                              this.state.errorMessageType
                            ]
                          }
                        ></ErrorMessageComponent>
                      </div>
                    </TabContent>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            <div class="side-container"></div>
          </div>
        </div>

        <Modal
          show={this.state.showModalDeletePaymentMethod}
          onHide={this.handleCloseDeletePaymentMethod}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["delete-payment-method"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.localizedText[
              "are-you-sure-you-want-to-delete-the-payment-method"
            ] + " "}
            <b>{this.state.selectedPaymentMethod.name}</b>
            {"?"}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleCloseDeletePaymentMethod}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button
              className="verify-button outside-primary-button"
              onClick={this.deletePaymentMethod}
              disabled={this.state.loadingSentDelete}
            >
              {this.state.loadingSentDelete ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["delete"]}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalPaymentDetails}
          onHide={this.handleClosePaymentDetails}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["payment-details"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="detail-container">
              {Object.keys(this.state.selectedPayment).length > 0 ? (
                <ReceiptComponent
                  data={this.getReceiptProps()}
                ></ReceiptComponent>
              ) : null}

              <div class="icons-row">
                {this.state.loadingDownload ? (
                  <div class="loader-icon">
                    <Spinner className="loader-download left-icon"></Spinner>
                  </div>
                ) : (
                  <IoMdDownload class="left-icon" onClick={this.downloadPDF} />
                )}
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <FaPrint class="right-icon" onClick={handlePrint} />
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div class="payment-detail-footer">
              {this.props.localizedText["if-you-need-help"]}
              <br />
              {this.props.localizedText["go-to-our-"]}
              <a href="https://www.blox.global/contact/">
                {this.props.localizedText["resolution-center"]}
              </a>{" "}
              {
                this.props.localizedText[
                  "for-help-with-this-transaction,-to-settle-a-dispute-or-open-a-claim"
                ]
              }
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalApprovePayment}
          onHide={this.handleCloseApprovePayment}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["approve-payment"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="approve-text">
              {
                this.props.localizedText[
                  "are-you-sure-you-want-to-approve-the-following-payment:"
                ]
              }
            </div>
            {Object.keys(this.state.selectedPayment).length > 0 ? (
              <ReceiptComponent
                data={this.getReceiptProps()}
              ></ReceiptComponent>
            ) : null}
          </Modal.Body>
          <Modal.Footer className="vendor-detail-modal-footer">
            <Button className="back-button outside-secondary-button">
              {this.props.localizedText["close"]}
            </Button>
            <div style={{ display: "flex" }}>
              <Button className="reject-button outside-primary-button">
                {this.props.localizedText["reject"]}
              </Button>
              <Button className="outside-primary-button">
                {this.props.localizedText["approve"]}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalDeleteVendor}
          onHide={this.handleCloseDeleteVendor}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {
                this.props.localizedText[
                  utils.getWhiteLabelText("delete-vendor", "vendor")
                ]
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.localizedText[
              utils.getWhiteLabelText(
                "are-you-sure-you-want-to-delete-the-vendor",
                "vendor"
              )
            ] + " "}
            <b>{this.state.vendor.name}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleCloseDeleteVendor}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button
              className="verify-button outside-primary-button"
              onClick={this.deleteVendor}
              disabled={this.state.loadingSentDelete}
            >
              {this.state.loadingSentDelete ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["delete"]}
            </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ display: "none" }} id="hidden-div">
          <div
            class="detail-container-print"
            ref={(el) => (this.componentRef = el)}
            id="pdf"
          >
            {Object.keys(this.state.selectedPayment).length > 0 ? (
              <PrintPDFComponent
                theme={this.props.theme}
                title={this.props.localizedText["transaction-details"]}
                receiptData={this.getReceiptProps()}
                selectedTransaction={this.state.selectedPayment}
                text={this.props.localizedText["powered-by-blox"]}
                localizedText={this.props.localizedText}
              ></PrintPDFComponent>
            ) : null}
          </div>{" "}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(VendorDetail);
