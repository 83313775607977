import axios from "axios";

class LoginAPI {
  login(username, password) {
    const headers = {};
    const requestBody = {
      username: username,
      password: password,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/login",
      requestBody,
      {
        headers: headers,
      }
    );
  }

  registerAccount(requestData) {
    const registerRequest = {
      fname: requestData.fName,
      lname: requestData.lName,
      email: requestData.email,
      phone: requestData.countryCode + requestData.phoneNumber,
      password: requestData.password,
      domicile: requestData.domicile,
      language: requestData.language,
      preferred_currency: requestData.preferredCurrency,
    };

    const headers = {
      "x-api-key": process.env.REACT_APP_X_API_KEY,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/v2/simple",
      registerRequest,
      {
        headers: headers,
      }
    );
  }

  getClientConfig(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/client/config",
      headers
    );
  }

  confirmPhone(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/confirmPhone",
      requestBody,
      headers
    );
  }

  confirmEmail(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/confirmEmail",
      requestBody,
      headers
    );
  }

  validatePhone(token, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      code: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/validatePhone",
      requestBody,
      headers
    );
  }

  validateEmail(token, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      code: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/validateEmail",
      requestBody,
      headers
    );
  }

  validateAuthPhone(token, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      code: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/register/gen_2fa_qrcode_with_smscode",
      requestBody,
      headers
    );
  }

  validateAuthEmail(token, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      code: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/register/gen_2fa_qrcode_with_emailcode",
      requestBody,
      headers
    );
  }

  validateAuth(token, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      code: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/validate_2fa_code",
      requestBody,
      headers
    );
  }

  forgotPassword(email) {
    const headers = {};
    const requestBody = {
      email: email,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/lost_pwd",
      requestBody,
      headers
    );
  }

  logout(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/logout",
      requestBody,
      headers
    );
  }
}

export const loginAPI = new LoginAPI();
