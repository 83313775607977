import { settingsAPI } from "../../../apis/SettingsAPI.jsx";
import { refdataAPI } from "../../../apis/RefdataAPI.jsx";

export default class SettingsService {
  getCountries(setState) {
    refdataAPI
      .getCountries()
      .then((response) => {
        console.log("getCountries", response);
        if (response.status === 200 && response.data) {
          setState({
            listCountries: response.data,
            loadingCountries: false,
          });
        } else {
        }
      })
      .catch((error) => {
        //Failure
      });
  }

  changePhone(token, state, setState, setMessages, getClientConfig) {
    settingsAPI
      .changePhone(token, state.countryCode + state.newPhoneNumber)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          getClientConfig();
          setState(
            {
              loadingSent: false,
            },
            () => {
              setState({
                stage: 2,
              });
            }
          );
        } else {
          //Failure
          setMessages("*an-error-has-ocurred-during-phone-number-change");
          setState({
            invalidCountryCode: true,
            invalidNewPhoneNumber: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("*an-error-has-ocurred-during-phone-number-change");
        setState({
          invalidCountryCode: true,
          invalidNewPhoneNumber: true,
          loadingSent: false,
        });
      });
  }
}
