import { payMeAPI } from "../../../apis/PayMeAPI.jsx";

export default class AddPluginService {
  getPaymentMethods(token, setState) {
    payMeAPI
      .getPaymentMethods(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getPaymentMethods success", response.data);
          setState({
            listPaymentMethods: response.data,
            loadingPaymentMethods: false,
          });
        } else {
          console.log("getPaymentMethods failure", response.data);
          setState({
            loadingPaymentMethods: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPaymentMethods error", error);
        setState({
          loadingPaymentMethods: false,
        });
      });
  }

  getAvailableCryptos(token, setState) {
    payMeAPI
      .getAvailableCryptos(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getAvailableCryptos success", response.data);
          setState({
            listCryptos: response.data,
            loadingCryptos: false,
          });
        } else {
          console.log("getAvailableCryptos failure", response.data);
          setState({
            loadingCryptos: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getAvailableCryptos error", error);
        setState({
          loadingCryptos: false,
        });
      });
  }

  getCustomFields(token, setState) {
    payMeAPI
      .getCustomFields(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getCustomFields success", response.data);
          setState({
            listCustomFields: response.data,
            loadingCustomFields: false,
          });
        } else {
          console.log("getCustomFields failure", response.data);
          setState({
            loadingCustomFields: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getCustomFields error", error);
        setState({
          loadingCustomFields: false,
        });
      });
  }

  addPlugin(
    token,
    description,
    paymentMethods,
    cryptosAccepted,
    htmlSchema,
    fields,
    invoiceAfterPayment,
    setState,
    setMessages
  ) {
    payMeAPI
      .createPlugin(
        token,
        description,
        paymentMethods,
        cryptosAccepted,
        htmlSchema,
        fields,
        invoiceAfterPayment
      )
      .then((response) => {
        if (response.status === 201) {
          console.log("createPlugin success", response);
          setState(
            {
              loadingSent: false,
            },
            () => {
              setState({
                stage: 2,
              });
            }
          );
        } else {
          console.log("createPlugin failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-create-the-plugin"
          );
          setState({
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        console.log("createPlugin error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-create-the-plugin");
        setState({
          loadingSent: false,
        });
      });
  }
}
