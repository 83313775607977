import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MdCheckCircle, MdOutlineError } from "react-icons/md";
import Button from "react-bootstrap/Button";
import "./result.css";
import ReceiptComponent from "../receipt/Receipt.jsx";
import { Spinner } from "react-bootstrap";
import { FaPrint } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { utils } from "../../utils/utils.jsx";
import PrintPDFComponent from "../printPDF/PrintPDF.jsx";

class Result extends Component {
  state = {
    loadingDownload: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  downloadPDF = () => {
    this.setState(
      {
        loadingDownload: true,
      },
      () => {
        utils.generatePDFFromReceipt(
          this.props.recipt.fileName,
          this.setState.bind(this)
        );
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="result-component-container">
          {this.props.success ? (
            <MdCheckCircle class="icon"></MdCheckCircle>
          ) : (
            <MdOutlineError class="icon"></MdOutlineError>
          )}
          <span class="result-title">{this.props.title}</span>
          <span class="description">{this.props.message}</span>

          {this.props.recipt && this.props.recipt.data ? (
            <div class="receipt-download-print-div">
              <ReceiptComponent
                data={this.props.recipt.data}
              ></ReceiptComponent>
              <div class="icons-row">
                {this.state.loadingDownload ? (
                  <div class="loader-icon">
                    <Spinner className="loader-download left-icon"></Spinner>
                  </div>
                ) : (
                  <IoMdDownload class="left-icon" onClick={this.downloadPDF} />
                )}

                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <FaPrint class="right-icon" onClick={handlePrint} />
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
            </div>
          ) : null}

          <div class="buttons-div">
            <Button
              className="continue-button outside-primary-button"
              onClick={this.props.primaryButtonFunction}
            >
              {this.props.primaryButtonText}
            </Button>
            {this.props.secondButton ? (
              <Button
                className="back-button outside-secondary-button"
                onClick={this.props.secondaryButtonFunction}
              >
                {this.props.secondaryButtonText}
              </Button>
            ) : null}
          </div>
        </div>

        <div id="hidden-div" style={{ display: "none" }}>
          <div
            class="detail-container-print"
            ref={(el) => (this.componentRef = el)}
            id="pdf"
          >
            {this.props.recipt && this.props.recipt.data ? (
              <PrintPDFComponent
                theme={this.props.theme}
                title={this.props.localizedText["transaction-details"]}
                receiptData={this.props.recipt.data}
                text={this.props.localizedText["powered-by-blox"]}
                localizedText={this.props.localizedText}
              ></PrintPDFComponent>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Result);
