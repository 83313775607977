import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./depositCash.css";
import DepositCashService from "./DepositCashService.jsx";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { Spinner } from "react-bootstrap";
import { utils } from "../../../utils/utils.jsx";
import ErrorMessageComponent from "../../errorMessage/ErrorMessage.jsx";
import ConversionLinkComponent from "../../conversionLink/ConversionLink.jsx";

class DepositCash extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    errorMessageType: "",

    currency: "",
    amount: "",
    conversion: "",
    fundsToRecieve: "",
    note: "",

    depositURL: "",

    loadingCurrencies: true,
    loadingConversion: false,
    loadingSent: false,

    invalidCurrency: false,
    invalidAmount: false,

    resultSuccess: true,
  };

  service = new DepositCashService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  handleChangeForRatio = (event, setState) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState(
      {
        [name]: value,
      },
      () => {
        this.props.setCurrency(this.state.currency);
        if (
          this.state.currency != "USD" &&
          this.state.amount != "" &&
          this.state.currency != ""
        ) {
          setState(
            {
              conversion: "",
              loadingConversion: true,
            },
            () => {
              this.service.getConversion(
                this.props.token,
                this.state.amount,
                this.state.currency,
                "USD",
                this.setState.bind(this),
                this.setMessages.bind(this)
              );
            }
          );
        } else if (this.state.currency == "USD") {
          setState(
            {
              conversion: "",
            },
            () => {
              setState({
                conversion: "1",
              });
            }
          );
        }
      }
    );
  };

  goToDeposit = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.currency != "" && this.state.amount != "") {
          if (
            this.state.currency != "COP" ||
            Number(this.state.amount) >= 10000
          ) {
            this.setState(
              {
                invalidCurrency: false,
                invalidAmount: false,

                loadingSent: true,
              },
              () => {
                this.service.depositCash(
                  this.props.token,
                  this.state.amount,
                  this.state.currency,
                  this.state.note,
                  Number(this.state.amount * this.state.conversion),
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              }
            );
          } else {
            this.setMessages("*minimum-deposit-for-colombian-pesos");
            this.setState({
              invalidAmount: true,
            });
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCurrency: this.state.currency == "",
            invalidAmount: this.state.amount == "",
          });
        }
      }
    );
  };

  removeCurrency = (array, currency) => {
    return array.filter((obj) => obj.base_currency !== currency);
  };

  render() {
    return (
      <React.Fragment>
        <div class="deposit-bank-div">
          {utils.conditionalPermission(this.props.account, ["can_convert"]) ? (
            <ConversionLinkComponent
              width={this.state.width}
              currencyFrom={this.state.currency}
              currencyTo={"USD"}
              region={this.props.region}
              localizedText={this.props.localizedText}
            />
          ) : null}
          <div class="section">
            <div class="left-input content-container">
              <div class="background"></div>
              <div class="content">
                <div class="form-div">
                  <Form.Group className="mb-3">
                    <Form.Label className={"input-label-inside-div"}>
                      {this.props.localizedText["currency"]}
                    </Form.Label>
                    <InputGroup className="mb-3 input-field">
                      <Form.Select
                        variant="outline-secondary"
                        onChange={(e) =>
                          this.handleChangeForRatio(e, this.setState.bind(this))
                        }
                        name="currency"
                        isInvalid={this.state.invalidCurrency}
                        style={{
                          width: "100%",
                          flex: "0 1 auto",
                          textAlign: "start",
                        }}
                        disabled={this.state.loadingSent}
                      >
                        <option></option>
                        {this.removeCurrency(
                          this.props.listCurrenciesFiat,
                          "BRL"
                        ).map((currency) => (
                          <option value={currency.base_currency}>
                            {currency.name +
                              " (" +
                              currency.base_currency +
                              ")"}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div class="form-div">
                  <Form.Group className="mb-3">
                    <Form.Label className="input-label-inside-div">
                      {this.props.localizedText["amount"]}
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="input-text no-background-input-inside"
                        type="number"
                        name="amount"
                        value={this.state.amount}
                        isInvalid={this.state.invalidAmount}
                        disabled={this.state.loadingSent}
                        autoComplete="off"
                        onChange={(e) =>
                          this.handleChangeForRatio(e, this.setState.bind(this))
                        }
                        onWheel={(e) => e.target.blur()}
                      />{" "}
                      <InputGroup.Text
                        className={
                          this.state.invalidAmount
                            ? "inside-input-symbol input-symbol-danger"
                            : "inside-input-symbol"
                        }
                      >
                        {this.state.currency}
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>{" "}
                </div>{" "}
                <div class="message-div">
                  {
                    this.props.localizedText[
                      "*upon-completion-of-your-payment,-you-will-be-redirected-back-to-this-page"
                    ]
                  }
                </div>
              </div>
            </div>{" "}
            <div class="right-div">
              <div class="value-input">
                <InputGroup className="input-group">
                  <Form.Label className="input-label-outside-div">
                    {this.props.localizedText["note-(optional)"]}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="note"
                    value={this.state.note}
                    onChange={(e) =>
                      utils.handleChange(e, this.setState.bind(this))
                    }
                    className="text-area"
                    disabled={this.state.loadingSent}
                  />
                </InputGroup>

                {this.state.conversion != "" ? (
                  <Form.Group className="mb-3 form-group fade-in-up">
                    <Form.Label className="input-label-outside-div">
                      {this.props.localizedText["funds-to-recieve"]}
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl
                        className="input-text no-background-input-outside"
                        type="text"
                        name="fundsToRecieve"
                        value={utils.roundDecimals(
                          "USD",
                          Number(this.state.amount * this.state.conversion)
                        )}
                        disabled
                      />
                      <InputGroup.Text className="outside-input-symbol">
                        USD
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                ) : null}
                {this.state.loadingConversion != "" ? (
                  <div>
                    <Spinner className="loading-conversions" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>{" "}
          <div>
            <ErrorMessageComponent
              errorMessage={
                this.props.localizedText[this.state.errorMessageType]
              }
            ></ErrorMessageComponent>
            <div class="buttons-div fade-in-up">
              <Button
                className="primary-button outside-primary-button"
                onClick={this.goToDeposit}
                disabled={this.state.loadingSent}
              >
                {this.state.loadingSent ? (
                  <Spinner className="loader-send-outside-div-button"></Spinner>
                ) : null}
                {this.props.localizedText["deposit"]}
              </Button>

              <Button
                className="secondary-button outside-secondary-button"
                onClick={this.props.tryToGoBackStage}
                disabled={this.state.loadingSent}
              >
                {this.props.localizedText["cancel"]}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(DepositCash);
