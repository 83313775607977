import axios from "axios";

class WalletAPI {
  getNetworks(token, crypto) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = { coin: crypto };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/wallets/get_inbound_networks_for_token",
      requestBody,
      headers
    );
  }

  getWallet(token, crypto, network) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = { coin: crypto, blockchain: network };

    var endpoint = "/wallets/get_inbound_wallet";

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + endpoint,
      requestBody,
      headers
    );
  }
}

export const walletAPI = new WalletAPI();
