import { depositAPI } from "../../../apis/DepositAPI.jsx";
export default class PayMeButtonService {
  getQuote(
    token,
    fiatCurrency,
    fiatAmount,
    cryptoSymbol,
    setState,
    setMessages
  ) {
    depositAPI
      .getQuote(token, fiatCurrency, fiatAmount, cryptoSymbol)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getQuote success", response);
          setState({
            loadingConversion: false,
            cryptoAmountToReceive: response.data.cryptoAmount,
          });
        } else {
          console.log("getQuote failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-convert-a-currency"
          );
          setState({
            loadingConversion: false,
            cryptoAmountToReceive: "",
          });
        }
      })
      .catch((error) => {
        console.log("getQuote error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-convert-a-currency");
        setState({
          loadingConversion: false,
          cryptoAmountToReceive: "",
        });
      });
  }

  checkoutUrl(
    token,
    cryptoSymbol,
    fiatAmount,
    payerEmail,
    payerName,
    invoiceNumber,
    memo,
    description,
    setState,
    setMessages,
    goToResultStage
  ) {
    depositAPI
      .checkoutUrl(
        token,
        cryptoSymbol,
        fiatAmount,
        payerEmail,
        payerName,
        invoiceNumber,
        memo,
        description
      )
      .then((response) => {
        if (response.status === 200 && response.data.length > 0) {
          console.log("checkoutUrl success", response);
          setState({ loadingSent: false });
          goToResultStage(
            true,
            "a-pay-link-has-been-sent-to-your-email-address"
          );
        } else {
          console.log("checkoutUrl failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-send-the-pay-link"
          );
          setState({ loadingSent: false });
        }
      })
      .catch((error) => {
        console.log("checkoutUrl error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-send-the-pay-link");
        setState({ loadingSent: false });
      });
  }
}
