import { portfolioAPI } from "../../apis/PortfolioAPI.jsx";
import { marketDataAPI } from "../../apis/MarketDataAPI.jsx";
import { swapAPI } from "../../apis/SwapAPI.jsx";

export default class TotalBalanceService {
  getDefaultPortfolio(token, setState, stopChecking) {
    portfolioAPI
      .getDefaultPortfolio(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("success", response);
          this.getPortfolioBalances(
            token,
            setState,
            response.data.name,
            stopChecking
          );
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getPortfolioBalances(token, setState, portfolioName, stopChecking) {
    portfolioAPI
      .getPortfolioBalances(token, portfolioName)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          var total = 0;
          var convertRequests = [];
          var convertResponses = [];
          var listSelectedCurrencies = [];
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].base_currency != "USD") {
              //conversion for fiat
              convertRequests.push(response.data[i].base_currency);
              convertResponses.push(
                swapAPI.convertEstimate(
                  token,
                  response.data[i].qty,
                  response.data[i].base_currency,
                  "USD",
                  0
                )
              );
            } else {
              total = total + response.data[i].qty;
              if (response.data[i].fav) {
                listSelectedCurrencies.push({
                  data: response.data[i],
                  value_usd: null,
                });
              }
            }
          }
          Promise.all(
            convertResponses.map((response) =>
              response.catch((err) => ({ error: err }))
            )
          )
            .then((convertResponses) => {
              console.log("convertResponses 2", convertResponses);
              for (var i = 0; i < convertResponses.length; i++) {
                if (convertResponses[i].data.amount_to_receive) {
                  total =
                    total + Number(convertResponses[i].data.amount_to_receive);
                  if (
                    response.data.filter(
                      (element) => element.base_currency == convertRequests[i]
                    )[0].fav
                  ) {
                    listSelectedCurrencies.push({
                      data: response.data.filter(
                        (element) => element.base_currency == convertRequests[i]
                      )[0],
                      value_usd: Number(
                        convertResponses[i].data.amount_to_receive
                      ),
                    });
                  }
                }
              }

              console.log("listSelectedCurrencies", listSelectedCurrencies);
              if (stopChecking != null) {
                stopChecking();
              }
              setState({
                loading: false,
                totalBalance: total,
                listSelectedCurrencies: listSelectedCurrencies,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }
}
