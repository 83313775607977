import { portfolioAPI } from "../../apis/PortfolioAPI.jsx";

export default class MonthlyPaymentReportService {
  generateMonthlyReportHTML(token, month, year, setState) {
    portfolioAPI
      .generateMonthlyReportHTML(token, month, year)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("generateMonthlyReportHTML success", response);
          setState({
            htmlData: response.data,
            loadingGenerate: false,
          });
        } else {
          console.log("generateMonthlyReportHTML failure", response);
          setState({
            loadingGenerate: false,
          });
        }
      })
      .catch((error) => {
        console.log("generateMonthlyReportHTML error", error);
        setState({ loadingGenerate: false });
      });
  }

  generateMonthlyReportPDF(
    token,
    month,
    year,
    setState,
    setMessages,
    downloadPDF
  ) {
    portfolioAPI
      .generateMonthlyReportPDF(token, month, year)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("generateMonthlyReportPDF success", response);
          setState({
            loadingDownload: true,
            pdfGeneratedSuccessfully: true,
            pdfGenerated: {
              name: "monthly-payment-report-" + month + "-" + year,
              data: response.data,
            },
          });
          downloadPDF(
            response.data,
            "monthly-payment-report-" + month + "-" + year
          );
        } else {
          console.log("generateMonthlyReportPDF failure", response);
          setState({
            loadingDownload: false,
            pdfGeneratedSuccessfully: false,
          });
        }
      })
      .catch((error) => {
        console.log("generateMonthlyReportPDF error", error);
        setState({
          loadingDownload: false,
          pdfGeneratedSuccessfully: false,
        });
      });
  }
}
