import { settingsAPI } from "../../../apis/SettingsAPI.jsx";

export default class SettingsService {
  changeEmail(token, state, setState, setMessages, getClientConfig) {
    settingsAPI
      .changeEmail(token, state.newEmail)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          getClientConfig();
          setState(
            {
              loadingSent: false,
            },
            () => {
              setState({
                stage: 2,
              });
            }
          );
        } else {
          //Failure
          setMessages("an-error-has-ocurred-during-email-change");
          setState({
            invalidNewEmail: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("an-error-has-ocurred-during-email-change");
        setState({
          invalidNewEmail: true,
          loadingSent: false,
        });
      });
  }
}
