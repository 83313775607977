import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./conversionComponent.css";
import ConversionComponentService from "./ConversionComponentService.jsx";
import { HiSwitchHorizontal } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { utils } from "../../utils/utils.jsx";
import { Link } from "react-router-dom";

class ConversionComponent extends Component {
  state = {
    amountFrom: "",
    amountTo: "",
    currencyFrom: "",
    currencyTo: "",
    conversion: "",
    lockedTime: "",

    switched: false,
    typeOfConversion: "send",

    listTimeLock: [
      {
        name: "10 Seconds",
        value: 10,
      },
      {
        name: "30 Seconds",
        value: 30,
      },
      {
        name: "1 Minute",
        value: 60,
      },
      {
        name: "2 Minutes",
        value: 120,
      },
      {
        name: "3 Minutes",
        value: 180,
      },
      {
        name: "4 Minutes",
        value: 240,
      },
      {
        name: "5 Minutes",
        value: 300,
      },
      {
        name: "10 Minutes",
        value: 600,
      },
      {
        name: "30 Minutes",
        value: 1800,
      },
    ],
    conversionDataEstimate: {},
    conversionDataLocked: {},
    conversionSwitched: false,
    conversionLockedSuccessfully: null,
    convertedSuccessfully: null,

    detailType: "current",

    loadingConversion: false,
    loadingEstimate: false,
    loadingLock: false,
    loadingConvertNow: false,
  };

  service = new ConversionComponentService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      currencyFrom: this.props.currencyFrom ? this.props.currencyFrom : "USD",
      currencyTo: this.props.currencyTo ? this.props.currencyTo : "USD",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (
        this.props.currencyFrom &&
        prevProps.currencyFrom != this.props.currencyFrom
      ) {
        this.setState(
          {
            currencyFrom: this.props.currencyFrom,
          },
          () => {
            this.estimateConversion();
          }
        );
      }
      if (
        this.props.currencyTo &&
        prevProps.currencyTo != this.props.currencyTo
      ) {
        this.setState(
          {
            currencyTo: this.props.currencyTo,
          },
          () => {
            this.estimateConversion();
          }
        );
      }
    }
  }

  handleChangeForEstimate = (event, type) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let typeOfConversion = "";

    if (type != null) {
      typeOfConversion = "typeOfConversion";
    }

    this.setState(
      {
        [name]: value,
        [typeOfConversion]: type,
      },
      () => {
        if (name == "lockedTime" && value == "") {
          this.setState(
            {
              detailType: "current",
            },
            () => {
              this.estimateConversion();
            }
          );
        } else {
          this.estimateConversion();
        }
      }
    );
  };

  switchTypeOfConversion = () => {
    if (this.state.typeOfConversion == "send") {
      this.setState(
        {
          typeOfConversion: "receive",
        },
        () => {
          this.estimateConversion();
        }
      );
    } else {
      this.setState(
        {
          typeOfConversion: "send",
        },
        () => {
          this.estimateConversion();
        }
      );
    }
  };

  switchConversion = () => {
    this.setState(
      {
        switched: !this.state.switched,
        currencyFrom: this.state.currencyTo,
        currencyTo: this.state.currencyFrom,
        amountFrom: this.state.amountTo,
      },
      () => {
        this.estimateConversion();
      }
    );
  };

  estimateConversion = () => {
    if (this.state.typeOfConversion == "send") {
      if (
        this.state.amountFrom != "" &&
        this.state.currencyFrom != "" &&
        this.state.currencyTo != ""
      ) {
        this.setState(
          {
            detailType: this.state.lockedTime != "" ? "estimate" : "current",
            loadingEstimate: true,
          },
          () => {
            this.service.convertEstimate(
              this.props.token,
              this.state.amountFrom,
              this.state.currencyFrom,
              this.state.currencyTo,
              this.state.lockedTime != "" ? this.state.lockedTime : 0,
              this.setState.bind(this)
            );
          }
        );
      }
    } else {
      if (
        this.state.amountTo != "" &&
        this.state.currencyFrom != "" &&
        this.state.currencyTo != ""
      ) {
        this.setState(
          {
            detailType: this.state.lockedTime != "" ? "estimate" : "current",
            loadingEstimate: true,
          },
          () => {
            this.service.reverseConvertEstimate(
              this.props.token,
              this.state.amountTo,
              this.state.currencyFrom,
              this.state.currencyTo,
              this.state.lockedTime != "" ? this.state.lockedTime : 0,
              this.setState.bind(this)
            );
          }
        );
      }
    }
  };

  lockConversion = () => {
    if (this.state.typeOfConversion == "send") {
      if (
        this.state.amountFrom != "" &&
        this.state.currencyFrom != "" &&
        this.state.currencyTo != "" &&
        this.state.lockedTime != ""
      ) {
        this.setState(
          {
            detailType: "lock",
            loadingLock: true,
            conversionLockedSuccessfully: false,
          },
          () => {
            this.service.conversionLock(
              this.props.token,
              this.state.amountFrom,
              this.state.currencyFrom,
              this.state.currencyTo,
              this.state.lockedTime,
              this.props.lockConversionCallBackFunction,
              this.setState.bind(this)
            );
          }
        );
      }
    } else {
      if (
        this.state.amountTo != "" &&
        this.state.currencyFrom != "" &&
        this.state.currencyTo != "" &&
        this.state.lockedTime != ""
      ) {
        this.setState(
          {
            detailType: "lock",
            loadingLock: true,
            conversionLockedSuccessfully: false,
          },
          () => {
            this.service.reverseConversionLock(
              this.props.token,
              this.state.amountTo,
              this.state.currencyFrom,
              this.state.currencyTo,
              this.state.lockedTime,
              this.props.lockConversionCallBackFunction,
              this.setState.bind(this)
            );
          }
        );
      }
    }
  };

  convertNow = () => {
    this.setState(
      {
        loadingConvertNow: true,
        convertedSuccessfully: false,
      },
      () => {
        this.service.convertNow(
          this.props.token,
          this.state.amountFrom,
          this.state.currencyFrom,
          this.state.currencyTo,
          this.props.convertNowCallBackFunction,
          this.setState.bind(this)
        );
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div
          class={
            this.props.component != null && !this.props.component
              ? "conversion-component-container for-page content-container-no-hover"
              : "conversion-component-container content-container-no-hover"
          }
        >
          <div class="conversion-component-title-div">
            <div class="conversion-component-title">
              {" "}
              {this.props.localizedText["conversion"]}
            </div>
          </div>
          <div class="conversion-component-sub-title-div">
            {" "}
            <InputGroup
              className={
                this.props.component != null && !this.props.component
                  ? "input-field input-field-page dropdown-filter"
                  : "input-field dropdown-filter"
              }
            >
              <Form.Select
                variant="outline-secondary"
                onChange={this.handleChangeForEstimate}
                name="lockedTime"
                style={{
                  width: "100%",
                  flex: "0 1 auto",
                  textAlign: "start",
                }}
                disabled={this.state.loadingLock}
              >
                <option></option>
                {this.state.listTimeLock.map((currency) => (
                  <option value={currency.value}>{currency.name}</option>
                ))}
              </Form.Select>
            </InputGroup>
            {this.props.component != null && !this.props.component ? null : (
              <Link
                class="conversion-component-sub-title link-inside"
                to="/dashboard/conversion"
              >
                {this.props.localizedText["see-locked-rates"]}
              </Link>
            )}{" "}
          </div>
          <div class="conversion-component-div">
            <div class="currency-amount-container">
              <Form.Group className="currency-amount-div">
                <Form.Label className="input-label-inside-div">
                  {this.props.localizedText["from"]}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    className="input-text no-background-input-inside"
                    type="number"
                    name="amountFrom"
                    value={this.state.amountFrom}
                    autoComplete="off"
                    onChange={(e) => this.handleChangeForEstimate(e, "send")}
                    onWheel={(e) => e.target.blur()}
                  />{" "}
                  <InputGroup.Text className={"inside-input-symbol"}>
                    {this.state.currencyFrom}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>{" "}
            </div>
            <HiSwitchHorizontal
              className="conversion-switch"
              onClick={this.switchConversion}
            ></HiSwitchHorizontal>
            <div class="currency-amount-container">
              <Form.Group className="currency-amount-div">
                <Form.Label className="input-label-inside-div">
                  {this.props.localizedText["to"]}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    className="input-text no-background-input-inside"
                    name="amountTo"
                    value={this.state.amountTo}
                    autoComplete="off"
                    onChange={(e) => this.handleChangeForEstimate(e, "receive")}
                    onWheel={(e) => e.target.blur()}
                  />
                  <InputGroup.Text className={"inside-input-symbol"}>
                    {this.state.currencyTo}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>{" "}
            </div>
          </div>

          {this.state.lockedTime != "" ? (
            <Button
              className="inside-primary-button"
              onClick={this.lockConversion}
              disabled={this.state.loadingLock}
            >
              {this.state.loadingLock ? (
                <Spinner className="loader-send-inside-div-button"></Spinner>
              ) : null}
              {this.state.conversionLockedSuccessfully != null &&
              this.state.conversionLockedSuccessfully ? (
                <FaCheck className="loader-send-inside-div-button" />
              ) : null}
              {this.props.localizedText["lock-rate"]}
            </Button>
          ) : (
            <Button
              className="inside-primary-button"
              onClick={this.convertNow}
              disabled={this.state.loadingConvertNow}
            >
              {this.state.loadingConvertNow ? (
                <Spinner className="loader-send-inside-div-button"></Spinner>
              ) : null}
              {this.state.convertedSuccessfully != null &&
              this.state.convertedSuccessfully ? (
                <FaCheck className="loader-send-inside-div-button" />
              ) : null}
              {this.props.localizedText["convert-now"]}
            </Button>
          )}
        </div>

        <div class="conversion-rate-detail-container content-container-no-hover">
          <div class="conversion-rate-detail-div">
            <div class="conversion-rate-detail-title-div">
              <div class="conversion-rate-detail-title">
                {this.props.localizedText["convert-detail"]}
              </div>
            </div>
            {this.state.detailType == "current" ? (
              <div class="conversion-rate-detail-div-row">
                <div class="row-label">
                  {this.props.localizedText["current-rate:"]}
                </div>
                {!this.state.loadingEstimate &&
                Object.keys(this.state.conversionDataEstimate).length > 0 ? (
                  <div class="row-value fade-in-up">
                    {"1 " +
                      this.state.conversionDataEstimate.from_currency +
                      " = " +
                      utils.roundDecimals(
                        "USD",
                        Number(
                          this.state.conversionDataEstimate.amount_to_receive
                        ) /
                          Number(
                            this.state.conversionDataEstimate.amount_to_send
                          )
                      )}
                  </div>
                ) : null}
                {this.state.loadingEstimate ? (
                  <Spinner className="loading-conversion-lock" />
                ) : null}
              </div>
            ) : null}
            {this.state.detailType == "current" ? (
              <div class="conversion-rate-detail-div-row">
                <div class="row-label">
                  {this.props.localizedText["current-amount:"]}
                </div>
                {!this.state.loadingEstimate &&
                Object.keys(this.state.conversionDataEstimate).length > 0 ? (
                  <div class="row-value fade-in-up">
                    {this.state.amountFrom +
                      " " +
                      (this.state.currencyFrom +
                        " = " +
                        utils.roundDecimals(
                          "USD",
                          Number(this.state.amountTo)
                        ) +
                        " " +
                        this.state.currencyTo)}
                  </div>
                ) : null}
                {this.state.loadingEstimate ? (
                  <Spinner className="loading-conversion-lock" />
                ) : null}
              </div>
            ) : null}
            {this.state.detailType == "estimate" ? (
              <div>
                <div class="conversion-rate-detail-div-row">
                  <div class="row-label">
                    {" "}
                    {this.props.localizedText["locked-time:"]}
                  </div>{" "}
                  <div>{this.state.lockedTime + " seconds"}</div>
                </div>
                <div class="conversion-rate-detail-div-row">
                  <div class="row-label">
                    {this.props.localizedText["estimated-rate:"]}
                  </div>{" "}
                  {!this.state.loadingEstimate &&
                  Object.keys(this.state.conversionDataEstimate).length > 0 ? (
                    <div class="row-value fade-in-up">
                      {"1 " +
                        this.state.conversionDataEstimate.from_currency +
                        " = " +
                        utils.roundDecimals(
                          "USD",
                          Number(
                            this.state.conversionDataEstimate.amount_to_receive
                          ) /
                            Number(
                              this.state.conversionDataEstimate.amount_to_send
                            )
                        ) +
                        " " +
                        this.state.conversionDataEstimate.to_currency}
                    </div>
                  ) : null}
                  {this.state.loadingEstimate ? (
                    <Spinner className="loading-conversion-lock" />
                  ) : null}
                </div>
                <div class="conversion-rate-detail-div-row">
                  <div class="row-label">
                    {this.props.localizedText["estimated-amount:"]}
                  </div>{" "}
                  {!this.state.loadingEstimate &&
                  Object.keys(this.state.conversionDataEstimate).length > 0 ? (
                    <div class="row-value fade-in-up">
                      {this.state.conversionDataEstimate.amount_to_send +
                        " " +
                        this.state.conversionDataEstimate.from_currency +
                        " = " +
                        utils.roundDecimals(
                          "USD",
                          Number(
                            this.state.conversionDataEstimate.amount_to_receive
                          )
                        ) +
                        " " +
                        this.state.conversionDataEstimate.to_currency}
                    </div>
                  ) : null}
                  {this.state.loadingEstimate ? (
                    <Spinner className="loading-conversion-lock" />
                  ) : null}
                </div>
              </div>
            ) : null}
            {this.state.detailType == "lock" ? (
              <div>
                <div class="conversion-rate-detail-div-row">
                  <div class="row-label">
                    {this.props.localizedText["locked-rate"]}
                  </div>{" "}
                  {!this.state.loadingLock &&
                  Object.keys(this.state.conversionDataLocked).length > 0 ? (
                    <div class="row-value fade-in-up">
                      {utils.roundDecimals(
                        "USD",
                        Number(
                          this.state.conversionDataLocked.amount_to_receive
                        ) /
                          Number(this.state.conversionDataLocked.amount_to_send)
                      ) +
                        " " +
                        this.state.conversionDataEstimate.to_currency}
                    </div>
                  ) : null}
                  {this.state.loadingLock ? (
                    <Spinner className="loading-conversion-lock" />
                  ) : null}
                </div>
                <div class="conversion-rate-detail-div-row">
                  <div class="row-label">
                    {this.props.localizedText["locked-amount:"]}{" "}
                  </div>{" "}
                  {!this.state.loadingLock &&
                  Object.keys(this.state.conversionDataLocked).length > 0 ? (
                    <div class="row-value fade-in-up">
                      {this.state.conversionDataLocked.amount_to_send +
                        " " +
                        this.state.conversionDataEstimate.from_currency +
                        " = " +
                        utils.roundDecimals(
                          "USD",
                          Number(
                            this.state.conversionDataLocked.amount_to_receive
                          )
                        ) +
                        " " +
                        this.state.conversionDataEstimate.to_currency}
                    </div>
                  ) : null}
                  {this.state.loadingLock ? (
                    <Spinner className="loading-conversion-lock" />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ConversionComponent);
