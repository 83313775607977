import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormControl from "react-bootstrap/FormControl";
import { withRouter } from "react-router-dom";
import "./forgotPassword.css";
import ForgotPasswordService from "./forgotPasswordService";
import Footer from "../../components/footer/Footer.jsx";
import ThemeRegionDiv from "../../components/theme-region-div/ThemeRegionDiv.jsx";
import { utils } from "../../utils/utils.jsx";
import ErrorMessageComponent from "../../components/errorMessage/ErrorMessage.jsx";

class ForgotPassword extends Component {
  state = {
    isMobileWidth: false,
    email: "",
    errorMessageType: "",

    loadingSent: false,

    invalidEmail: false,
  };

  service = new ForgotPasswordService();
  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  }

  componentDidUpdate(prevProps, prevState) {}

  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 1200) {
      flag = true;
    } else {
      flag = false;
    }

    this.setState({
      isMobileWidth: flag,
    });
  };

  goToVerification = (token) => {
    this.props.history.push({
      pathname: "/verification",
      megastate: {
        token: token,
      },
    });
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  doForgotPassword = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.email != "") {
          if (this.emailRegex.test(this.state.email)) {
            this.setState(
              {
                invalidEmail: false,
                loadingSent: true,
              },
              () => {
                this.service.forgotPassword(
                  this.state.email,
                  this.setState.bind(this),
                  this.setMessages.bind(this),
                  this.goToResultPage
                );
              }
            );
          } else {
            this.setMessages("*please-enter-a-valid-email");
            this.setState({
              invalidEmail: true,
            });
          }
        } else {
          this.setMessages("*email-is-missing");
          this.setState({
            invalidEmail: this.state.email == "",
          });
        }
      }
    );
  };

  goToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  goToCompanyWebsite = () => {
    window.location.replace(process.env.REACT_APP_COMPANY_WEBSITE);
  };

  goToResultPage = (value) => {
    if (value) {
      this.props.history.push({
        pathname: "/result",
        megastate: {
          success: value,
          type: "forgot-password",
          primaryButtonFunction: utils.goToURL.bind(this, this.props, "/"),
          secondButton: false,
        },
      });
    } else {
      //covered by errorMessage
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="login-container">
          <div class="login-2fa-div forgot-password-container">
            <div class="register-title">
              {this.props.localizedText["password-recovery"]}
            </div>
            <div class="description">
              {
                this.props.localizedText[
                  "please-enter-your-email-address-in-the-email-field"
                ]
              }
            </div>
            <div class="login-form-div div-parent-flex-column">
              <Form.Group className="mb-3">
                <Form.Label
                  className={
                    this.state.isMobileWidth
                      ? "input-label-outside-div"
                      : "input-label-inside-div"
                  }
                >
                  {this.props.localizedText["email-address"]}
                </Form.Label>
                <FormControl
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={(e) =>
                    utils.handleChange(e, this.setState.bind(this))
                  }
                  isInvalid={this.state.invalidEmail}
                  className="input-field"
                  disabled={this.state.loadingSent}
                />
              </Form.Group>
              <ErrorMessageComponent
                errorMessage={
                  this.props.localizedText[this.state.errorMessageType]
                }
              ></ErrorMessageComponent>
            </div>
            <div class="buttons-div">
              <Button
                className={
                  this.state.isMobileWidth
                    ? "primary-button outside-primary-button"
                    : "primary-button inside-primary-button"
                }
                onClick={this.doForgotPassword}
                disabled={this.state.loadingSent}
              >
                {this.state.loadingSent ? (
                  <Spinner
                    className={
                      this.state.isMobileWidth
                        ? "loader-send-outside-div-button"
                        : "loader-send-inside-div-button"
                    }
                  ></Spinner>
                ) : null}
                {this.props.localizedText["submit"]}
              </Button>

              <Button
                className={
                  this.state.isMobileWidth
                    ? "secondary-button outside-secondary-button"
                    : "secondary-button inside-secondary-button"
                }
                onClick={utils.goToURL.bind(this, this.props, "/")}
              >
                {this.props.localizedText["cancel"]}
              </Button>

              <div class="version-number">{this.props.version}</div>
            </div>
          </div>
          <ThemeRegionDiv
            changeTheme={this.props.changeTheme}
            theme={this.props.theme}
            handleModalOpen={this.props.handleModalOpen}
            region={this.props.region}
            localizedText={this.props.localizedText}
          ></ThemeRegionDiv>
        </div>
        <Footer
          region={this.props.region}
          localizedText={this.props.localizedText}
          theme={this.props.theme}
        ></Footer>
      </React.Fragment>
    );
  }
}

export default withRouter(ForgotPassword);
