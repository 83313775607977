import { loginAPI } from "../../apis/LoginAPI.jsx";
import { paymentAPI } from "../../apis/PaymentAPI.jsx";

export default class TransactionConfirmationService {
  getClientConfig(token, setState) {
    loginAPI
      .getClientConfig(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("config", response);
          setState({
            authChoice:
              response.data.auth_choice == "auth_pending" ||
              response.data.auth_choice == "auth_declined"
                ? "Phone"
                : "Google Auth",
          });

          if (
            response.data.auth_choice == "auth_pending" ||
            response.data.auth_choice == "auth_declined"
          ) {
            this.confirmPhone(token, setState);
          }
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  confirmPhone(token, setState) {
    loginAPI
      .confirmPhone(token)
      .then((response) => {
        if (response.status === 200) {
          //Success
          var mySubString = response.data.payload.substring(
            response.data.payload.indexOf("[") + 1,
            response.data.payload.lastIndexOf("]]")
          );

          setState({
            confirmPhoneMessage:
              "xxx-xxx-" +
              mySubString.slice(mySubString.length - 3, mySubString.length),
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }
}
