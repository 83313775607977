import axios from "axios";

class RefdataAPI {
  getCountries() {
    const headers = {};
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/refdata/countries",
      requestBody,
      headers
    );
  }

  getAllCountries() {
    const headers = {};
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/refdata/all_countries",
      requestBody,
      headers
    );
  }

  getCurrencies() {
    const headers = {};
    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/refdata/currencies",
      requestBody,
      headers
    );
  }

  getCurrenciesDeposit(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/payments/get_deposit_instruments",
      headers
    );
  }

  getCurrenciesWithdraw(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/payments/get_withdraw_instruments",
      headers
    );
  }

  getCurrenciesHolding(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(
      process.env.REACT_APP_BASE_API_URL + "/payments/get_hold_instruments",
      headers
    );
  }

  validateRoutingNumber(token, aba, type) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      aba: aba,
      type: type,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/validate_routing_number",
      requestBody,
      headers
    );
  }

  validateSwiftCode(token, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      swift: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/validate_swift",
      requestBody,
      headers
    );
  }

  getPaymentProvidersByCountry(token, countryCode) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = {
      country_code: countryCode,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/all_by_country",
      requestBody,
      headers
    );
  }
}

export const refdataAPI = new RefdataAPI();
