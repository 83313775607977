import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import TotalBalanceService from "./TotalBalanceService";
import { Spinner } from "react-bootstrap";
import "./totalBalance.css";
import { utils } from "../../utils/utils";

class TotalBalance extends Component {
  state = {
    account: {},
    totalBalance: 0,
    isHidden: false,
    loading: true,
    listSelectedCurrencies: [],
  };

  service = new TotalBalanceService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    } else {
      //ComponentDidUpdateWill take care of it
    }
    this.service.getDefaultPortfolio(
      this.props.token,
      this.setState.bind(this)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
      if (
        this.props.shouldCheckTotalBalance != null &&
        this.props.shouldCheckTotalBalance
      ) {
        this.refreshBalance();
      }
    }
  }

  toggleHidden = (value) => {
    this.setState({
      isHidden: value,
    });
  };

  refreshBalance = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.service.getDefaultPortfolio(
          this.props.token,
          this.setState.bind(this),
          this.props.stopChecking
        );
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="total-balance-container-mask">
          <div class="total-balance-container">
            {!this.props.noHideButton ? (
              <div class="total-balance-icons">
                <IoMdSettings
                  class="hide"
                  onClick={utils.goToURL.bind(
                    this,
                    this.props,
                    "/dashboard/settings"
                  )}
                />
                {this.state.isHidden ? (
                  <BsEyeSlashFill
                    class="hide isHidden"
                    onClick={this.toggleHidden.bind(this, false)}
                  />
                ) : (
                  <BsEyeFill
                    class="hide"
                    onClick={this.toggleHidden.bind(this, true)}
                  />
                )}{" "}
              </div>
            ) : null}
            <div class="total-balance-div content-container-no-hover">
              <div class="info-column">
                <div
                  class="image"
                  onClick={utils.goToURL.bind(
                    this,
                    this.props,
                    "/dashboard/settings"
                  )}
                ></div>
                <div class="text">
                  <div class="total-balance-title">
                    {" "}
                    {this.props.localizedText["balance"]}
                  </div>
                  <div class="username">{this.state.account.email}</div>
                  <div class="region">{this.state.account.domicile}</div>
                </div>
              </div>

              {this.state.loading ? (
                <Spinner className="loader-total-balance"></Spinner>
              ) : (
                <div class="total-column">
                  <div
                    class={
                      "number" +
                      (this.state.listSelectedCurrencies.length > 0 &&
                      ((!this.props.noHideButton && !this.state.isHidden) ||
                        (this.props.noHideButton && !this.props.isHidden))
                        ? " bottom-border"
                        : "")
                    }
                  >
                    <div class="total">{this.props.localizedText["total"]}</div>

                    {(!this.props.noHideButton && !this.state.isHidden) ||
                    (this.props.noHideButton && !this.props.isHidden) ? (
                      <div class="total-number">
                        ${utils.roundDecimals("USD", this.state.totalBalance)}
                      </div>
                    ) : (
                      <div>$*****</div>
                    )}
                  </div>

                  {this.state.listSelectedCurrencies.length > 0 &&
                  ((!this.props.noHideButton && !this.state.isHidden) ||
                    (this.props.noHideButton && !this.props.isHidden)) ? (
                    <div class="list-selected-currencies-div">
                      {this.state.listSelectedCurrencies.map((data) => (
                        <div class="list-selected-currencies-row">
                          <div>{data.data.base_currency}</div>{" "}
                          <div class="value">
                            $
                            {utils.roundDecimals(
                              "USD",
                              data.value_usd != null
                                ? data.value_usd
                                : data.data.qty
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TotalBalance);
