import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./plugins.css";
import GoBackButton from "../../../components/go-back-button/GoBackButton.jsx";
import Button from "react-bootstrap/Button";
import PluginsService from "./PluginsService";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { FaCheck, FaSearchMinus } from "react-icons/fa";
import { MdDelete, MdEdit, MdPayments } from "react-icons/md";
import { AiFillCopy } from "react-icons/ai";
import { LuPackageSearch } from "react-icons/lu";
import { utils } from "../../../utils/utils";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { ImSearch } from "react-icons/im";
import { Link } from "react-router-dom";

class Plugins extends Component {
  state = {
    width: window.innerWidth,

    listPlugins: [],
    listPluginsShowed: [],

    listLoadingSentChangeStatus: [],
    listPluginsCopied: [],
    listPayments: [],

    selectedDivIndex: null,

    loadingPlugins: true,
    loadingPayments: false,
    loadingSentDelete: false,

    selectedPlugin: {},

    showPaymentsDiv: false,
    showModalDeletePlugin: false,
  };

  service = new PluginsService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.service.getPlugins(this.props.token, this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  changePluginStatus = (id, currentStatus) => {
    var auxArr = this.state.listLoadingSentChangeStatus;
    auxArr.push(id);
    this.setState(
      {
        listLoadingSentChangeStatus: auxArr,
      },
      () => {
        console.log("currentStatus", currentStatus);
        if (currentStatus) {
          this.service.disablePlugin(
            this.props.token,
            id,
            this.state.listPlugins,
            this.state.listLoadingSentChangeStatus,
            this.setState.bind(this),
            this.setMessages.bind(this)
          );
        } else {
          this.service.enablePlugin(
            this.props.token,
            id,
            this.state.listPlugins,
            this.state.listLoadingSentChangeStatus,
            this.setState.bind(this),
            this.setMessages.bind(this)
          );
        }
      }
    );
  };

  openDeletePlugin = (plugin) => {
    this.setState({
      showModalDeletePlugin: true,
      selectedPlugin: plugin,
    });
  };

  handleClose = () => {
    this.setState({
      showModalDeletePlugin: false,
    });
  };

  deletePlugin = () => {
    this.setState(
      {
        loadingSentDelete: true,
      },
      () => {
        this.service.deletePlugin(
          this.props.token,
          this.state.selectedPlugin.id,
          this.state.listPlugins,
          this.setState.bind(this),
          this.setMessages.bind(this)
        );
      }
    );
  };

  copyText = (id, key) => {
    var auxArr = this.state.listPluginsCopied;
    auxArr.push(id);
    this.setState({
      listPluginsCopied: auxArr,
    });

    const text = `<script async src="https://payment-button-stg.s3.amazonaws.com/bloxpay-widget.js"></script> <bloxcross-widget public-key="${key}" cart-total-id="cart-total-value" ></bloxcross-widget>`;

    navigator.clipboard.writeText(text);
  };

  goToUpdatePlugin = (plugin) => {
    this.props.history.push({
      pathname: "/dashboard/pay-me/plugins/" + plugin.id,
      megastate: {
        plugin: plugin,
        forEdit: true,
      },
    });
  };

  showPayments = (plugin) => {
    this.props.history.push({
      pathname: "/dashboard/pay-me/plugins/" + plugin.id,
      megastate: {
        plugin: plugin,
      },
    });
  };

  skeletonCards = ({ cards }) => (
    <div class="div-plugins-cards">
      <div class="headers">
        <div class="detail-column">
          {this.props.localizedText["description"]}
        </div>
        <div class="payment-methods-column">
          {this.props.localizedText["payment-methods"]}
        </div>{" "}
        <div class="cryptos-column">{this.props.localizedText["cryptos"]}</div>
        <div class="status-column">{this.props.localizedText["status"]}</div>
        <div class="button-column">&nbsp;</div>
      </div>
      {cards.map(() => (
        <div class="plugins-row content-container-no-hover">
          <div class="detail-column-skeleton">
            <div class="description-skeleton skeleton-box"></div>
            <div class="date-skeleton skeleton-box"></div>
          </div>
          <div class="payment-methods-column-skeleton skeleton-box"></div>
          <div class="crypto-column-skeleton"></div>
          <div class="status-column-skeleton"></div>
          <div class="button-column-skeleton">
            <div class="icon-skeleton icon-left skeleton-box"></div>
            <div class="icon-skeleton icon-left skeleton-box"></div>
            <div class="icon-skeleton skeleton-box"></div>
          </div>
        </div>
      ))}
    </div>
  );

  render() {
    return (
      <React.Fragment>
        <div class="plugins-container">
          <div class="plugins-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard/pay-me"}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              <div class="title-div">
                <div class="title"> {this.props.localizedText["plugins"]}</div>
                <Link
                  to="/dashboard/pay-me/plugins/add"
                  class="primary-button outside-primary-button small-button"
                >
                  {this.props.localizedText["add-plugin"]}
                </Link>
              </div>
              <div class="filter-search-div">
                <InputGroup className="search-bar">
                  <ImSearch class="search-icon"></ImSearch>
                  <FormControl
                    onChange={(e) => {
                      utils.editSearchTermDefault(
                        e,
                        this.setState.bind(this),
                        this.state.listPlugins,
                        "listPluginsShowed",
                        ["description"]
                      );
                    }}
                    type="search"
                    id="search-bar-entities"
                    placeholder={this.props.localizedText["search-for-plugins"]}
                    autoComplete="off"
                  />
                </InputGroup>
              </div>
              <div class="bottom-content">
                {!this.state.loadingPlugins &&
                this.state.listPlugins.length > 0 &&
                this.state.listPluginsShowed.length > 0 ? (
                  <div class="div-plugins-cards">
                    <div class="headers">
                      <div class="detail-column">
                        {this.props.localizedText["description"]}
                      </div>
                      <div class="payment-methods-column">
                        {this.props.localizedText["payment-methods"]}
                      </div>{" "}
                      <div class="cryptos-column">
                        {this.props.localizedText["cryptos"]}
                      </div>
                      <div class="status-column">
                        {this.props.localizedText["status"]}
                      </div>
                      <div class="button-column">&nbsp;</div>
                    </div>
                    {this.state.listPluginsShowed.map((plugin, index) => (
                      <div
                        class={
                          "plugins-row content-container-no-hover " +
                          (this.state.listPluginsShowed.length - 1 === index
                            ? "last-indexed"
                            : "")
                        }
                      >
                        <div class="detail-column">
                          <div class="description">{plugin.description}</div>
                          <div class="date">
                            {utils.formatDateUSWithTime(plugin.createdAt)}
                          </div>
                        </div>
                        <div class="payment-methods-column">
                          {plugin.paymentMethods.join(", ")}
                        </div>
                        <div class="cryptos-column">
                          {plugin.cryptosAccepted.join(", ")}
                        </div>
                        <div class="status-column">
                          <div
                            class={
                              "status" +
                              (plugin.active
                                ? " success-status"
                                : " danger-status") +
                              (this.state.listLoadingSentChangeStatus.includes(
                                plugin.id
                              )
                                ? " loading-thing"
                                : " ")
                            }
                            onClick={() =>
                              this.changePluginStatus(plugin.id, plugin.active)
                            }
                          >
                            {this.state.listLoadingSentChangeStatus.includes(
                              plugin.id
                            ) ? (
                              <Spinner className="loader-send-inside-status"></Spinner>
                            ) : null}
                            {plugin.active
                              ? this.props.localizedText["enabled"]
                              : this.props.localizedText["disabled"]}
                          </div>
                        </div>
                        <div class="button-column">
                          <MdPayments
                            className="icon icon-left"
                            onClick={() => {
                              this.showPayments(plugin);
                            }}
                          ></MdPayments>
                          <MdEdit
                            className="icon icon-left"
                            onClick={this.goToUpdatePlugin.bind(this, plugin)}
                          ></MdEdit>
                          {this.state.listPluginsCopied.includes(plugin.id) ? (
                            <FaCheck class="icon icon-left" />
                          ) : (
                            <AiFillCopy
                              className="icon icon-left"
                              onClick={() =>
                                this.copyText(plugin.id, plugin.publicKey)
                              }
                            ></AiFillCopy>
                          )}
                          {false ? (
                            <MdDelete
                              className={"icon"}
                              onClick={() => this.openDeletePlugin(plugin)}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}

                {this.state.loadingPlugins ? (
                  <this.skeletonCards cards={[0, 1, 2, 3]}></this.skeletonCards>
                ) : null}

                {!this.state.loadingPlugins &&
                this.state.listPlugins.length > 0 &&
                this.state.listPluginsShowed.length == 0 ? (
                  <div class="no-list-div fade-in-up">
                    <FaSearchMinus className="empty-icon" />
                    <div class="title">
                      {this.props.localizedText["no-result-found"]}
                    </div>
                    <div class="sub-title">
                      {
                        this.props.localizedText[
                          "we-couldn't-find-any-items-matching-your-search"
                        ]
                      }
                    </div>
                  </div>
                ) : null}

                {!this.state.loadingPlugins &&
                this.state.listPlugins.length < 1 &&
                this.state.listPluginsShowed.length < 1 ? (
                  <div class="no-list-div fade-in-up">
                    <LuPackageSearch className="empty-icon" />
                    <div class="title">
                      {this.props.localizedText["no-plugins-yet"]}
                    </div>
                    <div class="sub-title">
                      {
                        this.props.localizedText[
                          "customizable-public-keys-for-tailored-needs"
                        ]
                      }
                    </div>
                    <Button
                      className="primary-button outside-primary-button small-button"
                      onClick={utils.goToURL.bind(
                        this,
                        this.props,
                        "/dashboard/pay-me/plugins/add"
                      )}
                    >
                      {this.props.localizedText["add-plugin"]}
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
            <div class="side-container"></div>
          </div>
        </div>

        <Modal
          show={this.state.showModalDeletePlugin}
          onHide={this.handleClose}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["delete-plugin"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.localizedText[
              "are-you-sure-you-want-to-delete-the-plugin"
            ] + " "}
            <b>{this.state.selectedPlugin.description}</b>
            {"?"}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="back-button outside-secondary-button"
              onClick={this.handleClose}
            >
              {this.props.localizedText["close"]}
            </Button>
            <Button
              className="verify-button outside-primary-button"
              disabled={this.state.loadingSentDelete}
              onClick={this.deletePlugin}
            >
              {this.state.loadingSentDelete ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["delete"]}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(Plugins);
