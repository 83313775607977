import { loginAPI } from "../../../apis/LoginAPI.jsx";
import { withdrawAPI } from "../../../apis/WithdrawAPI.jsx";
import { walletAPI } from "../../../apis/WalletAPI.jsx";

export default class WithdrawCryptoService {
  withdrawCrypto(
    token,
    portfolioId,
    walletAddress,
    cryptoToken,
    network,
    amount,
    code,
    goToResult
  ) {
    withdrawAPI
      .withdrawCrypto(
        token,
        portfolioId,
        walletAddress,
        cryptoToken,
        network,
        amount,
        code
      )
      .then((response) => {
        console.log("withdrawCrypto", response);
        if (response.status === 200 && response.data && response.data.success) {
          goToResult(true);
        } else {
          goToResult(false);
        }
      })
      .catch((error) => {
        //Failure
        console.log("withdrawCrypto error", error);
        goToResult(false);
      });
  }

  getNetworks(token, crypto, setState, setMessages) {
    walletAPI
      .getNetworks(token, crypto)
      .then((response) => {
        console.log("getNetworks", response.data);
        if (response.status === 200 && response.data) {
          setState(
            {
              listNetworks: response.data,
              loadingNetworks: false,
            },
            () => {
              if (response.data.length == 1) {
                setState({
                  network: response.data[0].blockchain,
                });
              }
            }
          );
        } else {
          setState({
            listNetworks:
              process.env.REACT_APP_ENV != "prod"
                ? JSON.parse(this.getNetworksMockResponse())
                : [],
            loadingNetworks: false,
          });
          setMessages("*an-error-has-ocurred-while-trying-to-get-the-networks");
        }
      })
      .catch((error) => {
        //Failure
        setState({
          listNetworks:
            process.env.REACT_APP_ENV != "prod"
              ? JSON.parse(this.getNetworksMockResponse())
              : [],
          loadingNetworks: false,
        });
        setMessages("*an-error-has-ocurred-while-trying-to-get-the-networks");
      });
  }

  getNetworksMockResponse() {
    return '[ { "coin": "BTC", "blockchain": "btc", "native_token": false, "description": "Bitcoin Blockchain ", "wallet_direction": "INBOUND" }, { "coin": "USDC", "blockchain": "trx", "native_token": false, "description": "Tron Blockchain ", "wallet_direction": "INBOUND" }, { "coin": "USDC", "blockchain": "eth", "native_token": false, "description": "Ethereum Blockchain ", "wallet_direction": "INBOUND" }, { "coin": "XLM", "blockchain": "xlm", "native_token": false, "description": "XLM ", "wallet_direction": "INBOUND" } ]';
  }
}
