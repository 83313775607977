import { loginAPI } from "../../apis/LoginAPI.jsx";
import { refdataAPI } from "../../apis/RefdataAPI.jsx";
import { utils } from "../../utils/utils";

export default class RegisterService {
  registerAccount(state, setState, goToResultPage) {
    loginAPI
      .registerAccount(state)
      .then((response) => {
        console.log("response registerAccount", response);
        if (response.data.code === 0) {
          goToResultPage(true);
        } else {
          goToResultPage(false);
        }
      })
      .catch((error) => {
        goToResultPage(false);
      });
  }

  getCountries(setState) {
    refdataAPI
      .getCountries()
      .then((response) => {
        console.log("getCountries", response);
        if (response.status === 200 && response.data) {
          setState({
            listCountries: utils.orderAlphabetically(response.data, "country"),
            listAreaCodes: utils.removeObjectDuplicates(
              response.data
                .filter((country) => country.phone_area_code)
                .sort((a, b) => {
                  const phone_area_codeA = a.phone_area_code;
                  const phone_area_codeB = b.phone_area_code;
                  if (phone_area_codeA < phone_area_codeB) {
                    return -1;
                  }
                  if (phone_area_codeA > phone_area_codeB) {
                    return 1;
                  }
                  return 0;
                }),
              "phone_area_code"
            ),
            loadingCountries: false,
          });
        } else {
        }
      })
      .catch((error) => {
        //Failure
      });
  }

  getCurrencies(setState) {
    refdataAPI
      .getCurrencies()
      .then((response) => {
        console.log("getCurrencies", response);
        if (response.status === 200 && response.data) {
          setState({
            listCurrencies: utils.orderAlphabetically(response.data, "name"),
            loadingCurrencies: false,
          });
        } else {
        }
      })
      .catch((error) => {
        //Failure
      });
  }
}
