import { adminAPI } from "../../../apis/AdminAPI.jsx";

export default class AdminPaymentsService {
  getPayments(token, setState) {
    adminAPI
      .getPayments(token)
      .then((response) => {
        if (
          response.status === 200 &&
          Array.isArray(response.data.transactions)
        ) {
          console.log("getPayments success", response);
          setState({
            loadingPayments: false,
            listPayments: response.data.transactions,
            listPaymentsShowed: response.data.transactions,
          });
        } else {
          //Failure
          console.log("getPayments failure", response);
          setState({
            loadingPayments: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPayments error", error);
        setState({
          loadingPayments: false,
        });
      });
  }

  approvePayment(token, transactionId, setState) {
    adminAPI
      .approvePayment(token, transactionId)
      .then((response) => {
        if (response.status === 200) {
          console.log("confirmPayment success", response);
          setState(
            {
              loadingSentApprove: false,
              showModalApprovePayment: false,
              selectedDivIndex: null,
              loadingPayments: true,
              listPayments: [],
              listPaymentsShowed: [],
            },
            () => {
              this.getPayments(token, setState);
            }
          );
        } else {
          console.log("confirmPayment failure", response);
          setState({
            loadingSentApprove: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("confirmPayment error", error);
        setState({
          loadingSentApprove: false,
        });
      });
  }

  getPaymentsMockResponse() {
    return [
      {
        creation_time: "2024-08-13T15:43:16.573213+00:00",
        order_id: null,
        transaction_id: "66963567611967820",
        transaction_type: "HOLD",
        transaction_subtype: null,
        from: "d72e2d0e-cebc-4d47-8c42-cf7025ee01ef",
        to: "123465656565",
        qty: 0,
        price: 64506,
        fee_amount: 6.0e-4,
        realized_pnl: 0,
        description: "Sending 0.0606000 BTC to 123465656565",
        childTransactions: [],
        totalChildTransactions: [],
      },
      {
        creation_time: "2024-08-13T15:43:16.573213+00:00",
        order_id: null,
        transaction_id: "66963567611967312",
        transaction_type: "WITHDRAW",
        transaction_subtype: "PAY_VENDOR",
        from: "d72e2d0e-cebc-4d47-8c42-cf7025ee01ef",
        to: "123465656565",
        qty: -10,
        price: 64506,
        fee_amount: 6.0e-4,
        realized_pnl: 0,
        description: "Sending 0.0606000 BTC to 123465656565",
        childTransactions: [],
        totalChildTransactions: [],
      },
    ];
  }
}
