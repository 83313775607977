import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./assets.css";
import AssetsService from "./AssetsService.jsx";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { ImStarFull, ImStarEmpty } from "react-icons/im";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { utils } from "../../utils/utils.jsx";
import { Spinner } from "react-bootstrap";
import SortInputComponent from "../small-components/sort-input/SortInput.jsx";

class Assets extends Component {
  state = {
    isMobileWidth: false,

    chartIndexPicked: 2,
    chartOption: [
      {
        name: "1D",
        length: 1,
        "length-in-granularity": 24,
        granularity: "HOUR",
        "number-points": 24,
      },
      {
        name: "5D",
        length: 5,
        "length-in-granularity": 120,
        granularity: "HOUR",
        "number-points": 40,
      },
      {
        name: "10D",
        length: 10,
        "length-in-granularity": 240,
        granularity: "HOUR",
        "number-points": 40,
      },
      {
        name: "1M",
        length: 30,
        "length-in-granularity": 30,
        granularity: "DAY",
        "number-points": 30,
      },
      {
        name: "2M",
        length: 60,
        "length-in-granularity": 60,
        granularity: "DAY",
        "number-points": 30,
      },
      {
        name: "3M",
        length: 90,
        "length-in-granularity": 90,
        granularity: "DAY",
        "number-points": 45,
      },
    ],

    listPortfolios: [],
    listPortfoliosShowed: [],
    listLoadingFavorite: [],
    loadingAssets: true,
    loadingHistoricalCandles: true,
  };

  service = new AssetsService();

  constructor(props) {
    super(props);

    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  }

  componentDidMount() {
    this.service.getDefaultPortfolio(
      this.props.token,
      this.setState.bind(this),
      this.state.chartOption,
      this.state.chartIndexPicked
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchTerm !== prevProps.searchTerm) {
      this.editSearchTermDefault(this.props.searchTerm);
    }
  }

  componentWillUnmount() {}

  vstyle = getComputedStyle(document.body);

  chartStyles = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
          color: this.vstyle.getPropertyValue("--inside-highlight-color"),
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
          color: this.vstyle.getPropertyValue("--inside-highlight-color"),
        },
      },
    },
    animation: { duration: 500, y: { from: 25 }, x: { from: false } },
    responsive: true,
    maintainAspectRatio: false,
  };

  getDataset = (chartData, lengthInGranularity, numberOfPoints) => {
    //numberOfPointswill always be so that intervals are the same, so that it can be proportional
    let values = [];
    let pointIndex = 0;
    // chartData.length is length in days
    const adder = lengthInGranularity / numberOfPoints;

    for (let i = 0; i < numberOfPoints; i++) {
      values.push(chartData[pointIndex][4]);
      pointIndex = pointIndex + adder;
    }

    let labels = [];
    for (let i = 0; i < numberOfPoints; i++) {
      labels.push("i" + i);
    }

    let chartDataWithStyles = {
      labels: labels,
      datasets: [
        {
          label: "",
          data: values,
          backgroundColor: this.vstyle.getPropertyValue(
            "--inside-highlight-color"
          ),
          borderColor: this.vstyle.getPropertyValue("--inside-highlight-color"),
          pointRadius: 0,
        },
      ],
    };

    return chartDataWithStyles;
  };

  handleFilterAssets = (e) => {
    if (e.target.value != "") {
      this.setState({
        listPortfoliosShowed: this.state.listPortfolios.filter(
          (element) => element.data.fiat.toString() == e.target.value
        ),
      });
    } else {
      this.setState({
        listPortfoliosShowed: this.state.listPortfolios,
      });
    }
  };

  handleChartLengthChange = (index) => {
    if (this.state.chartIndexPicked != index) {
      this.setState(
        {
          loadingHistoricalCandles: true,
        },
        () => {
          this.service.getHistoricalCandles(
            this.props.token,
            this.setState.bind(this),
            this.state.listPortfolios,
            this.state.chartOption,
            index
          );
        }
      );
    }
  };

  //improve functions to have same
  handleSortChange = (event) => {
    var sortedList = [];
    if (event.target.value == "alph") {
      sortedList = this.orderAssetsAlphabetically(
        this.state.listPortfoliosShowed,
        "data",
        "base_currency"
      );
    } else if (event.target.value == "amount") {
      sortedList = this.orderAssetsAmount(
        this.state.listPortfoliosShowed,
        "value_usd"
      );
    } else {
      sortedList = this.state.listPortfoliosShowed;
    }
    this.setState({
      listPortfoliosShowed: sortedList,
    });
  };

  editSearchTermDefault = (value) => {
    if (value != null && value != "") {
      this.setState({
        listPortfoliosShowed: this.filterFunction(
          this.state.listPortfolios,
          value
        ),
      });
    } else {
      this.setState({
        listPortfoliosShowed: this.state.listPortfolios,
      });
    }
  };

  filterFunction = (objects, value) => {
    var filteredObjects = [];
    var lowerCaseName = "";
    var flagMatches = false;
    for (const i in objects) {
      flagMatches = false;

      lowerCaseName = objects[i]["data"]["base_currency"].toLowerCase();
      if (lowerCaseName.includes(value.toLowerCase())) {
        flagMatches = true;
      }

      if (flagMatches) {
        filteredObjects.push(objects[i]);
      }
    }

    return filteredObjects;
  };

  orderAssetsAlphabetically = (array, key1, key2) => {
    if (Array.isArray(array) && array.length > 0) {
      array.sort((a, b) => {
        const valueA = a[key1][key2].toUpperCase(); // Convert to uppercase for case-insensitive sorting
        const valueB = b[key1][key2].toUpperCase();

        if (valueA < valueB) {
          return -1;
        } else if (valueA > valueB) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return array;
  };

  orderAssetsAmount = (array, value) => {
    if (Array.isArray(array) && array.length > 0) {
      array.sort((a, b) => {
        var valueA = a[value]; // Convert to uppercase for case-insensitive sorting
        var valueB = b[value];

        if (valueA == null) {
          valueA = a.data.qty;
        } else if (valueB == null) {
          valueB = b.data.qty;
        }

        if (valueA < valueB) {
          return 1;
        } else if (valueA > valueB) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    return array;
  };

  skeletonCards = ({ cards }) => (
    <div class="div-cards">
      {cards.map(() => (
        <div class="card-asset content-container">
          <div class="metadata-column-skeleton">
            <div class=" skeleton-box image-skeleton"></div>
            <div class="text-skeleton">
              <div class="skeleton-box title-skeleton"></div>
              <div class="skeleton-box abbreviation-skeleton"></div>
            </div>
          </div>
          <div class="skeleton-box  graph-column-skeleton"></div>
          <div class="balance-column-skeleton">
            <div class="skeleton-box value-skeleton"></div>
            <div class="skeleton-box percentage-skeleton"></div>
          </div>
        </div>
      ))}
    </div>
  );

  assets = ({ assets }) => (
    <div style={{ height: "100%" }}>
      <div class="time-length-div">
        {" "}
        <div class="filter-div">
          <div class="compare-option-div"></div>
          <div class="chart-length-option-div">
            {this.state.chartOption.map((option, index) => (
              <div
                class={
                  this.state.chartOption[this.state.chartIndexPicked]["name"] ==
                  option.name
                    ? "option option-picked"
                    : "option"
                }
                onClick={this.handleChartLengthChange.bind(this, index)}
              >
                {option.name}
              </div>
            ))}
          </div>
          <div class="currency-filter-div">
            <InputGroup className="input-field dropdown-filter">
              <Form.Select
                variant="outline-secondary"
                onChange={(e) => {
                  this.handleFilterAssets(e, this.setState.bind(this));
                }}
                style={{
                  width: "100%",
                  flex: "0 1 auto",
                  textAlign: "start",
                }}
              >
                <option value=""> {this.props.localizedText["all"]}</option>
                <option value={true}>{this.props.localizedText["fiat"]}</option>
                <option value={false}>
                  {this.props.localizedText["crypto"]}
                </option>
              </Form.Select>
            </InputGroup>
          </div>
          <div class="currency-filter-div">
            <SortInputComponent
              listOptions={[
                {
                  name: this.props.localizedText["alphabetically"],
                  value: "alph",
                },
                {
                  name: this.props.localizedText["amount"],
                  value: "amount",
                },
              ]}
              handleChange={this.handleSortChange}
            />
          </div>
        </div>{" "}
        {this.state.loadingHistoricalCandles ? (
          <Spinner className="loader-small-outside "></Spinner>
        ) : (
          <div style={{ width: "1.75rem" }}></div>
        )}
      </div>
      <div class="div-cards">
        {assets.map((asset, index) => (
          <div
            class="card-asset content-container"
            onClick={utils.goToURL.bind(
              this,
              this.props,
              "/dashboard/portfolio/asset/" + asset.data["base_currency"]
            )}
          >
            <div class="metadata-column">
              <div class="image"></div>
              <div class="text">
                <div class="title">{asset.data["base_currency"]}</div>
                <div class="abbreviation">{asset.data["base_currency"]}</div>
              </div>
            </div>
            <div class="chart-column">
              {asset["chart-data"] != null ? (
                <Line
                  options={this.chartStyles}
                  data={this.getDataset(
                    asset["chart-data"],
                    this.state.chartOption[this.state.chartIndexPicked][
                      "length-in-granularity"
                    ],
                    this.state.chartOption[this.state.chartIndexPicked][
                      "number-points"
                    ]
                  )}
                />
              ) : null}
            </div>
            <div class="favorite-column">
              {this.state.listLoadingFavorite.includes(
                asset.data["base_currency"]
              ) ? (
                <Spinner className="loader-favorite"></Spinner>
              ) : null}
              {!this.state.listLoadingFavorite.includes(
                asset.data["base_currency"]
              ) && asset.data["fav"] ? (
                <ImStarFull
                  className="favorite-icon"
                  onClick={(e) =>
                    this.checkFavorite(e, asset.data["base_currency"], false)
                  }
                />
              ) : null}
              {!this.state.listLoadingFavorite.includes(
                asset.data["base_currency"]
              ) && !asset.data["fav"] ? (
                <ImStarEmpty
                  className="favorite-icon"
                  onClick={(e) =>
                    this.checkFavorite(e, asset.data["base_currency"], true)
                  }
                />
              ) : null}
            </div>
            <div class="balance-column">
              {asset.data["base_currency"] == "USD" ? (
                <div class="value">
                  ${utils.roundDecimals("USD", asset.data["qty"])}
                </div>
              ) : (
                <div>
                  {" "}
                  {asset["value_usd"] != null ? (
                    <div class="value">
                      ${utils.roundDecimals("USD", asset["value_usd"])}
                    </div>
                  ) : (
                    <div class="value">-</div>
                  )}
                </div>
              )}

              <div class="percentage">
                {utils.roundDecimals(
                  asset.data["base_currency"],
                  asset.data["qty"]
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  checkFavorite = (event, currency, value) => {
    event.stopPropagation();
    var auxArr = this.state.listLoadingFavorite;
    auxArr.push(currency);
    this.disableChartAnimation();
    this.setState(
      {
        listLoadingFavorite: auxArr,
      },
      () => {
        this.service.changeCurrencyFavorite(
          this.props.token,
          this.state,
          this.setState.bind(this),
          currency,
          value,
          this.props.shouldCheckTotalBalance
        );
      }
    );
  };

  stopChecking = () => {
    this.setState(
      {
        shouldCheckTotalBalance: false,
      },
      () => {
        this.enableChartAnimation();
      }
    );
  };

  //Since enabling righ after setState is not working, I will be enabling the animation on a case by case basis, instead of having it as default
  disableChartAnimation = () => {
    this.chartStyles.animation.duration = 0;
  };

  enableChartAnimation = () => {
    setTimeout(() => {
      this.chartStyles.animation.duration = 500;
    }, 0);
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.loadingAssets ? (
          <this.assets assets={this.state.listPortfoliosShowed}></this.assets>
        ) : (
          <this.skeletonCards cards={[1, 2, 3, 4, 5, 6]}></this.skeletonCards>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Assets);
