import React from "react";
import ReceiptComponent from "../receipt/Receipt";
import { utils } from "../../utils/utils";
import "./printPDF.css";

const PrintPDFComponent = (props) => {
  const handleClick = () => {
    utils.goToURL(this.props, "/dashboard");
  };

  return (
    <div class="payment-detail-inner-div">
      <div className="payment-detail-company-header">
        <img
          className="print-img"
          src={"/themes/light/logo.png"}
          alt="Company Logo"
        />
        <div className="company-title">{process.env.REACT_APP_TITLE}</div>
      </div>
      <div className="payment-detail-title">{props.title}</div>

      <ReceiptComponent data={props.receiptData} />

      <div class="payment-detail-info">
        <div class="partial-border"></div>
        <div class="info-row">
          {
            props.localizedText[
              "this-reference-can-be-provided-to-the-recipients-financial"
            ]
          }
        </div>
        <div class="info-row">
          {props.localizedText["receipt-created-on"]}{" "}
          {utils.formatDateUSWithTime(new Date())}
        </div>
      </div>
      <div class="payment-detail-company-footer">
        <div class="legal-name">{process.env.REACT_APP_COMPANY_NAME}</div>
        <div class="bottom-info-div">
          <div class="bottom-info right-pipe">
            16192 Coastal Hwy, Lewes, Delaware 19958, US
          </div>
          <div class="bottom-info">support@bloxcross.com</div>
        </div>
      </div>
    </div>
  );
};

export default PrintPDFComponent;
