import { portfolioAPI } from "../../apis/PortfolioAPI.jsx";

export default class TransactionsService {
  getTransactionHistory(token, setState) {
    portfolioAPI
      .getTransactionHistory(token)
      .then((response) => {
        console.log("/portfolio/get_deep_portfolio_info", response);
        if (response.status === 200) {
          if (response.data.length === 0) {
            setState({
              loadingTransactions: false,
            });
          }
          const listTransactions =
            this.getAllTransactionsAndGroupByMonth(response);
          console.log("listTransactions", listTransactions);
          setState({
            listTransactions: listTransactions,
            listTransactionsShowed: listTransactions,
            loadingTransactions: false,
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("error /portfolio/get_deep_portfolio_info", error);
      });
  }

  getAllTransactionsAndGroupByMonth(response) {
    let array = [];

    for (let i = 0; i < response.data[0].holdings.length; i++) {
      for (
        var j = 0;
        j < response.data[0].holdings[i].transactions.length;
        j++
      ) {
        if (response.data[0].holdings[i].transactions[j].qty != 0) {
          response.data[0].holdings[i].transactions[j]["base_currency"] =
            response.data[0].holdings[i]["base_currency"];
          response.data[0].holdings[i].transactions[j]["creation_time"] =
            new Date(
              response.data[0].holdings[i].transactions[j]["creation_time"]
            ).toLocaleString("en-US", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              fractionalSecondDigits: 3,
              hour12: true,
            });
          array.push(response.data[0].holdings[i].transactions[j]);
        }
      }
    }

    for (let i = 0; i < array.length; i++) {
      if (array[i].childTransactions.length > 0) {
        let totalFeeAmount = 0;
        for (let j = 0; j < array[i].childTransactions.length; j++) {
          totalFeeAmount =
            totalFeeAmount + Number(array[i].childTransactions[j].qty);
        }
        array[i].fee_amount = totalFeeAmount;
      }
    }

    return this.groupByMonth(array);
  }

  groupByMonth(array) {
    const months = Object.entries(
      array.reduce((b, a) => {
        let m = new Date(a.creation_time).toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
        });
        if (b.hasOwnProperty(m)) b[m].push(a);
        else b[m] = [a];
        return b;
      }, {})
    )
      .sort((a, b) => {
        return (
          new Date(b[0].split("/").reverse().join("-")) -
          new Date(a[0].split("/").reverse().join("-"))
        );
      })
      .map((e) => ({ [e[0]]: e[1] }));

    var groupedByMonth = [];
    console.log("months", months);
    for (let i = 0; i < months.length; i++) {
      var monthKey = Object.keys(months[i])[0];

      var object = {
        [monthKey]: months[i][monthKey].sort(function (a, b) {
          return new Date(b.creation_time) - new Date(a.creation_time);
        }),
      };

      groupedByMonth.push(object);
    }

    return groupedByMonth;
  }
}
