import { portfolioAPI } from "../../../apis/PortfolioAPI.jsx";
import { refdataAPI } from "../../../apis/RefdataAPI.jsx";
import { vendorsAPI } from "../../../apis/VendorsAPI.jsx";
import { swapAPI } from "../../../apis/SwapAPI.jsx";
import { withdrawAPI } from "../../../apis/WithdrawAPI.jsx";
import { marketDataAPI } from "../../../apis/MarketDataAPI.jsx";
import { paymentAPI } from "../../../apis/PaymentAPI.jsx";

export default class PayVendorService {
  getVendorPaymentMethods(token, id, setState) {
    vendorsAPI
      .getVendorPaymentMethods(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getVendorPaymentMethods success", response);
          if (Array.isArray(response.data)) {
            let paymentMethod = null;
            response.data.forEach((account) => {
              account.json_build_object.accounts.forEach((account) => {
                if (account.payment_account_id === id) {
                  paymentMethod = account;
                  console.log("paymentAccount found", paymentMethod);
                  setState(
                    {
                      paymentMethod: account,
                      loadingPaymentMethod: false,
                    },
                    () => {
                      if (account.metadata && account.metadata.token_symbol) {
                        setState({
                          currencyFrom:
                            account.metadata.token_symbol.split("-")[0],
                          currencyTo:
                            account.metadata.token_symbol.split("-")[0],
                          network: account.metadata.token_symbol.split("-")[1],
                        });
                      }
                    }
                  );
                }
              });
            });
          } else {
            console.log("getVendorPaymentMethods error", response);
            setState({
              loadingPaymentMethod: false,
            });
          }
        } else {
          console.log("getVendorPaymentMethods failure", response);
          setState({
            loadingPaymentMethod: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  payVendor(token, id, state, code, setState, setMessages) {
    vendorsAPI
      .payVendor(token, id, state, code)
      .then((response) => {
        if (response.data.code === 0) {
          console.log("payVendor success", response);
          let fees = JSON.parse(response.data.payload).fees;
          let totalFees = 0;
          for (let i = 0; i < fees.length; i++) {
            totalFees =
              totalFees + Number(JSON.parse(response.data.payload).fees[i].qty);
          }

          let resultData = JSON.parse(response.data.payload);
          resultData.fee_amount = totalFees;

          setState({
            loadingSent: false,
            stage: state.stage + 1,
            resultSuccess: true,
            resultRecipt: resultData,
          });
        } else {
          console.log("payVendor failure", response);
          setState({
            loadingSent: false,
            stage: state.stage + 1,
            resultSuccess: false,
          });
        }
      })
      .catch((error) => {
        console.log("payVendor error", error);
        setState({
          loadingSent: false,
          stage: state.stage + 1,
          resultSuccess: false,
        });
      });
  }

  withdrawCrypto(
    token,
    walletAddress,
    cryptoToken,
    network,
    amount,
    code,
    stage,
    setState
  ) {
    portfolioAPI
      .getDefaultPortfolio(token)
      .then((responseDefaultPortfolio) => {
        if (
          responseDefaultPortfolio.status === 200 &&
          responseDefaultPortfolio.data
        ) {
          withdrawAPI
            .withdrawCrypto(
              token,
              responseDefaultPortfolio.data.portfolio_id,
              walletAddress,
              cryptoToken,
              network,
              amount,
              code
            )
            .then((response) => {
              if (
                response.status === 200 &&
                response.data &&
                response.data.success
              ) {
                console.log("withdrawCrypto success", response);
                setState({
                  loadingSent: false,
                  stage: stage + 1,
                  resultSuccess: true,
                });
              } else {
                console.log("withdrawCrypto failure", response);
                setState({
                  loadingSent: false,
                  stage: stage + 1,
                  resultSuccess: false,
                });
              }
            })
            .catch((error) => {
              //Failure
              console.log("withdrawCrypto error", error);
              setState({
                loadingSent: false,
                stage: stage + 1,
                resultSuccess: false,
              });
            });
        } else {
          console.log("getDefaultPortfolio failure", responseDefaultPortfolio);
          setState({
            loadingSent: false,
            stage: stage + 1,
            resultSuccess: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getDefaultPortfolio error", error);
        setState({
          loadingSent: false,
          stage: stage + 1,
          resultSuccess: false,
        });
      });
  }

  getCurrencies(token, setState) {
    refdataAPI
      .getCurrenciesHolding(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getCurrenciesHolding success", response);
          var arr = response.data;

          setState({
            listCurrenciesTo: arr,
          });
        } else {
          console.log("getCurrenciesHolding failure", response);
        }
      })
      .catch((error) => {
        console.log("getCurrenciesHolding error", error);
        //Failure
      });
  }

  getDefaultPortfolio(token, setState) {
    portfolioAPI
      .getDefaultPortfolio(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("success", response);
          this.getPortfolioBalances(token, setState, response.data.name);
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getPortfolioBalances(token, setState, portfolioName) {
    portfolioAPI
      .getPortfolioBalances(token, portfolioName)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let total = 0;
          let convertRequests = [];
          let convertResponses = [];
          setState({
            listCurrenciesFrom: response.data,
          });
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].base_currency != "USD") {
              //conversion for fiat
              convertRequests.push(response.data[i].base_currency);
              convertResponses.push(
                swapAPI.convertEstimate(
                  token,
                  response.data[i].qty,
                  response.data[i].base_currency,
                  "USD",
                  0
                )
              );
            } else {
              total = total + response.data[i].qty;
            }
          }

          Promise.all(
            convertResponses.map((response) =>
              response.catch((err) => ({ error: err }))
            )
          )
            .then((convertResponses) => {
              console.log("convertResponses", convertResponses);
              for (let i = 0; i < convertResponses.length; i++) {
                if (convertResponses[i].data.amount_to_receive) {
                  total =
                    total + Number(convertResponses[i].data.amount_to_receive);
                }
              }

              setState({
                loadingTotalBalance: false,
                totalBalance: total,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getConversion(
    token,
    amount,
    fromCurrency,
    toCurrency,
    setState,
    setMessages
  ) {
    swapAPI
      .convertEstimate(token, amount, fromCurrency, toCurrency, 0)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("convertEstimate success", response);
          setState({
            conversion:
              Number(response.data.amount_to_receive) /
              Number(response.data.amount_to_send),
            loadingConversion: false,
          });
        } else {
          console.log("convertEstimate failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-convert-a-currency"
          );
          setState({
            loadingConversion: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("convertEstimate error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-convert-a-currency");
        setState({
          loadingConversion: false,
        });
      });
  }

  calculateFee(
    token,
    payment_account_id,
    amount,
    setState,
    setMessages,
    handleChangeForInvalidAmount
  ) {
    paymentAPI
      .calculateFee(token, payment_account_id, amount)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("calculateFee success", response);
          let feesWithAmount = JSON.parse(response.data.payload);
          feesWithAmount["amount"] = amount;
          setState(
            {
              fees: feesWithAmount,
              loadingFees: false,
            },
            () => {
              handleChangeForInvalidAmount();
            }
          );
        } else {
          console.log("calculateFee failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-convert-a-currency"
          );
          setState({
            loadingFees: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("calculateFee error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-convert-a-currency");
        setState({
          loadingFees: false,
        });
      });
  }
}
