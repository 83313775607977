import axios from "axios";
import { vendorsAPI } from "../../../apis/VendorsAPI.jsx";
import { utils } from "../../../utils/utils";

export default class AssetsService {
  getVendors(token, setState) {
    vendorsAPI
      .getVendors(token)
      .then((response) => {
        setState({
          loading: false,
        });

        if (response.status === 200 && response.data) {
          if (Array.isArray(response.data)) {
            setState({
              listVendors: response.data,
              listVendorsShowed: response.data,
            });
          }
        } else {
        }
      })
      .catch((error) => {
        //Failure
      });
  }

  getVendorPaymentMethods(token, setState, reloadPaymentMethods) {
    vendorsAPI
      .getVendorPaymentMethods(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getVendorPaymentMethods success", response);
          if (Array.isArray(response.data)) {
            setState(
              {
                loadingPaymentMethods: false,
                listPaymentMethods: response.data,
              },
              () => {
                if (reloadPaymentMethods != null) {
                  reloadPaymentMethods();
                }
              }
            );
          } else {
            setState(
              {
                loadingPaymentMethods: false,
                listPaymentMethods: [],
              },
              () => {
                if (reloadPaymentMethods != null) {
                  reloadPaymentMethods();
                }
              }
            );
          }
        } else {
          console.log("getVendorPaymentMethods failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getVendorPaymentMethods error", error);
      });
  }

  getPayments(token, vendorId, setState) {
    vendorsAPI
      .getPayments(token, vendorId)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getPayments success", response);
          setState({
            loadingPayments: false,
            listPayments:
              process.env.REACT_APP_ENV == "local"
                ? this.getPaymentsMockResponse()
                : response.data,
            listPaymentsShowed:
              process.env.REACT_APP_ENV == "local"
                ? this.getPaymentsMockResponse()
                : response.data,
          });
        } else {
          console.log("getPayments failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPayments error", error);
      });
  }

  deletePaymentMethod(token, id, setState, reloadPaymentMethods) {
    vendorsAPI
      .deleteVendorPaymentAccount(token, id)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("deletePaymentMethod success", response);
          setState(
            {
              loadingSentDelete: false,
              showModalDeletePaymentMethod: false,
              loadingPaymentMethods: true,
            },
            () => {
              this.getVendorPaymentMethods(
                token,
                setState,
                reloadPaymentMethods
              );
            }
          );
        } else {
          console.log("deletePaymentMethod failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("deletePaymentMethod error", error);
      });
  }

  getPaymentsMockResponse() {
    return [
      {
        creation_time: "2024-01-11T21:42:15.118166+00:00",
        vendor: "william barrios",
        account: "william barrios",
        account_id: "37991b32-5945-4553-a949-7f977a25e3bc",
        description:
          "Description try, example number 1, this is example text just to make it long",
        amount: 1.0,
        paid_with: "1.0000000000000000 USD @ 1",
        status: "Approved",
        notes: '{"purpose":"For me"}',
      },
      {
        creation_time: "2024-01-11T21:42:15.118166+00:00",
        vendor: "william barrios",
        account: "william barrios",
        account_id: "37991b32-5945-4553-a949-7f977a25e3bc",
        description: "Description try, example number 2",
        amount: 1.0,
        paid_with: "1.0000000000000000 USD @ 1",
        status: "Failed",
        notes: '{"purpose":"For me"}',
      },
      {
        creation_time: "2024-01-11T21:42:15.118166+00:00",
        vendor: "william barrios",
        account: "william barrios",
        account_id: "37991b32-5945-4553-a949-7f977a25e3bc",
        description: null,
        amount: 1.0,
        paid_with: "1.0000000000000000 USD @ 1",
        status: "Pending",
        notes: '{"purpose":"For me"}',
      },
    ];
  }
}
