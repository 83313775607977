import { settingsAPI } from "../../apis/SettingsAPI.jsx";
import { utils } from "../../utils/utils";

export default class SettingsService {
  changePassword(
    token,
    state,
    setAccountAndToken,
    props,
    setState,
    setMessages
  ) {
    settingsAPI
      .changePassword(token, state.password, state.newPassword)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          utils.clearSession(props, setAccountAndToken);
        } else {
          //Failure
          setMessages("*an-error-has-ocurred-during-password-change");
          setState({
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        console.log(error.response);
        setMessages("*an-error-has-ocurred-during-password-change");
        setState({
          loadingSent: false,
        });
      });
  }
}
