import React, { Component } from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import DashboardAdminService from "./dashboardAdminService";
import AdminPayments from "../../admin/admin-payments/AdminPayments.jsx";
import AdminReports from "../../admin/admin-reports/AdminReports.jsx";

class DashboardAdmin extends Component {
  state = {
    linkedAccount: false,
    goBackNavBar: false,
    goBackUrl: "/dashboard",
    showVerifyModal: null,
    showPaymentInfoModal: null,
  };

  service = new DashboardAdminService();

  componentDidMount() {}

  changeGoBackNavBar = (value, url) => {
    this.setState({
      goBackNavBar: value,
      goBackUrl: url,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Switch>
          {this.props.account &&
          this.props.account.roles &&
          this.props.account.roles.some((role) => role.name == "Blox_Admin") ? (
            <Route
              exact
              path="/dashboard/admin/payments"
              render={(props) => (
                <div class="welcome-div-container">
                  <AdminPayments
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                  ></AdminPayments>
                </div>
              )}
            />
          ) : null}
          {this.props.account &&
          this.props.account.roles &&
          this.props.account.roles.some(
            (role) => role.name == "Company_Admin"
          ) ? (
            <Route
              exact
              path="/dashboard/admin/reports"
              render={(props) => (
                <div class="welcome-div-container">
                  <AdminReports
                    account={this.props.account}
                    token={this.props.token}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                  ></AdminReports>
                </div>
              )}
            />
          ) : null}
        </Switch>
      </React.Fragment>
    );
  }
}

export default withRouter(DashboardAdmin);
