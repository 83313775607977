import axios from "axios";
import { loginAPI } from "../../../apis/LoginAPI.jsx";
import { utils } from "../../../utils/utils";

export default class IdVerificaitonService {
  getClientConfig(token, setState) {
    loginAPI
      .getClientConfig(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          setState({
            publicKey: response.data.vouched_public_key,
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
      });
  }

  vouchedJobSubmitted(token, jobId, moveToResult, getClientConfig) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      jobid: jobId,
    };

    axios
      .post(
        process.env.REACT_APP_BASE_API_URL + "/client/vouched_job_submitted",
        requestBody,
        headers
      )
      .then((response) => {
        console.log("vouchedJobSubmittedRESPONSE", response);
        if (response.status === 200 && response.data.success) {
          console.log("vouchedJobSubmittedRESPONSE", response);
          getClientConfig();
          moveToResult(true, "general");
        } else {
          //Failure
          if (response.data.code == "1000239") {
            moveToResult(false, "already-verified");
          } else {
            moveToResult(false, "general");
          }
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        moveToResult(false, "general");
      });
  }
}
