import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./payMeCreditCard.css";
import PayMeCreditCardService from "./PayMeCreditCardService.jsx";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { utils } from "../../../utils/utils.jsx";
import ConversionLinkComponent from "../../conversionLink/ConversionLink.jsx";
import { Spinner } from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import ErrorMessageComponent from "../../errorMessage/ErrorMessage.jsx";

class PayMeCreditCard extends Component {
  state = {
    width: window.innerWidth,

    currency: "",
    amount: "",
    payerEmail: "",
    payerName: "",
    cryptoAmountToReceive: "",
    fiatAmountToReceive: "",
    invoiceNumber: "",
    memo: "",
    service: "",
    description: "",

    loadingConversion: false,
    loadingSent: false,

    invalidCurrency: false,
    invalidAmount: false,
    invalidPayerEmail: false,
    invalidInvoiceNumber: false,
    invalidMemo: false,
    invalidPayerName: false,

    resultSuccess: true,
  };

  service = new PayMeCreditCardService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  handleChangeForAmountInUSD = (event, setState) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name == "currency" && value != "") {
      this.props.setCurrency(value);
    }

    setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.currency != "" && this.state.amount) {
          if (this.state.currency != "USD") {
            this.setState(
              {
                loadingConversion: true,
              },
              () => {
                this.service.getQuote(
                  this.props.token,
                  "USD",
                  this.state.amount,
                  this.state.currency.toLowerCase(),
                  this.setState.bind(this),
                  this.setMessages.bind(this)
                );
              }
            );
          } else {
            this.setState({ fiatAmountToReceive: this.state.amount });
          }
        }
      }
    );
  };

  handleCheckout = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.currency != "" &&
          this.state.amount != "" &&
          this.state.payerEmail != "" &&
          this.state.payerName != "" &&
          this.state.invoiceNumber != "" &&
          this.state.memo != ""
        ) {
          this.setState(
            {
              invalidCurrency: false,
              invalidAmount: false,
              invalidPayerEmail: false,
              invalidPayerName: false,
              invalidInvoiceNumber: false,

              loadingSent: true,
            },
            () => {
              this.service.checkoutUrl(
                this.props.token,
                this.state.currency,
                this.state.amount,
                this.state.payerEmail,
                this.state.payerName,
                this.state.invoiceNumber,
                this.state.memo,
                this.state.description,
                this.setState.bind(this),
                this.setMessages.bind(this),
                this.props.goToResultStage.bind(this)
              );
            }
          );
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCurrency: this.state.currency == "",
            invalidAmount: this.state.amount == "",
            invalidPayerEmail: this.state.payerEmail == "",
            invalidPayerName: this.state.payerName == "",
            invalidInvoiceNumber: this.state.invoiceNumber == "",
            invalidMemo: this.state.memo == "",
          });
        }
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="pay-me-credit-card-div">
          {" "}
          {utils.conditionalPermission(this.props.account, ["can_convert"]) ? (
            <ConversionLinkComponent
              width={this.state.width}
              currencyFrom={this.state.currency}
              currencyTo={"USD"}
              region={this.props.region}
              localizedText={this.props.localizedText}
            />
          ) : null}
          <div class="section">
            <div class="left-input content-container">
              <div class="form-div">
                <Form.Group className="mb-3">
                  <Form.Label className={"input-label-inside-div"}>
                    {
                      this.props.localizedText[
                        "which-asset-would-you-like-to-recieve?"
                      ]
                    }
                  </Form.Label>
                  <InputGroup className="mb-3 input-field">
                    <Form.Select
                      variant="outline-secondary"
                      onChange={(e) =>
                        this.handleChangeForAmountInUSD(
                          e,
                          this.setState.bind(this)
                        )
                      }
                      name="currency"
                      style={{
                        width: "100%",
                        flex: "0 1 auto",
                        textAlign: "start",
                      }}
                      isInvalid={this.state.invalidCurrency}
                      disabled={this.state.loadingSent}
                    >
                      <option></option>
                      {this.props.listCurrenciesCreditCard.map((currency) => (
                        <option value={currency.symbol}>
                          {currency.symbol}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>{" "}
              <div class="form-div">
                <Form.Group className="mb-3">
                  <Form.Label className="input-label-inside-div">
                    {this.props.localizedText["amount-in-usd"]}
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="input-text no-background-input-inside"
                      type="number"
                      name="amount"
                      value={this.state.amount}
                      isInvalid={this.state.invalidAmount}
                      autoComplete="off"
                      onChange={(e) =>
                        this.handleChangeForAmountInUSD(
                          e,
                          this.setState.bind(this)
                        )
                      }
                      onWheel={(e) => e.target.blur()}
                    />{" "}
                    <InputGroup.Text
                      className={
                        this.state.invalidAmount
                          ? "inside-input-symbol input-symbol-danger"
                          : "inside-input-symbol"
                      }
                    >
                      USD
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </div>{" "}
              <div class="form-div">
                <Form.Group className="mb-3">
                  <Form.Label className="input-label-inside-div">
                    {this.props.localizedText["payer-email"]}
                  </Form.Label>
                  <FormControl
                    name="payerEmail"
                    value={this.state.payerEmail}
                    onChange={(e) =>
                      utils.handleChange(e, this.setState.bind(this))
                    }
                    isInvalid={this.state.invalidPayerEmail}
                    className="input-field"
                    disabled={this.state.loadingSent}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>{" "}
            </div>{" "}
            <div class="right-div">
              <div class="value-input">
                <Form.Group className="mb-3">
                  <Form.Label className="input-label-outside-div">
                    {this.props.localizedText["customer-name"]}
                  </Form.Label>
                  <FormControl
                    name="payerName"
                    value={this.state.payerName}
                    onChange={(e) =>
                      utils.handleChange(e, this.setState.bind(this))
                    }
                    isInvalid={this.state.invalidPayerName}
                    className="input-field"
                    disabled={this.state.loadingSent}
                    autoComplete="off"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="input-label-outside-div">
                    {this.props.localizedText["invoice-number"]}
                  </Form.Label>
                  <FormControl
                    name="invoiceNumber"
                    value={this.state.invoiceNumber}
                    onChange={(e) =>
                      utils.handleChange(e, this.setState.bind(this))
                    }
                    isInvalid={this.state.invalidInvoiceNumber}
                    className="input-field"
                    disabled={this.state.loadingSent}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="input-label-outside-div">
                    {this.props.localizedText["memo-service"]}
                  </Form.Label>
                  <FormControl
                    name="memo"
                    value={this.state.memo}
                    onChange={(e) =>
                      utils.handleChange(e, this.setState.bind(this))
                    }
                    isInvalid={this.state.invalidInvoiceNumber}
                    className="input-field"
                    disabled={this.state.loadingSent}
                    autoComplete="off"
                  />{" "}
                </Form.Group>
                <Form.Group className="mb-3">
                  <InputGroup className="input-group">
                    <Form.Label className="input-label-outside-div">
                      {this.props.localizedText["description-optional"]}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      value={this.state.description}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      className="text-area"
                      disabled={this.state.loadingSent}
                    />
                  </InputGroup>
                </Form.Group>
                {this.state.loadingConversion ? (
                  <div class="fade-in-up loading-quote">
                    <Spinner className="loading-conversions" />
                  </div>
                ) : null}
                {!this.state.loadingConversion &&
                (this.state.cryptoAmountToReceive != "" ||
                  this.state.fiatAmountToReceive != "") ? (
                  <div class="fee-div fade-in-up">
                    <div class="fee-title"></div>
                    {this.state.currency != "USD" ? (
                      <div>
                        <div class="fee-row">
                          {utils.formatJSONStringVariables(
                            this.props.localizedText[
                              "after-payment-you-will-recieve-in-your-portfolio"
                            ],
                            [
                              utils.roundDecimals(
                                this.state.currency,
                                this.state.cryptoAmountToReceive
                              ),
                              this.state.currency,
                            ]
                          )}
                        </div>
                        <div class="notice-row">
                          {
                            this.props.localizedText[
                              "note-this-is-a-current-estimation-and-the-final-amount-may-vary"
                            ]
                          }
                        </div>{" "}
                      </div>
                    ) : (
                      <div class="fee-row">
                        {utils.formatJSONStringVariables(
                          this.props.localizedText[
                            "after-payment-you-will-recieve-in-your-portfolio-usd"
                          ],
                          [
                            utils.roundDecimals(
                              this.state.currency,
                              this.state.fiatAmountToReceive
                            ),
                            this.state.currency,
                          ]
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>{" "}
          <ErrorMessageComponent
            errorMessage={this.props.localizedText[this.state.errorMessageType]}
          ></ErrorMessageComponent>
          <div class="buttons-div fade-in-up">
            <Button
              className="primary-button outside-primary-button"
              onClick={this.handleCheckout}
              disabled={this.state.loadingSent}
            >
              {this.state.loadingSent ? (
                <Spinner className="loader-send-outside-div-button"></Spinner>
              ) : null}
              {this.props.localizedText["send-payment-request"]}
            </Button>

            <Button
              className="secondary-button outside-secondary-button"
              onClick={this.props.tryToGoBackStage}
              disabled={this.state.loadingSent}
            >
              {this.props.localizedText["cancel"]}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PayMeCreditCard);
