import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./payMe.css";
import PayMeService from "./payMeService";
import GoBackButton from "../../components/go-back-button/GoBackButton.jsx";
import TotalBalance from "../../components/total-balance/TotalBalance.jsx";
import { Spinner } from "react-bootstrap";
import { utils } from "../../utils/utils.jsx";
import { IoCard } from "react-icons/io5";
import { MdKey } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { SiBitcoinsv } from "react-icons/si";
import PayMeCreditCard from "../../components/pay-me/pay-me-credit-card/PayMeCreditCard.jsx";
import PayMeCrypto from "../../components/pay-me/pay-me-crypto/PayMeCrypto.jsx";
import Result from "../../components/result/Result.jsx";
import { Link } from "react-router-dom";

class PayMe extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    listCurrenciesCreditCard: [],
    listCryptos: [],
    listNetworks: [],

    currency: "",
    crypto: "",

    loadingCurrencies: true,
    loadingConversion: false,

    resultSuccess: true,
    resultMessage: "",

    stage: 1,
  };

  service = new PayMeService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("this.props.history", this.props.history);
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    this.service.getCurrencies(this.props.token, this.setState.bind(this));
    //this.service.getPayMeCryptos(this.props.token, this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.tryToGoBackStage
      )
    );

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });

        if (this.props.phoneVerified != null && !this.props.phoneVerified) {
          utils.goToURL(this.props, "/dashboard/settings/verify-phone");
        } else if (
          this.props.emailVerified != null &&
          !this.props.emailVerified
        ) {
          utils.goToURL(this.props, "/dashboard/settings/verify-email");
        } else if (this.props.idVerified != null && !this.props.idVerified) {
          utils.goToURL(this.props, "/dashboard/settings/id-verification");
        }
      }
    }
  }

  moveStage = (stage) => {
    this.setState({
      stage: stage,
    });
  };

  goBackStage = () => {
    this.setState({
      stage: 1,
    });
  };

  tryToGoBackStage = () => {
    if (this.state.stage == 1) {
      utils.goToURL(this.props, "/dashboard");
    } else {
      this.goBackStage();
    }
  };

  goToResultStage = (resultSuccess, resultMessage) => {
    this.setState({
      resultSuccess: resultSuccess,
      resultMessage: resultMessage,
      stage: "result",
    });
  };

  copyText = () => {
    this.setState({
      inboundAddressCopied: true,
    });
    navigator.clipboard.writeText(this.state.inboundAddress);
  };

  handleDepositCloseModal = () => {
    this.setState({
      showDepositModal: false,
    });
  };

  getWidthForModal = () => {
    if (this.state.width > 1200) {
      return "600";
    } else if (this.state.width < 1200 && this.state.width > 820) {
      return this.state.width * 0.8;
    } else {
      return this.state.width * 0.9;
    }
  };

  getHeightForModal = () => {
    if (window.innerHeight > 820) {
      return window.innerHeight * 0.55;
    } else {
      return window.innerHeight * 0.65;
    }
  };

  setCurrency = (value) => {
    this.setState({
      currency: value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="pay-me-container">
          <div class="pay-me-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={this.tryToGoBackStage}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              {this.state.stage == 1 ||
              this.state.stage == 2 ||
              this.state.stage == 3 ? (
                <div>
                  {" "}
                  <TotalBalance
                    account={this.props.account}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    token={this.props.token}
                  ></TotalBalance>
                  <div class="title-div fade-in-up">
                    <div class="title">
                      {this.props.localizedText["pay-me"]}
                    </div>
                  </div>
                  {this.state.stage == 1 ? (
                    <div>
                      {this.state.loadingCurrencies ? (
                        <div class="fade-in-up">
                          <Spinner className="loader loading-currencies" />
                        </div>
                      ) : (
                        <div class="section">
                          <div class="option-div">
                            <div class="pay-me-list">
                              {utils.conditionalPermission(this.props.account, [
                                "can_pay_me_credit_card",
                              ]) ? (
                                <div
                                  className="pay-me-row-container credit-card-row-container content-container"
                                  onClick={this.moveStage.bind(this, 2)}
                                >
                                  <div className="metadata-column">
                                    <IoCard class="icon" />
                                    <div className="text">
                                      <div className="option-name">
                                        {" "}
                                        {
                                          this.props.localizedText[
                                            "pay-me-with-credit-card"
                                          ]
                                        }
                                      </div>
                                      <div className="option-description">
                                        {
                                          this.props.localizedText[
                                            "request-a-payment-by-sending-a-pay-link"
                                          ]
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <IoIosArrowForward class="arrow"></IoIosArrowForward>
                                </div>
                              ) : null}
                              {utils.conditionalPermission(this.props.account, [
                                "can_pay_me_crypto",
                              ]) ? (
                                <div
                                  className="pay-me-row-container credit-card-row-container content-container"
                                  onClick={this.moveStage.bind(this, 3)}
                                >
                                  <div className="metadata-column">
                                    <SiBitcoinsv class="icon" />
                                    <div className="text">
                                      <div className="option-name">
                                        {
                                          this.props.localizedText[
                                            "pay-me-with-crypto"
                                          ]
                                        }
                                      </div>
                                      <div className="option-description">
                                        {
                                          this.props.localizedText[
                                            "request-a-crypto-payment-to-an-email"
                                          ]
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <IoIosArrowForward class="arrow"></IoIosArrowForward>
                                </div>
                              ) : null}

                              {utils.conditionalPermission(this.props.account, [
                                "can_pay_me_plugins",
                              ]) ? (
                                <div
                                  className="pay-me-row-container credit-card-row-container content-container"
                                  onClick={utils.goToURL.bind(
                                    this,
                                    this.props,
                                    "/dashboard/pay-me/plugins"
                                  )}
                                >
                                  <div className="metadata-column">
                                    <MdKey class="icon" />
                                    <div className="text">
                                      <div className="option-name">
                                        {this.props.localizedText["plugins"]}
                                      </div>
                                      <div className="option-description">
                                        {
                                          this.props.localizedText[
                                            "manage-your-pay-me-button-plugins"
                                          ]
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <IoIosArrowForward class="arrow"></IoIosArrowForward>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                  {this.state.stage == 2 ? (
                    <PayMeCreditCard
                      changeGoBackNavBar={this.props.changeGoBackNavBar}
                      localizedText={this.props.localizedText}
                      listCurrenciesCreditCard={
                        this.state.listCurrenciesCreditCard
                      }
                      tryToGoBackStage={this.tryToGoBackStage}
                      setCurrency={this.setCurrency}
                      goToResultStage={this.goToResultStage}
                      token={this.props.token}
                      account={this.props.account}
                    />
                  ) : null}{" "}
                  {this.state.stage == 3 ? (
                    <PayMeCrypto
                      changeGoBackNavBar={this.props.changeGoBackNavBar}
                      localizedText={this.props.localizedText}
                      listCryptos={this.state.listCryptos}
                      tryToGoBackStage={this.tryToGoBackStage}
                      setCurrency={this.setCurrency}
                      goToResultStage={this.goToResultStage}
                      token={this.props.token}
                      account={this.props.account}
                    />
                  ) : null}{" "}
                </div>
              ) : null}{" "}
              {this.state.stage == "result" ? (
                <Result
                  success={this.state.resultSuccess}
                  secondButton={false}
                  title={
                    this.state.resultSuccess
                      ? this.props.localizedText["great-news!"]
                      : this.props.localizedText["something-went-wrong"]
                  }
                  message={this.props.localizedText[this.state.resultMessage]}
                  primaryButtonText={this.props.localizedText["continue"]}
                  primaryButtonFunction={utils.goToURL.bind(
                    this,
                    this.props,
                    "/dashboard"
                  )}
                ></Result>
              ) : null}
            </div>
            <div class="side-container"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PayMe);
