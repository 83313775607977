import axios from "axios";

class PaymentAPI {
  checkPeer(token, id) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      key: id,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/v2/check_peer",
      body,
      headers
    );
  }

  payPeer(token, phone, amount, currency, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      phone: phone,
      amount: Number(amount),
      currency: currency,
      code: code,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/send2peer",
      body,
      headers
    );
  }

  calculateFee(token, payment_account_id, amount) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      payment_account_id,
      amount: Number(amount),
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/calculate_fee",
      body,
      headers
    );
  }
}

export const paymentAPI = new PaymentAPI();
