import { payMeAPI } from "../../../apis/PayMeAPI.jsx";

export default class PluginService {
  getPaymentMethods(token, setState) {
    payMeAPI
      .getPaymentMethods(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getPaymentMethods success", response.data);
          setState({
            listPaymentMethods: response.data,
            loadingPaymentMethods: false,
          });
        } else {
          console.log("getPaymentMethods failure", response.data);
          setState({
            loadingPaymentMethods: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPaymentMethods error", error);
        setState({
          loadingPaymentMethods: false,
        });
      });
  }

  getAvailableCryptos(token, setState) {
    payMeAPI
      .getAvailableCryptos(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getAvailableCryptos success", response.data);
          setState({
            listPaymentMethods: response.data,
            loadingCryptos: false,
          });
        } else {
          console.log("getAvailableCryptos failure", response.data);
          setState({
            loadingCryptos: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getAvailableCryptos error", error);
        setState({
          loadingCryptos: false,
        });
      });
  }

  getCustomFields(token, setState) {
    payMeAPI
      .getCustomFields(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getCustomFields success", response.data);
          setState({
            listCustomFields: response.data,
            loadingCustomFields: false,
          });
        } else {
          console.log("getCustomFields failure", response.data);
          setState({
            loadingCustomFields: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getCustomFields error", error);
        setState({
          loadingCustomFields: false,
        });
      });
  }

  getPlugins(token, setState) {
    payMeAPI
      .getPlugins(token)
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("getPlugin success", response.data);
          setState({
            listPlugins: response.data,
            listPluginsShowed: response.data,
            loadingPlugins: false,
          });
        } else {
          console.log("getPlugin failure", response.data);
          setState({
            loadingPlugins: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("getPlugin error", error);
        setState({
          loadingPlugins: false,
        });
      });
  }

  disablePlugin(
    token,
    id,
    listPlugin,
    listLoadingSentChangeStatus,
    setState,
    setMessages
  ) {
    payMeAPI
      .disablePlugin(token, id)
      .then((response) => {
        if (response.status === 204) {
          console.log("disablePlugin success", response);
          var listPluginAux = listPlugin;
          const index = listPluginAux.findIndex((item) => item.id == id);
          if (index !== -1) {
            listPluginAux[index].active = false;
          }

          var auxAurr = listLoadingSentChangeStatus.filter(
            (item) => item != id
          );

          setState({
            listPlugin: listPluginAux,
            listLoadingSentChangeStatus: auxAurr,
          });
        } else {
          console.log("disablePlugin failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-change-the-status-of-the-plugin"
          );
        }
      })
      .catch((error) => {
        //Failure
        console.log("disablePlugin error", error);
        setMessages(
          "*an-error-has-ocurred-while-trying-to-change-the-status-of-the-plugin"
        );
      });
  }

  enablePlugin(
    token,
    id,
    listPlugin,
    listLoadingSentChangeStatus,
    setState,
    setMessages
  ) {
    payMeAPI
      .enablePlugin(token, id)
      .then((response) => {
        if (response.status === 204) {
          console.log("enablePlugin success", response);

          var listPluginAux = listPlugin;
          const index = listPluginAux.findIndex((item) => item.id == id);
          if (index !== -1) {
            listPluginAux[index].active = true;
          }

          var auxAurr = listLoadingSentChangeStatus.filter(
            (item) => item != id
          );

          setState({
            listPlugin: listPluginAux,
            listLoadingSentChangeStatus: auxAurr,
          });
        } else {
          console.log("enablePlugin failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-change-the-status-of-the-plugin"
          );
        }
      })
      .catch((error) => {
        //Failure
        console.log("enablePlugin error", error);
        setMessages(
          "*an-error-has-ocurred-while-trying-to-change-the-status-of-the-plugin"
        );
      });
  }

  deletePlugin(token, id, listPlugin, setState, setMessages) {
    payMeAPI
      .deletePlugin(token, id)
      .then((response) => {
        if (response.status === 204) {
          console.log("deletePlugin success", response);
          var auxAurr = listPlugin.filter((item) => item.id != id);
          console.log(
            "listPlugin.filter((item) => item.id != id)",
            listPlugin.filter((item) => item.id != id)
          );
          console.log("auxAurr", auxAurr);
          setState({
            loadingSentDelete: false,
            listPlugin: auxAurr,
            showModalDeletePlugin: false,
          });
        } else {
          console.log("deletePlugin failure", response);
          setMessages(
            "*an-error-has-ocurred-while-trying-to-delete-the-plugin"
          );
          setState({
            loadingSentDelete: false,
            showModalDeletePlugin: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log("deletePlugin error", error);
        setMessages("*an-error-has-ocurred-while-trying-to-delete-the-plugin");
        setState({
          loadingSentDelete: false,
          showModalDeletePlugin: false,
        });
      });
  }
}
