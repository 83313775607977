import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Button from "react-bootstrap/Button";
import { utils } from "../../utils/utils";
import "./goBackButton.css";

class GoBackButton extends Component {
  state = {
    flagFullText: true,
    isSticky: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.flagFullText) {
      this.setState({
        flagFullText: false,
      });
    } else {
      //ComponentDidUpdateWill take care of it
      this.setState({
        flagFullText: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      this.setState({
        isSticky: this.props.isSticky,
      });
    }
  }

  goBack = () => {
    if (
      (this.props.goBack && typeof this.props.goBack === "string") ||
      this.props.goBack instanceof String
    ) {
      utils.goToURL(this.props, this.props.goBack);
    } else if (this.props.goBack) {
      this.props.goBack();
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.flagFullText ? (
          <Button className="go-back-button" onClick={this.goBack.bind(this)}>
            <IoMdArrowRoundBack class="arrow"></IoMdArrowRoundBack>
            {this.props.localizedText["go-back"]}
          </Button>
        ) : (
          <Button
            className={
              this.state.isSticky
                ? "go-back-button layout-sticky"
                : "go-back-button layout"
            }
            onClick={this.goBack.bind(this)}
          >
            <IoMdArrowRoundBack class="arrow"></IoMdArrowRoundBack>
            {this.props.localizedText["back"]}
          </Button>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(GoBackButton);
