import axios from "axios";

class SettingsAPI {
  //activate GAUTH 2fa
  alwasyOn2FA(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/always_on_2fa",
      requestBody,
      headers
    );
  }

  //Deactivate GAUTH 2fa
  decline2FA(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {};

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/decline_2fa",
      requestBody,
      headers
    );
  }

  changeName(token, firstName, lastName) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      fname: firstName,
      lname: lastName,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/client/update_name",
      requestBody,
      headers
    );
  }

  changeEmail(token, email) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      new_email: email,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/change_email",
      requestBody,
      headers
    );
  }

  changePhone(token, phone) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      new_phone: phone,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/change_phone",
      requestBody,
      headers
    );
  }

  changePassword(token, currentPassword, newPassword) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      current_password: currentPassword,
      new_password: newPassword,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/register/change_pwd",
      requestBody,
      headers
    );
  }

  deleteAccount(token, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      code: code,
      notes: "Tired of this!",
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/client/delete_account",
      requestBody,
      headers
    );
  }

  deactivateAccount(token, code) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      code: code,
      notes: "Tired of this!",
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/client/deactivate_account",
      requestBody,
      headers
    );
  }
}

export const settingsAPI = new SettingsAPI();
