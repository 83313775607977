import React, { Component } from "react";
import "./paymentInfoModal.css";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class PaymentInfoModal extends Component {
  state = { show: true };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
    }
  }

  goToURL = (url) => {
    this.handleClose();
    this.props.history.push({ pathname: url, megastate: { from: true } });
  };

  handleClose = () => {
    this.props.toggleModalVerify(false);
  };

  getTitle = (type) => {
    switch (type) {
      default:
        return this.props.localizedText["important-notice"];
    }
  };

  getMessage = (type) => {};

  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.props.showVerifyModal}
          onHide={this.handleClose}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.getTitle(process.env.REACT_APP_REGION)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              this.props.localizedText[
                "please-note-that-we-have-updated-your-wallet-address-make-sure-to-refer-to-your-new-wallet-address"
              ]
            }
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <Button
              className="outside-secondary-button"
              onClick={this.handleClose}
            >
              {this.props.localizedText["close"]}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(PaymentInfoModal);
