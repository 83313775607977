import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import CryptoJS from "crypto-js";
import { PDFDocument } from "pdf-lib";

class Utils {
  //check if window width reaches breaking point (responsive)
  checkWindowWidth = (setState, changeGoBackNavBar, flagButton, url) => {
    const windowSize = window.innerWidth;
    let flag = true;

    if (windowSize <= 1000) {
      flag = true;
    } else {
      flag = false;
    }

    this.checkGoBackNavBar(windowSize, changeGoBackNavBar, flagButton, url);

    setState({
      isMobileWidth: flag,
      width: windowSize,
    });
  };

  //Show back button from navbar in responsive view
  checkGoBackNavBar = (windowSize, propsCheckGoBackNavBar, flagButton, url) => {
    if (windowSize <= 1200) {
      propsCheckGoBackNavBar(flagButton, url);
    }
  };

  //generic handle input change
  handleChange = (event, setState) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState({
      [name]: value,
    });
  };

  handleChangeForInvalidAmount = (
    listCurrencies,
    amount,
    currency,
    setMessages,
    setState
  ) => {
    if (amount != "" && currency != "") {
      if (
        listCurrencies.filter((element) => element.base_currency == currency)
          .length <= 0 ||
        Number(amount) >
          listCurrencies.filter(
            (element) => element.base_currency == currency
          )[0].qty
      ) {
        setMessages();
        setState({
          invalidAmount: true,
        });
      } else {
        setState({
          invalidAmount: false,
          errorMessageType: "",
        });
      }
    }
  };

  getTokenFromStorage = () => {
    if (process.env.REACT_APP_ENV != "prod") {
      return localStorage.getItem("user");
    } else {
      return sessionStorage.getItem("user");
    }
  };

  //generic go back function
  goBack = (props) => {
    props.history.goBack();
  };

  editSearchTermDefault = (e, setState, list, listShowed, attributes) => {
    if (e.target.value != null && e.target.value != "") {
      setState({
        [listShowed]: this.filterFunction(list, attributes, e.target.value),
      });
    } else {
      setState({
        [listShowed]: list,
      });
    }
  };

  editSearchTermDefaultSearch = (e, setState, list, listShowed, attributes) => {
    if (e.target.value != null && e.target.value != "") {
      setState({
        [listShowed]: this.filterFunction(list, attributes, e.target.value),
      });
    } else {
      setState({
        [listShowed]: [],
      });
    }
  };

  filterFunction = (objects, attributes, value) => {
    var filteredObjects = [];
    var lowerCaseName = "";
    var flagMatches = false;
    for (const i in objects) {
      flagMatches = false;
      for (const j in attributes) {
        if (objects[i][attributes[j]] != null) {
          lowerCaseName = objects[i][attributes[j]].toLowerCase();
          if (lowerCaseName.includes(value.toLowerCase())) {
            flagMatches = true;
          }
        }
      }
      if (flagMatches) {
        filteredObjects.push(objects[i]);
      }
    }

    return filteredObjects;
  };

  turnDateToString = (dateISO) => {
    let newDate = null;
    if (dateISO != null) {
      newDate = new Date(dateISO);
    } else {
      newDate = "";
    }
    console.log(newDate);
    //only get Month and date (day)
    return newDate.toUTCString().slice(5, 11);
  };

  formatDateUS = (dateISO) => {
    let newDate = null;
    if (dateISO != null) {
      const auxDate = new Date(dateISO);
      newDate = auxDate.toLocaleDateString("en-us");
    } else {
      newDate = "";
    }
    return newDate;
  };

  formatDateUSWithTime = (dateISO) => {
    let newDate = null;
    if (dateISO != null) {
      const auxDate = new Date(dateISO);
      newDate = auxDate.toLocaleString("en-us");
    } else {
      newDate = "";
    }
    return newDate;
  };

  orderAlphabetically = (array, key) => {
    if (Array.isArray(array) && array.length > 0) {
      array.sort((a, b) => {
        const valueA = a[key].toUpperCase(); // Convert to uppercase for case-insensitive sorting
        const valueB = b[key].toUpperCase();

        if (valueA < valueB) {
          return -1;
        } else if (valueA > valueB) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return array;
  };

  removeObjectDuplicates = (array, key) => {
    let lookup = {};
    array.forEach((element) => {
      lookup[element[key]] = element;
    });
    return Object.keys(lookup).map((key) => lookup[key]);
  };

  formatNumber(num, decimals) {
    return num.toLocaleString("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }

  roundDecimals = (currency, value) => {
    if (value != null) {
      if (
        (currency != null &&
          currency != "" &&
          currency != "USD" &&
          currency != "USDT" &&
          currency != "USDC") ||
        (Number(value) < 1 && Number(value) > -1)
      ) {
        return this.formatNumber(value, 5);
      } else {
        return this.formatNumber(value, 2);
      }
    } else {
      return "";
    }
  };

  roundToXDecimals = (value, x) => {
    var numb = Number(value);
    numb = +numb.toFixed(x);
    //rounds only if neccesary
    return numb;
  };

  generatePDF = async (name, base64String, setState) => {
    try {
      // Decode the base64 string into a Uint8Array
      const pdfData = Uint8Array.from(atob(base64String), (c) =>
        c.charCodeAt(0)
      );

      // Load the PDF document
      const pdfDoc = await PDFDocument.load(pdfData);

      // Create a blob from the PDF document
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = name + ".pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Set the state to indicate the loading is complete
      setState({
        loadingDownload: false,
        pdfGeneratedSuccessfully: true,
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
      setState({
        loadingDownload: false,
        pdfGeneratedSuccessfully: false,
      });
    }
  };

  generatePDFFromReceipt = async (name, setState) => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      scale: 1.5,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("hidden-div").style.display = "block";
      },
    }).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
      setState({
        loadingDownload: false,
      });
      pdf.save(name + ".pdf");
    });
  };

  goToURL = (props, url) => {
    console.log("Pushing url: ", url);
    props.history.push({ pathname: url, megastate: { from: true } });
  };

  getResultTitle = (success) => {
    if (success) {
      return "good-news!";
    } else {
      return "something-went-wrong";
    }
  };

  formatJSONStringVariables = (text, variables) => {
    return text.replace(/%@/g, () => variables.shift());
  };

  decrypt(value, secretKey) {
    try {
      var bytes = CryptoJS.AES.decrypt(value, secretKey);
      var decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedValue;
    } catch (e) {
      console.error("Decryption failed for value:", value);
      return null;
    }
  }

  clearSession = (props, setAccountAndToken) => {
    //save remember email
    let rememberEmail = localStorage.getItem("remember-email")
      ? localStorage.getItem("remember-email")
      : "";
    localStorage.clear();
    if (rememberEmail != "") {
      localStorage.setItem("remember-email", rememberEmail);
    }

    //setAccountAndToken will redirect to login
    setAccountAndToken({}, "", 0);
  };

  getWhiteLabelText = (string, keyPart) => {
    if (
      process.env.REACT_APP_NEW_VENDOR_KEY &&
      process.env.REACT_APP_NEW_VENDOR_KEY != ""
    ) {
      let newString = string.replace(
        keyPart,
        process.env.REACT_APP_NEW_VENDOR_KEY
      );
      return newString;
    }
    return string;
  };

  conditionalPermission = (account, modules) => {
    return (
      process.env.REACT_APP_ENV == "local" ||
      (account &&
        account.roles &&
        account.roles.some(
          (role) => role.name && role.name.includes("Developer")
        )) ||
      (account.permissions &&
        modules.some((module) =>
          account.permissions.some((permission) => permission.includes(module))
        ))
    );
  };
}

export const utils = new Utils();
